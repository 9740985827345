/* eslint-disable react-hooks/exhaustive-deps */
import { useAppContext } from 'module/auth/context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionPermission } from 'shared/enum/action-permission.enum';
import AuthorizationService from 'shared/service/authorization.service';
import { useAuthContext } from '../auth/context/auth-context';

interface IProp {
    actionPermissionToPass: ActionPermission[];
    children: any;
}

export default function AdminGuard({ actionPermissionToPass, children }: IProp) {
    const navigate = useNavigate();
    const authContext = useAuthContext();
    const appContext = useAppContext();

    const getActionPermission = async () => {
        const result = await AuthorizationService.findOne(appContext.user?.roleName || '');
        authContext.setActionPermission(result.actions);
    };

    const matchRoles = (actions: string[], userActions: string[]) => {
        let result = false;
        userActions.forEach((element) => {
            if (actions.includes(element)) {
                result = true;
                return;
            }
        });
        return result;
    };

    useEffect(() => {
        if (appContext.user) {
            if (authContext.actionPermission.length === 0) {
                getActionPermission();
            } else if (!matchRoles(actionPermissionToPass, authContext.actionPermission)) {
                navigate('/forbidden');
            }
        } else {
            navigate('/login');
        }
    }, [authContext.hasToken, authContext.actionPermission]);

    return { ...children };
}
