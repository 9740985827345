export interface IProp {
    active?: boolean;
    activeColor?: string;
    defaultColor?: string;
}

const IconPartition = (props: IProp) => {
    const { active = false, activeColor, defaultColor } = props;
    const fill = active ? activeColor ?? '#638C1C' : defaultColor || '#474C55';
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_7_2)">
                <path
                    d="M14 8.725V8.225C14.138 8.225 14.25 8.11309 14.25 7.975V6.475C14.25 6.33691 14.138 6.225 14 6.225H12.65L12.6016 6.03721C12.491 5.60771 12.3193 5.19629 12.0915 4.81572L11.9915 4.64844L12.95 3.68828C13.0465 3.5916 13.0465 3.43496 12.95 3.33828L11.8883 2.275C11.7902 2.18154 11.6362 2.18154 11.5382 2.275L10.5779 3.23525L10.4107 3.13525C10.0296 2.90732 9.61777 2.73564 9.1877 2.625L9 2.57617V1.225C9 1.08691 8.88809 0.974999 8.75 0.974999H7.25C7.11192 0.974999 7 1.08691 7 1.225V2.575L6.81221 2.62353C6.38272 2.73398 5.97129 2.90566 5.59053 3.1332L5.42324 3.2333L4.46328 2.275C4.36514 2.18076 4.21016 2.18076 4.11201 2.275L3.05 3.33672C3.00332 3.3833 2.97705 3.44658 2.97705 3.5125C2.97705 3.57851 3.00332 3.6417 3.05 3.68828L4.01025 4.64844L3.91025 4.81572C3.68252 5.19629 3.51074 5.60771 3.40029 6.03721L3.35127 6.225H2C1.86191 6.225 1.75 6.33691 1.75 6.475V7.975C1.75 8.11309 1.86191 8.225 2 8.225V8.725C1.58584 8.725 1.25 8.38916 1.25 7.975V6.475C1.25 6.06084 1.58584 5.725 2 5.725H2.96729C3.07041 5.3793 3.20986 5.04551 3.3833 4.7293L2.6958 4.0418C2.55518 3.90146 2.47617 3.71103 2.47617 3.5125C2.47617 3.31396 2.55518 3.12353 2.6958 2.9833L3.7583 1.9208C4.0543 1.6375 4.521 1.6375 4.81699 1.9208L5.5043 2.60801C5.82051 2.43467 6.1544 2.29551 6.5 2.19248V1.225C6.5 0.810838 6.83584 0.474998 7.25 0.474998H8.75C9.16416 0.474998 9.5 0.810838 9.5 1.225V2.19248C9.8457 2.29551 10.1794 2.43496 10.4957 2.6083L11.1832 1.9208C11.4793 1.6375 11.9459 1.6375 12.242 1.9208L13.3043 2.98301C13.4448 3.12334 13.5237 3.31357 13.5237 3.51221C13.5237 3.71084 13.4448 3.90127 13.3043 4.0415L12.6168 4.729C12.7901 5.04541 12.9295 5.3793 13.0327 5.725H14C14.4142 5.725 14.75 6.06084 14.75 6.475V7.975C14.75 8.38916 14.4142 8.725 14 8.725Z"
                    fill={fill}
                />
                <path
                    d="M11.5 7.225H11C11 5.56816 9.65684 4.225 8 4.225C6.34316 4.225 5 5.56816 5 7.225H4.5C4.5 5.29199 6.06699 3.725 8 3.725C9.93291 3.725 11.5 5.29199 11.5 7.225Z"
                    fill={fill}
                />
                <path
                    d="M8 9.225C7.17158 9.225 6.5 8.55332 6.5 7.725C6.5 6.89658 7.17158 6.225 8 6.225C8.82852 6.225 9.5 6.89658 9.5 7.725C9.49912 8.55312 8.82813 9.22412 8 9.225ZM8 6.725C7.44775 6.725 7 7.17275 7 7.725C7 8.27724 7.44775 8.725 8 8.725C8.55225 8.725 9 8.27724 9 7.725C9 7.17275 8.55225 6.725 8 6.725Z"
                    fill={fill}
                />
                <path
                    d="M12.25 10.225C11.4216 10.225 10.75 9.55332 10.75 8.725C10.75 7.89658 11.4216 7.225 12.25 7.225C13.0784 7.225 13.75 7.89658 13.75 8.725C13.7491 9.55312 13.0781 10.2241 12.25 10.225ZM12.25 7.725C11.6978 7.725 11.25 8.17275 11.25 8.725C11.25 9.27724 11.6978 9.725 12.25 9.725C12.8022 9.725 13.25 9.27724 13.25 8.725C13.25 8.17275 12.8022 7.725 12.25 7.725Z"
                    fill={fill}
                />
                <path
                    d="M3.75 10.225C2.92158 10.225 2.25 9.55332 2.25 8.725C2.25 7.89658 2.92158 7.225 3.75 7.225C4.57852 7.225 5.25 7.89658 5.25 8.725C5.24912 9.55312 4.57813 10.2241 3.75 10.225ZM3.75 7.725C3.19775 7.725 2.75 8.17275 2.75 8.725C2.75 9.27724 3.19775 9.725 3.75 9.725C4.30225 9.725 4.75 9.27724 4.75 8.725C4.75 8.17275 4.30225 7.725 3.75 7.725Z"
                    fill={fill}
                />
                <path
                    d="M14.5164 10.8664C14.1438 10.6104 13.7022 10.4737 13.25 10.475H11.25C11.1284 10.4761 11.007 10.4871 10.8872 10.5079C10.7272 10.2577 10.5191 10.0419 10.275 9.87266C10.2384 9.84502 10.1999 9.81993 10.1598 9.79776C9.81006 9.58594 9.40879 9.47442 9 9.47501H7C6.23545 9.47227 5.52305 9.86241 5.11348 10.5079C4.99336 10.4871 4.87178 10.4761 4.75 10.475H2.75C2.29824 10.4737 1.85664 10.6102 1.48428 10.8659C0.868848 11.2847 0.500391 11.9807 0.5 12.725V13.725C0.500879 14.4076 0.898047 15.0275 1.51807 15.3135C1.74697 15.4213 1.99707 15.4765 2.25 15.475H4.75V14.975H2.5V12.225H2V14.9499C1.90586 14.9311 1.81445 14.9006 1.72773 14.8595C1.28486 14.6553 1.00098 14.2126 1 13.725V12.725C1.00059 12.1457 1.2876 11.6042 1.7665 11.2784C2.05586 11.0798 2.39893 10.974 2.75 10.975H4.75C4.79404 10.975 4.83652 10.9797 4.87949 10.9826C4.79492 11.2211 4.75107 11.4721 4.75 11.725V14.225C4.75088 14.915 5.31006 15.4741 6 15.475H6.75V11.475H6.25V14.975H6C5.58584 14.975 5.25 14.6392 5.25 14.225V11.725C5.25 10.7584 6.03359 9.97501 7 9.97501H9C9.32168 9.9752 9.63692 10.0643 9.91123 10.2322C9.93662 10.2461 9.96104 10.2617 9.98418 10.2789C10.2185 10.4394 10.4107 10.6537 10.5447 10.9039C10.6794 11.1566 10.75 11.4386 10.75 11.725V14.225C10.75 14.6392 10.4142 14.975 10 14.975H9.75V11.475H9.25V15.475H10C10.69 15.4741 11.2491 14.915 11.25 14.225V11.725C11.2488 11.4721 11.2052 11.2212 11.1207 10.9826C11.1638 10.9797 11.2063 10.975 11.25 10.975H13.25C13.6013 10.974 13.9446 11.0801 14.2342 11.2789C14.7127 11.6049 14.9993 12.146 15 12.725V13.725C14.9991 14.2144 14.7131 14.6583 14.2681 14.8614C14.1826 14.9019 14.0926 14.9315 14 14.9499V12.225H13.5V14.975H11.25V15.475H13.75C14.0015 15.4766 14.2501 15.4221 14.4777 15.3154C15.0998 15.0304 15.499 14.4093 15.5 13.725V12.725C15.4996 11.981 15.1314 11.2853 14.5164 10.8664V10.8664Z"
                    fill={fill}
                />
                <path d="M7 14.975H9V15.475H7V14.975Z" fill={fill} />
            </g>
            <defs>
                <clipPath id="clip0_7_2">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconPartition;
