/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import '../partition.scss';
import UserService from 'shared/service/user.service';
import { IUser } from 'shared/interface/user.interface';
import { buildFileView } from 'shared/service/file';
import { iconClose } from 'shared/assets/icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface IProps {
    type?: string;
    user: string;
    onRemoveSale?(): void;
}

function SaleCard(props: IProps) {
    const { t } = useTranslation();
    const { user = '', type = 'member', onRemoveSale } = props;
    const [userDetail, setUserDetail] = useState<IUser | undefined>();

    useEffect(() => {
        if (user) {
            getUserInfo();
        }
    }, [user]);

    const getUserInfo = async () => {
        if (user) {
            const result = await UserService.getUserInfo(user);
            if (result) {
                setUserDetail(result);
            }
        }
    };

    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('pending-approval')}
        </Tooltip>
    );

    return (
        <div className="container-fluid px-3 py-2 w-100 sale-card mt-2 rounded position-relative">
            <div className="d-flex gap-3 align-items-center">
                <span className="fs-6 uppercase">
                    {type === 'member' ? 'Sale' : 'Sale manager'}
                </span>
                {!userDetail?.allowAccessProgram && (
                    <OverlayTrigger
                        placement="left"
                        delay={{ show: 100, hide: 200 }}
                        overlay={renderTooltip}>
                        <i className="fa-solid fa-universal-access" style={{ color: 'red' }}></i>
                    </OverlayTrigger>
                )}
            </div>
            {type === 'member' && (
                <img
                    role="button"
                    onClick={() => onRemoveSale && onRemoveSale()}
                    alt=""
                    src={iconClose}
                    style={{ width: '1.25rem', height: '1.25rem' }}
                    className="position-absolute top-0 end-0"
                />
            )}

            <div className="d-flex flex-wrap w-100 gx-3 gy-3 w-100 align-items-center">
                <img
                    alt=""
                    src={buildFileView(userDetail?.avatar)}
                    style={{ width: '2rem', height: '2rem', objectFit: 'cover' }}
                    className="rounded-circle me-1"
                />
                <span className="fs-6">{userDetail?.name}</span>
            </div>
        </div>
    );
}

export default SaleCard;
