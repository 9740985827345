/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { ICompany } from 'shared/interface/company.interface';
import UserService from 'shared/service/user.service';
import { CompanyService } from './service/company.service';

interface IProp {
    show: boolean;
    company?: ICompany;
    onHide: (data: ICompany | null) => void;
}

export default function EditCompany(prop: IProp) {
    const { show, company } = prop;
    const { t } = useTranslation();
    const [validated, setValidated] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [director, setDirector] = useState<{ value: string; label: string }>();

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity()) {
            let res: ICompany | null = null;
            if (company) {
                // @ts-ignore
                window.gtag('event', 'EDIT_COMPANY', {});
                res = await CompanyService.update(company._id, {
                    name,
                    code,
                    director: director?.value,
                });

            } else {
                // @ts-ignore
                window.gtag('event', 'CREATE_COMPANY', {});
                res = await CompanyService.create({ name, code, director: director?.value });
            }
            if (res) {
                toast.success(t('successfully'), { position: 'bottom-center' });
                reset();
                prop.onHide(res);
            }
        }
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<{ label: string; value: string }[]>((resolve) => {
            setTimeout(async () => {
                const res = await UserService.search(inputValue);
                resolve(
                    res.map((u) => {
                        return {
                            label: u.name,
                            value: u._id,
                        };
                    })
                );
            }, 300);
        });

    const initCompany = () => {
        setName(company ? company.name : '');
        setCode(company ? company.code : '');
    };

    const getUsers = async (id: string) => {
        const res = await UserService.getUserInfo(id);
        const mapped = {
            label: res.name,
            value: res._id,
        };
        if (company && company.director) {
            setDirector({ value: res._id, label: res.name });
        }
        return mapped;
    };

    const reset = () => {
        setName('');
        setCode('');
    };

    useEffect(() => {
        initCompany();
        if (company?.director) {
            getUsers(company.director);
        }
    }, [company]);

    return (
        <Modal show={show} dialogClassName="max-width-800" fullscreen="sm-down">
            <Modal.Header className="flex-column">
                <div className="d-flex justify-content-end w-100">
                    <button
                        className="btn btn-icon border-0 rounded-circle"
                        onClick={() => {
                            prop.onHide(null);
                        }}
                    >
                        <i className="fa-solid fa-xmark text-primary"/>
                    </button>
                </div>
                <div className="d-flex justify-content-center">
                    <span className="fs-3 fw-semibold">
                        {company ? t('edit-info') : t('add-company')}
                    </span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} noValidate={true} validated={validated}>
                    <div className="row">
                        <Form.Group as={Col} sm="12">
                            <Form.Label>{t('name')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Control
                                    type="text"
                                    className="text-gray"
                                    required={true}
                                    placeholder={t('name')}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('required-field')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="row mt-2">
                        <Form.Group as={Col} sm="12">
                            <Form.Label>{t('user-code')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Control
                                    type="text"
                                    className="text-gray"
                                    required={true}
                                    placeholder={t('user-code')}
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('required-field')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="row">
                        <Form.Group as={Col} sm="12" className="mt-2">
                            <Form.Label>{t('director')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <AsyncSelect
                                    className="w-100"
                                    cacheOptions={true}
                                    isClearable={true}
                                    value={director ? director : undefined}
                                    onChange={(newValue) =>
                                        setDirector(
                                            newValue
                                                ? {
                                                      label: newValue?.label,
                                                      value: newValue?.value,
                                                  }
                                                : undefined
                                        )
                                    }
                                    defaultOptions={director ? [director] : []}
                                    loadOptions={promiseOptions}
                                    isMulti={false}
                                />
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <button type="submit" className="btn btn-primary w-50">
                            {t('finish')}
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
