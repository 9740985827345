import React from 'react';
import { useTranslation } from 'react-i18next';
import { IFarm } from 'shared/interface/farm.interface';
import { buildFileView } from 'shared/service/file';
import { iconAddress } from 'shared/assets/icons';
interface IProps {
    farm: IFarm;
    onPress(): void;
}

function FarmCard(props: IProps) {
    const { farm, onPress } = props;
    const { t } = useTranslation();

    return (
        <div className="w-100 bg-secondary rounded">
            <div className="d-flex w-100 position-relative">
                <img
                    alt=""
                    src={buildFileView(farm.cover)}
                    className="rounded-top"
                    style={{
                        objectFit: 'cover',
                        height: '124px',
                        width: '100%',
                    }}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = '/image/picture/default.jpeg';
                    }}
                />
                <div className="position-absolute" style={{ left: '12px', top: '96px' }}>
                    <img
                        alt=""
                        src={buildFileView(farm.avatar)}
                        style={{ width: 56, height: 56, objectFit: 'cover' }}
                        className="rounded-circle"
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/image/picture/default.jpeg';
                        }}
                    />
                </div>
            </div>
            <div className="d-flex w-100 mt-5 px-2">
                <span className="farm-name">{farm.title}</span>
            </div>
            <div className="d-flex w-100 pb-2 align-items-center px-2">
                <img alt="" src={iconAddress} className="me-2" />
                <span className="farm-address">{farm.location}</span>
            </div>
            <div className="d-flex w-100 pb-2 align-items-center px-2 pb-2">
                <button
                    className="btn btn-primary w-100 rounded-1"
                    onClick={() => onPress && onPress()}
                >
                    {t('view-detail')}
                </button>
            </div>
        </div>
    );
}

export default FarmCard;
