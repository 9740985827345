/* eslint-disable react-hooks/exhaustive-deps */
import SingleQuestion from 'module/client/question/evaluate/single-question/single-question';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    IBioReportQuestion,
    ICalculatedFinalResult,
    IBioReportListQuestion,
    IBioReportViolatedListQuestion,
    IBioReportComparationQuestion,
} from 'shared/interface/bio-report.interface';
import { IUser } from 'shared/interface/user.interface';
import { compareViolatedQuestion } from 'shared/util/bio-report';
interface IProp {
    finalResult1?: ICalculatedFinalResult;
    finalResult2?: ICalculatedFinalResult;
    finalResult3?: ICalculatedFinalResult;
    performer1?: IUser;
    performer2?: IUser;
    performer3?: IUser;
}

export default function ViolatedCompare(prop: IProp) {
    const { finalResult1, finalResult2, finalResult3 } = prop;

    const { t } = useTranslation();

    const [groupViolatedQuestions, setGroupViolatedQuestions] = useState<
        IBioReportViolatedListQuestion[]
    >([]);

    useEffect(() => {
        const ret = compareViolatedQuestion([
            finalResult1?.bioReport,
            finalResult2?.bioReport,
            finalResult3?.bioReport,
        ]);
        if (ret) {
            setGroupViolatedQuestions(ret);
        }
    }, [finalResult1, finalResult2, finalResult3]);

    return (
        <div className="row mt-3 py-3 div-shadow">
            <h3>{t('violated-point')}</h3>
            <div className="table-responsive">
                <table className="table table-hover table-bordered">
                    <thead>
                        <tr className="bg-secondary align-middle">
                            <th scope="col">
                                <span className="text-uppercase">
                                    {t('total-violated-point-got')}
                                </span>
                            </th>
                            {finalResult1?.bioReport && (
                                <th scope="col">
                                    <div className="d-flex gap-3">
                                        <span className="my-auto">{t('report')} 1</span>
                                    </div>
                                </th>
                            )}
                            {finalResult2?.bioReport && (
                                <th scope="col">
                                    <div className="d-flex gap-3">
                                        <span className="my-auto">{t('report')} 2</span>
                                    </div>
                                </th>
                            )}
                            {finalResult3?.bioReport && (
                                <th scope="col">
                                    <div className="d-flex gap-3">
                                        <span className="my-auto">{t('report')} 3</span>
                                    </div>
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {groupViolatedQuestions?.map((list, index) => {
                            if (
                                !list?.groupViolatedQuestions?.length &&
                                !list?.violatedQuestions?.length
                            ) {
                                return null;
                            }
                            return (
                                <ViolatedQuestionListCompare
                                    listQuestion={list}
                                    key={index}
                                    showCol1={finalResult1?.bioReport ? true : false}
                                    showCol2={finalResult2?.bioReport ? true : false}
                                    showCol3={finalResult3?.bioReport ? true : false}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

interface IViolatedQuestionListCompareProps {
    listQuestion: IBioReportViolatedListQuestion;
    showCol1: boolean;
    showCol2: boolean;
    showCol3: boolean;
}

function ViolatedQuestionListCompare({
    listQuestion,
    showCol1 = false,
    showCol2 = false,
    showCol3 = false,
}: IViolatedQuestionListCompareProps) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <tr className="align-middle">
                <td colSpan={1}>
                    <span className="fs-4">{listQuestion?.groupTitle}</span>
                </td>
                <td colSpan={3}>
                    <div className="d-flex align-middle justify-content-center">
                        <button
                            className="btn btn-outline-secondary rounded-circle border-0 py-0"
                            onClick={() => setOpen((prev) => !prev)}
                        >
                            {open ? (

                                <i className="fa-solid fa-caret-up"/>
                            ) : (

                                <i className="fa-solid fa-caret-down"/>
                            )}
                        </button>
                    </div>
                </td>
            </tr>
            {open && (
                <>
                    {!listQuestion.isGroupStyle ? (
                        <>
                            {listQuestion?.violatedQuestions?.map((question, index) => {
                                return (
                                    <SingleQuestionCompare
                                        question={question}
                                        key={index}
                                        showCol1={showCol1}
                                        showCol2={showCol2}
                                        showCol3={showCol3}
                                    />
                                );
                            })}
                        </>
                    ) : (
                        <>
                            {listQuestion.groupViolatedQuestions?.map((groupQuestion, index) => {
                                if (groupQuestion.questions.length > 0) {
                                    return (
                                        <GroupQuestionCompare
                                            groupQuestion={groupQuestion}
                                            showCol1={showCol1}
                                            showCol2={showCol2}
                                            showCol3={showCol3}
                                            key={index}
                                        />
                                    );
                                }
                                return null;
                            })}
                        </>
                    )}
                </>
            )}
        </>
    );
}

interface IGroupQuestionCompareProps {
    groupQuestion: {
        isGroupStyle: boolean;
        groupTitle: string;
        yesAnswerTitle: string;
        noAnswerTitle: string;
        questions: IBioReportComparationQuestion[];
        point?: number;
        totalPoint?: number;
        percentage?: number;
    };
    showCol1: boolean;
    showCol2: boolean;
    showCol3: boolean;
}

const GroupQuestionCompare = ({
    groupQuestion,
    showCol1 = false,
    showCol2 = false,
    showCol3 = false,
}: IGroupQuestionCompareProps) => {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <>
            <tr className="align-middle">
                <td colSpan={1}>
                    <span className="fs-5 ms-3">{groupQuestion?.groupTitle}</span>
                </td>
                <td colSpan={3}>
                    <div className="d-flex align-middle justify-content-center">
                        <button
                            className="btn btn-outline-secondary rounded-circle border-0 py-0"
                            onClick={() => setOpen((prev) => !prev)}
                        >
                            {open ? (

                                <i className="fa-solid fa-caret-up"/>
                            ) : (

                                <i className="fa-solid fa-caret-down"/>
                            )}
                        </button>
                    </div>
                </td>
            </tr>
            {open && (
                <>
                    {groupQuestion.questions.map((question, index) => {
                        return (
                            <SingleQuestionCompare
                                question={question}
                                key={index}
                                showCol1={showCol1}
                                showCol2={showCol2}
                                showCol3={showCol3}
                            />
                        );
                    })}
                </>
            )}
        </>
    );
};

interface ISingleQuestionCompareProps {
    question: IBioReportComparationQuestion;
    showCol1: boolean;
    showCol2: boolean;
    showCol3: boolean;
}

const SingleQuestionCompare = ({
    question,
    showCol1 = false,
    showCol2 = false,
    showCol3 = false,
}: ISingleQuestionCompareProps) => {
    return (
        <tr className="align-middle bg-white">
            <td>{question.title}</td>
            {showCol1 && (
                <td>
                    <div className="my-auto text-center">
                        {question.ans0 && (

                            <i className="fa-solid fa-circle-check text-success fs-4"/>
                        )}
                        {!question.ans0 && (

                            <i className="fa-solid fa-circle-xmark text-danger fs-4"/>
                        )}
                    </div>
                </td>
            )}
            {showCol2 && (
                <td>
                    <div className="my-auto text-center">
                        {question.ans1 && (

                            <i className="fa-solid fa-circle-check text-success fs-4"/>
                        )}
                        {!question.ans1 && (

                            <i className="fa-solid fa-circle-xmark text-danger fs-4"/>
                        )}
                    </div>
                </td>
            )}
            {showCol3 && (
                <td>
                    <div className="my-auto text-center">
                        {question.ans2 && (

                            <i className="fa-solid fa-circle-check text-success fs-4"/>
                        )}
                        {!question.ans2 && (

                            <i className="fa-solid fa-circle-xmark text-danger fs-4"/>
                        )}
                    </div>
                </td>
            )}
        </tr>
    );
};
