import { IBioReportRetail } from 'shared/interface/bio-report-retail.interface';
import api from './api';
import { toast } from 'react-toastify';
import { RiskLevel } from 'shared/enum/bio-report.enum';
import { IPagination } from 'shared/interface/base.interface';

const url = 'bio-report-retail';

export const BioReportRetailService = {
    initialize: async (farmId: string, questionSet: string): Promise<IBioReportRetail> => {
        const res = await api.post(`${url}/initialize`, { farmId, questionSet });
        return res.data;
    },

    getReportByFarmId: async (
        farmId: string[],
        from?: Date,
        to?: Date,
        owningUsers?: string[],
        riskLevels?: RiskLevel[],
        questionSet?: string[],
        page: number = 1,
        limit: number = 999999
    ): Promise<IPagination<IBioReportRetail>> => {
        const additionalParams = {};
        if (from && to) {
            additionalParams['from'] = from.toISOString();
            additionalParams['to'] = to.toISOString();
        }
        if (owningUsers?.length) {
            additionalParams['owningUsers'] = owningUsers;
        }
        if (riskLevels?.length) {
            additionalParams['riskLevels'] = riskLevels;
        }
        if (questionSet) {
            additionalParams['questionSet'] = questionSet;
        }
        const res = await api.get(`${url}`, {
            params: {
                farmId,
                ...additionalParams,
                page,
                limit,
            },
        });
        return res.data;
    },

    update: async (
        id: string,
        dto?: IBioReportRetail,
        isDone?: boolean
    ): Promise<IBioReportRetail> => {
        const extraDto = {};
        if (isDone) {
            extraDto['isDone'] = isDone;
        }
        if (id) {
            const res = await api.patch(`${url}/${id}`, { ...dto, ...extraDto });
            return res.data;
        }

        return {} as any;
    },

    findById: async (id: string): Promise<IBioReportRetail> => {
        const res = await api.get(`${url}/${id}`);
        return res.data;
    },

    findPerformers: async (farmId: string, from: Date, to: Date) => {
        const res = await api.get(`${url}/performers`, {
            params: {
                farmId,
                from: from.toISOString(),
                to: to.toISOString(),
            },
        });
        return res.data;
    },

    findByFarmPerformer: async (
        farmId: string,
        userId: string,
        from: Date,
        to: Date,
        questionSet?: string
    ): Promise<IBioReportRetail[]> => {
        const res = await api.get(`${url}/report-by-farm-performer`, {
            params: {
                farmId,
                userId,
                from: from.toISOString(),
                to: to.toISOString(),
                questionSet,
            },
        });

        return res.data;
    },

    delete: async (id: string) => {
        try {
            const res = await api.delete(`${url}/${id}`);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    },
};
