import { RiskLevel } from 'shared/enum/bio-report.enum';
import { IBioReport, IDashboardReport } from 'shared/interface/bio-report.interface';
import api from 'shared/service/api';

const url = 'admin-dashboard-report';

export default class AdminDashboardService {
    static async findAll(
        from: Date,
        to: Date,
        owningCompanies?: string[],
        owningUsers?: string[]
    ): Promise<IDashboardReport> {
        const params = {};
        if (owningCompanies && owningCompanies.length) {
            params['owningCompanies'] = owningCompanies;
        }
        if (owningUsers && owningUsers.length) {
            params['owningUsers'] = owningUsers;
        }
        const response = await api.get(url, {
            params: {
                from: from.toISOString(),
                to: to.toISOString(),
                ...params,
            },
        });
        return response.data;
    }

    static async findWithAnalytic(
        from: Date,
        to: Date,
        owningCompanies?: string[],
        owningUsers?: string[],
        questionId?: string,
        answer?: boolean,
        riskLevel?: RiskLevel,
        position?: number,
    ): Promise<IBioReport[]> {
        const params = {};
        if (owningCompanies && owningCompanies.length) {
            params['owningCompanies'] = owningCompanies;
        }
        if (owningUsers && owningUsers.length) {
            params['owningUsers'] = owningUsers;
        }
        if (questionId && answer !== undefined) {
            params['questionId'] = questionId;
            params['answer'] = answer;
        }
        if (riskLevel && position) {
            params['riskLevel'] = riskLevel;
            params['position'] = position;
        }
        const response = await api.get(`${url}/with-analytic`, {
            params: {
                from: from.toISOString(),
                to: to.toISOString(),
                ...params,
            },
        });
        return response.data;
    }

    static async getExcel(
        from: Date,
        to: Date,
        owningCompanies?: string[],
        owningUsers?: string[]
    ) {
        const params = {};
        if (owningCompanies && owningCompanies.length) {
            params['owningCompanies'] = owningCompanies;
        }
        if (owningUsers && owningUsers.length) {
            params['owningUsers'] = owningUsers;
        }
        const response = await api.get(`${url}/excel`, {
            params: {
                from: from.toISOString(),
                to: to.toISOString(),
                ...params,
            },
            responseType: 'blob',
        });
        return response.data;
    }
}
