/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { LocalStoreName } from './shared/enum/local-store.enum';
import NotFound from './module/public/not-found/not-found';
import { useAuthContext, useAppContext } from './module/auth/context';
import { AdminRoutes, ClientRoutes, PublicRoutes } from './config/endPoint/route';
import ClientGuard from './module/client/client-guard';
import { Sidebar } from './shared/components';
import LoadingSpinner from './shared/components/loading-spinner/loading-spinner';
import { jwtDecode } from 'jwt-decode';
import { ITokenPayload } from 'shared/interface/auth.interface';
import SidebarAdmin from 'shared/components/sidebar/sidebar-admin';
import AdminGuard from 'module/admin/admin-guard';
import Forbidden from 'module/public/forbidden/forbidden';
import usePushNotifications from 'module/webPush/usePushNotifications';
import { isMobile } from 'react-device-detect';
import VersionService from 'shared/service/version.service';
import appInfo from '../package.json';

function App() {
    const authContext = useAuthContext();
    const appContext = useAppContext();
    if (!isMobile) {
        const { onClickAskUserPermission } = usePushNotifications();
        useEffect(() => {
            if (authContext.hasToken) {
                onClickAskUserPermission();
            }
        }, [authContext.hasToken]);
    }

    useEffect(() => {
        const token = localStorage.getItem(LocalStoreName.TOKEN) || null;
        if (token && !authContext.hasToken) {
            authContext.setHasToken(true);
            authContext.setDisableNavBar(false);
            const decodeToken = jwtDecode<ITokenPayload>(token);
            appContext.setUser(decodeToken);
        }
    }, [authContext.hasToken]);

    useEffect(() => {
        checkLatestVersion();
    }, []);

    const checkLatestVersion = async () => {
        const version = await VersionService.getLatestVersion();
        if (version !== appInfo.version) {
            // clear cache
            caches.keys().then(function (names: string[]) {
                for (const name of names) { caches.delete(name); }
            });
            window.location.reload();
        }
    };

    return (
        <div className="app">
            <BrowserRouter>
                {/* <Sidebar route={ClientRoutes} /> */}
                <Routes>
                    <Route path="*" element={<NotFound />} />
                    <Route path="/" element={<Navigate to="login" />} />
                    <Route path="/forbidden" element={<Forbidden />} />
                    {PublicRoutes.length > 0 &&
                        PublicRoutes.map((route) => {
                            return (
                                <Route key={route.path} path={route.path} element={route.element} />
                            );
                        })}
                    {ClientRoutes.length > 0 && appContext.user && (
                        <Route element={<Sidebar route={ClientRoutes} />}>
                            {ClientRoutes.map((route) => {
                                return (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        element={<ClientGuard>{route.element}</ClientGuard>}
                                    />
                                );
                            })}
                        </Route>
                    )}
                    {AdminRoutes.length > 0 && appContext.user && (
                        <Route element={<SidebarAdmin route={AdminRoutes} />}>
                            {AdminRoutes.map((route) => {
                                return (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        element={
                                            <AdminGuard
                                                actionPermissionToPass={
                                                    route.actionPermissionToPass || []
                                                }
                                            >
                                                {route.element}
                                            </AdminGuard>
                                        }
                                    />
                                );
                            })}
                        </Route>
                    )}
                </Routes>
            </BrowserRouter>
            <LoadingSpinner loading={authContext.loading} />
        </div>
    );
}

export default App;
