/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { subMonths } from 'date-fns';
import DatePicker, { registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import enUS from 'date-fns/locale/en-US';
import vi from 'date-fns/locale/vi';
import { useAppContext, useAuthContext } from 'module/auth/context';

import { Col, Form, InputGroup } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { CompanyService } from '../company-management/service/company.service';
import UserService from 'shared/service/user.service';
import { AdminDashboardAnalyticFilter } from './report-list-dashboard-admin-retail';
import { FilterStore } from 'shared/enum/local-store.enum';
import AdminDashboardRetailService from './service/admin-dashboard-retail.service';
import { IDashboardReportRetail } from 'shared/interface/bio-report-retail.interface';
import ReportListDashboardRetailAdmin from './report-list-dashboard-admin-retail';
import ReportAnalysisRetail from './report-analysis/report-analysis-retail';
import ReportCountCalculateRetail from './report-count-calculate/report-count-calculate';
import ReportQuestionAnalysisRetail from './report-question-analysis/report-question-analysis';

registerLocale('en-US', enUS);
registerLocale('vi', vi);

export default function AdminDashboardRetail() {
    const { t, i18n } = useTranslation();
    const [dashboardReport, setDashboardReport] = useState<IDashboardReportRetail>();
    const [from, setFrom] = useState<Date>(subMonths(new Date(), 1));
    const [to, setTo] = useState<Date>(new Date());
    const [owningCompany, setOwningCompany] = useState<{ value: string; label: string }[]>([]);
    const [owningUser, setOwningUser] = useState<{ value: string; label: string }[]>([]);
    const [showReportList, setShowReportList] = useState<boolean>(false);
    const [analyticFilter, setAnalyticFilter] = useState<AdminDashboardAnalyticFilter>({
        from,
        to,
        owningCompanies: owningCompany.map((o) => o.value),
        owningUsers: owningUser.map((o) => o.value),
    });
    const [init, setInit] = useState<boolean>(true);

    const authContext = useAuthContext();
    const appContext = useAppContext();

    const filterRef = useRef({
        owningCompany,
        owningUser,
        from,
        to,
    });

    const getDashboard = async () => {
        authContext.setLoading(true);
        const res = await AdminDashboardRetailService.findAll(
            from,
            to,
            owningCompany.map((c) => c.value),
            owningUser.map((c) => c.value)
        );
        setDashboardReport(res);
        authContext.setLoading(false);

        filterRef.current = {
            owningCompany,
            owningUser,
            from,
            to,
        };
    };

    useEffect(() => {
        // @ts-ignore
        window.gtag('event', 'GO_TO_DASHBOARD_ADMIN_RETAIL', { name: appContext.user?.name });
        getFilter();
        return () => {
            setFilter();
        };
    }, []);

    useEffect(() => {
        if (!init) {
            getDashboard();
        }
    }, [init]);

    const getFilter = () => {
        // get filter from local storage
        const rawFilter = localStorage.getItem(FilterStore.ADMIN_DASHBOARD_FILTER);
        if (rawFilter) {
            const convertedFilter = JSON.parse(rawFilter);
            if (convertedFilter.owningCompany) {
                setOwningCompany(convertedFilter.owningCompany);
            }
            if (convertedFilter.owningUser) {
                setOwningUser(convertedFilter.owningUser);
            }
            if (convertedFilter.from) {
                setFrom(new Date(convertedFilter.from));
            }
            if (convertedFilter.to) {
                setTo(new Date(convertedFilter.to));
            }
        }
        setInit(false);
    };

    const setFilter = () => {
        localStorage.setItem(FilterStore.ADMIN_DASHBOARD_FILTER, JSON.stringify(filterRef.current));
    };

    const promiseCompanyOptions = (inputValue: string) =>
        new Promise<{ label: string; value: string }[]>((resolve) => {
            setTimeout(async () => {
                const res = await CompanyService.find(1, 999, inputValue);
                resolve(
                    res.docs.map((u) => {
                        return {
                            label: u.name,
                            value: u._id,
                        };
                    })
                );
            }, 300);
        });

    const promiseUserOptions = (inputValue: string) =>
        new Promise<{ label: string; value: string }[]>((resolve) => {
            setTimeout(async () => {
                const res = await UserService.search(inputValue);
                resolve(
                    res.map((u) => {
                        return {
                            label: u.name,
                            value: u._id,
                        };
                    })
                );
            }, 300);
        });

    const downloadExcel = async () => {
        // @ts-ignore
        window.gtag('event', 'DOWNLOAD_EXCEL_DASHBOARD_ADMIN', {});
        const response = await AdminDashboardRetailService.getExcel(
            from,
            to,
            owningCompany.map((o) => o.value),
            owningUser.map((o) => o.value)
        );
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Report.xlsx');
        document.body.appendChild(link);
        link.click();
    };

    return (
        <>
            <div className="container-fluid">
                <div className="d-flex mt-3">
                    <span className="fs-3 text-primary fw-bold">{t('dashboard')}</span>
                </div>
                <div className="div-shadow rounded p-3">
                    <div className="row my-3">
                        <div className="col-12 col-sm-4">
                            <div className="input-group flex-nowrap">
                                <span className="input-group-text">{t('from-date')}</span>
                                <DatePicker
                                    locale={i18n.language}
                                    dateFormat={
                                        i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                    }
                                    className="form-control"
                                    selected={from}
                                    onChange={(date: Date) => setFrom(date)}
                                />
                            </div>
                        </div>

                        <div className="col-12 col-sm-4">
                            <div className="input-group flex-nowrap">
                                <span className="input-group-text">{t('to-date')}</span>
                                <DatePicker
                                    locale={i18n.language}
                                    dateFormat={
                                        i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                    }
                                    className="form-control"
                                    selected={to}
                                    onChange={(date: Date) => setTo(date)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <Form.Group as={Col} sm="4">
                            <Form.Label>{t('customer')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <AsyncSelect
                                    className="w-100"
                                    cacheOptions={true}
                                    isClearable={true}
                                    placeholder={t('customer')}
                                    value={owningCompany ? owningCompany : undefined}
                                    onChange={(newValue) => {
                                        const companies = newValue.map((v) => ({
                                            label: v.label,
                                            value: v.value,
                                        }));
                                        setOwningCompany(companies);
                                    }}
                                    defaultOptions={owningCompany ? owningCompany : []}
                                    loadOptions={promiseCompanyOptions}
                                    isMulti={true}
                                />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} sm="4">
                            <Form.Label>{t('incharge-people')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <AsyncSelect
                                    className="w-100"
                                    cacheOptions={true}
                                    isClearable={true}
                                    placeholder={t('incharge-people')}
                                    value={owningUser ? owningUser : undefined}
                                    onChange={(newValue) => {
                                        const users = newValue.map((v) => ({
                                            label: v.label,
                                            value: v.value,
                                        }));
                                        setOwningUser(users);
                                    }}
                                    defaultOptions={owningUser ? owningUser : []}
                                    loadOptions={promiseUserOptions}
                                    isMulti={true}
                                />
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="d-flex justify-content-end mb-2">
                        <div>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    // @ts-ignore
                                    window.gtag('event', 'SEARCH_IN_DASHBOARD_ADMIN', {});
                                    getDashboard();
                                }}>
                                {t('search')}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end my-2">
                    <button
                        className="btn btn-outline btn-outline-primary"
                        onClick={() => downloadExcel()}>
                        {t('download-excel')}
                    </button>
                </div>
                <div className="row g-0">
                    <div className="col-12 col-sm-6 div-shadow p-2 rounded">
                        <ReportAnalysisRetail
                            dashboardReport={dashboardReport}
                            setReportWithAnalyticFilter={(filter) => {
                                setAnalyticFilter({
                                    ...filter,
                                    from,
                                    to,
                                    owningCompanies: owningCompany.map((o) => o.value),
                                    owningUsers: owningUser.map((o) => o.value),
                                });
                                setTimeout(() => {
                                    setShowReportList(true);
                                }, 300);
                            }}
                        />
                    </div>
                    <div className="col-12 col-sm-6 div-shadow p-2 rounded">
                        <ReportCountCalculateRetail
                            dashboardReport={dashboardReport}
                        />
                    </div>
                </div>
                <div className="row g-0 mt-3">
                    <div className="col-12 div-shadow p-2 rounded">
                        <ReportQuestionAnalysisRetail
                            dashboardReport={dashboardReport}
                            setReportWithAnalyticFilter={(filter) => {
                                setAnalyticFilter({
                                    ...filter,
                                    from,
                                    to,
                                    owningCompanies: owningCompany.map((o) => o.value),
                                    owningUsers: owningUser.map((o) => o.value),
                                });
                                setTimeout(() => {
                                    setShowReportList(true);
                                }, 300);
                            }}
                        />
                    </div>
                </div>
            </div>
            <ReportListDashboardRetailAdmin
                show={showReportList}
                onHide={() => setShowReportList(false)}
                filter={analyticFilter}
            />
        </>
    );
}
