/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './farms.scss';
import FarmService from './service/farm.service';
import { IFarm, IGroupFarms } from 'shared/interface/farm.interface';
import { useNavigate } from 'react-router-dom';
import { FarmCard } from 'shared/components';
import { groupBy } from 'lodash';
import Company from './components/company';

export default function Farms() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [groupedFarms, setGroupFarms] = useState<IGroupFarms[]>([]);

    useEffect(() => {
        // @ts-ignore
        window.gtag('event', 'GO_TO_FARM_LIST_CLIENT', {});
        getFarms();
    }, []);

    const getFarms = async () => {
        const resultFarms = await FarmService.findMyFarms();
        const grouped = groupBy(resultFarms.docs, 'owningCompany');
        const result: IGroupFarms[] = [];
        for (const [key, value] of Object.entries(grouped)) {
            result.push({
                companyId: key,
                farms: value as unknown as IFarm[],
            });
        }
        setGroupFarms(result);
    };

    return (
        <div className="w-100 d-flex g-0">
            <div className="w-100 col-sm-8 p-3">
                <div className="d-flex">
                    <span className="title ms-2">{t('farms')}</span>
                </div>
                {groupedFarms.map((group) => (
                    <div key={group.companyId} className="mt-3 div-shadow p-3">
                        <div className="d-flex bg-white p-2">
                            <Company id={group.companyId} />
                        </div>
                        <div className="row flex-wrap mt-3 w-100 gx-3 gy-3">
                            {group.farms.map((farm, index) => {
                                return (
                                    <div key={index} className="col-lg-3 col-md-4">
                                        <FarmCard
                                            farm={farm}
                                            onPress={() => navigate(`/farms/${farm._id}`)}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
