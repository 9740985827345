import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TopicService from './service/topic.service';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18n';
import { buildFileView } from 'shared/service/file';
import { ITopic } from 'shared/interface/topic.interface';
import { logo } from 'shared/assets/images';
import { iconClock } from 'shared/assets/icons';
import { format } from 'date-fns';

export default function Topic() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [newsList, setNewsList] = useState<ITopic[]>([]);
    useEffect(() => {
        getNewsList();
    }, []);

    const getNewsList = async () => {
        const result = await TopicService.find(1, 100);
        if (result && result.docs) {
            setNewsList(result.docs);
        }
    };

    return (
        <div className="container-fluid ">
            <div className="row justify-content-center h-100">
                <div
                    className="d-flex w-100 justify-content-start py-2"
                    style={{ backgroundColor: 'rgba(0, 131, 60, 0.1)' }}
                >
                    <img
                        alt="logo"
                        className="clickable"
                        onClick={() => navigate('/dashboard')}
                        src={logo}
                        style={{ height: 32 }}
                    />
                </div>
                <div
                    className="d-flex mt-4 flex-column h-100 justify-content-between"
                    style={{ maxWidth: 853 }}
                >
                    <div className="d-flex">
                        <span className="title ms-2">{t('news')}</span>
                    </div>
                    {newsList.map((topic, index) => {
                        return (
                            <div
                                key={index}
                                className="col-12 rounded main-news-box p-2 clickable"
                                onClick={() => navigate(`/news/${topic._id}`)}
                            >
                                <div className="row h-100 rounded border gx-0">
                                    <div className="col-12 col-sm-4">
                                        <img
                                            alt=""
                                            className="theme-picture rounded-start"
                                            src={buildFileView(topic?.image)}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = '/image/picture/default.jpeg';
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-8 px-3 py-3 d-flex flex-column justify-content-between">
                                        <div>
                                            <div className="row">
                                                <span className="main-news-title">
                                                    {topic?.title}
                                                </span>
                                            </div>
                                            <div className="row">
                                                <span className="main-news-detail">
                                                    {topic?.previewContent}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="me-1">
                                                <img
                                                    alt="clock"
                                                    style={{
                                                        width: '1.25rem',
                                                        height: '1.25rem',
                                                    }}
                                                    src={iconClock}
                                                />
                                            </div>
                                            <div>
                                                <span className="create-at">
                                                    {' '}
                                                    {topic &&
                                                        format(
                                                            new Date(topic?.createdAt),
                                                            i18n.language === 'vi'
                                                                ? 'dd/MM/yyyy'
                                                                : 'MM/dd/yyyy'
                                                        )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
