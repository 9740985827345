import { toast } from 'react-toastify';
import {
    CreateAuthorizationDto,
    IAuthorization,
    IAuthorizationPagination,
} from 'shared/interface/authorization.interface';
import api from './api';

const url = '/authorization';

export default class AuthorizationService {
    static async findOne(idOrName: string): Promise<IAuthorization> {
        try {
            const res = await api.get(`${url}/${idOrName}`);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async create(dto: CreateAuthorizationDto): Promise<IAuthorization> {
        try {
            const res = await api.post(`${url}`, dto);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async update(id: string, dto: CreateAuthorizationDto): Promise<IAuthorization> {
        try {
            const res = await api.patch(`${url}/${id}`, dto);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async findAll(): Promise<IAuthorizationPagination> {
        try {
            const res = await api.get(`${url}`, {
                params: {
                    page: 1,
                    limit: 100,
                },
            });
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async delete(id: string): Promise<IAuthorization> {
        try {
            const res = await api.delete(`${url}/${id}`);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }
}
