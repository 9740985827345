import { IQuestion, IQuestionPagination } from 'shared/interface/question.interface';
import api from 'shared/service/api';

const url = '/question';

export default class QuestionService {
    static async getById(id: string): Promise<IQuestion> {
        const result = await api.get(`${url}/${id}`);
        return result.data;
    }

    static async findAll(page: number = 1, limit: number = 10): Promise<IQuestionPagination> {
        const res = await api.get(url, {
            params: {
                page,
                limit,
            },
        });
        return res.data;
    }

    static async create(dto: any, image?: File) {
        const formData = new FormData();
        if (image) {
            formData.append('image', image as any);
        }
        formData.append('customCode', dto.customCode);
        formData.append('title', dto.title);
        formData.append('questionType', dto.questionType);
        formData.append('point', dto.point);
        formData.append('isViolatedPoint', dto.isViolatedPoint);
        formData.append('isRequired', 'true');
        formData.append('suggestText', dto.suggestText);
        const res = await api.post(`${url}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return res.data;
    }

    static async update(id: string, dto: any, image?: File): Promise<IQuestion> {
        const formData = new FormData();
        if (image) {
            formData.append('image', image as any);
        }
        formData.append('customCode', dto.customCode);
        formData.append('title', dto.title);
        formData.append('questionType', dto.questionType);
        formData.append('point', dto.point);
        formData.append('isViolatedPoint', dto.isViolatedPoint);
        formData.append('isRequired', 'true');
        formData.append('suggestText', dto.suggestText);
        const res = await api.patch(`${url}/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return res.data;
    }

    static async delete(id: string): Promise<IQuestion> {
        const res = await api.delete(`${url}/${id}`);
        return res.data;
    }

    static async search(keyword: string): Promise<IQuestion[]> {
        const res = await api.get(`${url}/search`, {
            params: {
                keyword,
            },
        });
        return res.data;
    }
}
