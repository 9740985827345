import Farms from 'module/client/farm/farms';
import FarmDetail from 'module/client/farm/farm-detail';
import Report from 'module/client/report/report';
import Topic from 'module/client/topic/topic';
import TopicDetail from 'module/client/topic/topic-detail';
import { IconHome, IconReport, IconEvaluate, IconRole } from 'shared/assets/iconComponents';
import Login from '../../module/auth/login/login';
import SignUp from '../../module/auth/signup/signup';
import Dashboard from '../../module/client/dashboard/dashboard';
import { IRoute } from '../../shared/interface/route.interface';
import Evaluate from 'module/client/question/evaluate/evaluate';
import BioReportDetail from 'module/client/bio-report/bio-report-detail/bio-report-detail';
import AdminDashboard from 'module/admin/admin-dashboard/admin-dashboard';
import { ActionPermission } from 'shared/enum/action-permission.enum';
import UserManagement from 'module/admin/user-management/user-management';
import FarmManagement from 'module/admin/farm-management/farm-management';
import Pending from 'module/public/pending/pending';
import TopicManagement from 'module/admin/topic/topic-management';
import TopicEdit from 'module/admin/topic/topic-edit';
import PartitionManagement from 'module/admin/partition-management';
import RuntimeSetting from 'module/admin/runtime-setting/runtime-setting';
import QuestionManagement from 'module/admin/question/question-management';
import ReportManagement from 'module/admin/report-management';
import BioReportCompare from 'module/client/bio-report/bio-report-compare/bio-report-compare';
import CompanyManagement from 'module/admin/company-management/company-management';
import Maintenance from 'module/public/maintenance/maintenance';
import IconEnterprise from 'shared/assets/iconComponents/iconEnterprise';
import AuthorizationManagement from 'module/admin/authorization-management/authorization-management';
import BioReportRequest from 'module/admin/bio-report-request/bio-report-request';
import QuestionManagementRetail from 'module/admin/question-retail/question-management-retail';
import BioReportRetailDetail from 'module/client/bio-report-retail/bio-report-retail-detail/bio-report-retail-detail';
import BioReportCompareRetail from 'module/client/bio-report-retail/bio-report-retail-compare/bio-report-compare-retail';
import ReportManagementRetail from 'module/admin/report-management-retail';
import AdminDashboardRetail from 'module/admin/admin-dashboard-retail/admin-dashboard-retail';
import AutomationImport from 'module/admin/automation-import/automation-import';

export const PublicRoutes: IRoute[] = [
    { path: '/login', element: <Login /> },
    { path: '/news', element: <Topic /> },
    { path: '/news/:topicId', element: <TopicDetail /> },
    { path: '/signup', element: <SignUp /> },
    { path: '/pending', element: <Pending /> },
    { path: '/maintenance', element: <Maintenance /> },
];

export const ClientRoutes: IRoute[] = [
    {
        path: '/dashboard',
        element: <Dashboard />,
        icon: <IconHome defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'home',
    },
    {
        path: '/bio-report',
        element: <Report />,
        icon: <IconReport defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'report',
    },
    {
        path: '/bio-report/:bioReportId',
        element: <BioReportDetail />,
        icon: <IconReport defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'report',
    },
    {
        path: '/evaluate',
        element: <Evaluate />,
        icon: <IconEvaluate defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'evaluate',
    },
    {
        path: '/farms',
        element: <Farms />,
        icon: <IconEvaluate defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'evaluate',
    },
    {
        path: '/farms/:farmId',
        element: <FarmDetail />,
    },
    {
        path: '/bio-report-compare',
        element: <BioReportCompare />,
    },
    {
        path: '/bio-report-retail/:bioReportId',
        element: <BioReportRetailDetail />,
    },
    {
        path: '/bio-report-retail-compare',
        element: <BioReportCompareRetail />,
    },
];

export const AdminRoutes: IRoute[] = [
    {
        path: '/admin/dashboard',
        element: <AdminDashboard />,
        icon: <IconHome defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'home',
        actionPermissionToPass: [ActionPermission.ADMIN_DASHBOARD_REPORT],
    },
    {
        path: '/admin/dashboard-retail',
        element: <AdminDashboardRetail />,
        icon: <IconHome defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'home',
        actionPermissionToPass: [ActionPermission.ADMIN_DASHBOARD_REPORT],
    },
    {
        path: '/admin/user',
        element: <UserManagement />,
        icon: <IconHome defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'user',
        actionPermissionToPass: [ActionPermission.USER_GET_ALL],
    },
    {
        path: '/admin/farms',
        element: <FarmManagement />,
        icon: <IconHome defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'farms',
        actionPermissionToPass: [ActionPermission.USER_GET_ALL],
    },
    {
        path: '/admin/farms/:farmId',
        element: <FarmDetail from="admin" />,
        actionPermissionToPass: [ActionPermission.USER_GET_ALL],
    },
    {
        path: '/admin/topic',
        element: <TopicManagement />,
        icon: <IconHome defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'user',
        actionPermissionToPass: [ActionPermission.USER_GET_ALL],
    },
    {
        path: '/admin/topic/:id',
        element: <TopicEdit />,
        icon: <IconHome defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'user',
        actionPermissionToPass: [ActionPermission.USER_GET_ALL],
    },
    {
        path: '/admin/topic/create',
        element: <TopicEdit />,
        icon: <IconHome defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'user',
        actionPermissionToPass: [ActionPermission.USER_GET_ALL],
    },
    {
        path: '/admin/partition',
        element: <PartitionManagement />,
        icon: <IconHome defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'user',
        actionPermissionToPass: [ActionPermission.USER_GET_ALL],
    },
    {
        path: '/admin/runtime-setting',
        element: <RuntimeSetting />,
        icon: <IconHome defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'runtime setting',
        actionPermissionToPass: [ActionPermission.USER_GET_ALL],
    },
    {
        path: '/admin/question',
        element: <QuestionManagement />,
        icon: <IconHome defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'Question management',
        actionPermissionToPass: [ActionPermission.USER_GET_ALL],
    },
    {
        path: '/admin/bio-report',
        element: <ReportManagement />,
        icon: <IconHome defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'Report management',
        actionPermissionToPass: [ActionPermission.USER_GET_ALL],
    },
    {
        path: '/admin/bio-report-retail',
        element: <ReportManagementRetail />,
        icon: <IconHome defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'Report management (Retail)',
        actionPermissionToPass: [ActionPermission.USER_GET_ALL],
    },
    {
        path: '/admin/bio-report-request',
        element: <BioReportRequest />,
        icon: <IconReport defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'report',
        actionPermissionToPass: [ActionPermission.USER_GET_ALL],
    },
    {
        path: '/admin/company',
        element: <CompanyManagement />,
        icon: <IconEnterprise defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'Report management',
        actionPermissionToPass: [ActionPermission.USER_GET_ALL],
    },
    {
        path: '/admin/authorization',
        element: <AuthorizationManagement />,
        icon: <IconRole defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'Authorization management',
        actionPermissionToPass: [ActionPermission.USER_GET_ALL],
    },

    {
        path: '/admin/question-retail',
        element: <QuestionManagementRetail />,
        icon: <IconRole defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'Question Management Retail',
        actionPermissionToPass: [ActionPermission.USER_GET_ALL],
    },

    {
        path: '/admin/automation-import',
        element: <AutomationImport />,
        icon: <IconRole defaultColor="#474C55" activeColor="#638C1C" />,
        label: 'Question Management Retail',
        actionPermissionToPass: [ActionPermission.AUTOMATION_IMPORT_CREATE],
    },
];
