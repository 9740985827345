import { OTPMethod } from '../../../../shared/enum/otp.enum';
import { ILoginResponse } from '../../../../shared/interface/auth.interface';
import api from '../../../../shared/service/api';
import { LocalStoreName } from 'shared/enum/local-store.enum';

const url = '/token';
const urlAuth = '/auth';
export default class AuthService {
    static async getOTP(type: OTPMethod, phone: string) {
        const res = await api.post(url, { type, phone });
        return res.data;
    }

    static async login(phone: string, otp: string): Promise<ILoginResponse> {
        const res = await api.post(`${urlAuth}/login`, { phone, token: otp });
        return res.data;
    }
    static async retrieveToken (): Promise<string | null> {
        const result = await api.post('auth/retrieve-token', {
            refreshToken: localStorage.getItem(LocalStoreName.REFRESH_TOKEN),
        });
        if (result.data.token) {
            localStorage.setItem(LocalStoreName.TOKEN, result.data.token);
            return result.data.token;
        }
        return null;
    }
}
