/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import '../farms.scss';
import { buildFileView } from 'shared/service/file';
import { useDropzone } from 'react-dropzone';

interface ICoverProps {
    setCover: any;
    previousCover?: string;
}
const EditCover = (props: ICoverProps) => {
    const { previousCover, setCover } = props;
    const [newFile, setNewFile] = useState<File>();

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/jpeg': ['.jpeg', '.png'],
        },
        maxFiles: 1,
    });

    useEffect(() => {
        acceptedFiles.forEach((file, index: number) => {
            setCover(file);
            setNewFile(file);
        });
    }, [acceptedFiles]);

    return (
        <div {...getRootProps({ className: 'drop-zone p-5 clickable' })}>
            <input {...getInputProps()} />
            <div className="d-flex justify-content-center">
                <button className="btn btn-outline-light btn-lg" type="button">
                    {newFile ? (
                        <img
                            className="w-100"
                            alt="farm-cover"
                            src={URL.createObjectURL(newFile)}
                        />
                    ) : (
                        <img
                            className="w-100"
                            alt="farm-cover"
                            src={buildFileView(previousCover)}
                        />
                    )}
                </button>
            </div>
        </div>
    );
};

export default EditCover;
