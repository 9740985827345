/* eslint-disable react-hooks/exhaustive-deps */
import { IAuthorization } from 'shared/interface/authorization.interface';
import { Col, Form, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ActionPermission } from 'shared/enum/action-permission.enum';
import AuthorizationService from 'shared/service/authorization.service';
import { toast } from 'react-toastify';

interface IProp {
    show: boolean;
    authorization?: IAuthorization;
    onHide: (data: IAuthorization | null) => void;
}

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'transparent',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    borderRadius: 10,
    overflowWrap: 'anywhere',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '#f7f8f9' : 'transparent',
    padding: grid,
    width: 250,
    borderRadius: 10,
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    marginRight: 16,
    maxHeight: '50vh',
    overflow: 'auto',
});

export default function EditAuthorization(prop: IProp) {
    const { show, authorization } = prop;
    const { t } = useTranslation();
    const [validated, setValidated] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [actions, setActions] = useState<string[]>([]);
    const [allActions, setAllActions] = useState<string[]>([]);

    const initialize = () => {
        setName(authorization ? authorization.name : '');
        setActions(authorization ? authorization.actions : []);
        setDescription(authorization ? authorization.description : '');
    };

    useEffect(() => {
        if (authorization) {
            setAllActions(
                Object.values(ActionPermission).filter(
                    (el) => authorization?.actions.indexOf(el) < 0
                )
            );
        } else {
            setAllActions(Object.values(ActionPermission));
        }
        initialize();
    }, [authorization]);

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity()) {
            let res: IAuthorization;
            if (authorization) {
                res = await AuthorizationService.update(authorization._id, {
                    name,
                    description,
                    actions,
                });
            } else {
                res = await AuthorizationService.create({ name, description, actions });
            }
            if (res) {
                toast.success(t('successfully'), { position: 'bottom-center' });
                prop.onHide(res);
                initialize();
            }
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result as string[];
    };

    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    const getList = (id: string) => {
        if (id === 'actions') {
            return actions;
        } else if (id === 'allActions') {
            return allActions;
        }
        return actions;
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(getList(source.droppableId), source.index, destination.index);

            if (source.droppableId === 'actions') {
                setActions(items);
            }
        } else {
            const result = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            );

            setActions(result['actions']);
            setAllActions(result['allActions']);
        }
    };

    return (
        <Modal show={show} dialogClassName="max-width-800" fullscreen="sm-down">
            <Modal.Header className="flex-column">
                <div className="d-flex justify-content-end w-100">
                    <button
                        className="btn btn-icon border-0 rounded-circle"
                        onClick={() => {
                            prop.onHide(null);
                        }}
                    >
                        <i className="fa-solid fa-xmark text-primary"/>
                    </button>
                </div>
                <div className="d-flex justify-content-center">
                    <span className="fs-3 fw-semibold">
                        {authorization ? t('edit-info') : t('add-role')}
                    </span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} noValidate={true} validated={validated}>
                    <div className="row">
                        <Form.Group as={Col} sm="12">
                            <Form.Label>{t('name')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Control
                                    type="text"
                                    className="text-gray"
                                    required={true}
                                    placeholder={t('name')}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('required-field')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="row">
                        <Form.Group as={Col} sm="12">
                            <Form.Label>{t('description')}</Form.Label>
                            <InputGroup hasValidation={false}>
                                <Form.Control
                                    type="text"
                                    as="textarea"
                                    className="text-gray"
                                    required={false}
                                    placeholder={t('description')}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <Form.Label className="mt-2">{t('permissions')}</Form.Label>
                    <div className="px-3 d-flex justify-content-around">
                        <span className="text-secondary">{t('allowed-permissions')}</span>
                        <span className="text-secondary">{t('all-permissions')}</span>
                    </div>
                    <div className="px-3 d-flex justify-content-around">
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="actions">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {actions.map((item, index) => (
                                            <Draggable key={item} draggableId={item} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        {item}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            <Droppable droppableId="allActions">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {allActions.map((item, index) => (
                                            <Draggable key={item} draggableId={item} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        {item}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <button type="submit" className="btn btn-primary w-50">
                            {t('finish')}
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
