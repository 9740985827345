import QuestionSetService from 'module/client/question/service/questionSet.service';
import { useEffect, useState } from 'react';
import { IQuestionSetRetail } from 'shared/interface/question-set-retail.interface';
import { QuestionSetRetailService } from 'shared/service/question-set-retail.service';

export default function QuestionSetTitleRetail({ id }: { id: string }) {
    const [questionSet, setQuestionSet] = useState<IQuestionSetRetail>();

    const getQuestionSet = async () => {
        const res = await QuestionSetRetailService.findOne(id);
        setQuestionSet(res);
    };

    useEffect(() => {
        getQuestionSet();
    }, [id]);

    return <span>{questionSet?.title}</span>;
}
