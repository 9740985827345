import { useTranslation } from 'react-i18next';
import { IDashboardReport } from 'shared/interface/bio-report.interface';

interface IProp {
    dashboardReport?: IDashboardReport;
}

export default function ReportCountCalculate(prop: IProp) {
    const { t } = useTranslation();
    const { dashboardReport } = prop;

    return (
        <div className="d-flex flex-column justify-content-around align-items-center h-100 fs-5">
            <div className="swing-in-right-fwd d-flex div-shadow-1 justify-content-between align-items-center w-100 h-100 p-3">
                <span className="text-secondary">{t('report-quantity')}</span>
                <span className="text-primary fw-semibold">
                    {dashboardReport?.totalReportCount
                        ? dashboardReport?.totalReportCount
                        : t('no-data')}
                </span>
            </div>
            <div className="swing-in-right-fwd d-flex div-shadow-1 flex-column justify-content-start align-items-center w-100 h-100 p-3">
                <div className="d-flex justify-content-between w-100">
                    <span className="text-secondary">{t('average-percentage')}</span>
                    <span className="text-primary fw-semibold">
                        {dashboardReport?.totalPercentageAverage
                            ? dashboardReport?.totalPercentageAverage.toFixed(2) + '%'
                            : t('no-data')}
                    </span>
                </div>
                <div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <span className="text-secondary fs-6">
                                {t('design-evaluation-percentage')}
                            </span>
                        </div>
                        <div className="col-12 col-md-6">
                            <span className="text-secondary fs-6">
                                {t('process-evaluation-percentage')}
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <span className="text-primary fw-semibold fs-6 text-center">
                                {dashboardReport?.percentageDesignEvaluation
                                    ? dashboardReport?.percentageDesignEvaluation.toFixed(2) + '%'
                                    : t('no-data')}
                            </span>
                        </div>
                        <div className="col-12 col-md-6">
                            <span className="text-primary fw-semibold fs-6 text-center">
                                {dashboardReport?.percentageProcessEvaluation
                                    ? dashboardReport?.percentageProcessEvaluation.toFixed(2) + '%'
                                    : t('no-data')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="swing-in-right-fwd d-flex div-shadow-1 justify-content-between align-items-center w-100 h-100 p-3">
                <span className="text-secondary">{t('average-violated-point')}</span>
                <span className="text-primary fw-semibold">
                    {dashboardReport?.totalReportCount
                        ? dashboardReport?.totalViolatedAverage.toFixed(2)
                        : t('no-data')}
                </span>
            </div>
        </div>
    );
}
