/* eslint-disable react-hooks/exhaustive-deps */
import { useAppContext } from 'module/auth/context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthorizationService from 'shared/service/authorization.service';
import { LocalStoreName } from '../../shared/enum/local-store.enum';
import { useAuthContext } from '../auth/context/auth-context';

export default function ClientGuard({ children }: any) {
    const navigate = useNavigate();
    const authContext = useAuthContext();
    const appContext = useAppContext();

    const getActionPermission = async () => {
        const result = await AuthorizationService.findOne(appContext.user?.roleName || '');
        authContext.setActionPermission(result.actions);
    };

    useEffect(() => {
        const token = localStorage.getItem(LocalStoreName.TOKEN) || null;
        if (token) {
            authContext.setHasToken(true);
            if (!appContext.user?.allowAccessProgram) {
                navigate('/pending');
            }
            getActionPermission();
        } else {
            navigate('/login');
        }
    }, [authContext.hasToken]);

    return { ...children };
}
