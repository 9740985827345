export enum ActionPermission {
  ADMIN_POST = 'ADMIN_POST',

  AUTHORIZATION_CREATE = 'AUTHORIZATION_CREATE',
  AUTHORIZATION_GET = 'AUTHORIZATION_GET',
  AUTHORIZATION_UPDATE = 'AUTHORIZATION_UPDATE',
  AUTHORIZATION_REMOVE = 'AUTHORIZATION_REMOVE',

  TOKEN_GET = 'TOKEN_GET',
  TOKEN_UPDATE = 'TOKEN_UPDATE',
  TOKEN_REMOVE = 'TOKEN_REMOVE',

  USER_CREATE = 'USER_CREATE',
  USER_GET = 'USER_GET',
  USER_GET_ALL = 'USER_GET_ALL',
  USER_UPDATE = 'USER_UPDATE',
  USER_REMOVE = 'USER_REMOVE',
  USER_GET_NOT_ADMIN = 'USER_GET_NOT_ADMIN',

  QUESTION_CREATE = 'QUESTION_CREATE',
  QUESTION_GET = 'QUESTION_GET',
  QUESTION_UPDATE = 'QUESTION_UPDATE',
  QUESTION_REMOVE = 'QUESTION_REMOVE',

  QUESTION_SET_CREATE = 'QUESTION_SET_CREATE',
  QUESTION_SET_GET = 'QUESTION_SET_GET',
  QUESTION_SET_UPDATE = 'QUESTION_SET_UPDATE',
  QUESTION_SET_REMOVE = 'QUESTION_SET_REMOVE',

  SALE_MANAGER_CREATE = 'SALE_MANAGER_CREATE',
  SALE_MANAGER_GET = 'SALE_MANAGER_GET',
  SALE_MANAGER_UPDATE = 'SALE_MANAGER_UPDATE',
  SALE_MANAGER_REMOVE = 'SALE_MANAGER_REMOVE',

  FARM_CREATE = 'FARM_CREATE',
  FARM_GET = 'FARM_GET',
  FARM_GET_ALL = 'FARM_GET_ALL',
  FARM_UPDATE = 'FARM_UPDATE',
  FARM_REMOVE = 'FARM_REMOVE',

  BIO_CREATE = 'BIO_CREATE',
  BIO_GET = 'BIO_GET',
  BIO_UPDATE = 'BIO_UPDATE',
  BIO_REMOVE = 'BIO_REMOVE',

  TOPIC_CREATE = 'TOPIC_CREATE',
  TOPIC_GET = 'TOPIC_GET',
  TOPIC_UPDATE = 'TOPIC_UPDATE',
  TOPIC_REMOVE = 'TOPIC_REMOVE',

  ADMIN_DASHBOARD_REPORT = 'ADMIN_DASHBOARD_REPORT',
  RUNTIME_SETTING = 'RUNTIME_SETTING',
  SHOW_ADMIN_ACCESS_BUTTON = 'SHOW_ADMIN_ACCESS_BUTTON',

  EXCEL_GET_ALL = 'EXCEL_GET_ALL',
  EXCEL_GET = 'EXCEL_GET',

  COMPANY_GET = 'COMPANY_GET',
  COMPANY_GET_ALL = 'COMPANY_GET_ALL',
  COMPANY_UPDATE = 'COMPANY_UPDATE',
  COMPANY_CREATE = 'COMPANY_CREATE',
  COMPANY_REMOVE = 'COMPANY_REMOVE',

  BIO_REQUEST_CREATE = 'BIO_REQUEST_CREATE',
  BIO_REQUEST_GET_ALL = 'BIO_REQUEST_GET_ALL',
  BIO_REQUEST_GET = 'BIO_REQUEST_GET',
  BIO_REQUEST_UPDATE = 'BIO_REQUEST_UPDATE',
  BIO_REQUEST_REMOVE = 'BIO_REQUEST_REMOVE',

  AUTOMATION_IMPORT_CREATE = 'AUTOMATION_IMPORT_CREATE',
}
