/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Collapse, Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePicker, { registerLocale } from 'react-datepicker';
import { enUS, vi } from 'date-fns/locale';
import { format, subMonths } from 'date-fns';
import { IBioReportRequest } from 'shared/interface/bio-report-request.interface';
import BioReportRequestService from 'shared/service/bio-report-request.service';
import { emptyBox } from 'shared/assets/images';
import PerformerName from '../report-management/performerName';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuthContext } from 'module/auth/context';
import { useAppContext } from 'module/auth/context';

registerLocale('en-US', enUS);
registerLocale('vi', vi);

export default function BioReportRequest() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const authContext = useAuthContext();
    const appContext = useAppContext();
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [from, setFrom] = useState<Date>(subMonths(new Date(), 1));
    const [to, setTo] = useState<Date>(new Date());
    const [requests, setRequests] = useState<IBioReportRequest[]>([]);
    const [page, setPage] = useState<number>(1);
    const [limit] = useState<number>(10);
    const [hasNextPage, setHasNextPage] = useState<boolean>(false);
    const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(false);

    const renderApprove = (props) => (
        <Tooltip id="button-tooltip-approve" {...props}>
            {t('approve')}
        </Tooltip>
    );

    const renderReject = (props) => (
        <Tooltip id="button-tooltip-reject" {...props}>
            {t('reject')}
        </Tooltip>
    );

    const onSearch = async (e: any) => {
        e.preventDefault();
        getRequests();
    };

    const getRequests = async () => {
        authContext.setLoading(true);
        const res = await BioReportRequestService.findAll(page, limit, from, to);
        setRequests(res.docs.filter((d) => !d.resolve));
        setHasNextPage(res.hasNextPage);
        setHasPreviousPage(res.hasPrevPage);
        authContext.setLoading(false);
    };

    const approve = async (idRequest: string) => {
        authContext.setLoading(true);
        const res = await BioReportRequestService.update(idRequest, { resolve: true });
        if (res) {
            toast.success(t('successfully'), { position: 'bottom-center' });
            if (appContext.requests > 0) {
                appContext.setRequests(appContext.requests - 1);
            }
            const newRequests = requests.map((r) => {
                if (r._id === idRequest) {
                    r.resolve = true;
                }
                return r;
            });
            setRequests([...newRequests]);
        }
        authContext.setLoading(false);
    };

    const reject = async (idRequest: string) => {
        authContext.setLoading(true);
        const res = await BioReportRequestService.delete(idRequest);
        if (res) {
            toast.success(t('successfully'), { position: 'bottom-center' });
            if (appContext.requests > 0) {
                appContext.setRequests(appContext.requests - 1);
            }
            setRequests([...requests.filter((r) => r._id !== idRequest)]);
        }
        authContext.setLoading(false);
    };

    useEffect(() => {
        getRequests();
    }, [page]);

    return (
        <div className="container">
            <div className="d-flex flex-column w-100 mb-3 div-shadow rounded">
                <button
                    className="btn btn-outline-secondary w-100 border-0"
                    onClick={() => setOpenFilter(!openFilter)}
                >
                    <div className="d-flex justify-content-between">
                        <i className="fa-solid fa-filter my-auto"/>
                        <span className="fw-bold">{t('filter')}</span>
                        <i className="fa-solid fa-circle-arrow-down my-auto"/>
                    </div>
                </button>
                <Collapse in={openFilter} className="py-2">
                    <div className="p-3">
                        <Form onSubmit={(e) => onSearch(e)}>
                            <div className="row">
                                <div className="col-12 col-sm-4">
                                    <div className="input-group flex-nowrap">
                                        <span className="input-group-text">{t('from-date')}</span>
                                        <DatePicker
                                            locale={i18n.language}
                                            dateFormat={
                                                i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                            }
                                            className="form-control"
                                            selected={from}
                                            onChange={(date: Date) => {
                                                setFrom(date);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                    <div className="input-group flex-nowrap">
                                        <span className="input-group-text">{t('to-date')}</span>
                                        <DatePicker
                                            locale={i18n.language}
                                            dateFormat={
                                                i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                            }
                                            className="form-control"
                                            selected={to}
                                            onChange={(date: Date) => setTo(date)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end gap-2 mt-2">
                                <button className="btn btn-primary" type="submit">
                                    {t('search')}
                                </button>
                            </div>
                        </Form>
                    </div>
                </Collapse>
            </div>
            <div className="card">
                <Table responsive={true} hover={true} striped={true}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{t('request')}</th>
                            <th>{t('report-id')}</th>
                            <th>{t('performer')}</th>
                            <th>{t('perform-date')}</th>
                            <th>#</th>
                        </tr>
                    </thead>
                    <tbody className="w-100">
                        {requests.map((r, index) => (
                            <tr className="clickable align-middle" key={r._id}>
                                <td onClick={() => navigate(`/bio-report/${r.reportId}`)}>
                                    {index + 1}
                                </td>
                                <td onClick={() => navigate(`/bio-report/${r.reportId}`)}>
                                    {t(r.action)}
                                </td>
                                <td onClick={() => navigate(`/bio-report/${r.reportId}`)}>
                                    {t(r.reportId)}
                                </td>
                                <td onClick={() => navigate(`/bio-report/${r.reportId}`)}>
                                    <PerformerName id={r.createdBy} />
                                </td>
                                <td onClick={() => navigate(`/bio-report/${r.reportId}`)}>
                                    {format(
                                        new Date(r.createdAt),
                                        i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                    )}
                                </td>
                                <td>
                                    {!r.resolve ? (
                                        <div className="d-flex gap-2">
                                            <OverlayTrigger
                                                placement="auto"
                                                overlay={renderApprove}
                                            >
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => approve(r._id)}
                                                >
                                                    <i className="fa-solid fa-check"/>
                                                </button>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="auto"
                                                overlay={renderReject}
                                            >
                                                <button
                                                    className="btn btn-danger"
                                                    onClick={() => reject(r._id)}
                                                >
                                                    <i className="fa-solid fa-x"/>
                                                </button>
                                            </OverlayTrigger>
                                        </div>
                                    ) : (
                                        <div className="text-primary">{t('resolved')}</div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {requests.length === 0 && (
                    <div className="d-flex py-3 flex-column align-items-center justify-content-center w-100">
                        <img src={emptyBox} alt="empty-box" />
                    </div>
                )}
                <nav className="d-flex justify-content-end">
                    <ul className="pagination">
                        <li className={['page-item', !hasPreviousPage && 'disabled'].join(' ')}>
                            <a className="page-link" href="#" onClick={() => setPage(page - 1)}>
                                {t('previous')}
                            </a>
                        </li>
                        {hasPreviousPage && (
                            <li className="page-item">
                                <a className="page-link" href="#" onClick={() => setPage(page - 1)}>
                                    {page - 1}
                                </a>
                            </li>
                        )}
                        <li className="page-item active">
                            <span className="page-link">{page}</span>
                        </li>
                        {hasNextPage && (
                            <li className="page-item">
                                <a className="page-link" href="#" onClick={() => setPage(page + 1)}>
                                    {page + 1}
                                </a>
                            </li>
                        )}
                        <li className={['page-item', !hasNextPage && 'disabled'].join(' ')}>
                            <a className="page-link" href="#" onClick={() => setPage(page + 1)}>
                                {t('next')}
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}
