export interface IProp {
    active?: boolean;
    activeColor?: string;
    defaultColor?: string;
}

const IconHome = (props: IProp) => {
    const { active = false, activeColor, defaultColor } = props;
    const fill = active ? activeColor ?? '#638C1C' : defaultColor || '#474C55';
    return (
        <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M3.99 12.0333H5.51L4.75 10.7667L3.99 12.0333Z" fill="black" />
            <path
                d="M15.8333 1.9H9.5V0.633333C9.5 0.253333 9.24667 0 8.86667 0C8.48667 0 8.23333 0.253333 8.23333 0.633333V1.9H1.9C0.823333 1.9 0 2.72333 0 3.8V15.2C0 16.2767 0.823333 17.1 1.9 17.1H8.23333V18.3667C8.23333 18.7467 8.48667 19 8.86667 19C9.24667 19 9.5 18.7467 9.5 18.3667V17.1H15.8333C16.91 17.1 17.7333 16.2767 17.7333 15.2V3.8C17.7333 2.72333 16.91 1.9 15.8333 1.9ZM6.84 3.8C7.15667 3.8 7.47333 4.11667 7.47333 4.43333C7.47333 4.81333 7.15667 5.06667 6.84 5.06667C6.52333 5.06667 6.20667 4.75 6.20667 4.43333C6.20667 4.05333 6.52333 3.8 6.84 3.8ZM4.81333 3.8C5.13 3.8 5.44667 4.11667 5.44667 4.43333C5.44667 4.81333 5.13 5.06667 4.81333 5.06667C4.43333 5.06667 4.18 4.75 4.18 4.43333C4.18 4.05333 4.49667 3.8 4.81333 3.8ZM2.72333 3.8C3.04 3.8 3.35667 4.11667 3.35667 4.43333C3.35667 4.81333 3.04 5.06667 2.72333 5.06667C2.40667 5.06667 2.09 4.75 2.09 4.43333C2.09 4.05333 2.40667 3.8 2.72333 3.8ZM7.28333 13.87C6.7944 14.1924 6.52333 13.8067 6.39667 13.6167L6.20667 13.3H3.29333L3.10333 13.6167C2.91333 13.9333 2.52193 14.0214 2.21667 13.87C1.88607 13.706 1.83667 13.3 1.96333 12.9833L4.18 9.18333C4.43333 8.80333 5.06667 8.80333 5.25667 9.18333L7.47333 12.9833C7.66333 13.3 7.6 13.68 7.28333 13.87V13.87ZM9.5 15.8333C9.5 16.2133 9.24667 16.4667 8.86667 16.4667C8.48667 16.4667 8.23333 16.2133 8.23333 15.8333V3.16667C8.23333 2.78667 8.48667 2.53333 8.86667 2.53333C9.24667 2.53333 9.5 2.78667 9.5 3.16667V15.8333ZM13.6167 13.9333H11.4C11.02 13.9333 10.7667 13.68 10.7667 13.3V9.5C10.7667 9.12 11.02 8.86667 11.4 8.86667H12.9833C13.87 8.86667 14.5667 9.56333 14.5667 10.45C14.5667 10.64 14.5033 10.83 14.44 11.02C14.8833 11.2733 15.2 11.78 15.2 12.35C15.2 13.2367 14.5033 13.9333 13.6167 13.9333Z"
                fill={fill}
            />
            <path
                d="M13.6167 12.0333H12.0333V12.6667H13.6167C13.8067 12.6667 13.9333 12.54 13.9333 12.35C13.9333 12.16 13.8067 12.0333 13.6167 12.0333Z"
                fill={fill}
            />
            <path
                d="M13.3 10.45C13.3 10.26 13.1733 10.1333 12.9833 10.1333H12.0333V10.7667H12.9833C13.1733 10.7667 13.3 10.64 13.3 10.45Z"
                fill={fill}
            />
        </svg>
    );
};

export default IconHome;
