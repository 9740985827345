import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import './loading-spinner.scss';

interface IProp {
    loading: boolean;
}

export default function LoadingSpinner(prop: IProp) {
    const { t } = useTranslation();
    return (
        <>
            {prop.loading && (
                <div className="spinner-container d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" />
                    <div className="d-flex text-primary ms-2 mt-1">
                        <h3>{t('please-wait')}</h3>
                    </div>
                </div>
            )}
        </>
    );
}
