/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useAuthContext } from 'module/auth/context';
import { useEffect, useState, useRef } from 'react';
import { Col, Collapse, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ConfirmDialog } from 'shared/components';
import { ICompany } from 'shared/interface/company.interface';
import EditCompany from './edit-company';
import { CompanyService } from './service/company.service';
import { FilterStore } from 'shared/enum/local-store.enum';

export default function CompanyManagement() {
    const { t } = useTranslation();
    const authContext = useAuthContext();
    const [companies, setCompanies] = useState<ICompany[]>([]);
    const [page, setPage] = useState<number>(0);
    const [limit] = useState<number>(10);
    const [hasNextPage, setHasNextPage] = useState<boolean>(false);
    const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(false);
    const [keyword, setKeyword] = useState<string>('');
    const [selectedCompany, setSelectedCompany] = useState<ICompany>();
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const filterRef = useRef({
        keyword,
        page,
    });
    const getCompanies = async () => {
        authContext.setLoading(true);
        const res = await CompanyService.find(page, limit, keyword !== '' ? keyword : undefined);
        setCompanies(res.docs);
        setHasNextPage(res.hasNextPage);
        setHasPreviousPage(res.hasPrevPage);
        authContext.setLoading(false);
    };

    useEffect(() => {
        // get filter from local storage
        const rawFilter = localStorage.getItem(FilterStore.ADMIN_COMPANY_MANAGEMENT_FILTER);
        if (rawFilter) {
            const convertedFilter = JSON.parse(rawFilter);
            if (convertedFilter.keyword) {
                setOpenFilter(true);
                setKeyword(convertedFilter.keyword);
            }
            if (convertedFilter.page) {
                setPage(convertedFilter.page);
            } else {
                setPage(1);
            }
        } else {
            setPage(1);
        }
        return () => {
            // set filter when component unmount
            localStorage.setItem(
                FilterStore.ADMIN_COMPANY_MANAGEMENT_FILTER,
                JSON.stringify(filterRef.current)
            );
        };
    }, []);

    useEffect(() => {
        filterRef.current = {
            keyword,
            page,
        };
    }, [keyword, page]);

    useEffect(() => {
        if (page) {
            getCompanies();
        }
    }, [page]);

    const renderAddTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('add-company')}
        </Tooltip>
    );

    const deleteCompany = async () => {
        const res = await CompanyService.delete(selectedCompany?._id || '');
        if (res) {
            toast.success(t('successfully'), { position: 'bottom-center' });
            setCompanies(companies.filter((r) => r._id !== selectedCompany?._id));
        }
    };

    const checkKeyPress = (ev) => {
        ev.preventDefault();
        if (ev.key === 'Enter') {
            if (page > 1) {
                setPage(1);
            } else {
                getCompanies();
            }
        }
    };

    return (
        <div className="container-fluid">
            <h3>{t('customer-management')}</h3>
            <div className="d-flex flex-column w-100 mb-3 div-shadow rounded">
                <button
                    className="btn btn-outline-secondary w-100 border-0"
                    onClick={() => setOpenFilter(!openFilter)}
                >
                    <div className="d-flex justify-content-between">
                        <i className="fa-solid fa-filter my-auto"/>
                        <span className="fw-bold">{t('filter')}</span>
                        <i className="fa-solid fa-circle-arrow-down my-auto"/>
                    </div>
                </button>
                <Collapse in={openFilter} className="py-2">
                    <div className="p-3">
                        <Form
                            className="row"
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <Form.Group as={Col} sm="4" className="mb-3">
                                <Form.Label>{t('name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('name')}
                                    value={keyword}
                                    onChange={(event) => setKeyword(event.target.value)}
                                    onKeyUpCapture={checkKeyPress}
                                />
                            </Form.Group>
                        </Form>
                        <div className="d-flex justify-content-end">
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    // @ts-ignore
                                    window.gtag('event', 'SEARCH_IN_COMPANIES', {});
                                    if (page > 1) {
                                        setPage(1);
                                    } else {
                                        getCompanies();
                                    }
                                }}
                            >
                                <i className="fa-solid fa-magnifying-glass my-auto"/>
                                <span className="ms-3">{t('search')}</span>
                            </button>
                        </div>
                    </div>
                </Collapse>
            </div>
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                        <tr className="table-secondary">
                            <th scope="col"/>
                            <th scope="col">{t('name')}</th>
                            <th scope="col">{t('user-code')}</th>
                            <th scope="col" className="d-flex justify-content-end">
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 100, hide: 200 }}
                                    overlay={renderAddTooltip}
                                >
                                    <button
                                        className="btn btn-success"
                                        onClick={() => {
                                            setSelectedCompany(undefined);
                                            setShowEdit(true);
                                        }}
                                    >
                                        <i className="fa-solid fa-plus"/>
                                    </button>
                                </OverlayTrigger>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {companies &&
                            companies.map((company, index) => (
                                <tr className="align-middle" key={company._id}>
                                    <td>{index + limit * (page - 1) + 1}</td>
                                    <td>{company.name}</td>
                                    <td>{company.code}</td>
                                    <td className="d-flex justify-content-end">
                                        <button
                                            className="btn btn-outline-secondary"
                                            onClick={() => {
                                                setSelectedCompany(company);
                                                setTimeout(() => {
                                                    setShowEdit(true);
                                                }, 200);
                                            }}
                                        >
                                            <i className="fa-solid fa-pen-clip"/>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary"
                                            onClick={() => {
                                                setSelectedCompany(company);
                                                setTimeout(() => {
                                                    setShowDelete(true);
                                                }, 200);
                                            }}
                                        >
                                            <i className="fa-solid fa-trash-can"/>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <nav className="d-flex justify-content-end">
                    <ul className="pagination">
                        <li className={['page-item', !hasPreviousPage && 'disabled'].join(' ')}>
                            <a className="page-link" href="#" onClick={() => setPage(page - 1)}>
                                {t('previous')}
                            </a>
                        </li>
                        {hasPreviousPage && (
                            <li className="page-item">
                                <a className="page-link" href="#" onClick={() => setPage(page - 1)}>
                                    {page - 1}
                                </a>
                            </li>
                        )}
                        <li className="page-item active">
                            <span className="page-link">{page}</span>
                        </li>
                        {hasNextPage && (
                            <li className="page-item">
                                <a className="page-link" href="#" onClick={() => setPage(page + 1)}>
                                    {page + 1}
                                </a>
                            </li>
                        )}
                        <li className={['page-item', !hasNextPage && 'disabled'].join(' ')}>
                            <a className="page-link" href="#" onClick={() => setPage(page + 1)}>
                                {t('next')}
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <ConfirmDialog
                title={t('confirmation-title')}
                body={`${t('are-you-sure-to-delete')} ${selectedCompany?.name}`}
                confirmTitle={t('yes')}
                declineTitle={t('cancel')}
                show={showDelete}
                onHide={function (result: boolean): void {
                    if (result) {
                        deleteCompany();
                    }
                    setShowDelete(false);
                }}
            />
            <EditCompany
                company={selectedCompany}
                show={showEdit}
                onHide={function (data: ICompany | null): void {
                    setShowEdit(false);
                    if (data) {
                        if (selectedCompany) {
                            setCompanies(
                                companies.map((r) => {
                                    if (r._id === data._id) {
                                        r = data;
                                    }
                                    return r;
                                })
                            );
                        } else {
                            setCompanies([...companies, data]);
                        }
                    }
                }}
            />
        </div>
    );
}
