export enum FinalBioReportResultEnum {
    NINETY_HUNDRED_GREATER_3 = 'NINETY_HUNDRED_GREATER_3',
    NINETY_HUNDRED_FROM_1_TO_3 = 'NINETY_HUNDRED_FROM_1_TO_3',
    NINETY_HUNDRED_0 = 'NINETY_HUNDRED_0',

    EIGHTY_NINETY_GREATER_3 = 'EIGHTY_NINETY_GREATER_3',
    EIGHTY_NINETY_FROM_1_TO_3 = 'EIGHTY_NINETY_FROM_1_TO_3',
    EIGHTY_NINETY_0 = 'EIGHTY_NINETY_0',

    LOWER_EIGHTY_GREATER_3 = 'LOWER_EIGHTY_GREATER_3',
    LOWER_EIGHTY_FROM_1_TO_3 = 'LOWER_EIGHTY_FROM_1_TO_3',
    LOWER_EIGHTY_0 = 'LOWER_EIGHTY_0',
}

export enum RiskLevel {
    LOW = 'low',
    MEDIUM = 'medium',
    HIGH = 'high',
    WARNING = 'warning',
}

export enum REPORT_TYPE {
    BIG_COMPANY = 'BIG_COMPANY',
    RETAIL = 'RETAIL',
}
