/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { iconAddress, iconInfo, iconPencil, iconTrash } from 'shared/assets/icons';
import './farms.scss';
import FarmService from './service/farm.service';
import { IFarm } from 'shared/interface/farm.interface';
import { useParams, useNavigate } from 'react-router-dom';
import { buildFileView } from 'shared/service/file';
import { EditFarmInfoModal } from './components';
import { ModalMode } from 'shared/enum/modal-mode.enum';
import { ConfirmDialog } from 'shared/components';
import LoadingSpinner from 'shared/components/loading-spinner/loading-spinner';
import UserService from 'shared/service/user.service';
import { IUser } from 'shared/interface/user.interface';
import axios from 'axios';

interface IProps {
    from?: string;
}

export default function FarmDetail(props: IProps) {
    const { from } = props;
    const { t } = useTranslation();
    const { farmId } = useParams();
    const navigate = useNavigate();
    const [farm, setFarm] = useState<IFarm>();
    const [visibleEditFarmModal, setVisibleEditFarmModal] = useState<boolean>(false);
    const [forceReload, setForceReload] = useState<boolean>(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [technician, setTechnician] = useState<IUser[]>([]);

    useEffect(() => {
        getFarmDetail();
    }, []);

    useEffect(() => {
        if (forceReload) {
            getFarmDetail();
        }
    }, [forceReload]);

    const getFarmDetail = async () => {
        if (farmId) {
            const result = await FarmService.getFarmById(farmId);
            if (result) {
                setFarm(result);
                setForceReload(false);
                if (result.owningTechnician) {
                    getOwningTechnician(result.owningTechnician);
                }
            }
        }
    };

    const getOwningTechnician = async (ids: string[]) => {
        const result = await axios.all(ids.map((id) => UserService.getUserInfo(id)));
        setTechnician(result);
    };

    const onDeleteFarm = async () => {
        setLoading(true);
        if (farm?._id) {
            const result = await FarmService.deleteFarm(farm._id);
            if (result) {
                navigate('/admin/farms');
            }
        }
        setLoading(false);
        setShowConfirmDialog(false);
    };

    return (
        <div className="w-100 d-flex flex-column align-items-center">
            <div className="d-flex flex-column w-100 bg-secondary" style={{ maxWidth: 853 }}>
                <div className="d-flex mt-3">
                    <span className="title ms-2">{t('farm-detail')}</span>
                </div>
                <div className="w-100 d-flex position-relative mt-2">
                    <img
                        alt=""
                        src={buildFileView(farm?.cover)}
                        style={{ height: 136, width: '100%', objectFit: 'cover' }}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/image/picture/default.jpeg';
                        }}
                    />
                    <img
                        alt=""
                        src={buildFileView(farm?.avatar)}
                        style={{
                            height: 64,
                            width: 64,
                            objectFit: 'cover',
                            left: '20px',
                            top: '100px',
                        }}
                        className="rounded-circle position-absolute"
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/image/picture/default.jpeg';
                        }}
                    />
                </div>
                <div className="d-flex w-100 flex-column p-3">
                    <div className="d-flex mt-5">
                        <span className="farm-title">{farm?.title ?? ''}</span>
                    </div>
                    <div className="d-flex w-100 pb-2 align-items-center mt-1">
                        <img alt="" src={iconAddress} className="me-2" />
                        <span className="farm-address">{farm?.location ?? ''}</span>
                    </div>
                    <div
                        className={[
                            'd-flex w-100 mt-2 pb-2',
                            !technician && 'border-bottom pb-3',
                        ].join(' ')}
                    >
                        <span className="farm-description">{farm?.description}</span>
                    </div>
                    {technician && (
                        <div className="d-flex w-100 mt-2 pb-3 border-bottom">
                            <span className="farm-description">{`${t(
                                'technician'
                            )}: ${technician?.map((tech) => `${tech.name}, `)}`}</span>
                        </div>
                    )}
                    <div className="d-flex w-100 mt-3 align-items-center">
                        <span className="farm-description col-lg-2 col-md-3 col-sm-4 col-5 me-2">
                            {t('number-of-sow')}
                        </span>
                        <span className="farm-property">{`${farm?.numberOfSow ?? ''} ${t(
                            'pigs-unit'
                        )}`}</span>
                    </div>
                    <div className="d-flex w-100 mt-2 pb-3 border-bottom align-items-center">
                        <span className="farm-description col-lg-2 col-md-3 col-sm-4 col-5 me-2">
                            {t('number-of-pig')}
                        </span>
                        <span className="farm-property">{`${farm?.numberOfPork ?? ''} ${t(
                            'pigs-unit'
                        )}`}</span>
                    </div>
                    <div className="d-flex w-100 align-items-center mt-3">
                        <div className="col-lg-4 col-md-5 col-sm-7 col-8 me-2 d-flex">
                            <img alt="" src={iconInfo} className="me-2" />
                            <span className="farm-description">{t('nearest-pig-farm')}</span>
                        </div>
                        <span className="col farm-property">{`${
                            farm?.pigFarmInNearestRadius ?? ''
                        } m`}</span>
                    </div>
                    <div className="d-flex w-100 align-items-center mt-2">
                        <div className="col-lg-4 col-md-5 col-sm-7 col-8 me-2 d-flex">
                            <img alt="" src={iconInfo} className="me-2" />
                            <span className="farm-description">{t('nearest-other-farm')}</span>
                        </div>
                        <span className="col farm-property">{`${
                            farm?.poultryFarmInNearestRadius ?? ''
                        } m`}</span>
                    </div>
                    <div className="d-flex w-100 align-items-center mt-2">
                        <div className="col-lg-4 col-md-5 col-sm-7 col-8 me-2 d-flex">
                            <img alt="" src={iconInfo} className="me-2" />
                            <span className="farm-description">
                                {t('nearest-residential-area')}
                            </span>
                        </div>
                        <span className="col farm-property">{`${
                            farm?.residentialAreaInNearestRadius ?? ''
                        } m`}</span>
                    </div>
                    <div className="d-flex mt-4">
                        <div
                            role="button"
                            className="btn-edit me-2"
                            onClick={() => setVisibleEditFarmModal(true)}
                        >
                            <img alt="" src={iconPencil} className="me-2" />
                            {t('edit')}
                        </div>
                        {from === 'admin' && (
                            <div
                                role="button"
                                className="btn-edit"
                                onClick={() => setShowConfirmDialog(true)}
                            >
                                <img alt="" src={iconTrash} className="me-2" />
                                {t('delete-farm')}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ConfirmDialog
                show={showConfirmDialog}
                title={t('delete-farm')}
                body={`${t('are-you-sure-to-delete')}`}
                confirmTitle={t('confirm')}
                declineTitle={t('cancel')}
                onHide={(confirm) => {
                    if (confirm) {
                        onDeleteFarm();
                    } else {
                        setShowConfirmDialog(false);
                    }
                }}
            />
            {farm && (
                <EditFarmInfoModal
                    show={visibleEditFarmModal}
                    handleClose={() => setVisibleEditFarmModal(false)}
                    farm={farm}
                    onChangeFarm={(farm) => setFarm(farm)}
                    mode={ModalMode.EDIT}
                />
            )}
            <LoadingSpinner loading={loading} />
        </div>
    );
}
