import { useTranslation } from 'react-i18next';
import { IBioReportQuestion, IGroupViolated } from 'shared/interface/bio-report.interface';
import 'shared/styles/animation.scss';
import './bio-report-tab.scss';

interface IProp {
    finalResult?: {
        finalPoint: number;
        finalTotalPoint: number;
        percentage: number;
        totalViolated: number;
    };
    violatedQuestions?: IBioReportQuestion[];
    groupViolated: IGroupViolated[];
}

export default function BioReportTab(prop: IProp) {
    const { finalResult, violatedQuestions, groupViolated } = prop;
    const { t } = useTranslation();

    const buildViolatedPoint = () => {
        return groupViolated?.map((group, indexGroup) => {
            if (group.failQuestions.length > 0 || group.successQuestions.length > 0) {
                return (
                    <ul key={indexGroup} className="list-group list-group-flush mt-3">
                        <li className="list-group-item border-bottom px-3">
                            <h4>{group.title}</h4>
                            <div className="row">
                                {[...group.failQuestions, ...group.successQuestions].map(
                                    (question, index) => (
                                        <div
                                            key={index}
                                            className={[
                                                'col-12 col-sm-6 border p-3 d-flex flex-column justify-content-between',
                                                !question.answerBoolean && 'bg-secondary-danger',
                                            ].join(' ')}
                                        >
                                            <div>
                                                <div className="d-flex">
                                                    {t('question')} {question.indexNumber}
                                                </div>
                                                <div className="d-flex text-secondary">
                                                    {question.title}
                                                </div>
                                            </div>

                                            <div className="d-flex mt-3">
                                                {question.answerBoolean && (
                                                    <span>
                                                        <i className="fa-solid fa-circle-check text-success fs-4"/>{' '}
                                                        {t('passed')}
                                                    </span>
                                                )}
                                                {!question.answerBoolean && (
                                                    <span>
                                                        <i className="fa-solid fa-circle-xmark text-danger fs-4"/>{' '}
                                                        {t('not-passed')}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </li>
                    </ul>
                );
            } else {
                return  <div key={indexGroup}/>;
            }
        });
    };

    return (
        <div className="swing-in-right-fwd bg-white">
            {violatedQuestions && (
                <div className="row bg-cargill-gray-darker border-bottom g-0 ps-3 pe-4">
                    <div className="col-10 py-3">
                        <span className="text-secondary">{t('total-violated-point-got')}</span>
                    </div>
                    <div className="col-2 text-center py-3">
                        <span className="badge bg-secondary-danger text-danger fs-6 rounded">
                            {String(finalResult?.totalViolated).padStart(2, '0')}/
                            {violatedQuestions.length}
                        </span>
                    </div>
                </div>
            )}
            {violatedQuestions && buildViolatedPoint()}
        </div>
    );
}
