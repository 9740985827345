import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IUser } from 'shared/interface/user.interface';
import { buildFileView } from 'shared/service/file';
import PendingUserFarm from '../pending-user-farm/pending-user-farm';

interface IProp {
    user?: IUser;
    show: boolean;
    onHide: (data: any) => void;
    onEditClicked: () => void;
}

export default function DetailUser(prop: IProp) {
    const { t } = useTranslation();
    const { user, show } = prop;

    return (
        <Modal show={show} dialogClassName="max-width-800" fullscreen="sm-down">
            <Modal.Header className="flex-column">
                <div className="d-flex justify-content-end w-100">
                    <button
                        className="btn btn-icon border-0 rounded-circle"
                        onClick={() => {
                            prop.onHide(null);
                        }}
                    >
                        <i className="fa-solid fa-xmark text-primary"/>
                    </button>
                </div>
                <div className="d-flex justify-content-center">
                    <span className="fs-3 fw-semibold">{t('info-detail')}</span>
                </div>
                <div className="d-flex justify-content-between px-3 mt-3 w-100">
                    <div className="d-flex flex-column">
                        <span className="fw-semibold">{user?.name}</span>
                        <span
                            className={[
                                user?.roleName === 'USER' && 'text-warning',
                                user?.roleName === 'ADMIN' && 'text-danger',
                                user?.roleName === 'SALE_MANAGER' && 'text-success',
                                user?.roleName === 'SALE' && 'text-info',
                            ].join(' ')}
                        >
                            {t(user?.roleName || '')}
                        </span>
                    </div>
                    <div className="d-flex">
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => prop.onEditClicked()}
                        >
                            <i className="fa-solid fa-pencil me-2"/>
                            {t('edit-info')}
                        </button>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row g-0 ps-3">
                    <span className="col-3 text-secondary">{t('customer-code')}</span>
                    <span className="col-4">{user?.cargillUserCode}</span>
                </div>
                <div className="row g-0 ps-3">
                    <span className="col-3 text-secondary">{t('Email')}</span>
                    <span className="col-4">{user?.email}</span>
                </div>
                <div className="row g-0 ps-3">
                    <span className="col-3 text-secondary">{t('phone')}</span>
                    <span className="col-4">{user?.phone}</span>
                </div>
                <div className="row g-0 ps-3">
                    <span className="col-3 text-secondary">{t('affiliated-farm')}</span>
                    <span className="col-4">
                        <PendingUserFarm userId={user?._id} />
                    </span>
                </div>
                <hr />
                <div className="row g-0 ps-3">
                    <span className="col-12 text-secondary">{t('zalo-account')}</span>
                </div>
                <div className="d-flex g-0 ps-3">
                    <img
                        alt=""
                        style={{ width: 50, height: 50 }}
                        className="rounded-circle"
                        src={buildFileView(user?.avatar)}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/image/picture/default.jpeg';
                        }}
                    />
                    <span className="col-3 my-auto ms-3">{user?.zalo || t('no-data')}</span>
                </div>
            </Modal.Body>
        </Modal>
    );
}
