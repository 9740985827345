/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Stepper, Step } from 'react-form-stepper';
import QuestionSetService from '../service/questionSet.service';
import { IQuestionSet } from 'shared/interface/question-set.interface';
import SingleQuestion from './single-question/single-question';
import { IBioReport, IBioReportQuestion } from 'shared/interface/bio-report.interface';
import GroupQuestion from './group-question/group-question';
import BioReportService from 'module/client/bio-report/service/bio-report.service';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'shared/components/confirm-dialog/confirm-dialog';
import { toast } from 'react-toastify';
import QuestionPickFarm from '../pick-farm/question-pick-farm';
import { QuestionType } from 'shared/enum/question.enum';
import cloneDeep from 'lodash.clonedeep';
import PickQuestionSet from '../pick-question-set/pick-question-set';
import { REPORT_TYPE } from 'shared/enum/bio-report.enum';
import { IBioReportRetail } from 'shared/interface/bio-report-retail.interface';
import { BioReportRetailService } from 'shared/service/bio-report-retail.service';
import EvaluateRetail from './evaluate-retail';

const stepStyleDto = {
    activeBgColor: '#638c1c',
    activeTextColor: '#ffffff',
    completedBgColor: '#638c1c',
    completedTextColor: '#ffffff',
    inactiveBgColor: '#F3F3F3',
    inactiveTextColor: 'rgba(99, 140, 28, 0.3)',
    size: '2em',
    circleFontSize: '1rem',
    labelFontSize: '0.875rem',
    borderRadius: '50%',
    fontWeight: '500',
};

export default function Evaluate() {
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [show, setShow] = useState<boolean>(false);
    const [showPickSet, setShowPickSet] = useState<boolean>(false);
    const [showPickFarm, setShowPickFarm] = useState<boolean>(false);

    const [questionSet, setQuestionSet] = useState<IQuestionSet>();
    const [type, setType] = useState<string>('');
    const [activeStep, setActiveStep] = useState<number>(0);
    const [bioReport, setBioReport] = useState<IBioReport>();
    const [bioReportRetail, setBioReportRetail] = useState<IBioReportRetail>();

    const [timer, setTimer] = useState<number>(20);

    const getActiveQuestionSet = useCallback(async () => {
        const result = await QuestionSetService.getActive();
        setQuestionSet(result);
    }, []);

    const initializeReport = useCallback(async () => {
        if (type === REPORT_TYPE.BIG_COMPANY) {
            const result = await BioReportService.initialize(
                searchParams.get('id') || '',
                questionSet?._id ?? ''
            );
            setBioReport(result);
        } else if (type === REPORT_TYPE.RETAIL) {
            const result = await BioReportRetailService.initialize(
                searchParams.get('id') || '',
                questionSet?._id ?? ''
            );
            setBioReportRetail(result);
        }
    }, [searchParams, questionSet]);

    useEffect(() => {
        // @ts-ignore
        window.gtag('event', 'GO_TO_EVALUATE', {});
        if (!searchParams.get('id')) {
            setShowPickFarm(true);
            return;
        }
        if (!questionSet) {
            setShowPickSet(true);
        }
        if (questionSet) {
            initializeReport();
        }
    }, [searchParams.get('id'), questionSet]);

    const temporarySave = async () => {
        await BioReportService.update(bioReport?._id || '', bioReport);
    };

    useEffect(() => {
        let interval;
        if (type === REPORT_TYPE.BIG_COMPANY) {
            if (timer === 0) {
                temporarySave();
                setTimer(20);
            }
            interval = setInterval(() => {
                if (timer > 0) {
                    setTimer(timer - 1);
                }
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [timer]);

    const setIcon = (index: number) => {
        if (index === activeStep) {
            return <i className="fa-solid fa-pen" />;
        } else if (index < activeStep) {
            return <i className="fa-solid fa-check" />;
        } else {
            return <i className="fa-solid fa-circle" />;
        }
    };

    const setAnswerYesNo = (answer: boolean, index: number) => {
        if (bioReport) {
            const newBioReport = cloneDeep(bioReport);
            newBioReport.listQuestion[activeStep].questions[index].answerBoolean = answer;
            setBioReport(newBioReport);
        }
    };

    const setGroupAnswer = (
        answer: {
            isGroupStyle: boolean;
            groupTitle: string;
            yesAnswerTitle: string;
            noAnswerTitle: string;
            questions: IBioReportQuestion[];
        }[]
    ) => {
        if (bioReport) {
            const newBioReport = cloneDeep(bioReport) as IBioReport;
            newBioReport.listQuestion[activeStep].groupQuestions = answer;
            setBioReport(newBioReport);
        }
    };

    const setAnswerFillIn = (answer: string, questionIndex: number) => {
        if (bioReport) {
            const newBioReport = cloneDeep(bioReport) as IBioReport;
            newBioReport.listQuestion[activeStep].questions[questionIndex].answerText = answer;
            setBioReport(newBioReport);
        }
    };

    const reviewAndScroll = () => {
        if (bioReport) {
            // for (let i = 0; i < bioReport?.listQuestion.length; i++) {
            if (!bioReport.listQuestion[activeStep].isGroupStyle) {
                for (let j = 0; j < bioReport.listQuestion[activeStep].questions.length; j++) {
                    if (
                        bioReport.listQuestion[activeStep].questions[j].answerBoolean === null &&
                        bioReport.listQuestion[activeStep].questions[j].questionType ===
                            QuestionType.YES_NO
                    ) {
                        const startNum = getStartNumber();
                        toast.dark(`${t('missing-answer-question')} ${startNum + j}`);
                        const elem = document.getElementById(
                            bioReport.listQuestion[activeStep].questions[j].dataCore
                        );
                        elem?.scrollIntoView({
                            behavior: 'smooth',
                        });
                        return false;
                    }
                }
            } else {
                for (let j = 0; j < bioReport.listQuestion[activeStep].groupQuestions.length; j++) {
                    for (
                        let k = 0;
                        k < bioReport.listQuestion[activeStep].groupQuestions[j].questions.length;
                        k++
                    ) {
                        if (
                            bioReport.listQuestion[activeStep].groupQuestions[j].questions[k]
                                .answerBoolean === null
                        ) {
                            let startNum = getStartNumber();
                            let sum = 0;
                            if (j > 0) {
                                for (let i = 0; i < j; i++) {
                                    sum +=
                                        bioReport.listQuestion[activeStep].groupQuestions[i]
                                            .questions.length;
                                }
                            }
                            startNum += sum;
                            toast.dark(`${t('missing-answer-question')} ${startNum + k} `);
                            const elem = document.getElementById(
                                bioReport.listQuestion[activeStep].groupQuestions[j].questions[k]
                                    .dataCore
                            );
                            elem?.scrollIntoView({
                                behavior: 'smooth',
                            });
                            return false;
                        }
                    }
                }
            }
            // }
            return true;
        }
        return false;
    };

    const nextStep = async () => {
        if (activeStep + 1 === questionSet?.listQuestion?.length) {
            await BioReportService.update(bioReport?._id || '', bioReport, false);
            setShow(true);
            return;
        }
        const ok = reviewAndScroll();
        setActiveStep(ok ? activeStep + 1 : activeStep);
    };

    const saveAndGo = async () => {
        const result = await BioReportService.update(bioReport?._id || '', bioReport, true);
        // @ts-ignore
        window.gtag('event', 'FINISH_TEST', {});
        if (result) {
            toast.success(t('save-successfully'), { position: 'bottom-center' });
            navigate({
                pathname: `/bio-report/${bioReport?._id}`,
            });
        }
    };

    const getStartNumber = () => {
        let sum = 0;
        for (let i = 0; i < activeStep; i++) {
            if (!bioReport?.listQuestion[i].isGroupStyle) {
                sum += bioReport?.listQuestion[i].questions.length || 0;
            } else {
                let subSum = 0;
                bioReport.listQuestion[i].groupQuestions.forEach((e) => {
                    subSum += e.questions.length;
                });
                sum += subSum;
            }
        }
        return sum + 1;
    };

    const buildQuestions = () => {
        const list = questionSet?.listQuestion[activeStep];
        if (list?.isGroupStyle) {
            return (
                <div className="bg-secondary p-3 round-1">
                    <GroupQuestion
                        groupTitle={list.groupTitle as string}
                        yesAnswerTitle={list.yesAnswerTitle}
                        noAnswerTitle={list.noAnswerTitle}
                        groupQuestions={list.groupQuestions}
                        onAnswer={function (
                            result: {
                                isGroupStyle: boolean;
                                groupTitle: string;
                                yesAnswerTitle: string;
                                noAnswerTitle: string;
                                questions: IBioReportQuestion[];
                            }[]
                        ): void {
                            setGroupAnswer(result);
                        }}
                        initializeGroupQuestions={
                            bioReport?.listQuestion[activeStep].groupQuestions || []
                        }
                        startNumber={getStartNumber()}
                    />
                    <div className="d-flex justify-content-center mt-3">
                        <button
                            className="btn btn-primary w-75 rounded-1"
                            onClick={() => nextStep()}>
                            {t('continue')}
                        </button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="bg-secondary p-3 round-1">
                    <div className="d-flex">
                        <span className="text-primary text-uppercase fw-bold h4">
                            {list?.groupTitle}
                        </span>
                    </div>
                    <div className="overflow-auto px-1" style={{ height: '60vh' }}>
                        {list?.questions.map((id, questionIndex) => (
                            <SingleQuestion
                                key={id}
                                yesAnswerTitle={list.yesAnswerTitle}
                                noAnswerTitle={list.noAnswerTitle}
                                id={id}
                                questionIndex={questionIndex}
                                onAnswer={(result) => {
                                    setAnswerYesNo(result, questionIndex);
                                }}
                                onAnswerFillIn={function (result: string): void {
                                    setAnswerFillIn(result, questionIndex);
                                }}
                                initializeValue={
                                    bioReport?.listQuestion[activeStep]?.questions[questionIndex]
                                        ?.answerBoolean
                                }
                                initializeFillValue={
                                    bioReport?.listQuestion[activeStep]?.questions[questionIndex]
                                        ?.answerText || ''
                                }
                                startNumber={getStartNumber() + questionIndex}
                            />
                        ))}
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <button
                            className="btn btn-primary w-75 rounded-1"
                            onClick={() => nextStep()}>
                            {t('continue')}
                        </button>
                    </div>
                </div>
            );
        }
    };

    return (
        <div>
            {type === REPORT_TYPE.BIG_COMPANY && (
                <div>
                    <Stepper activeStep={activeStep} styleConfig={stepStyleDto}>
                        {questionSet &&
                            questionSet?.listQuestion?.length > 0 &&
                            questionSet?.listQuestion?.map((list, index) => (
                                <Step
                                    key={index}
                                    label={list.groupTitle}
                                    children={setIcon(index)}
                                    onClick={() => setActiveStep(index)}
                                />
                            ))}
                    </Stepper>
                    {buildQuestions()}
                </div>
            )}
            {type === REPORT_TYPE.RETAIL && (
                <EvaluateRetail bioReportRetail={bioReportRetail}></EvaluateRetail>
            )}
            <ConfirmDialog
                title={t('do-you-want-to-save-result')}
                body={t('question-confirm-body')}
                confirmTitle={t('yes')}
                declineTitle={t('continue-to-edit')}
                show={show}
                onHide={function (result: boolean): void {
                    if (result) {
                        saveAndGo();
                    }
                    setShow(false);
                }}
            />
            <QuestionPickFarm
                show={showPickFarm}
                onHide={function (result: string | null): void {
                    if (result) {
                        setShowPickFarm(false);
                    } else {
                        navigate('/');
                    }
                }}
                disableClose={true}
            />
            <PickQuestionSet
                show={showPickSet}
                onHide={function (result: IQuestionSet | null, type: string): void {
                    if (result) {
                        setType(type);
                        setQuestionSet(result);
                        setShowPickSet(false);
                    } else {
                        navigate('/');
                    }
                }}
                disableClose={true}
            />
        </div>
    );
}
