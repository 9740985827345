export interface IProp {
    active?: boolean;
    activeColor?: string;
    defaultColor?: string;
}

const IconHome = (props: IProp) => {
    const { active = false, activeColor, defaultColor } = props;
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M1 8.875H6.25C6.73125 8.875 7.125 8.48125 7.125 8V1C7.125 0.51875 6.73125 0.125 6.25 0.125H1C0.51875 0.125 0.125 0.51875 0.125 1V8C0.125 8.48125 0.51875 8.875 1 8.875ZM1 15.875H6.25C6.73125 15.875 7.125 15.4812 7.125 15V11.5C7.125 11.0187 6.73125 10.625 6.25 10.625H1C0.51875 10.625 0.125 11.0187 0.125 11.5V15C0.125 15.4812 0.51875 15.875 1 15.875ZM9.75 15.875H15C15.4812 15.875 15.875 15.4812 15.875 15V8C15.875 7.51875 15.4812 7.125 15 7.125H9.75C9.26875 7.125 8.875 7.51875 8.875 8V15C8.875 15.4812 9.26875 15.875 9.75 15.875ZM8.875 1V4.5C8.875 4.98125 9.26875 5.375 9.75 5.375H15C15.4812 5.375 15.875 4.98125 15.875 4.5V1C15.875 0.51875 15.4812 0.125 15 0.125H9.75C9.26875 0.125 8.875 0.51875 8.875 1Z"
                fill={active ? activeColor ?? '#638C1C' : defaultColor || '#474C55'}
            />
        </svg>
    );
};

export default IconHome;
