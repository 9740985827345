import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActiveUser from './active-user/active-user';
import BlockedUser from './blocked-user/block-user';
import PendingApprovalUser from './pending-approval-user/pending-approval-user';

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function UserManagement() {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState<number>(0);

    return (
        <div className="container-fluid p-3">
            <ul className="nav nav-tabs mb-3">
                <li className="nav-item">
                    <a
                        className={['nav-link', activeTab === 0 && 'active'].join(' ')}
                        aria-current="page"
                        href="#"
                        onClick={() => {
                            setActiveTab(0);
                        }}
                    >
                        <span className="fw-semibold">{t('pending-approval')}</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={['nav-link', activeTab === 1 && 'active'].join(' ')}
                        href="#"
                        onClick={() => {
                            setActiveTab(1);
                        }}
                    >
                        <span className="fw-semibold">{t('active')}</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={['nav-link', activeTab === 2 && 'active'].join(' ')}
                        href="#"
                        onClick={() => {
                            setActiveTab(2);
                        }}
                    >
                        <span className="fw-semibold">{t('blocked')}</span>
                    </a>
                </li>
            </ul>
            <div className="my-3">
                {activeTab === 0 && <PendingApprovalUser />}
                {activeTab === 1 && <ActiveUser />}
                {activeTab === 2 && <BlockedUser />}
            </div>
        </div>
    );
}
