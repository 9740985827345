/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { IQuestionRetail } from 'shared/interface/question-retail.interface';
import {
    IQuestionInSetRetail,
    IQuestionSetRetail,
} from 'shared/interface/question-set-retail.interface';
import { QuestionRetailService } from 'shared/service/question-retail.service';
import { QuestionSetRetailService } from 'shared/service/question-set-retail.service';
import { v4 as uuid } from 'uuid';

interface IProp {
    questionSet?: IQuestionSetRetail;
    show: boolean;
    onHide: (result: IQuestionSetRetail | null) => void;
}

export default function EditQuestionSetRetail(prop: IProp) {
    const { questionSet, show } = prop;
    const { t } = useTranslation();

    const [validated, setValidated] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [listQuestion, setListQuestion] = useState<IQuestionInSetRetail[]>([]);
    const [populatedQuestions, setPopulatedQuestions] = useState<IQuestionRetail[]>([]);

    const getAllQuestions = async () => {
        if (questionSet) {
            let questionIds: string[] = [];
            questionSet.listQuestion.forEach((list) => {
                if (!list.isGroupStyle) {
                    questionIds = [...questionIds, ...list.questions];
                } else {
                    list.groupQuestions.forEach((group) => {
                        questionIds = [...questionIds, ...group.questions];
                    });
                }
            });
            const result = await axios.all(
                questionIds.map((id) => {
                    return QuestionRetailService.find(id);
                })
            );
            setPopulatedQuestions(result);
        }
    };

    const getDefaultSelect = (ids: string[], indexList: number) => {
        return ids.map((id) => {
            const found = populatedQuestions.find((q) => q._id === id);
            const foundIDs = listQuestion[indexList].questions.find((e) => e === id);
            return {
                label: found ? `${found?.customCode} - ${found?.title}` : `id = ${foundIDs}`,
                value: found ? found?._id : foundIDs,
            };
        });
    };

    const init = () => {
        setTitle(questionSet ? questionSet.title : '');
        setListQuestion(questionSet ? questionSet.listQuestion : []);
        getAllQuestions();
    };

    useEffect(() => {
        init();
    }, [questionSet]);

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity()) {
            let res;
            if (questionSet) {
                res = await QuestionSetRetailService.update(questionSet._id, {
                    title,
                    listQuestion,
                });
            } else {
                res = await QuestionSetRetailService.create({ title, listQuestion });
            }
            if (res) {
                toast.success(t('successfully'), { position: 'bottom-center' });
                prop.onHide(res);
            }
        }
    };

    const addSingleGroup = () => {
        const newList: IQuestionInSetRetail = {
            isGroupStyle: false,
            yesAnswerTitle: '',
            noAnswerTitle: '',
            questions: [],
            groupQuestions: [],
        };
        setListQuestion([...listQuestion, newList]);
    };

    const removeSingleGroup = (indexList: number) => {
        listQuestion.splice(indexList, 1);
        setListQuestion([...listQuestion]);
    };

    const addMultipleGroup = () => {
        const newList: IQuestionInSetRetail = {
            isGroupStyle: true,
            yesAnswerTitle: '',
            noAnswerTitle: '',
            questions: [],
            groupQuestions: [],
        };
        setListQuestion([...listQuestion, newList]);
    };

    const removeMultipleGroup = (indexList: number, indexGroup: number) => {
        listQuestion[indexList].groupQuestions.splice(indexGroup, 1);
        setListQuestion([...listQuestion]);
    };

    const setTitleYes = (value: string, index: number) => {
        listQuestion[index].yesAnswerTitle = value;
        setListQuestion([...listQuestion]);
    };

    const setSubTitleYes = (value: string, indexList: number, indexGroup: number) => {
        listQuestion[indexList].groupQuestions[indexGroup].yesAnswerTitle = value;
        setListQuestion([...listQuestion]);
    };

    const setTitleNo = (value: string, index: number) => {
        listQuestion[index].noAnswerTitle = value;
        setListQuestion([...listQuestion]);
    };

    const setSubTitleNo = (value: string, indexList: number, indexGroup: number) => {
        listQuestion[indexList].groupQuestions[indexGroup].noAnswerTitle = value;
        setListQuestion([...listQuestion]);
    };

    const setGroupTitle = (value: string, index: number) => {
        listQuestion[index].groupTitle = value;
        setListQuestion([...listQuestion]);
    };

    const setSubGroupTitle = (value: string, indexList: number, indexGroup: number) => {
        listQuestion[indexList].groupQuestions[indexGroup].groupTitle = value;
        setListQuestion([...listQuestion]);
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<{ label: string; value: string }[]>((resolve) => {
            setTimeout(async () => {
                const res = await QuestionRetailService.search(inputValue);
                resolve(
                    res.map((u) => {
                        return {
                            label: `${u.customCode}: ${u.title}`,
                            value: u._id,
                        };
                    })
                );
            }, 300);
        });

    const addSingleListQuestion = (
        questions: { value: string; label: string }[],
        indexList: number
    ) => {
        listQuestion[indexList].questions = questions.map((q) => q.value);
        setListQuestion([...listQuestion]);
    };

    const addMultipleListQuestion = (
        questions: { value: string; label: string }[],
        indexList: number,
        indexGroup: number
    ) => {
        listQuestion[indexList].groupQuestions[indexGroup].questions = questions.map(
            (q) => q.value
        );
        setListQuestion([...listQuestion]);
    };

    const addSubGroup = (indexList: number) => {
        const groupQuestions = {
            isGroupStyle: false,
            groupTitle: '',
            yesAnswerTitle: '',
            noAnswerTitle: '',
            questions: [],
        };
        listQuestion[indexList].groupQuestions = [
            ...listQuestion[indexList].groupQuestions,
            groupQuestions,
        ];
        setListQuestion([...listQuestion]);
    };

    return (
        <Modal show={show} dialogClassName="max-width-800" fullscreen="sm-down">
            <Modal.Header className="flex-column">
                <div className="d-flex justify-content-end w-100">
                    <button
                        type="button"
                        className="btn btn-icon border-0 rounded-circle"
                        onClick={() => {
                            prop.onHide(null);
                        }}>
                        <i className="fa-solid fa-xmark text-primary" />
                    </button>
                </div>
                <div className="d-flex justify-content-center">
                    <span className="fs-3 fw-semibold">
                        {questionSet ? t('edit-info') : t('create-question-set')}
                    </span>
                </div>
            </Modal.Header>
            <Modal.Body className="p-5">
                <Form onSubmit={handleSubmit} noValidate={true} validated={validated}>
                    <div className="row">
                        <Form.Group as={Col} sm="12">
                            <Form.Label>{t('title')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Control
                                    type="text"
                                    className="text-gray"
                                    required={true}
                                    placeholder={t('title')}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('required-field')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12 col-md-6">
                            <button
                                className="btn btn-secondary d-flex justify-content-between gap-2"
                                type="button"
                                onClick={() => addSingleGroup()}>
                                <i className="fa-solid fa-circle-dot my-auto" />
                                <span className="my-auto text-end">
                                    {t('add-step-single-group')}
                                </span>
                            </button>
                        </div>
                    </div>
                    {listQuestion.map((list, indexList) => {
                        if (!list.isGroupStyle) {
                            return (
                                <div key={uuid()} className="div-shadow p-3 mt-3">
                                    <div className="d-flex justify-content-between">
                                        <h5>
                                            {t('group')}:{indexList + 1}
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={() => removeSingleGroup(indexList)}>
                                            <i className="fa-solid fa-trash-can" />
                                        </button>
                                    </div>
                                    <div className="row mt-2">
                                        <Form.Group as={Col} sm="12">
                                            <Form.Label>{t('group-title')}</Form.Label>
                                            <InputGroup hasValidation={true}>
                                                <Form.Control
                                                    type="text"
                                                    className="text-gray"
                                                    required={true}
                                                    placeholder={t('group-title')}
                                                    value={list.groupTitle}
                                                    onChange={(e) =>
                                                        setGroupTitle(e.target.value, indexList)
                                                    }
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </div>
                                    <div className="row mt-2">
                                        <Form.Group as={Col} sm="12" className="mt-2">
                                            <Form.Label>{t('list-question')}</Form.Label>
                                            <InputGroup hasValidation={true}>
                                                <AsyncSelect
                                                    isClearable={true}
                                                    className="w-100"
                                                    cacheOptions={true}
                                                    value={getDefaultSelect(
                                                        list.questions,
                                                        indexList
                                                    )}
                                                    onChange={(newValue) => {
                                                        addSingleListQuestion(
                                                            newValue as any,
                                                            indexList
                                                        );
                                                    }}
                                                    loadOptions={promiseOptions}
                                                    isMulti={true}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <div key={indexList} className="div-shadow p-3 mt-3">
                                    <div className="d-flex justify-content-between">
                                        <h5>
                                            {t('group')}:{indexList + 1}
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={() => removeSingleGroup(indexList)}>
                                            <i className="fa-solid fa-trash-can" />
                                        </button>
                                    </div>
                                    <div className="row">
                                        <Form.Group as={Col} sm="12">
                                            <Form.Label>{t('group-title')}</Form.Label>
                                            <InputGroup hasValidation={true}>
                                                <Form.Control
                                                    type="text"
                                                    className="text-gray"
                                                    required={true}
                                                    placeholder={t('group-title')}
                                                    value={list.groupTitle}
                                                    onChange={(e) =>
                                                        setGroupTitle(e.target.value, indexList)
                                                    }
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 col-sm-6">
                                            <button
                                                type="button"
                                                className="btn btn-info"
                                                onClick={() => addSubGroup(indexList)}>
                                                <i className="fa-solid fa-plus" />
                                                <span className="ms-3">{t('add-sub-group')}</span>
                                            </button>
                                        </div>
                                    </div>
                                    {listQuestion[indexList].groupQuestions.map(
                                        (group, indexGroup) => (
                                            <div key={indexGroup} className="div-shadow p-3 mt-3">
                                                <div className="d-flex justify-content-between">
                                                    <h5>
                                                        {t('group')}:{indexGroup + 1}
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-danger"
                                                        onClick={() =>
                                                            removeMultipleGroup(
                                                                indexList,
                                                                indexGroup
                                                            )
                                                        }>
                                                        <i className="fa-solid fa-trash-can" />
                                                    </button>
                                                </div>
                                                <div className="row mt-2">
                                                    <Form.Group as={Col} sm="12">
                                                        <Form.Label>{t('group-title')}</Form.Label>
                                                        <InputGroup hasValidation={true}>
                                                            <Form.Control
                                                                type="text"
                                                                className="text-gray"
                                                                required={true}
                                                                placeholder={t('group-title')}
                                                                value={group.groupTitle}
                                                                onChange={(e) =>
                                                                    setSubGroupTitle(
                                                                        e.target.value,
                                                                        indexList,
                                                                        indexGroup
                                                                    )
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>
                                                </div>
                                                <div className="row mt-2">
                                                    <Form.Group as={Col} sm="12" md="6">
                                                        <Form.Label>
                                                            {t('yes-answer-title')}
                                                        </Form.Label>
                                                        <InputGroup hasValidation={true}>
                                                            <Form.Control
                                                                type="text"
                                                                className="text-gray"
                                                                required={true}
                                                                placeholder={t('yes-answer-title')}
                                                                value={group.yesAnswerTitle}
                                                                onChange={(e) =>
                                                                    setSubTitleYes(
                                                                        e.target.value,
                                                                        indexList,
                                                                        indexGroup
                                                                    )
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="12" md="6">
                                                        <Form.Label>
                                                            {t('no-answer-title')}
                                                        </Form.Label>
                                                        <InputGroup hasValidation={true}>
                                                            <Form.Control
                                                                type="text"
                                                                className="text-gray"
                                                                required={true}
                                                                placeholder={t('no-answer-title')}
                                                                value={group.noAnswerTitle}
                                                                onChange={(e) =>
                                                                    setSubTitleNo(
                                                                        e.target.value,
                                                                        indexList,
                                                                        indexGroup
                                                                    )
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>
                                                </div>
                                                <div className="row mt-2">
                                                    <Form.Group as={Col} sm="12" className="mt-2">
                                                        <Form.Label>
                                                            {t('list-question')}
                                                        </Form.Label>
                                                        <InputGroup hasValidation={true}>
                                                            <AsyncSelect
                                                                isClearable={true}
                                                                className="w-100"
                                                                cacheOptions={true}
                                                                value={getDefaultSelect(
                                                                    group.questions,
                                                                    0
                                                                )}
                                                                onChange={(newValue) => {
                                                                    addMultipleListQuestion(
                                                                        newValue as any,
                                                                        indexList,
                                                                        indexGroup
                                                                    );
                                                                }}
                                                                loadOptions={promiseOptions}
                                                                isMulti={true}
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>
                                                </div>
                                                <div className="row">
                                                    {/* {list.questions.map((question, indexQuestion) => (
                                            <div
                                                key={indexQuestion}
                                                className="col-12 col-sm-6"
                                            ></div>
                                        ))} */}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            );
                        }
                    })}
                    <div className="row justify-content-center mt-3">
                        <button type="submit" className="col-12 col-sm-6 btn btn-primary">
                            {t('finish')}
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
