/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { QuestionType } from 'shared/enum/question.enum';
import {
    IBioReportListQuestion,
    IBioReportQuestion,
    ICalculatedFinalResult,
} from 'shared/interface/bio-report.interface';
import { IUser } from 'shared/interface/user.interface';
import './report-detail-compare.scss';

interface IProp {
    finalResult1?: ICalculatedFinalResult;
    finalResult2?: ICalculatedFinalResult;
    finalResult3?: ICalculatedFinalResult;
    performer1?: IUser;
    performer2?: IUser;
    performer3?: IUser;
}

export default function ReportDetailCompare(prop: IProp) {
    const { finalResult1, finalResult2, finalResult3 } = prop;
    const { t } = useTranslation();

    const [fillInGroups1, setFillInGroups1] = useState<IBioReportListQuestion[]>([]);
    const [fillInGroups2, setFillInGroups2] = useState<IBioReportListQuestion[]>([]);
    const [fillInGroups3, setFillInGroups3] = useState<IBioReportListQuestion[]>([]);

    const [listCollapse, setListCollapse] = useState<number[]>([]);
    const [childListCollapse, setChildListCollapse] = useState<string[]>([]);

    const checkHasFillIn = (questions: IBioReportQuestion[]) => {
        for (const question of questions) {
            if (question.questionType === QuestionType.FILL_IN) {
                return true;
            }
        }
        return false;
    };

    const processFillInGroup = (index: number) => {
        if (index === 1) {
            if (finalResult1 && fillInGroups1.length === 0) {
                finalResult1?.bioReport.listQuestion.forEach((list) => {
                    if (!list.isGroupStyle && checkHasFillIn(list.questions)) {
                        setFillInGroups1([...fillInGroups1, list]);
                    }
                });
            }
        } else if (index === 2 && fillInGroups2.length === 0) {
            if (finalResult2) {
                finalResult2?.bioReport.listQuestion.forEach((list) => {
                    if (!list.isGroupStyle && checkHasFillIn(list.questions)) {
                        setFillInGroups2([...fillInGroups2, list]);
                    }
                });
            }
        } else if (index === 3 && fillInGroups3.length === 0) {
            if (finalResult3) {
                finalResult3?.bioReport.listQuestion.forEach((list) => {
                    if (!list.isGroupStyle && checkHasFillIn(list.questions)) {
                        setFillInGroups3([...fillInGroups3, list]);
                    }
                });
            }
        }
    };

    useEffect(() => {
        processFillInGroup(1);
        processFillInGroup(2);
        processFillInGroup(3);
    }, [finalResult1, finalResult2, finalResult3]);

    const renderAvatar = (index: number) => {
        return (
            <div className="d-table-cell">
                <span className="my-auto">
                    {t('report')} {index}
                </span>
            </div>
        );
    };

    const toggleIndexList = (index: number) => {
        if (listCollapse.includes(index)) {
            setListCollapse([...listCollapse.filter((l) => l !== index)]);
        } else {
            setListCollapse([...listCollapse, index]);
        }
    };

    const toggleChildList = (indexList: number, indexGroup: number) => {
        if (childListCollapse.includes(`${indexList}-${indexGroup}`)) {
            setChildListCollapse([
                ...childListCollapse.filter((l) => l !== `${indexList}-${indexGroup}`),
            ]);
        } else {
            setChildListCollapse([...childListCollapse, `${indexList}-${indexGroup}`]);
        }
    };

    const buildSingleQuestionList = (indexList: number, indexGroup?: number) => {
        if (!finalResult1?.bioReport.listQuestion[indexList].isGroupStyle) {
            return (
                <div>
                    {finalResult1?.bioReport.listQuestion[indexList].questions.map(
                        (question, index) => (
                            <div
                                key={index}
                                className={`row rounded ${
                                    question.isViolatedPoint
                                        ? 'border border-danger question-picking-bg-wrong'
                                        : 'question-picking-bg-correct'
                                } m-1`}
                            >
                                <div className="col-5 my-auto text-secondary py-2 ps-5">
                                    <span>{question.title}</span>
                                </div>
                                <div className="col-2 text-center py-2 d-flex justify-content-center align-items-center">
                                    {finalResult1?.bioReport.listQuestion[indexList].questions[
                                        index
                                    ].answerBoolean ? (

                                        <i className="fa-solid fa-circle-check text-success fs-4"/>
                                    ) : (

                                        <i className="fa-solid fa-circle-xmark text-danger fs-4"/>
                                    )}
                                </div>
                                <div className="col-2 text-center py-2 d-flex justify-content-center align-items-center">
                                    {finalResult2?.bioReport.listQuestion[indexList].questions[
                                        index
                                    ].answerBoolean ? (

                                        <i className="fa-solid fa-circle-check text-success fs-4"/>
                                    ) : (

                                        <i className="fa-solid fa-circle-xmark text-danger fs-4"/>
                                    )}
                                </div>
                                <div className="col-2 text-center py-2 d-flex justify-content-center align-items-center">
                                    {finalResult3?.bioReport.listQuestion[indexList].questions[
                                        index
                                    ].answerBoolean ? (

                                        <i className="fa-solid fa-circle-check text-success fs-4"/>
                                    ) : (

                                        <i className="fa-solid fa-circle-xmark text-danger fs-4"/>
                                    )}
                                </div>
                                <div className="col-1 text-center py-2"/>
                            </div>
                        )
                    )}
                </div>
            );
        } else {
            return (
                <div>
                    {finalResult1?.bioReport.listQuestion[indexList].groupQuestions[
                        indexGroup || 0
                    ].questions.map((question, index) => (
                        <div
                            key={index}
                            className={`row px-3 m-1 rounded ${
                                question.isViolatedPoint
                                    ? 'border border-danger question-picking-bg-wrong'
                                    : 'question-picking-bg-correct'
                            }`}
                        >
                            <div className="col-5 my-auto text-secondary py-2 ps-5">
                                <span>{question.title}</span>
                            </div>
                            <div className="col-2 text-center py-2 d-flex justify-content-center align-items-center">
                                {finalResult1?.bioReport.listQuestion[indexList].groupQuestions[
                                    indexGroup || 0
                                ].questions[index].answerBoolean ? (

                                    <i className="fa-solid fa-circle-check text-success fs-4"/>
                                ) : (

                                    <i className="fa-solid fa-circle-xmark text-danger fs-4"/>
                                )}
                            </div>
                            <div className="col-2 text-center py-2 d-flex justify-content-center align-items-center">
                                {finalResult2?.bioReport.listQuestion[indexList].groupQuestions[
                                    indexGroup || 0
                                ].questions[index].answerBoolean ? (

                                    <i className="fa-solid fa-circle-check text-success fs-4"/>
                                ) : (

                                    <i className="fa-solid fa-circle-xmark text-danger fs-4"/>
                                )}
                            </div>
                            <div className="col-2 text-center py-2 d-flex justify-content-center align-items-center">
                                {finalResult3?.bioReport.listQuestion[indexList].groupQuestions[
                                    indexGroup || 0
                                ].questions[index].answerBoolean ? (

                                    <i className="fa-solid fa-circle-check text-success fs-4"/>
                                ) : (

                                    <i className="fa-solid fa-circle-xmark text-danger fs-4"/>
                                )}
                            </div>
                            <div className="col-1 text-center py-2"/>
                        </div>
                    ))}
                </div>
            );
        }
    };

    const buildGroupQuestionList = (indexList: number) => {
        return finalResult1?.bioReport.listQuestion[indexList].groupQuestions.map(
            (group, indexGroup) => (
                <div key={indexGroup}>
                    <div
                        className="d-flex justify-content-between p-3 ps-4  div-picking-bg clickable"
                        onClick={() => {
                            toggleChildList(indexList, indexGroup);
                        }}
                    >
                        <span>{group.groupTitle}</span>
                        <button className="btn btn-outline-secondary rounded-circle border-0 py-0">
                            {childListCollapse.includes(`${indexList}-${indexGroup}`) ? (

                                <i className="fa-solid fa-caret-up"/>
                            ) : (

                                <i className="fa-solid fa-caret-down"/>
                            )}
                        </button>
                    </div>
                    <Collapse in={childListCollapse.includes(`${indexList}-${indexGroup}`)}>
                        <div>{buildSingleQuestionList(indexList, indexGroup)}</div>
                    </Collapse>
                </div>
            )
        );
    };

    const renderFillInQuestion = (index: number, indexGroup: number, indexQuestion: number) => {
        let question;
        if (index === 1) {
            question = fillInGroups1[indexGroup].questions[indexQuestion];
        } else if (index === 2) {
            question = fillInGroups2[indexGroup].questions[indexQuestion];
        } else if (index === 3) {
            question = fillInGroups3[indexGroup].questions[indexQuestion];
        }
        if (question) {
            return (
                <>
                    <span>{question.answerText}</span>
                </>
            );
        } else {
            return  <div/>;
        }
    };

    return (
        <div className="row mt-3 py-3 div-shadow">
            <h3>{t('report')}</h3>
            <div className="div-shadow mt-3">
                <div className="row bg-secondary g-0 px-1 mt-3 align-middle">
                    <div className="col-5 py-3 my-auto">
                        <div className="d-table-cell">
                            <span className="fw-bold">{t('point-from-evaluation')}</span>
                        </div>
                    </div>
                    <div className="col-2 text-center py-3 my-auto border">{renderAvatar(1)}</div>
                    <div className="col-2 text-center py-3 my-auto border">{renderAvatar(2)}</div>
                    <div className="col-2 text-center py-3 my-auto border">{renderAvatar(3)}</div>
                    <div className="col-1 text-center py-3"/>
                </div>
                <div className="row bg-secondary g-0 px-1">
                    <div className="col-5 py-3 border">
                        <span className="text-secondary"/>
                    </div>
                    <div className="col-2 text-center py-3 border">% {t('passed')}</div>
                    <div className="col-2 text-center py-3 border">% {t('passed')}</div>
                    <div className="col-2 text-center py-3 border">% {t('passed')}</div>
                    <div className="col-1 text-center py-3"/>
                </div>
                <div className="row px-1 g-0 clickable div-picking-bg">
                    <div className="col-5 my-auto py-3 border ps-1">{t('total')}</div>
                    <div className="col-2 text-center my-auto py-3 border">
                        {finalResult1?.finalResult.percentage.toFixed(2)}%
                    </div>
                    <div className="col-2 text-center my-auto py-3 border">
                        {finalResult2?.finalResult.percentage.toFixed(2)}%
                    </div>
                    <div className="col-2 text-center my-auto py-3 border">
                        {finalResult3?.finalResult.percentage.toFixed(2)}%
                    </div>
                </div>
                {finalResult1 &&
                    finalResult2 &&
                    finalResult3 &&
                    finalResult1.bioReport.listQuestion.map(
                        (list, indexList) =>
                            !checkHasFillIn(list.questions) && (
                                <div key={indexList}>
                                    <div
                                        className="row g-0 px-1 clickable div-picking-bg"
                                        onClick={() => {
                                            toggleIndexList(indexList);
                                        }}
                                    >
                                        <div className="col-5 my-auto py-3 border ps-1">
                                            {list.groupTitle}
                                        </div>
                                        <div className="col-2 text-center my-auto py-3 border">
                                            {finalResult1.finalResult.percentage.toFixed(2)}%
                                        </div>
                                        <div className="col-2 text-center my-auto py-3 border">
                                            {finalResult2.finalResult.percentage.toFixed(2)}%
                                        </div>
                                        <div className="col-2 text-center my-auto py-3 border">
                                            {finalResult3.finalResult.percentage.toFixed(2)}%
                                        </div>
                                        <div className="col-1 text-center my-auto py-3">
                                            <button className="btn btn-outline-primary rounded-circle border-0 py-0">
                                                {listCollapse?.includes(indexList) ? (

                                                    <i className="fa-solid fa-caret-up"/>
                                                ) : (

                                                    <i className="fa-solid fa-caret-down"/>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                    <Collapse in={listCollapse?.includes(indexList)}>
                                        <div className="div-shadow-1 border-0">
                                            {!list.isGroupStyle &&
                                                buildSingleQuestionList(indexList)}
                                            {list.isGroupStyle && buildGroupQuestionList(indexList)}
                                        </div>
                                    </Collapse>
                                </div>
                            )
                    )}
            </div>
            {fillInGroups1.length > 0 &&
                fillInGroups2.length > 0 &&
                fillInGroups3.length > 0 &&
                fillInGroups1.map((group, index) => (
                    <div key={index} className="mt-3">
                        <div className="row bg-secondary border-bottom px-3 report-detail-fixed-header">
                            <div className="col-6 py-3">
                                <span>{group.groupTitle}</span>
                            </div>
                            <div className="col-2 py-3">{renderAvatar(1)}</div>
                            <div className="col-2 py-3">{renderAvatar(2)}</div>
                            <div className="col-2 py-3">{renderAvatar(3)}</div>
                        </div>
                        <div>
                            {group.questions.map((question, indexQuestion) => (
                                <div
                                    className="row px-3 border-bottom div-picking-bg"
                                    key={question.dataCore}
                                >
                                    <div className="col-6 py-3 my-auto">
                                        <span className="d-flex text-secondary">
                                            {question.title}
                                        </span>
                                        <span className="d-flex text-primary">
                                            {question.suggestText}
                                        </span>
                                    </div>
                                    <div className="col-2 py-3 text-center my-auto">
                                        {renderFillInQuestion(1, index, indexQuestion)}
                                    </div>
                                    <div className="col-2 py-3 text-center my-auto">
                                        {renderFillInQuestion(2, index, indexQuestion)}
                                    </div>
                                    <div className="col-2 py-3 text-center my-auto">
                                        {renderFillInQuestion(3, index, indexQuestion)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
        </div>
    );
}
