import { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AdminDashboardAnalyticFilter } from '../report-list-dashboard-admin-retail';
import { IDashboardReportRetail } from 'shared/interface/bio-report-retail.interface';

interface IProp {
    dashboardReport?: IDashboardReportRetail;
    setReportWithAnalyticFilter: (filter: AdminDashboardAnalyticFilter) => void;
}

export default function ReportQuestionAnalysisRetail(prop: IProp) {
    const { t } = useTranslation();
    const { dashboardReport } = prop;
    const [ascYes, setAscYes] = useState(false);
    const [ascNo, setAscNo] = useState(false);

    return (
        <div className="swing-in-right-fwd">
            <div className="d-flex fs-4 fw-bold text-secondary mb-2">{t('question-analysis')}</div>
            <div className="row">
                {dashboardReport && dashboardReport.questionsAnswer.length === 0 && (
                    <div className="d-flex justify-content-center text-secondary">
                        {t('no-data')}
                    </div>
                )}

                <Table responsive={true} striped={true}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{t('question')}</th>
                            <th className="text-primary">
                                0 {t('point')}
                            </th>
                            <th className="text-primary">
                                1 {t('point')}
                            </th>
                            <th className="text-primary">
                                2 {t('point')}
                            </th>
                            <th className="text-primary">
                                3 {t('point')}
                            </th>
                            <th className="text-primary">
                                4 {t('point')}
                            </th>
                            <th className="text-primary">
                                5 {t('point')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {dashboardReport &&
                            dashboardReport?.questionsAnswer.length > 0 &&
                            dashboardReport?.questionsAnswer.map((question, index) => (
                                <tr key={index}>
                                    <td className="text-secondary">{index + 1}</td>
                                    <td>{question.title}</td>
                                    <td className="text-secondary">
                                        <Button
                                            variant="link"
                                            onClick={() =>
                                                prop.setReportWithAnalyticFilter({
                                                    answer: 0,
                                                    questionId: question._id,
                                                })
                                            }>
                                            {question.answers.find((e) => e.point === 0)
                                                ? question.answers.find((e) => e.point === 0)?.count
                                                : '-'}
                                        </Button>
                                    </td>
                                    <td className="text-secondary">
                                        <Button
                                            variant="link"
                                            onClick={() =>
                                                prop.setReportWithAnalyticFilter({
                                                    answer: 1,
                                                    questionId: question._id,
                                                })
                                            }>
                                            {question.answers.find((e) => e.point === 1)
                                                ? question.answers.find((e) => e.point === 1)?.count
                                                : '-'}
                                        </Button>
                                    </td>
                                    <td className="text-secondary">
                                        <Button
                                            variant="link"
                                            onClick={() =>
                                                prop.setReportWithAnalyticFilter({
                                                    answer: 2,
                                                    questionId: question._id,
                                                })
                                            }>
                                            {question.answers.find((e) => e.point === 2)
                                                ? question.answers.find((e) => e.point === 2)?.count
                                                : '-'}
                                        </Button>
                                    </td>
                                    <td className="text-secondary">
                                        <Button
                                            variant="link"
                                            onClick={() =>
                                                prop.setReportWithAnalyticFilter({
                                                    answer: 3,
                                                    questionId: question._id,
                                                })
                                            }>
                                            {question.answers.find((e) => e.point === 3)
                                                ? question.answers.find((e) => e.point === 3)?.count
                                                : '-'}
                                        </Button>
                                    </td>
                                    <td className="text-secondary">
                                        <Button
                                            variant="link"
                                            onClick={() =>
                                                prop.setReportWithAnalyticFilter({
                                                    answer: 4,
                                                    questionId: question._id,
                                                })
                                            }>
                                            {question.answers.find((e) => e.point === 4)
                                                ? question.answers.find((e) => e.point === 4)?.count
                                                : '-'}
                                        </Button>
                                    </td>
                                    <td className="text-secondary">
                                        <Button
                                            variant="link"
                                            onClick={() =>
                                                prop.setReportWithAnalyticFilter({
                                                    answer: 5,
                                                    questionId: question._id,
                                                })
                                            }>
                                            {question.answers.find((e) => e.point === 5)
                                                ? question.answers.find((e) => e.point === 5)?.count
                                                : '-'}
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}
