/* eslint-disable react-hooks/exhaustive-deps */
import FarmService from 'module/client/farm/service/farm.service';
import UserService from 'shared/service/user.service';
import { CompanyService } from 'module/admin/company-management/service/company.service';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFarm } from 'shared/interface/farm.interface';
import { Accordion, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface IProp {
    userId?: string;
    textClass?: string;
}

export default function PendingUserFarm(prop: IProp) {
    const { t } = useTranslation();
    const [farms, setFarms] = useState<IFarm[]>([]);

    const getFarms = async () => {
        if (prop.userId) {
            const res = await FarmService.findByUserId(prop.userId);
            setFarms(res.docs);
        }
    };
    useEffect(() => {
        getFarms();
    }, [prop.userId]);

    return (
        <div className={[farms.length === 0 && 'bg-secondary'].join(' ')}>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{t('farm')}</Accordion.Header>
                    <Accordion.Body>
                        {farms.length ? (
                            farms.map((farm) => (
                                <div
                                    key={farm._id}
                                    className={['d-flex flex-column', prop.textClass].join(' ')}>
                                    <span
                                        className="fs-5 fw-semibold"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            window.open(
                                                `${window.location.origin}/admin/farms/${farm._id}`,
                                                '_blank'
                                            );
                                        }}>
                                        {farm.title}
                                    </span>
                                    <div className="d-flex">
                                        <FarmUser
                                            type={'customer'}
                                            userIds={[farm.owningCompany]}
                                        />
                                        <FarmUser type={'sale'} userIds={farm.owningSales} />
                                    </div>
                                </div>
                            ))
                        ) : (
                            <span className="text-secondary">{t('no-data')}</span>
                        )}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}

interface IFarmUserProp {
    userIds: string[];
    type: string;
}

const FarmUser: React.FC<IFarmUserProp> = ({ type = 'customer', userIds = [] }) => {
    const [users, setUsers] = useState<{ name: string; value: string }[]>();
    const { t } = useTranslation();

    useEffect(() => {
        getUser();
    }, [userIds]);

    const getUser = async () => {
        const fullList = await Promise.all(
            type === 'customer'
                ? userIds.map((company) => {
                      return company && company !== 'undefined'
                          ? CompanyService.findOne(company)
                          : null;
                  })
                : userIds.map((user) => {
                      return UserService.getUserInfo(user);
                  })
        );

        const sanitizeList = fullList.map((user) => {
            return {
                name: user.name,
                value: user._id,
            };
        });
        setUsers(sanitizeList);
    };

    const renderTooltip = (
        <Tooltip>
            <div className="d-flex flex-column">
                {users?.map((user, index) => {
                    return (
                        <span className="text-uppercase fst-italic" key={index}>
                            {user.name}
                        </span>
                    );
                })}
            </div>
        </Tooltip>
    );

    return (
        <OverlayTrigger placement="bottom" delay={{ show: 100, hide: 200 }} overlay={renderTooltip}>
            {type === 'customer' ? (
                <span className="fs-6">
                    <i className="fa fa-user me-1" />
                    {t('customer')}
                </span>
            ) : (
                <span className="fs-6">
                    <i className="fa fa-users ic-w me-1" />
                    {t('sale-incharge')}
                </span>
            )}
        </OverlayTrigger>
    );
};
