/* eslint-disable react-hooks/exhaustive-deps */
import QuestionService from 'module/client/question/service/question.service';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IQuestion } from 'shared/interface/question.interface';

interface IProp {
    id: string;
    onAnswer: (result: boolean) => void;
    yesAnswerTitle: string;
    noAnswerTitle: string;
    initializeValue?: boolean;
    questionNumber?: number;
}

export default function GroupSingleQuestion(prop: IProp) {
    const { t } = useTranslation();
    const [question, setQuestion] = useState<IQuestion>();

    const getQuestion = async () => {
        const result = await QuestionService.getById(prop.id);
        setQuestion(result);
    };

    useEffect(() => {
        getQuestion();
    }, [prop.id]);

    return (
        <>
            {question && (
                <div className="row border-bottom g-0" id={prop.id}>
                    <div className="col-8 py-3 ps-2">
                        <div className="d-flex flex-column">
                            <span className="text-primary">
                                {t('question')} {prop.questionNumber}
                            </span>
                            <span>{question.title}</span>
                        </div>
                    </div>
                    <div className="col-2 py-3">
                        <div className="form-check d-flex justify-content-center align-items-center h-100">
                            <input
                                onClick={() => {
                                    prop?.onAnswer(false);
                                }}
                                className="form-check-input"
                                type="radio"
                                name={`flexRadioDefault${prop.id}`}
                                id="flexRadioDefault1"
                                defaultChecked={prop.initializeValue === false}
                            />
                            <label className="form-check-label"/>
                        </div>
                    </div>
                    <div className="col-2 py-3 border-start">
                        <div className="form-check d-flex justify-content-center align-items-center h-100">
                            <input
                                onClick={() => {
                                    prop?.onAnswer(true);
                                }}
                                className="form-check-input"
                                type="radio"
                                name={`flexRadioDefault${prop.id}`}
                                id="flexRadioDefault1"
                                defaultChecked={prop.initializeValue === true}
                            />
                            <label className="form-check-label"/>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
