import { SignupDto } from '../../../../shared/dto/signup.dto';
import { ILoginResponse } from '../../../../shared/interface/auth.interface';
import api from '../../../../shared/service/api';

const urlAuth = '/auth';
export default class SignupService {
    static async signup(dto: SignupDto, otp: string): Promise<Omit<ILoginResponse, 'hasSignup'>> {
        const res = await api.post(`${urlAuth}/${otp}/signup`, dto);
        return res.data;
    }
}
