import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { iconClose } from 'shared/assets/icons';
import { IUser } from 'shared/interface/user.interface';
import { Col, Form, InputGroup } from 'react-bootstrap';
import UserService from 'shared/service/user.service';
import AuthService from 'module/auth/login/service/login.service';
import LoadingSpinner from 'shared/components/loading-spinner/loading-spinner';
import { useAppContext } from 'module/auth/context';
import { toast } from 'react-toastify';
import { EditAvatar } from 'module/client/farm/components';
import { jwtDecode } from 'jwt-decode';
import { ITokenPayload } from 'shared/interface/auth.interface';

interface IProps {
    show: boolean;
    handleClose(): void;
}

function EditUserInfoModal(props: IProps) {
    const { handleClose, show = false } = props;
    const { t } = useTranslation();
    const appContext = useAppContext();
    const [userDetail, setUserDetail] = useState<IUser | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [newAvatar, setNewAvatar] = useState<File>();

    useEffect(() => {
        if (appContext.user) {
            getUserInfo(appContext.user.id);
        }
    }, [appContext.user]);

    const getUserInfo = async (id: string) => {
        const result = await UserService.getUserInfo(id);
        setUserDetail(result);
    };

    const onSaveUserInfo = async () => {
        setLoading(true);
        if (userDetail) {
            const result = await UserService.updateUserInfo(userDetail._id, userDetail);
            if (result) {
                toast.success(t('successfully'), { position: 'bottom-center' });
            }
            if (newAvatar) {
                const uploadAvatarResult = await UserService.updateUserAvatar(
                    userDetail._id,
                    newAvatar
                );
                if (uploadAvatarResult) {
                    const renewToken = await AuthService.retrieveToken();
                    if (renewToken) {
                        const decodeToken = jwtDecode<ITokenPayload>(renewToken);
                        appContext.setUser(decodeToken);
                    }
                }
            }
        }
        setLoading(false);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="farm-modal-custom">
            <Modal.Header className="w-100">
                {/* <Modal.Title className="text-primary">{t('edit-farm-info')}</Modal.Title> */}
                <div className="d-flex justify-content-center w-100 position-relative">
                    <span className="farm-modal-title mt-4">{t('edit-user-info')}</span>
                    <img
                        role="button"
                        onClick={() => handleClose()}
                        alt=""
                        src={iconClose}
                        className="position-absolute top-0 end-0"
                    />
                </div>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div className="d-flex flex-column w-100" style={{ maxWidth: 600 }}>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <span className="farm-property">{t('avatar')}</span>
                        {/* <span className="farm-address" role="button">
                            {t('edit')}
                        </span> */}
                    </div>
                    <div className="d-flex py-3 w-100 justify-content-center border-bottom">
                        <EditAvatar previousAvatar={userDetail?.avatar} setAvatar={setNewAvatar} />
                    </div>
                    {userDetail && (
                        <div className="d-flex py-3 w-100 justify-content-center flex-column">
                            <Form.Group as={Col} sm="12" className="w-100">
                                <Form.Label className="fw-semibold" style={{ color: '#474C55' }}>
                                    {t('full-name')}
                                </Form.Label>
                                <InputGroup hasValidation={true}>
                                    <Form.Control
                                        type="text"
                                        className="rounded-1"
                                        required={true}
                                        placeholder={t('full-name')}
                                        defaultValue={userDetail?.name}
                                        onChange={(e) =>
                                            setUserDetail({
                                                ...userDetail,
                                                name: e.target.value,
                                            })
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t('required-field')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} sm="12" className="w-100 mt-3">
                                <Form.Label className="fw-semibold" style={{ color: '#474C55' }}>
                                    {t('phone')}
                                </Form.Label>
                                <InputGroup hasValidation={true}>
                                    <Form.Control
                                        type="text"
                                        className="rounded-1"
                                        required={true}
                                        placeholder={t('phone')}
                                        defaultValue={userDetail?.phone}
                                        disabled={true}
                                        onChange={(e) =>
                                            setUserDetail({
                                                ...userDetail,
                                                phone: e.target.value,
                                            })
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t('required-field')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} sm="12" className="w-100 mt-3">
                                <Form.Label className="fw-semibold" style={{ color: '#474C55' }}>
                                    {t('email')}
                                </Form.Label>
                                <InputGroup hasValidation={true}>
                                    <Form.Control
                                        type="text"
                                        className="rounded-1"
                                        required={true}
                                        placeholder={t('email')}
                                        defaultValue={userDetail?.email}
                                        onChange={(e) =>
                                            setUserDetail({
                                                ...userDetail,
                                                email: e.target.value,
                                            })
                                        }
                                    />
                                </InputGroup>
                            </Form.Group>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <div className="d-flex justify-content-center w-100 mb-5" style={{ maxWidth: 600 }}>
                    <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                            onSaveUserInfo();
                        }}
                    >
                        {t('save')}
                    </button>
                </div>
            </Modal.Footer>

            <LoadingSpinner loading={loading} />
        </Modal>
    );
}

export default EditUserInfoModal;
