/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import TopicService from 'module/client/topic/service/topic.service';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITopic } from 'shared/interface/topic.interface';
import { format } from 'date-fns';
import { buildFileView } from 'shared/service/file';
import { toast } from 'react-toastify';
import ConfirmDialog from 'shared/components/confirm-dialog/confirm-dialog';
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function TopicManagement() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [page, setPage] = useState<number>(1);
    const [limit] = useState<number>(10);
    const [hasNextPage, setHasNextPage] = useState<boolean>(false);
    const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(false);
    const [topics, setTopics] = useState<ITopic[]>([]);
    const [show, setShow] = useState<boolean>(false);
    const [select, setSelect] = useState<ITopic>();

    const renderAddTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('add-topic')}
        </Tooltip>
    );

    const getTopics = async () => {
        const res = await TopicService.find(page, limit);
        setTopics(res.docs);
        setHasNextPage(res.hasNextPage);
        setHasPreviousPage(res.hasPrevPage);
    };

    const deleteTopic = async () => {
        const res = await TopicService.remove(select?._id || '');
        if (res) {
            toast.success(t('successfully'), { position: 'bottom-center' });
            setTopics(topics.filter(t => t._id !== select?._id));
            setSelect(undefined);
        }
    };

    useEffect(() => {
        getTopics();
    }, [page, limit]);

    return (
        <div className="container-fluid">
            <h2 className="mt-2">{t('topic-management')}</h2>
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                        <tr className="table-secondary">
                            <th scope="col"/>
                            <th scope="col">{t('preview-image')}</th>
                            <th scope="col">{t('title')}</th>
                            <th scope="col">{t('preview-content')}</th>
                            <th scope="col">{t('url')}</th>
                            <th scope="col">{t('updated-at')}</th>
                            <th scope="col">
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 100, hide: 200 }}
                                    overlay={renderAddTooltip}
                                >
                                    <button
                                        className="btn btn-success"
                                        onClick={() => {
                                            navigate('/admin/topic/create');
                                        }}
                                    >
                                        <i className="fa-solid fa-plus"/>
                                    </button>
                                </OverlayTrigger>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {topics.length > 0 &&
                            topics.map((topic, index) => (
                                <tr key={topic._id} className="align-middle">
                                    <th>{index + (limit * (page - 1)) + 1}</th>
                                    <td>
                                        <img
                                            alt=""
                                            style={{maxWidth: 500}}
                                            className="theme-picture img-fluid"
                                            src={buildFileView(topic.image)}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = '/image/picture/default.jpeg';
                                            }}
                                        />
                                    </td>
                                    <td>{topic.title}</td>
                                    <td>{topic.previewContent}</td>
                                    <td>{topic.url}</td>
                                    <td>
                                        {format(
                                            new Date(topic.updatedAt),
                                            i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                        )}
                                    </td>
                                    <td>
                                        <button
                                            className="btn btn-outline-secondary"
                                            onClick={() => {
                                                navigate(`/admin/topic/${topic._id}`);
                                            }}
                                        >
                                            <i className="fa-solid fa-pen-clip"/>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary"
                                            onClick={() => {
                                                setSelect(topic);
                                                setShow(true);
                                            }}
                                        >
                                            <i className="fa-solid fa-trash-can"/>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <nav className="d-flex justify-content-end">
                    <ul className="pagination">
                        <li className={['page-item', !hasPreviousPage && 'disabled'].join(' ')}>
                            <a className="page-link" href="#" onClick={() => setPage(page - 1)}>
                                {t('previous')}
                            </a>
                        </li>
                        {hasPreviousPage && (
                            <li className="page-item">
                                <a className="page-link" href="#" onClick={() => setPage(page - 1)}>
                                    {page - 1}
                                </a>
                            </li>
                        )}
                        <li className="page-item active">
                            <span className="page-link">{page}</span>
                        </li>
                        {hasNextPage && (
                            <li className="page-item">
                                <a className="page-link" href="#" onClick={() => setPage(page + 1)}>
                                    {page + 1}
                                </a>
                            </li>
                        )}
                        <li className={['page-item', !hasNextPage && 'disabled'].join(' ')}>
                            <a className="page-link" href="#" onClick={() => setPage(page + 1)}>
                                {t('next')}
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <ConfirmDialog
                title={t('confirmation-title')}
                body={t('are-you-sure-to-delete')}
                confirmTitle={t('yes')}
                declineTitle={t('cancel')}
                show={show}
                onHide={function (result: boolean): void {
                    if (result) {
                        deleteTopic();
                    }
                    setShow(false);
                }}
            />
        </div>
    );
}
