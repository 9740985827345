import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { OTPMethod } from 'shared/enum/otp.enum';

interface IProp {
    show: boolean;
    onHide: (result: OTPMethod | null) => void;
    disableClose?: boolean;
}

export default function PickingSendingType(prop: IProp) {
    const { t } = useTranslation();
    return (
        <Modal show={prop.show}>
            <Modal.Header className="flex-column">
                <div className="d-flex justify-content-end w-100">
                    <button
                        disabled={prop.disableClose || false}
                        className="btn btn-icon border-0 rounded-circle"
                        onClick={() => {
                            prop.onHide(null);
                        }}
                    >
                        <i className="fa-solid fa-xmark text-primary"/>
                    </button>
                </div>
                <div className="d-flex justify-content-center">
                    <span className="fs-4">{t('pick-a-method')}</span>
                </div>
                <div className="d-flex justify-content-center">
                    <span className="text-secondary">
                        {t('Please-pick-a-method-to-receive-otp')}
                    </span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div
                    className="div-shadow p-3 mt-2 rounded div-picking clickable"
                    onClick={() => {
                        // @ts-ignore
                        window.gtag('event', 'SEND_OTP_SMS', {});
                        prop.onHide(OTPMethod.SMS);
                    }}
                >
                    <div className="d-flex">
                        <img
                            alt=""
                            className="rounded-circle avatar-farm"
                            src={'/image/picture/sms-logo.png'}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = '/image/picture/default.jpeg';
                            }}
                        />
                        <span className="ms-3 fs-5 my-auto">{t('sms')}</span>
                    </div>
                </div>
                <div
                    className="div-shadow p-3 mt-2 rounded div-picking clickable"
                    onClick={() => {
                        // @ts-ignore
                        window.gtag('event', 'SEND_OTP_ZALO', {});
                        prop.onHide(OTPMethod.ZALO);
                    }}
                >
                    <div className="d-flex">
                        <img
                            alt=""
                            className="rounded-circle avatar-farm"
                            src={'/image/picture/zalo-logo.png'}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = '/image/picture/default.jpeg';
                            }}
                        />
                        <span className="ms-3 fs-5 my-auto">{t('zalo')}</span>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
