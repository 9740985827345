import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SalceCard, AddMore } from './index';
import '../partition.scss';
import PartitionService from '../service/partition.service';
import { ITeam } from 'shared/interface/partition.interface';
import _ from 'lodash';
import LoadingSpinner from 'shared/components/loading-spinner/loading-spinner';
import { IUser } from 'shared/interface/user.interface';
import { iconClose } from 'shared/assets/icons';
import { ConfirmDialog } from 'shared/components';

interface ITeamCardProps {
    team: ITeam;
    teamName: string;
    listOfSaleHasTeam: string[];
    onUpdateTeam(team: ITeam): void;
    onRemoveTeam(): void;
}

const TeamCard = (props: ITeamCardProps) => {
    const { team, teamName, onUpdateTeam, onRemoveTeam, listOfSaleHasTeam } = props;
    const { owningUser, sales, _id } = team;
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);

    const onAddMember = async (newMember: IUser) => {
        setLoading(true);
        const tempTeam = _.cloneDeep(team);
        tempTeam.sales = [...tempTeam.sales, newMember._id];
        const result = await PartitionService.update(_id, tempTeam);
        if (result) {
            // @ts-ignore
            window.gtag('event', 'UPDATE_TEAM', {});
            onUpdateTeam(tempTeam);
        }

        setLoading(false);
    };

    const onRemoveMember = async (saleIndex: number) => {
        setLoading(true);
        const tempTeam = _.cloneDeep(team);
        tempTeam.sales.splice(saleIndex, 1);
        const result = await PartitionService.update(_id, tempTeam);
        if (result) {
            onUpdateTeam(tempTeam);
        }

        setLoading(false);
    };

    const onDeleteTeam = async () => {
        setLoading(true);
        const result = await PartitionService.delete(team._id);
        if (result && onRemoveTeam) {
            onRemoveTeam();
        }
        setLoading(false);
    };

    return (
        <div className="w-100 border px-3 py-2 bg-light mx-2 rounded position-relative">
            <img
                role="button"
                onClick={() => setShowConfirm(true)}
                alt=""
                src={iconClose}
                style={{ width: '1.5rem', height: '1.5rem' }}
                className="position-absolute top-0 end-0"
            />
            <span className="fs-5 uppercase fw-semibold">{teamName}</span>
            <SalceCard type="manager" user={owningUser} />
            {sales.map((sale, saleIndex) => {
                return (
                    <SalceCard
                        key={saleIndex}
                        user={sale}
                        onRemoveSale={() => onRemoveMember(saleIndex)}
                    />
                );
            })}

            <AddMore
                listOfSaleHasTeam={listOfSaleHasTeam}
                team={_id}
                onSave={(newMember) => {
                    onAddMember(newMember);
                }}
            />
            <LoadingSpinner loading={loading} />
            <ConfirmDialog
                show={showConfirm}
                title={t('remove-team')}
                body={`${t('remove-team-confirm-body')}`}
                confirmTitle={t('confirm')}
                declineTitle={t('cancel')}
                onHide={(confirm) => {
                    if (confirm) {
                        onDeleteTeam();
                    } else {
                        setShowConfirm(false);
                    }
                }}
            />
        </div>
    );
};

export default TeamCard;
