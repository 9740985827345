import { useAppContext } from 'module/auth/context';
import { useEffect, useState } from 'react';
import { Accordion, Alert, Button, Form, ListGroup } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
    AutomationImportDto,
    IAutomationImportMessage,
} from 'shared/interface/automation-import.interface';
import { AutomationImportService } from 'shared/service/automation-import.service';
import { io } from 'socket.io-client';

const URL = process.env.REACT_APP_API_URL ?? 'http://localhost:3003';
const topic = 'automation-import-debug';

const AutomationImport = () => {
    let socket = io(URL.replace('/v1', ''), {
        transports: ['websocket', 'polling'],
    });
    const appContext = useAppContext();
    const { t } = useTranslation();
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        },
        maxFiles: 1,
    });
    const [dto, setDto] = useState<AutomationImportDto>({
        companyName: true,
        address: true,
        email: true,
        nameOfUser: true,
    });
    const [result, setResult] = useState<{ result: boolean; time: number }>();
    const [messages, setMessages] = useState<IAutomationImportMessage[]>([
        // {
        //     type: 'warning',
        //     message:
        //         'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
        // },
        // {
        //     type: 'secondary',
        //     message:
        //         'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
        // },
        // {
        //     type: 'danger',
        //     message:
        //         'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
        // },
        // {
        //     type: 'primary',
        //     message:
        //         'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
        // },
        // {
        //     type: 'success',
        //     message:
        //         'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
        // },
    ]);

    const onSubmit = async () => {
        try {
            setMessages([]);
            const res = await AutomationImportService.import(dto, acceptedFiles[0]);
            if (res) {
                toast.success(`${t('finish-time')}: ${res?.time} s`);
                setResult(res);
            }
        } catch (error) {
            toast.error(`Error`);
        }
    };

    const socketSubscription = () => {
        socket.on('connect', () => {
            socket.emit('join-automation', { userID: appContext.user?.id });
        });
        socket.on(topic, (data: IAutomationImportMessage) => {
            setTimeout(() => {
                setMessages((prev) => {
                    return [data, ...prev];
                });
            }, 100);
        });
    };

    useEffect(() => {
        socketSubscription();
        if (!socket) {
            socket = io(URL.replace('/v1', ''), {
                transports: ['websocket', 'polling'],
            });
        }
    }, []);

    return (
        <div className="d-flex flex-column gap-2 p-5">
            <div className="d-flex w-100">
                <Accordion defaultActiveKey="0" className="w-100">
                    <Accordion.Item eventKey="1" className="w-100">
                        <Accordion.Header className="w-100">Giải thích</Accordion.Header>
                        <Accordion.Body>
                            <ListGroup>
                                <ListGroup.Item>
                                    <ListGroup>
                                        <ListGroup.Item>
                                            Sheet Sales list: Sử dụng cột B, C, D, G, H
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Phạm vi ảnh hưởng: Quản lý người dùng, quản lý phân vùng
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Thay đổi thông tin người dùng (SALE, SALE MANAGER) và
                                            Group sale
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Skip những dòng không có SAP Code
                                        </ListGroup.Item>
                                    </ListGroup>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <ListGroup>
                                        <ListGroup.Item>
                                            Sheet Master dealer: Sử dụng cột A, G, L, M, P
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Phạm vi ảnh hưởng: Quản lý người dùng, quản lý khách
                                            hàng, quản lý trại
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Thay đổi thông tin người dùng (KHÁCH HÀNG), khách hàng,
                                            trại
                                        </ListGroup.Item>
                                    </ListGroup>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <ListGroup>
                                        <ListGroup.Item>
                                            Sheet Billing report SO-Sales matrix: Sử dụng cột B, E,
                                            G, I, K
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Phạm vi ảnh hưởng: Quản lý trại
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Thay đổi người chịu trách nhiệm cho trại
                                        </ListGroup.Item>
                                    </ListGroup>
                                </ListGroup.Item>
                            </ListGroup>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center gap-2">
                    <h4 className="fw-bold">{t('override')}: </h4>
                    <Form.Check // prettier-ignore
                        type="checkbox"
                        id="companyName"
                        label={t('customer-name')}
                        checked={dto.companyName}
                        onChange={(e) => setDto({ ...dto, companyName: e.target.checked })}
                    />
                    <Form.Check // prettier-ignore
                        type="checkbox"
                        id="address"
                        label={t('address')}
                        checked={dto.address}
                        onChange={(e) => setDto({ ...dto, address: e.target.checked })}
                    />
                    <Form.Check // prettier-ignore
                        type="checkbox"
                        id="email"
                        label={t('email')}
                        checked={dto.email}
                        onChange={(e) => setDto({ ...dto, email: e.target.checked })}
                    />
                    <Form.Check // prettier-ignore
                        type="checkbox"
                        id="companyName"
                        label={t('name-of-user')}
                        checked={dto.nameOfUser}
                        onChange={(e) => setDto({ ...dto, nameOfUser: e.target.checked })}
                    />
                </div>
                <a href={`${URL.replace('/v1', '')}/automation-upload-template.xlsx`}>
                    <Button variant="secondary" type="button">
                        <i className="fa-solid fa-download me-3"></i>
                        {t('download-example-file')}
                    </Button>
                </a>
            </div>
            <div {...getRootProps({ className: 'drop-zone p-5 clickable' })}>
                <input {...getInputProps()} />
                <div className="d-flex justify-content-center">
                    <button className="btn btn-outline-secondary btn-lg" type="button">
                        <i className="fa-solid fa-download" />
                    </button>
                </div>
                <div className="d-flex justify-content-center mt-2">
                    {t('choose-a-file-or-drag-it-here')}
                </div>
                <div className="d-flex justify-content-center text-secondary mt-2">
                    {t('automation-file-rule')}
                </div>
            </div>
            {acceptedFiles.length > 0 && <div>{acceptedFiles[0].name}</div>}
            <Button type="button" onClick={onSubmit}>
                {t('OK')}
            </Button>
            <div className="div-shadow w-100 p-3">
                <div className="d-flex justify-content-between">
                    <h2>LOG</h2>
                    <div>
                        {result && (
                            <span>
                                {t('finish-time')}: {result?.time} s
                            </span>
                        )}
                    </div>
                </div>
                <div className="w-full bg-secondary" style={{ height: 0.5 }}></div>
                <div className="d-flex flex-column overflow-auto" style={{ maxHeight: '80vh' }}>
                    {messages.map((mess, index) => (
                        <Alert variant={mess.type} key={index}>
                            {mess.message}
                        </Alert>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AutomationImport;
