import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ConfirmDialog } from 'shared/components';
import { IQuestionRetail } from 'shared/interface/question-retail.interface';
import { QuestionRetailService } from 'shared/service/question-retail.service';
import EditQuestionRetail from './edit-question-retail';
import { buildFileView } from 'shared/service/file';
import { QuestionType } from 'shared/enum/question.enum';
import { Collapse } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';

export default function QuestionRetail() {
    const { t } = useTranslation();
    const [questions, setQuestions] = useState<IQuestionRetail[]>([]);
    const [page, setPage] = useState<number>(1);
    const [limit] = useState<number>(10);
    const [hasNextPage, setHasNextPage] = useState<boolean>(false);
    const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(false);
    const [select, setSelect] = useState<IQuestionRetail>();
    const [show, setShow] = useState<boolean>(false);
    const [showEdit, setShowEdit] = useState<boolean>(false);

    const [selectedCollapseID, setSelectedCollapseID] = useState<string>('');

    const getQuestions = async () => {
        const res = await QuestionRetailService.findAll(page, limit);
        setQuestions(res.docs);
        setHasNextPage(res.hasNextPage);
        setHasPreviousPage(res.hasPrevPage);
    };

    const deleteQuestion = async () => {
        const res = await QuestionRetailService.delete(select?._id || '');
        if (res) {
            setQuestions(questions.filter((q) => q._id !== select?._id));
            toast.success(t('successfully'), { position: 'bottom-center' });
        }
    };

    useEffect(() => {
        getQuestions();
    }, [page, limit]);

    const onHideQuestion = (result: IQuestionRetail | null) => {
        setShowEdit(false);
        if (result) {
            if (!select) {
                setQuestions([result, ...questions]);
            } else {
                setQuestions([
                    ...questions.map((q) => {
                        if (q._id === result._id) {
                            q = result;
                        }
                        return q;
                    }),
                ]);
            }
        }
    };

    const toggleCollapse = (id: string) => {
        if (id === selectedCollapseID) {
            setSelectedCollapseID('');
        } else {
            setSelectedCollapseID(id);
        }
    };

    return (
        <div className="p-2">
            <div className="d-flex justify-content-between">
                <h4 className="fw-semibold">{t('list-question')}</h4>
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        setSelect(undefined);
                        setShowEdit(true);
                    }}>
                    <i className="fa-solid fa-plus" />
                    <span className="ms-3">{t('create-question')}</span>
                </button>
            </div>
            <div className="table-responsive mt-2">
                <table
                    className="table table-hover align-middle"
                    style={{ tableLayout: 'fixed', wordWrap: 'break-word' }}>
                    <thead>
                        <tr className="table-secondary">
                            <th scope="col" />
                            <th scope="col">{t('question-code')}</th>
                            <th scope="col">{t('title')}</th>
                            <th scope="col">{t('image')}</th>
                            <th scope="col">{t('question-type')}</th>
                            <th scope="col">{t('point-answer')}</th>
                            <th scope="col">{t('violated-question')}</th>
                            <th scope="col" />
                        </tr>
                    </thead>
                    <tbody>
                        {questions.length > 0 &&
                            questions.map((q, index) => (
                                <tr key={index} className="align-middle">
                                    <th>{index + limit * (page - 1) + 1}</th>
                                    <td>{q.customCode}</td>
                                    <td>{q.title}</td>
                                    <td>
                                        <img
                                            alt=""
                                            style={{ maxWidth: 500 }}
                                            className="theme-picture img-fluid"
                                            src={buildFileView(q.image)}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = '/image/picture/default.jpeg';
                                            }}
                                        />
                                    </td>
                                    <td
                                        className={
                                            q.questionType === QuestionType.YES_NO
                                                ? 'text-danger'
                                                : 'text-success'
                                        }>
                                        {q.questionType}
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            className="btn btn-outline-info w-100 mb-3"
                                            onClick={() => toggleCollapse(q._id)}>
                                            <FaEye />
                                        </button>
                                        <Collapse in={selectedCollapseID === q._id}>
                                            <div className="div-shadow p-2">
                                                {q.points.map((e, index) => (
                                                    <p key={index}>
                                                        {e.answer} - {e.point}
                                                    </p>
                                                ))}
                                            </div>
                                        </Collapse>
                                    </td>
                                    <td>{q.isViolatedPoint ? t('yes') : t('no')}</td>
                                    <td>
                                        <button
                                            className="btn btn-outline-secondary"
                                            onClick={() => {
                                                setSelect(q);
                                                setTimeout(() => {
                                                    setShowEdit(true);
                                                }, 200);
                                            }}>
                                            <i className="fa-solid fa-pen-clip" />
                                        </button>
                                        <button
                                            className="btn btn-outline-danger"
                                            onClick={() => {
                                                setSelect(q);
                                                setTimeout(() => {
                                                    setShow(true);
                                                }, 200);
                                            }}>
                                            <i className="fa-solid fa-trash-can" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <nav className="d-flex justify-content-end">
                    <ul className="pagination">
                        <li className={['page-item', !hasPreviousPage && 'disabled'].join(' ')}>
                            <a className="page-link" href="#" onClick={() => setPage(page - 1)}>
                                {t('previous')}
                            </a>
                        </li>
                        {hasPreviousPage && (
                            <li className="page-item">
                                <a className="page-link" href="#" onClick={() => setPage(page - 1)}>
                                    {page - 1}
                                </a>
                            </li>
                        )}
                        <li className="page-item active">
                            <span className="page-link">{page}</span>
                        </li>
                        {hasNextPage && (
                            <li className="page-item">
                                <a className="page-link" href="#" onClick={() => setPage(page + 1)}>
                                    {page + 1}
                                </a>
                            </li>
                        )}
                        <li className={['page-item', !hasNextPage && 'disabled'].join(' ')}>
                            <a className="page-link" href="#" onClick={() => setPage(page + 1)}>
                                {t('next')}
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <ConfirmDialog
                title={t('confirmation-title')}
                body={t('are-you-sure-to-delete')}
                confirmTitle={t('yes')}
                declineTitle={t('cancel')}
                show={show}
                onHide={function (result: boolean): void {
                    if (result) {
                        deleteQuestion();
                    }
                    setShow(false);
                }}
            />
            <EditQuestionRetail
                question={select}
                show={showEdit}
                onHide={function (result: IQuestionRetail | null): void {
                    onHideQuestion(result);
                }}
            />
        </div>
    );
}
