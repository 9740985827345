export enum LocalStoreName {
    TOKEN = 'TOKEN',
    REFRESH_TOKEN = 'REFRESH_TOKEN',
    BIO_REPORT = 'BIO_REPORT',
}

export enum FilterStore {
    CLIENT_BIO_REPORT_FILTER = 'CLIENT_BIO_REPORT_FILTER',
    ADMIN_BIO_REPORT_FILTER = 'ADMIN_BIO_REPORT_FILTER',
    ADMIN_BIO_REPORT_FILTER_RETAIL = 'ADMIN_BIO_REPORT_FILTER_RETAIL',
    ADMIN_ACTIVE_USER_FILTER = 'ADMIN_ACTIVE_USER_FILTER',
    ADMIN_COMPANY_MANAGEMENT_FILTER = 'ADMIN_COMPANY_MANAGEMENT_FILTER',
    ADMIN_FARM_MANAGEMENT_FILTER = 'ADMIN_FARM_MANAGEMENT_FILTER',
    ADMIN_DASHBOARD_FILTER = 'ADMIN_DASHBOARD_FILTER',
}
