import { useTranslation } from 'react-i18next';
import './footer-help.scss';

export default function FooterHelp() {
    const { t } = useTranslation();
    return (
        <div className="bg-white footer-bg w-100">
            <div className="p-3">
                <div className="d-flex fw-bold">{t('contact-us')}</div>
                <div className="d-flex mt-2">
                    <i className="fa-solid fa-phone text-primary"/>
                    <span className="text-primary fw-bold ms-2">0933159655</span>
                </div>
            </div>
        </div>
    );
}
