/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './not-found.scss';

export default function NotFound() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className="page-404 w-100 h-100">
            <div className="noise" />
            <div className="overlay" />
            <div className="terminal">
                <h1>
                    {t('error')} <span className="errorcode">404</span>
                </h1>
                <p className="output">{t('404-first-line')}</p>
                <p className="output">
                    {t('please-try-to')} <a href="#" onClick={() => navigate('/login')}>{t('go-back')}</a>{' '}
                    {t('or')} <a href="#2" onClick={() => navigate('/login')}>{t('return-to-homepage')}</a>.
                </p>
                <p className="output">{t('good-luck')}</p>
            </div>
        </div>
    );
}
