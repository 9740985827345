import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TeamCard, CreateTeamModal } from './components';
import './partition.scss';
import PartitionService from './service/partition.service';
import { ITeam } from 'shared/interface/partition.interface';
import cloneDeep from 'lodash.clonedeep';

function PartitionManagement() {
    const { t } = useTranslation();
    const [showModalCreate, setShowModalCreate] = useState<boolean>(false);
    const [teams, setTeams] = useState<ITeam[]>([]);
    const [listOfSaleHasTeam, setListOfSaleHasTeam] = useState<string[]>([]);

    useEffect(() => {
        getAllTeams();
    }, []);

    useEffect(() => {
        let tempList: string[] = [];
        teams.forEach((team) => {
            tempList = [...tempList, ...team.sales];
        });
        setListOfSaleHasTeam(tempList);
    }, [teams]);

    const getAllTeams = async () => {
        const result = await PartitionService.getAll();
        if (result.docs) {
            setTeams(result.docs);
        }
    };

    return (
        <div className="container-fluid p-3 w-100">
            <div className="d-flex justify-content-between">
                <span className="title ms-2">{t('partition-management')}</span>
                <button
                    className="btn btn-primary"
                    onClick={() => setShowModalCreate(true)}
                    // disabled={accountType === RoleName.NONE}
                >
                    {t('add-new-team')}
                </button>
            </div>
            <div className="row w-100 mt-3">
                {teams.map((team, index) => {
                    return (
                        <div key={index} className="col-3">
                            <TeamCard
                                listOfSaleHasTeam={listOfSaleHasTeam}
                                teamName={`Team ${index + 1}`}
                                team={team}
                                onUpdateTeam={(updatedTeam) => {
                                    const tempTeams = cloneDeep(teams);
                                    tempTeams[index] = updatedTeam;
                                    setTeams(tempTeams);
                                }}
                                onRemoveTeam={() => {
                                    const tempTeams = cloneDeep(teams);
                                    tempTeams.splice(index, 1);
                                    setTeams(tempTeams);
                                }}
                            />
                        </div>
                    );
                })}
            </div>
            <CreateTeamModal
                onAddTeam={(newTeam) => {
                    setTeams([...teams, newTeam]);
                }}
                show={showModalCreate}
                handleClose={() => setShowModalCreate(false)}
            />
        </div>
    );
}

export default PartitionManagement;
