/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { IUser } from 'shared/interface/user.interface';
import UserService from 'shared/service/user.service';

interface IProp {
    id?: string;
}

export default function PerformerName(prop: IProp) {
    const { id } = prop;
    const [performer, setPerformer] = useState<IUser | undefined>(undefined);
    const getFarm = async () => {
        const result = await UserService.getUserInfo(id || '');
        setPerformer(result);
    };
    useEffect(() => {
        if (id) {
            getFarm();
        }
    }, [id]);
    return <span>{performer ? performer.name : ''}</span>;
}
