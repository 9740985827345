import { useTranslation } from 'react-i18next';
import { IDashboardReportRetail } from 'shared/interface/bio-report-retail.interface';

interface IProp {
    dashboardReport?: IDashboardReportRetail;
}

export default function ReportCountCalculateRetail(prop: IProp) {
    const { t } = useTranslation();
    const { dashboardReport } = prop;

    return (
        <div className="d-flex flex-column justify-content-around align-items-center h-100 fs-5">
            <div className="swing-in-right-fwd d-flex div-shadow-1 justify-content-between align-items-center w-100 h-100 p-3">
                <span className="text-secondary">{t('report-quantity')}</span>
                <span className="text-primary fw-semibold">
                    {dashboardReport?.totalReportCount
                        ? dashboardReport?.totalReportCount
                        : t('no-data')}
                </span>
            </div>
            <div className="swing-in-right-fwd d-flex div-shadow-1 flex-column justify-content-start align-items-center w-100 h-100 p-3">
                <div className="d-flex justify-content-between w-100">
                    <h4 className="text-secondary">{t('average-percentage')}</h4>
                    <span className="text-primary fw-semibold">
                        {dashboardReport?.totalPercentageAverage
                            ? dashboardReport?.totalPercentageAverage.toFixed(2) + '%'
                            : t('no-data')}
                    </span>
                </div>
                <div className="d-flex flex-column gap-3 mt-3 div-shadow-1 p-3">
                    {dashboardReport?.percentageEvaluation.map((e) => (
                        <div className="d-flex justify-content-between flex-wrap">
                            <h5>{e.title}</h5>
                            <span>
                                {((e.passEvaluation * 100) / e.totalEvaluation).toFixed(2)}%
                            </span>
                        </div>
                    ))}
                </div>
            </div>
            <div className="swing-in-right-fwd d-flex div-shadow-1 justify-content-between align-items-center w-100 h-100 p-3">
                <span className="text-secondary">{t('average-violated-point')}</span>
                <span className="text-primary fw-semibold">
                    {dashboardReport?.totalReportCount
                        ? dashboardReport?.totalViolatedAverage.toFixed(2)
                        : t('no-data')}
                </span>
            </div>
        </div>
    );
}
