/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ActiveFarms from './active-farm';
import { EditFarmInfoModal } from 'module/client/farm/components';
import { ModalMode } from 'shared/enum/modal-mode.enum';
import FarmService from 'module/client/farm/service/farm.service';
import { IFarm, IGroupFarms } from 'shared/interface/farm.interface';
import { LoadMore } from 'shared/components/';
import { groupBy } from 'lodash';
import { Col, Collapse, Form } from 'react-bootstrap';
import { useAuthContext } from 'module/auth/context';
import { FilterStore } from 'shared/enum/local-store.enum';

const LIMIT_SIZE = 10;

function FarmManagement() {
    const { t } = useTranslation();
    const authContext = useAuthContext();
    const [showModalCreate, setShowModalCreate] = useState<boolean>(false);
    const [farms, setFarms] = useState<IFarm[]>([]);
    const [page, setPage] = useState<number>(0);
    const [hasNextPage, setHasNextPage] = useState<boolean>(false);
    const [groupedFarms, setGroupFarms] = useState<IGroupFarms[]>([]);
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [keyword, setKeyword] = useState<string>('');
    const [init, setInit] = useState<boolean>(true);
    const filterRef = useRef({
        keyword,
    });

    useEffect(() => {
        initialization();

        return () => {
            // set filter when component unmount
            localStorage.setItem(
                FilterStore.ADMIN_FARM_MANAGEMENT_FILTER,
                JSON.stringify(filterRef.current)
            );
        };
    }, []);

    const initialization = async () => {
        setInit(true);
        // get filter from local storage
        const rawFilter = localStorage.getItem(FilterStore.ADMIN_FARM_MANAGEMENT_FILTER);
        if (rawFilter) {
            const convertedFilter = JSON.parse(rawFilter);
            if (convertedFilter.keyword) {
                setOpenFilter(true);
                setKeyword(convertedFilter.keyword);
            }
        }
        setPage(1);
        setInit(false);
    };

    useEffect(() => {
        filterRef.current = {
            keyword,
        };
    }, [keyword]);

    useEffect(() => {
        if (!init) {
            if (page) {
                getActiveFarmList(page, LIMIT_SIZE);
            }
        }
    }, [page, init]);

    const getActiveFarmList = async (pageNumber = 1, pageLimit = LIMIT_SIZE) => {
        authContext.setLoading(true);
        const result = await FarmService.getAll(false, pageNumber, pageLimit, keyword);
        authContext.setLoading(false);
        if (result && result.docs) {
            setFarms([...farms, ...result.docs]);
            let grouped = groupBy([...farms, ...result.docs], 'owningCompany');
            if (keyword !== '') {
                grouped = groupBy([...result.docs], 'owningCompany');
                setFarms([...result.docs]);
            }
            const resultGrouped: IGroupFarms[] = [];
            for (const [key, value] of Object.entries(grouped)) {
                resultGrouped.push({
                    companyId: key,
                    farms: value as unknown as IFarm[],
                });
            }
            setGroupFarms(resultGrouped);
            setHasNextPage(result.hasNextPage);
        }
    };

    const checkKeyPress = (ev) => {
        ev.preventDefault();
        if (ev.key === 'Enter') {
            if (page > 1) {
                setPage(1);
            } else {
                getActiveFarmList(page, LIMIT_SIZE);
            }
        }
    };

    return (
        <div className="container-fluid p-3">
            <div className="d-flex justify-content-between">
                <span className="title ms-2">{t('farms')}</span>
                <button
                    className="btn btn-primary"
                    onClick={() => setShowModalCreate(true)}
                    // disabled={accountType === RoleName.NONE}
                >
                    {t('add-farm')}
                </button>
            </div>
            <div className="d-flex flex-column w-100 mb-3 div-shadow rounded">
                <button
                    className="btn btn-outline-secondary w-100 border-0"
                    onClick={() => setOpenFilter(!openFilter)}
                >
                    <div className="d-flex justify-content-between">
                        <i className="fa-solid fa-filter my-auto"/>
                        <span className="fw-bold">{t('filter')}</span>
                        <i className="fa-solid fa-circle-arrow-down my-auto"/>
                    </div>
                </button>
                <Collapse in={openFilter} className="py-2">
                    <div className="p-3">
                        <Form
                            className="row"
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <Form.Group as={Col} sm="4" className="mb-3">
                                <Form.Label>{t('name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('name')}
                                    value={keyword}
                                    onChange={(event) => setKeyword(event.target.value)}
                                    onKeyUpCapture={checkKeyPress}
                                />
                            </Form.Group>
                        </Form>
                        <div className="d-flex justify-content-end">
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    // @ts-ignore
                                    window.gtag('event', 'SEARCH_IN_FARM_ADMIN', {});
                                    if (page > 1) {
                                        setPage(1);
                                    } else {
                                        getActiveFarmList(page, LIMIT_SIZE);
                                    }
                                }}
                            >
                                <i className="fa-solid fa-magnifying-glass my-auto"/>
                                <span className="ms-3">{t('search')}</span>
                            </button>
                        </div>
                    </div>
                </Collapse>
            </div>
            <ActiveFarms groupedFarms={groupedFarms} />
            {hasNextPage && (
                <div className="d-flex w-100 justify-content-center">
                    <LoadMore onClick={() => setPage(page + 1)} />
                </div>
            )}
            <EditFarmInfoModal
                show={showModalCreate}
                mode={ModalMode.CREATE}
                handleClose={() => setShowModalCreate(false)}
                onAddFarm={(newFarm) => setFarms([newFarm, ...farms])}
            />
        </div>
    );
}

export default FarmManagement;
