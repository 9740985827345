/* eslint-disable react-hooks/exhaustive-deps */
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ICalculatedFinalResult } from 'shared/interface/bio-report.interface';
import { IUser } from 'shared/interface/user.interface';
import { buildFileView } from 'shared/service/file';
import './chart-compare.scss';
import 'shared/styles/text-animation.scss';
import { ICalculatedFinalResultRetail } from 'shared/interface/bio-report-retail.interface';

interface IProp {
    finalResult1?: ICalculatedFinalResultRetail;
    finalResult2?: ICalculatedFinalResultRetail;
    finalResult3?: ICalculatedFinalResultRetail;
    performer1?: IUser;
    performer2?: IUser;
    performer3?: IUser;
}

export default function ChartCompareRetail(prop: IProp) {
    const { finalResult1, finalResult2, finalResult3, performer1, performer2, performer3 } = prop;
    const { t, i18n } = useTranslation();
    const [x1, setX1] = useState<number>(-1);
    const [y1, setY1] = useState<number>(-1);
    const [x2, setX2] = useState<number>(-1);
    const [y2, setY2] = useState<number>(-1);
    const [x3, setX3] = useState<number>(-1);
    const [y3, setY3] = useState<number>(-1);

    const [selectedFinalResult, setSelectedFinalResult] = useState<ICalculatedFinalResultRetail>();
    const [selectedPerformer, setSelectedPerformer] = useState<IUser>();

    const renderTooltip1 = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            <div style={{ width: 190 }} className="p-1">
                <div className="d-flex justify-content-between">
                    <span>{t('performer')}</span>
                    <span>{performer1?.name}</span>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                    <span>{t('perform-date')}</span>
                    <span>
                        {finalResult1?.bioReport &&
                            format(
                                new Date(finalResult1?.bioReport.updatedAt),
                                i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                            )}
                    </span>
                </div>
                <hr />
                <div className="d-flex justify-content-between fs-6">
                    <span>{`% ${t('passed')}`}</span>
                    <span>{finalResult1?.bioReport?.percentage?.toFixed(2)}%</span>
                </div>

                <hr />
                <div className="d-flex justify-content-between fs-6">
                    <span>{`${t('violated-point')}`}</span>
                    <span>{finalResult1?.finalResult.totalViolated}</span>
                </div>
            </div>
        </Tooltip>
    );

    const renderTooltip2 = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            <div style={{ width: 190 }} className="p-1">
                <div className="d-flex justify-content-between">
                    <span>{t('performer')}</span>
                    <span>{performer2?.name}</span>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                    <span>{t('perform-date')}</span>
                    <span>
                        {finalResult2?.bioReport &&
                            format(
                                new Date(finalResult2?.bioReport.updatedAt),
                                i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                            )}
                    </span>
                </div>
                <hr />
                <div className="d-flex justify-content-between fs-6">
                    <span>{`% ${t('passed')}`}</span>
                    <span>{finalResult2?.bioReport?.percentage.toFixed(2)}%</span>
                </div>
                <hr />
                <div className="d-flex justify-content-between fs-6">
                    <span>{`${t('violated-point')}`}</span>
                    <span>{finalResult2?.finalResult.totalViolated}</span>
                </div>
            </div>
        </Tooltip>
    );

    const renderTooltip3 = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            <div style={{ width: 190 }} className="p-1">
                <div className="d-flex justify-content-between">
                    <span>{t('performer')}</span>
                    <span>{performer3?.name}</span>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                    <span>{t('perform-date')}</span>
                    <span>
                        {finalResult3?.bioReport &&
                            format(
                                new Date(finalResult3?.bioReport.updatedAt),
                                i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                            )}
                    </span>
                </div>
                <hr />
                <div className="d-flex justify-content-between fs-6">
                    <span>{`% ${t('passed')}`}</span>
                    <span>{finalResult3?.bioReport?.percentage.toFixed(2)}%</span>
                </div>
                <hr />
                <div className="d-flex justify-content-between fs-6">
                    <span>{`${t('violated-point')}`}</span>
                    <span>{finalResult3?.finalResult.totalViolated}</span>
                </div>
            </div>
        </Tooltip>
    );

    const preProcess = () => {
        if (finalResult1) {
            if (finalResult1?.finalResult.percentage < 70) {
                setY1(2);
            } else if (
                finalResult1?.finalResult.percentage >= 70 &&
                finalResult1?.finalResult.percentage <= 85
            ) {
                setY1(1);
            } else {
                setY1(0);
            }

            if (finalResult1.finalResult.totalViolated > 5) {
                setX1(0);
            } else if (
                finalResult1.finalResult.totalViolated >= 3 &&
                finalResult1.finalResult.totalViolated <= 5
            ) {
                setX1(1);
            } else {
                setX1(2);
            }
        }
        if (finalResult2) {
            if (finalResult2?.finalResult.percentage < 70) {
                setY2(2);
            } else if (
                finalResult2?.finalResult.percentage >= 70 &&
                finalResult2.finalResult?.percentage <= 85
            ) {
                setY2(1);
            } else {
                setY2(0);
            }

            if (finalResult2.finalResult.totalViolated > 5) {
                setX2(0);
            } else if (
                finalResult2.finalResult.totalViolated >= 3 &&
                finalResult2.finalResult.totalViolated <= 5
            ) {
                setX2(1);
            } else {
                setX2(2);
            }
        }
        if (finalResult3) {
            if (finalResult3?.finalResult.percentage < 70) {
                setY3(2);
            } else if (
                finalResult3?.finalResult.percentage >= 70 &&
                finalResult3?.finalResult.percentage <= 85
            ) {
                setY3(1);
            } else {
                setY3(0);
            }

            if (finalResult3.finalResult.totalViolated > 5) {
                setX3(0);
            } else if (
                finalResult3.finalResult.totalViolated >= 3 &&
                finalResult3.finalResult.totalViolated <= 5
            ) {
                setX3(1);
            } else {
                setX3(2);
            }
        }
    };

    const renderAvt = (index: number) => {
        let src;
        let overlay;
        let addStyle = {};
        let clickAction;
        if (index === 1) {
            src = buildFileView(performer1?.avatar);
            overlay = renderTooltip1;
            clickAction = () => {
                setSelectedFinalResult(finalResult1);
                setSelectedPerformer(performer1);
            };
        } else if (index === 2) {
            src = buildFileView(performer2?.avatar);
            overlay = renderTooltip2;
            if (x2 === x1 && y2 === y1) {
                addStyle = { left: -8, zIndex: 2 };
            }
            clickAction = () => {
                setSelectedFinalResult(finalResult2);
                setSelectedPerformer(performer2);
            };
        } else {
            src = buildFileView(performer3?.avatar);
            overlay = renderTooltip3;
            if (x3 === x1 && y3 === y1 && x3 === x2 && y3 === y2 && x1 === x2 && y1 === y2) {
                addStyle = { left: -16, zIndex: 3 };
            } else if ((x3 === x1 && y3 === y1) || (x3 === x2 && y3 === y2)) {
                addStyle = { left: -8, zIndex: 3 };
            }
            clickAction = () => {
                setSelectedFinalResult(finalResult3);
                setSelectedPerformer(performer3);
            };
        }
        return (
            <OverlayTrigger placement="auto" delay={{ show: 100, hide: 200 }} overlay={overlay}>
                <div>
                    <img
                        alt="avt"
                        className="border border-white rounded-circle clickable"
                        onClick={clickAction}
                        style={{
                            width: 28,
                            height: 28,
                            zIndex: 1,
                            ...addStyle,
                        }}
                        src={src}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/image/picture/default.jpeg';
                        }}
                    />
                </div>
            </OverlayTrigger>
        );
    };

    const activeCheck = (x: number, y: number) => {
        if ((x1 === x && y1 === y) || (x2 === x && y2 === y) || (x3 === x && y3 === y)) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        preProcess();
        setSelectedFinalResult(finalResult1);
        setSelectedPerformer(performer1);
    }, [finalResult1, finalResult2, finalResult3]);

    return (
        <div className="row mt-2 div-shadow py-3">
            <div className="col-12 py-2">
                <h3 className="ps-2">{t('chart')}</h3>
                <div className="bio-chart swing-in-right-fwd div-shadow p-0 p-sm-3">
                    <div className="row g-0">
                        <div className="col-1 bg-cargill-gray" />
                        <div className="col-11">
                            <div className="row g-0">
                                <div className="col-3 bg-cargill-gray" />
                                <div className="col-9 bg-cargill-gray-darker text-secondary text-uppercase text-center py-2">{`<- ${t(
                                    'fail-vital-point'
                                )} ->`}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-0">
                        <div className="col-1 bg-cargill-gray" />
                        <div className="col-11">
                            <div className="row g-0">
                                <div className="col-3 bg-cargill-gray" />
                                <div className="col-3 d-flex justify-content-center align-items-center border py-2">
                                    {'>5'}
                                </div>
                                <div className="col-3 d-flex justify-content-center align-items-center border py-2">
                                    3-5
                                </div>
                                <div className="col-3 d-flex justify-content-center align-items-center border py-2">
                                    {'<3'}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-0">
                        <div
                            className="col-1 d-flex flex-column justify-content-center text-secondary bg-cargill-gray-darker text-uppercase text-center"
                            style={{ writingMode: 'vertical-rl', transform: 'scale(-1)' }}>{`<- ${t(
                            'total-point'
                        )} ->`}</div>
                        <div className="col-11">
                            <div className="row g-0">
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center border text-center">
                                    86% - 100%
                                </div>
                                <div
                                    className={[
                                        'flex-column col-3 bio-chart-bg-yellow d-flex justify-content-center align-items-center border py-4',
                                        activeCheck(0, 0) && 'bio-chart-bg-yellow-active',
                                    ].join(' ')}>
                                    <div className="d-flex position-relative">
                                        {x1 === 0 && y1 === 0 && renderAvt(1)}
                                        {x2 === 0 && y2 === 0 && renderAvt(2)}
                                        {x3 === 0 && y3 === 0 && renderAvt(3)}
                                    </div>
                                    <span
                                        className={[
                                            'bio-chart-text-yellow text-center',
                                            activeCheck(0, 0) && 'text-active-chart',
                                        ].join(' ')}>
                                        {t('high')}
                                    </span>
                                </div>
                                <div
                                    className={[
                                        'col-3 bio-chart-bg-blue d-flex justify-content-center align-items-center border flex-column py-4',
                                        activeCheck(1, 0) && 'bio-chart-bg-blue-active',
                                    ].join(' ')}>
                                    <div className="d-flex position-relative">
                                        {x1 === 1 && y1 === 0 && renderAvt(1)}
                                        {x2 === 1 && y2 === 0 && renderAvt(2)}
                                        {x3 === 1 && y3 === 0 && renderAvt(3)}
                                    </div>
                                    <span
                                        className={[
                                            'bio-chart-text-blue text-center',
                                            activeCheck(1, 0) && 'text-active-chart',
                                        ].join(' ')}>
                                        {t('medium')}
                                    </span>
                                </div>
                                <div
                                    className={[
                                        'flex-column col-3 bio-chart-bg-green d-flex justify-content-center align-items-center border py-4',
                                        activeCheck(2, 0) && 'bio-chart-bg-green-active',
                                    ].join(' ')}>
                                    <div className="d-flex position-relative">
                                        {x1 === 2 && y1 === 0 && renderAvt(1)}
                                        {x2 === 2 && y2 === 0 && renderAvt(2)}
                                        {x3 === 2 && y3 === 0 && renderAvt(3)}
                                    </div>
                                    <span
                                        className={[
                                            'bio-chart-text-green text-center',
                                            activeCheck(2, 0) && 'text-active-chart',
                                        ].join(' ')}>
                                        {t('low')}
                                    </span>
                                </div>
                            </div>
                            <div className="row g-0">
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center border text-center">
                                    70% - 85%
                                </div>
                                <div
                                    className={[
                                        'flex-column col-3 bio-chart-bg-red d-flex justify-content-center align-items-center border py-4',
                                        activeCheck(0, 1) && 'bio-chart-bg-red-active',
                                    ].join(' ')}>
                                    <div className="d-flex position-relative">
                                        {x1 === 0 && y1 === 1 && renderAvt(1)}
                                        {x2 === 0 && y2 === 1 && renderAvt(2)}
                                        {x3 === 0 && y3 === 1 && renderAvt(3)}
                                    </div>
                                    <span
                                        className={[
                                            'bio-chart-text-red text-center',
                                            activeCheck(0, 1) && 'text-active-chart',
                                        ].join(' ')}>
                                        {t('warning')}
                                    </span>
                                </div>
                                <div
                                    className={[
                                        'flex-column col-3 bio-chart-bg-yellow d-flex justify-content-center align-items-center border py-4',
                                        activeCheck(1, 1) && 'bio-chart-bg-yellow-active',
                                    ].join(' ')}>
                                    <div className="d-flex position-relative">
                                        {x1 === 1 && y1 === 1 && renderAvt(1)}
                                        {x2 === 1 && y2 === 1 && renderAvt(2)}
                                        {x3 === 1 && y3 === 1 && renderAvt(3)}
                                    </div>
                                    <span
                                        className={[
                                            'bio-chart-text-yellow text-center',
                                            activeCheck(1, 1) && 'text-active-chart',
                                        ].join(' ')}>
                                        {t('high')}
                                    </span>
                                </div>
                                <div
                                    className={[
                                        'flex-column col-3 bio-chart-bg-blue d-flex justify-content-center align-items-center border py-4',
                                        activeCheck(2, 1) && 'bio-chart-bg-blue-active',
                                    ].join(' ')}>
                                    <div className="d-flex position-relative">
                                        {x1 === 2 && y1 === 1 && renderAvt(1)}
                                        {x2 === 2 && y2 === 1 && renderAvt(2)}
                                        {x3 === 2 && y3 === 1 && renderAvt(3)}
                                    </div>
                                    <span
                                        className={[
                                            'bio-chart-text-blue text-center',
                                            activeCheck(2, 1) && 'text-active-chart',
                                        ].join(' ')}>
                                        {t('medium')}
                                    </span>
                                </div>
                            </div>
                            <div className="row g-0">
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center border text-center">
                                    {'<70%'}
                                </div>
                                <div
                                    className={[
                                        'flex-column col-3 bio-chart-bg-red d-flex justify-content-center align-items-center border py-4',
                                        activeCheck(0, 2) && 'bio-chart-bg-red-active',
                                    ].join(' ')}>
                                    <div className="d-flex position-relative">
                                        {x1 === 0 && y1 === 2 && renderAvt(1)}
                                        {x2 === 0 && y2 === 2 && renderAvt(2)}
                                        {x3 === 0 && y3 === 2 && renderAvt(3)}
                                    </div>
                                    <span
                                        className={[
                                            'bio-chart-text-red text-center',
                                            activeCheck(0, 2) && 'text-active-chart',
                                        ].join(' ')}>
                                        {t('warning')}
                                    </span>
                                </div>
                                <div
                                    className={[
                                        'flex-column col-3 bio-chart-bg-yellow d-flex justify-content-center align-items-center border py-4',
                                        activeCheck(1, 2) && 'bio-chart-bg-yellow-active',
                                    ].join(' ')}>
                                    <div className="d-flex position-relative">
                                        {x1 === 1 && y1 === 2 && renderAvt(1)}
                                        {x2 === 1 && y2 === 2 && renderAvt(2)}
                                        {x3 === 1 && y3 === 2 && renderAvt(3)}
                                    </div>
                                    <span
                                        className={[
                                            'bio-chart-text-yellow text-center',
                                            activeCheck(1, 2) && 'text-active-chart',
                                        ].join(' ')}>
                                        {t('high')}
                                    </span>
                                </div>
                                <div
                                    className={[
                                        'flex-column col-3 bio-chart-bg-blue d-flex justify-content-center align-items-center border py-4',
                                        activeCheck(2, 2) && 'bio-chart-bg-blue-active',
                                    ].join(' ')}>
                                    <div className="d-flex position-relative">
                                        {x1 === 2 && y1 === 2 && renderAvt(1)}
                                        {x2 === 2 && y2 === 2 && renderAvt(2)}
                                        {x3 === 2 && y3 === 2 && renderAvt(3)}
                                    </div>
                                    <span
                                        className={[
                                            'bio-chart-text-blue text-center',
                                            activeCheck(2, 2) && 'text-active-chart',
                                        ].join(' ')}>
                                        {t('medium')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
