import { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IDashboardReport } from 'shared/interface/bio-report.interface';
import { ClientDashboardAnalyticFilter } from './report-list-dashboard-admin';

interface IProp {
    dashboardReport?: IDashboardReport;
    setReportWithAnalyticFilter: (filter: ClientDashboardAnalyticFilter) => void;
}

export default function ReportQuestionAnalysis(prop: IProp) {
    const { t } = useTranslation();
    const { dashboardReport } = prop;
    const [ascYes, setAscYes] = useState(false);
    const [ascNo, setAscNo] = useState(false);

    const sortByYes = () => {
        setAscYes(!ascYes);
        if (ascYes) {
            dashboardReport?.questionsYesNoCount.sort((a, b) => a.yesCount - b.yesCount);
        } else {
            dashboardReport?.questionsYesNoCount.sort((a, b) => b.yesCount - a.yesCount);
        }
    };
    const sortByNo = () => {
        setAscNo(!ascNo);
        if (ascNo) {
            dashboardReport?.questionsYesNoCount.sort((a, b) => a.noCount - b.noCount);
        } else {
            dashboardReport?.questionsYesNoCount.sort((a, b) => b.noCount - a.noCount);
        }
    };

    return (
        <div className="swing-in-right-fwd">
            <div className="d-flex fs-4 fw-bold text-secondary mb-2">{t('question-analysis')}</div>
            <div className="row">
                {dashboardReport && dashboardReport.questionsYesNoCount.length === 0 && (
                    <div className="d-flex justify-content-center text-secondary">
                        {t('no-data')}
                    </div>
                )}

                <Table responsive={true} striped={true}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{t('question')}</th>
                            <th className="text-primary clickable" onClick={() => sortByYes()}>
                                {t('yes')}
                            </th>
                            <th className="text-danger clickable" onClick={() => sortByNo()}>
                                {t('no')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {dashboardReport &&
                            dashboardReport?.questionsYesNoCount.length > 0 &&
                            dashboardReport?.questionsYesNoCount.map((question, index) => (
                                <tr key={index}>
                                    <td className="text-secondary">{index + 1}</td>
                                    <td>{question.title}</td>
                                    <td className="text-secondary">
                                        <Button
                                            variant="link"
                                            onClick={() =>
                                                prop.setReportWithAnalyticFilter({
                                                    answer: true,
                                                    questionId: question._id,
                                                })
                                            }
                                        >
                                            {question.yesCount}
                                        </Button>
                                    </td>
                                    <td className="text-secondary">
                                        <Button
                                            variant="link"
                                            onClick={() =>
                                                prop.setReportWithAnalyticFilter({
                                                    answer: false,
                                                    questionId: question._id,
                                                })
                                            }
                                        >
                                            {question.noCount}
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}
