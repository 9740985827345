import { Button, Modal } from 'react-bootstrap';

interface IProp {
    title: string;
    body: string;
    confirmTitle: string;
    declineTitle: string;
    show: boolean;
    onHide: (result: boolean) => void;
}

export default function ConfirmDialog(prop: IProp) {
    const { show, title, body, confirmTitle, declineTitle, onHide } = prop;
    return (
        <Modal show={show}>
            <Modal.Header closeButton={true} onHide={() => onHide(false)}>
                <Modal.Title className="text-primary">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onHide(false)}>
                    <span className="fw-semibold">{declineTitle}</span>
                </Button>
                <Button className="btn btn-primary" onClick={() => onHide(true)}>
                    <span className="fw-semibold">{confirmTitle}</span>
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
