/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LocalStoreName } from 'shared/enum/local-store.enum';

export default function Pending() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem(LocalStoreName.TOKEN)) {
            navigate('/login');
        }
    }, []);

    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <span className="fw-semibold fs-2 text-secondary text-center">
                {t('pending-title')}
            </span>
            <button
                className="btn btn-primary"
                onClick={() => {
                    localStorage.clear();
                    setTimeout(() => {
                        location.reload();
                    }, 1000);
                }}
            >
                {t('login')}
            </button>
        </div>
    );
}
