import { toast } from 'react-toastify';
import { ICompany, IPaginationCompany } from 'shared/interface/company.interface';
import api from 'shared/service/api';

const url = '/company';

export class CompanyService {
    static async find(page: number = 1, limit: number = 10, keyword?: string): Promise<IPaginationCompany> {
        try {
            const res = await api.get(url, {
                params: { page, limit, keyword },
            });
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async delete(id: string): Promise<ICompany> {
        try {
            const res = await api.delete(`${url}/${id}`);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async create(dto: any): Promise<ICompany> {
        try {
            const res = await api.post(`${url}`, dto);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async update(id: string, dto: any): Promise<ICompany> {
        try {
            const res = await api.patch(`${url}/${id}`, dto);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async findOne(id: string): Promise<ICompany> {
        try {
            const res = await api.get(`${url}/${id}`);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }
}
