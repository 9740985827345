import { useTranslation } from 'react-i18next';
import {
    IBioReportQuestionRetail,
    RetailPoint,
} from 'shared/interface/bio-report-retail.interface';

interface Props {
    question: IBioReportQuestionRetail;
    startNumber: number;
    defaultAnswer?: RetailPoint;
    onAnswer: (answer: RetailPoint) => void;
}

export default function SingleQuestionRetail(props: Props) {
    const { question, startNumber, onAnswer, defaultAnswer } = props;
    const { t } = useTranslation();

    return (
        <div className="div-shadow p-3 mt-3 rounded">
            <div id={question.dataCore}>
                <div className="d-flex mt-2">
                    <span className="text-primary">
                        {t('question')} {startNumber || 0}
                    </span>
                </div>
                <div className="d-flex mt-2">{question.title}</div>
                <div className="container">
                    <div className="row gap-2 pt-2">
                        {question.points.map((e, index) => (
                            <div key={index} className="form-check form-check-inline col col-md-5">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    checked={e.answer === defaultAnswer?.answer}
                                    name={`${question.dataCore}-radio`}
                                    onChange={(event) => {
                                        if (event.target.checked) {
                                            onAnswer(e);
                                        }
                                    }}
                                />
                                <label className="form-check-label">{e.answer}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
