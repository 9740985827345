/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ConfirmDialog } from 'shared/components';
import { IUser } from 'shared/interface/user.interface';
import UserService from 'shared/service/user.service';
import PendingUserFarm from '../pending-user-farm/pending-user-farm';

export default function BlockedUser() {
    const { t } = useTranslation();
    const [page, setPage] = useState<number>(1);
    const [limit] = useState<number>(10);
    const [hasNextPage, setHasNextPage] = useState<boolean>(false);
    const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(false);
    const [blockedUsers, setBlockedUsers] = useState<IUser[]>();
    const [selectUser, setSelectUser] = useState<IUser>();
    const [showBlockConfirmation, setShowBlockConfirmation] = useState<boolean>(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

    const getUsers = async () => {
        const resBlocked = await UserService.findAll(page, limit, false, true);
        setBlockedUsers(resBlocked.docs);
        setHasNextPage(resBlocked.hasNextPage);
        setHasPreviousPage(resBlocked.hasPrevPage);
    };

    const unblockUser = async () => {
        const res = await UserService.unblock(selectUser?._id || '');
        if (res) {
            toast.success(t('successfully'), { position: 'bottom-center' });
            setBlockedUsers(blockedUsers?.filter((u) => u._id !== selectUser?._id));
        }
    };

    const deleteUser = async () => {
        // @ts-ignore
        window.gtag('event', 'DELETE_USER', { id: selectUser?._id });
        const res = await UserService.delete(selectUser?._id || '');
        if (res) {
            toast.success(t('successfully'), { position: 'bottom-center' });
            setBlockedUsers(blockedUsers?.filter((u) => u._id !== selectUser?._id));
        }
    };

    const renderUnBlockTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('unblock-user')}
        </Tooltip>
    );

    const renderDeleteTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('delete-user')}
        </Tooltip>
    );

    useEffect(() => {
        getUsers();
    }, [page, limit]);

    return (
        <div>
            <table className="table table-hover">
                <thead>
                    <tr className="table-secondary">
                        <th scope="col"/>
                        <th scope="col">{t('full-name')}</th>
                        <th scope="col">{t('phone')}</th>
                        <th scope="col">{t('email')}</th>
                        <th scope="col">{t('user-code')}</th>
                        <th scope="col">{t('affiliated-farm')}</th>
                        <th scope="col">{t('role')}</th>
                        <th scope="col"/>
                    </tr>
                </thead>
                <tbody>
                    {blockedUsers &&
                        blockedUsers.map((user, index) => (
                            <tr className="align-middle" key={user._id}>
                                <th>{index + limit * (page - 1) + 1}</th>
                                <td>{user.name}</td>
                                <td>{user.phone}</td>
                                <td>{user.email}</td>
                                <td>{user.cargillUserCode}</td>
                                <td>
                                    <PendingUserFarm userId={user._id} textClass="text-secondary" />
                                </td>
                                <td
                                    className={[
                                        user.roleName === 'USER' && 'text-warning',
                                        user.roleName === 'ADMIN' && 'text-danger',
                                        user.roleName === 'SALE_MANAGER' && 'text-success',
                                        user.roleName === 'SALE' && 'text-info',
                                    ].join(' ')}
                                >
                                    {t(user.roleName)}
                                </td>
                                <td>
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 100, hide: 200 }}
                                        overlay={renderUnBlockTooltip}
                                    >
                                        <button
                                            className="btn btn-success"
                                            onClick={() => {
                                                setSelectUser(user);
                                                setShowBlockConfirmation(true);
                                            }}
                                        >
                                            <i className="fa-solid fa-unlock"/>
                                        </button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 100, hide: 200 }}
                                        overlay={renderDeleteTooltip}
                                    >
                                        <button
                                            className="btn btn-danger"
                                            onClick={() => {
                                                setSelectUser(user);
                                                setShowDeleteConfirmation(true);
                                            }}
                                        >
                                            <i className="fa-solid fa-person-circle-minus"/>
                                        </button>
                                    </OverlayTrigger>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <nav className="d-flex justify-content-end">
                <ul className="pagination">
                    <li className={['page-item', !hasPreviousPage && 'disabled'].join(' ')}>
                        <a className="page-link" href="#" onClick={() => setPage(page - 1)}>
                            {t('previous')}
                        </a>
                    </li>
                    {hasPreviousPage && (
                        <li className="page-item">
                            <a className="page-link" href="#" onClick={() => setPage(page - 1)}>
                                {page - 1}
                            </a>
                        </li>
                    )}
                    <li className="page-item active">
                        <span className="page-link">{page}</span>
                    </li>
                    {hasNextPage && (
                        <li className="page-item">
                            <a className="page-link" href="#" onClick={() => setPage(page + 1)}>
                                {page + 1}
                            </a>
                        </li>
                    )}
                    <li className={['page-item', !hasNextPage && 'disabled'].join(' ')}>
                        <a className="page-link" href="#" onClick={() => setPage(page + 1)}>
                            {t('next')}
                        </a>
                    </li>
                </ul>
            </nav>
            <ConfirmDialog
                title={t('confirmation-title')}
                body={t('unblocking-confirmation')}
                confirmTitle={t('yes')}
                declineTitle={t('cancel')}
                show={showBlockConfirmation}
                onHide={function (result: boolean): void {
                    setShowBlockConfirmation(false);
                    if (result) {
                        unblockUser();
                    }
                }}
            />
            <ConfirmDialog
                title={t('confirmation-title')}
                body={t('delete-confirmation')}
                confirmTitle={t('yes')}
                declineTitle={t('cancel')}
                show={showDeleteConfirmation}
                onHide={function (result: boolean): void {
                    setShowDeleteConfirmation(false);
                    if (result) {
                        deleteUser();
                    }
                }}
            />
        </div>
    );
}
