export interface IProp {
    active?: boolean;
    activeColor?: string;
    defaultColor?: string;
}

const IconEvaluate = (props: IProp) => {
    const { active = false, activeColor, defaultColor } = props;
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none">
            <path
                d="M11.8519 1.18519H10.6667V0.592593C10.6667 0.435427 10.6042 0.284699 10.4931 0.173566C10.382 0.0624338 10.2312 0 10.0741 0C9.91691 0 9.76618 0.0624338 9.65505 0.173566C9.54391 0.284699 9.48148 0.435427 9.48148 0.592593V1.18519H7.11111V0.592593C7.11111 0.435427 7.04868 0.284699 6.93755 0.173566C6.82641 0.0624338 6.67568 0 6.51852 0C6.36135 0 6.21063 0.0624338 6.09949 0.173566C5.98836 0.284699 5.92593 0.435427 5.92593 0.592593V1.18519H3.55556V0.592593C3.55556 0.435427 3.49312 0.284699 3.38199 0.173566C3.27086 0.0624338 3.12013 0 2.96296 0C2.8058 0 2.65507 0.0624338 2.54394 0.173566C2.4328 0.284699 2.37037 0.435427 2.37037 0.592593V1.18519H1.18519C0.870965 1.18554 0.569717 1.31053 0.34753 1.53271C0.125342 1.7549 0.000359361 2.05615 0 2.37037V13.6296C0.000718919 14.2581 0.250684 14.8606 0.695059 15.3049C1.13943 15.7493 1.74193 15.9993 2.37037 16H10.6667C11.2951 15.9993 11.8976 15.7493 12.342 15.3049C12.7864 14.8606 13.0363 14.2581 13.037 13.6296V2.37037C13.0367 2.05615 12.9117 1.7549 12.6895 1.53271C12.4673 1.31053 12.1661 1.18554 11.8519 1.18519ZM8.88889 11.2593H4.14815C3.99098 11.2593 3.84025 11.1968 3.72912 11.0857C3.61799 10.9746 3.55556 10.8238 3.55556 10.6667C3.55556 10.5095 3.61799 10.3588 3.72912 10.2476C3.84025 10.1365 3.99098 10.0741 4.14815 10.0741H8.88889C9.04605 10.0741 9.19678 10.1365 9.30792 10.2476C9.41905 10.3588 9.48148 10.5095 9.48148 10.6667C9.48148 10.8238 9.41905 10.9746 9.30792 11.0857C9.19678 11.1968 9.04605 11.2593 8.88889 11.2593ZM8.88889 8.88889H4.14815C3.99098 8.88889 3.84025 8.82646 3.72912 8.71532C3.61799 8.60419 3.55556 8.45346 3.55556 8.2963C3.55556 8.13913 3.61799 7.9884 3.72912 7.87727C3.84025 7.76614 3.99098 7.7037 4.14815 7.7037H8.88889C9.04605 7.7037 9.19678 7.76614 9.30792 7.87727C9.41905 7.9884 9.48148 8.13913 9.48148 8.2963C9.48148 8.45346 9.41905 8.60419 9.30792 8.71532C9.19678 8.82646 9.04605 8.88889 8.88889 8.88889Z"
                fill={active ? activeColor ?? '#638C1C' : defaultColor || '#474C55'}
            />
        </svg>
    );
};

export default IconEvaluate;
