/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IQuestion } from 'shared/interface/question.interface';
import { useDropzone } from 'react-dropzone';
import { QuestionType } from 'shared/enum/question.enum';
import { buildFileView } from 'shared/service/file';
import QuestionService from 'module/client/question/service/question.service';
import { toast } from 'react-toastify';

interface IProp {
    question?: IQuestion;
    onHide: (result: IQuestion | null) => void;
    show: boolean;
}

export default function EditQuestion(prop: IProp) {
    const { question, show } = prop;
    const { t } = useTranslation();

    const [validated, setValidated] = useState<boolean>(false);

    const [customCode, setCustomCode] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [questionType, setQuestionType] = useState<string>('');
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/jpeg': ['.jpeg', '.png'],
        },
        maxFiles: 1,
    });
    const [previewImage, setPreviewImage] = useState<File>();
    const [files, setFiles] = useState<any>(undefined);
    const [point, setPoint] = useState<number>(0);
    const [suggestText, setSuggestText] = useState<string>('');
    const [isViolatedPoint, setIsViolatedPoint] = useState<boolean>();

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity()) {
            let res;
            if (question) {
                res = await QuestionService.update(
                    question._id,
                    {
                        customCode,
                        title,
                        questionType,
                        point,
                        isViolatedPoint,
                        isRequired: true,
                        suggestText,
                    },
                    previewImage
                );
            } else {
                res = await QuestionService.create(
                    {
                        customCode,
                        title,
                        questionType,
                        point,
                        isViolatedPoint,
                        isRequired: true,
                        suggestText,
                    },
                    previewImage
                );
            }
            if (res) {
                toast.success(t('successfully'), { position: 'bottom-center' });
                prop.onHide(res);
            }
        }
    };

    const init = () => {
        setCustomCode(question ? question.customCode : '');
        setTitle(question ? question.title : '');
        setQuestionType(question ? question.questionType : QuestionType.YES_NO);
        setPoint(question ? question.point : 0);
        setIsViolatedPoint(question ? question.isViolatedPoint : undefined);
        setSuggestText(question ? question.suggestText : '');
    };

    useEffect(() => {
        setFiles(
            acceptedFiles.map((file, index: number) => {
                setPreviewImage(file);
                const url = URL.createObjectURL(file);
                return <img key={index} src={url} className="img-fluid" alt="" />;
            })
        );
    }, [acceptedFiles]);

    useEffect(() => {
        init();
    }, [question]);

    return (
        <Modal show={show} dialogClassName="max-width-800" fullscreen="sm-down">
            <Modal.Header className="flex-column">
                <div className="d-flex justify-content-end w-100">
                    <button
                        className="btn btn-icon border-0 rounded-circle"
                        onClick={() => {
                            prop.onHide(null);
                        }}
                    >
                        <i className="fa-solid fa-xmark text-primary"/>
                    </button>
                </div>
                <div className="d-flex justify-content-center">
                    <span className="fs-3 fw-semibold">
                        {question ? t('edit-info') : t('create-question')}
                    </span>
                </div>
            </Modal.Header>
            <Modal.Body className="p-5">
                <Form onSubmit={handleSubmit} noValidate={true} validated={validated}>
                    <div className="row">
                        <Form.Group as={Col} sm="12">
                            <Form.Label>{t('question-code')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Control
                                    type="text"
                                    className="text-gray"
                                    required={true}
                                    placeholder={t('question-code')}
                                    value={customCode}
                                    onChange={(e) => setCustomCode(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('required-field')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="row mt-2">
                        <Form.Group as={Col} sm="12">
                            <Form.Label>{t('title')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Control
                                    type="text"
                                    className="text-gray"
                                    required={true}
                                    placeholder={t('title')}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('required-field')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="row">
                        <Form.Group as={Col} sm="12">
                            <Form.Label>{t('question-type')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Select
                                    className="text-gray"
                                    required={true}
                                    placeholder={t('question-type')}
                                    value={questionType}
                                    onChange={(e) => setQuestionType(e.target.value)}
                                >
                                    {Object.values(QuestionType).map((type) => (
                                        <option key={type} value={type}>
                                            {type}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {t('required-field')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="row mt-2">
                        <Form.Group as={Col} sm="12" md="6">
                            <Form.Label>{t('preview-image')}</Form.Label>
                            <div {...getRootProps({ className: 'drop-zone p-5 clickable' })}>
                                <input {...getInputProps()} />
                                <div className="d-flex justify-content-center">
                                    <button
                                        className="btn btn-outline-secondary btn-lg"
                                        type="button"
                                    >
                                        <i className="fa-solid fa-download"/>
                                    </button>
                                </div>
                                <div className="d-flex justify-content-center mt-2">
                                    {t('choose-a-file-or-drag-it-here')}
                                </div>
                                <div className="d-flex justify-content-center text-secondary mt-2">
                                    {t('file-rule')}
                                </div>
                            </div>
                        </Form.Group>
                        <div className="col-12 col-sm-6 p-3">
                            {files && files.length > 0 ? (
                                files
                            ) : (
                                <div>
                                    {question && (
                                        <img
                                            className="img-fluid"
                                            src={buildFileView(question?.image)}
                                            alt=""
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = '/image/picture/default.jpeg';
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row mt-2">
                        <Form.Group as={Col} sm="12">
                            <Form.Label>{t('suggest-text')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Control
                                    type="text"
                                    className="text-gray"
                                    placeholder={t('suggest-text')}
                                    value={suggestText}
                                    onChange={(e) => setSuggestText(e.target.value)}
                                />
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="row mt-2">
                        <Form.Group as={Col} sm="12">
                            <Form.Label>{t('max-point')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Control
                                    type="number"
                                    min={0}
                                    className="text-gray"
                                    required={true}
                                    placeholder={t('max-point')}
                                    value={point}
                                    onChange={(e) => setPoint(parseInt(e.target.value, 10))}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('required-field')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="row mt-2">
                        <Form.Group as={Col} sm="12">
                            <Form.Label>{t('violated-question')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Check
                                    inline={true}
                                    label={t('yes')}
                                    name="group1"
                                    type="radio"
                                    defaultChecked={isViolatedPoint === true}
                                    onClick={() => setIsViolatedPoint(true)}
                                />
                                <Form.Check
                                    inline={true}
                                    label={t('no')}
                                    name="group1"
                                    type="radio"
                                    defaultChecked={isViolatedPoint === false}
                                    onClick={() => setIsViolatedPoint(false)}
                                />
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="row justify-content-center mt-3">
                        <button type="submit" className="col-12 col-sm-6 btn btn-primary">
                            {t('finish')}
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
