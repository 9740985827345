import { useTranslation } from 'react-i18next';

export default function Maintenance() {
    const { t } = useTranslation();
    return (
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <h1>{t('under-maintenance')}</h1>
        </div>
    );
}
