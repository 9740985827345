import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QuestionRetail from './question-retail';
import QuestionSetRetail from './question-set-retail';

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function QuestionManagement() {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState<number>(0);

    return (
        <div className="container-fluid">
            <ul className="nav nav-tabs mb-3">
                <li className="nav-item">
                    <a
                        className={['nav-link', activeTab === 0 && 'active'].join(' ')}
                        aria-current="page"
                        href="#"
                        onClick={() => {
                            setActiveTab(0);
                        }}
                    >
                        <span className="fw-semibold">{t('list-question-set')}</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={['nav-link', activeTab === 1 && 'active'].join(' ')}
                        aria-current="page"
                        href="#"
                        onClick={() => {
                            setActiveTab(1);
                        }}
                    >
                        <span className="fw-semibold">{t('list-question')}</span>
                    </a>
                </li>
            </ul>
            {activeTab === 0 && <QuestionSetRetail />}
            {activeTab === 1 && <QuestionRetail />}
        </div>
    );
}
