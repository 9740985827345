/* eslint-disable react-hooks/exhaustive-deps */
import { CompanyService } from 'module/admin/company-management/service/company.service';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICompany } from 'shared/interface/company.interface';

interface IProp {
    id?: string;
}

export default function Company(prop: IProp) {
    const { id } = prop;
    const { t } = useTranslation();
    const [company, setCompany] = useState<ICompany>();

    const getCompany = async () => {
        if (id && id !== 'undefined') {
            const res = await CompanyService.findOne(id);
            setCompany(res);
        }
    };

    useEffect(() => {
        getCompany();
    }, [id]);

    return <span className="fs-5 fw-bold text-primary">{company?.name || t('other')}</span>;
}
