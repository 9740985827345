import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IQuestionSet } from 'shared/interface/question-set.interface';
import QuestionSetService from '../service/questionSet.service';
import { emptyBox } from 'shared/assets/images';
import { IQuestionSetRetail } from 'shared/interface/question-set-retail.interface';
import { QuestionSetRetailService } from 'shared/service/question-set-retail.service';
import { REPORT_TYPE } from 'shared/enum/bio-report.enum';

interface IProp {
    show: boolean;
    onHide: (result: IQuestionSet | null, type: string) => void;
    disableClose?: boolean;
}

export default function PickQuestionSet(prop: IProp) {
    const [questionSets, setQuestionSets] = useState<IQuestionSet[]>([]);
    const [questionSetsRetail, setQuestionSetsRetail] = useState<IQuestionSetRetail[]>([]);

    const getQuestionSets = async () => {
        const result = await QuestionSetService.findAll(1, 99999);
        setQuestionSets(result.docs.filter((e) => e.isActive));
    };

    const getQuestionSetsRetail = async () => {
        const result = await QuestionSetRetailService.findAll(1, 99999);
        setQuestionSetsRetail(result.docs.filter((e) => e.isActive));
    };

    useEffect(() => {
        getQuestionSets();
        getQuestionSetsRetail();
    }, []);

    return (
        <Modal show={prop.show}>
            <Modal.Header className="flex-column">
                <div className="d-flex justify-content-end w-100">
                    <button
                        className="btn btn-icon border-0 rounded-circle"
                        onClick={() => {
                            prop.onHide(null, '');
                        }}>
                        <i className="fa-solid fa-xmark text-primary" />
                    </button>
                </div>
                <div className="d-flex justify-content-center">
                    <span className="fs-4">{t('pick-question-set')}</span>
                </div>
                <div className="d-flex justify-content-center">
                    <span className="text-secondary">{t('pick-question-set-description')}</span>
                </div>
            </Modal.Header>
            <Modal.Body>
                {questionSets?.length > 0 ? (
                    questionSets.map((set, index) => (
                        <div
                            key={index}
                            className="div-shadow p-3 mt-2 rounded div-picking clickable"
                            onClick={() => {
                                prop.onHide(set, REPORT_TYPE.BIG_COMPANY);
                            }}>
                            <div className="d-flex">
                                <span className="ms-3 fs-5 my-auto">{set.title}</span>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="d-flex py-3 flex-column align-items-center justify-content-center w-100">
                        <img src={emptyBox} alt="empty-box" />
                        <span>{t('no-question-set')}</span>
                    </div>
                )}
                <div
                    style={{ width: 100, height: 1 }}
                    className="border border-secondary mt-2 d-flex justify-content-center"></div>
                {questionSetsRetail?.length > 0 ? (
                    questionSetsRetail.map((set, index) => (
                        <div
                            key={index}
                            className="div-shadow p-3 mt-2 rounded div-picking clickable"
                            onClick={() => {
                                prop.onHide(set, REPORT_TYPE.RETAIL);
                            }}>
                            <div className="d-flex">
                                <span className="ms-3 fs-5 my-auto">{set.title}</span>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="d-flex py-3 flex-column align-items-center justify-content-center w-100">
                        <img src={emptyBox} alt="empty-box" />
                        <span>{t('no-question-set')}</span>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
}
