import { IFarm, IPaginationFarm } from 'shared/interface/farm.interface';
import api from '../../../../shared/service/api';

const url = '/farm';

const convertDataToFomData = (data: any) => {
    const formData = new FormData();
    for (const prop in data) {
        if (Object.prototype.hasOwnProperty.call(data, prop)) {
            if (prop.toString() === 'avatar' || prop.toString() === 'cover') {
                formData.append(prop.toString(), data[prop]);
            } else {
                formData.append(prop.toString(), data[prop].toString());
            }
        }
    }
    return formData;
};

export default class FarmService {
    static async findByUserId(userId: string): Promise<IPaginationFarm> {
        const res = await api.get(`${url}/by-userId`, {
            params: {
                userId,
            },
        });
        return res.data;
    }

    static async findMyFarms(page: number = 1, limit: number = 500, keyword?: string): Promise<IPaginationFarm> {
        const additionalParams = {};
        if (keyword) {
            additionalParams['keyword'] = keyword;
        }
        const res = await api.get(`${url}/mine`, {
            params: {
                page,
                limit,
                ...additionalParams,
            },
        });
        return res.data;
    }

    static async getFarmById(farmId: string, populate: boolean = false): Promise<IFarm> {
        const res = await api.get(`${url}/${farmId}`, {
            params: {
                populate,
            },
        });
        return res.data;
    }

    static async updateFarmById(farmId: string, data: any): Promise<IFarm> {
        const formData = convertDataToFomData(data);
        const res = await api.patch(`${url}/${farmId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return res.data;
    }

    static async getAll(
        deleted: boolean,
        page: number = 1,
        limit: number = 100,
        keyword?: string,
        owningCompanies?: string[]
    ): Promise<IPaginationFarm> {
        const additionalParams = {};
        if (keyword) {
            additionalParams['keyword'] = keyword;
        }
        if (owningCompanies) {
            additionalParams['owningCompanies'] = owningCompanies;
        }
        const res = await api.get(`${url}`, {
            params: {
                deleted,
                limit,
                page,
                ...additionalParams,
            },
        });
        return res.data;
    }

    static async createFarm(farm: any): Promise<any> {
        const formData = convertDataToFomData(farm);
        const res = await api.post(`${url}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return res.data;
    }

    static async deleteFarm(farmId: string): Promise<any> {
        const res = await api.delete(`${url}/${farmId}`);
        return res.data;
    }
}
