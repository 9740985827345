import { useEffect, useState } from 'react';
import { IBioReportQuestion } from 'shared/interface/bio-report.interface';
import './group-question.scss';
import GroupSingleQuestion from './group-single-question/group-single-question';
import cloneDeep from 'lodash.clonedeep';

interface IGroup {
    isGroupStyle: boolean;
    groupTitle: string;
    yesAnswerTitle: string;
    noAnswerTitle: string;
    questions: IBioReportQuestion[];
}
interface IProp {
    groupQuestions: {
        isGroupStyle: boolean;
        groupTitle?: string;
        yesAnswerTitle: string;
        noAnswerTitle: string;
        questions: string[];
    }[];
    groupTitle: string;
    yesAnswerTitle: string;
    noAnswerTitle: string;
    onAnswer: (result: IGroup[]) => void;
    initializeGroupQuestions: IGroup[];
    startNumber?: number;
}

export default function GroupQuestion(prop: IProp) {
    const [groupQuestions, setGroupQuestions] = useState<IGroup[]>(prop.initializeGroupQuestions);

    useEffect(() => {
        setGroupQuestions(prop.initializeGroupQuestions);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [prop.initializeGroupQuestions]);

    const getStartQuestionNum = (index: number) => {
        let sum = prop.startNumber || 0;
        if (index > 0) {
            for (let i = 0; i < index; i++) {
                sum += prop.groupQuestions[i].questions.length;
            }
        }
        return sum;
    };

    const buildBigGroup = () => {
        return prop.groupQuestions?.map((group, index) => (
            <div key={index} className="div-shadow mt-3 py-3">
                <div className="d-flex px-2">
                    <span className="text-uppercase text-primary h5 fw-semibold">
                        {group.groupTitle}
                    </span>
                </div>
                <div className="row group-question-header g-0">
                    <div className="col-8 py-3"/>
                    <div className="col-2 py-3 text-center">{group.noAnswerTitle}</div>
                    <div className="col-2 py-3 border-start text-center">
                        {group.yesAnswerTitle}
                    </div>
                </div>
                <div className="overflow-auto" style={{ height: '40vh' }}>
                    {group.questions.map((question, indexQ) => (

                        <GroupSingleQuestion
                            key={question}
                            id={question}
                            onAnswer={function (result: boolean): void {
                                const newGroup: IGroup[] = cloneDeep(groupQuestions);
                                newGroup[index].questions[indexQ].answerBoolean = result;
                                setGroupQuestions(newGroup);
                                prop.onAnswer(newGroup);
                            }}
                            yesAnswerTitle={group.yesAnswerTitle}
                            noAnswerTitle={group.noAnswerTitle}
                            initializeValue={
                                prop.initializeGroupQuestions[index].questions[indexQ].answerBoolean
                            }
                            questionNumber={indexQ + getStartQuestionNum(index)}
                        />
                    ))}
                </div>
            </div>
        ));
    };

    return (
        <div style={{ height: '70vh' }} className="overflow-auto">
            {prop.groupQuestions?.length > 0 && buildBigGroup()}
        </div>
    );
}
