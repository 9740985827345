import {
    IQuestionRetail,
    IQuestionRetailPagination,
} from 'shared/interface/question-retail.interface';
import api from './api';

const url = '/question-retail';

export const QuestionRetailService = {
    create: async (dto: any, image?: File): Promise<IQuestionRetail> => {
        const formData = new FormData();
        if (image) {
            formData.append('image', image as any);
        }
        formData.append('customCode', dto.customCode);
        formData.append('title', dto.title);
        formData.append('questionType', dto.questionType);
        formData.append('points', JSON.stringify(dto.point));
        formData.append('isViolatedPoint', dto.isViolatedPoint);
        formData.append('isRequired', 'true');
        formData.append('realPoint', dto.realPoint);
        formData.append('suggestText', dto.suggestText);
        const res = await api.post(`${url}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return res.data;
    },

    update: async (id: string, dto: any, image?: File): Promise<IQuestionRetail> => {
        const formData = new FormData();
        if (image) {
            formData.append('image', image as any);
        }
        formData.append('customCode', dto.customCode);
        formData.append('title', dto.title);
        formData.append('questionType', dto.questionType);
        formData.append('points', JSON.stringify(dto.point));
        formData.append('isViolatedPoint', dto.isViolatedPoint);
        formData.append('isRequired', 'true');
        formData.append('realPoint', dto.realPoint);
        formData.append('suggestText', dto.suggestText);
        const res = await api.patch(`${url}/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return res.data;
    },

    find: async (id: string): Promise<IQuestionRetail> => {
        const res = await api.get(`${url}/${id}`);
        return res.data;
    },

    search: async (keyword: string): Promise<IQuestionRetail[]> => {
        const res = await api.get(`${url}/search`, {
            params: {
                keyword,
            },
        });
        return res.data;
    },

    findAll: async (page: number = 1, limit: number = 10): Promise<IQuestionRetailPagination> => {
        const res = await api.get(url, {
            params: {
                page,
                limit,
            },
        });
        return res.data;
    },

    delete: async (id: string): Promise<IQuestionRetail> => {
        const res = await api.delete(`${url}/${id}`);
        return res.data;
    },
};
