import { toast } from 'react-toastify';
import {
    CreateBioReportRequestDto,
    IBioReportRequest,
    IBioReportRequestPagination,
    UpdateBioReportRequestDto,
} from 'shared/interface/bio-report-request.interface';
import api from './api';

const url = 'bio-report-request';

export default class BioReportRequestService {
    static async create(dto: CreateBioReportRequestDto): Promise<IBioReportRequest> {
        try {
            const res = await api.post(url, dto);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async findAll(
        page: number,
        limit: number,
        from?: Date,
        to?: Date
    ): Promise<IBioReportRequestPagination> {
        try {
            const queryParams = {};
            if (from) {
                queryParams['from'] = from.toISOString();
            }
            if (to) {
                queryParams['to'] = to.toISOString();
            }
            const res = await api.get(url, {
                params: {
                    ...queryParams,
                    page,
                    limit,
                },
            });

            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async findById(id: string): Promise<IBioReportRequest> {
        try {
            const res = await api.get(`${url}/${id}`);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async update(id: string, dto: UpdateBioReportRequestDto) {
        try {
            const res = await api.patch(`${url}/${id}`, dto);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async delete(id: string): Promise<IBioReportRequest> {
        try {
            const res = await api.delete(`${url}/${id}`);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }
}
