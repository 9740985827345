import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ConfirmDialog } from 'shared/components';
import { IQuestionSetRetail } from 'shared/interface/question-set-retail.interface';
import { QuestionSetRetailService } from 'shared/service/question-set-retail.service';
import EditQuestionSetRetail from './edit-question-set-retail';

export default function QuestionSetRetail() {
    const { t } = useTranslation();
    const [questionSets, setQuestionSets] = useState<IQuestionSetRetail[]>([]);
    const [page, setPage] = useState<number>(1);
    const [limit] = useState<number>(10);
    const [hasNextPage, setHasNextPage] = useState<boolean>(false);
    const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(false);
    const [select, setSelect] = useState<IQuestionSetRetail>();
    const [showEdit, setShowEdit] = useState<boolean>(false);

    const [show, setShow] = useState<boolean>(false);

    const getQuestionSets = async () => {
        const res = await QuestionSetRetailService.findAll(page, limit);
        setQuestionSets(res.docs);
        setHasNextPage(res.hasNextPage);
        setHasPreviousPage(res.hasPrevPage);
    };

    useEffect(() => {
        getQuestionSets();
    }, [page, limit]);

    const getSetImage = (index: number) => {
        if (index % 4 === 0) {
            return '/image/icon/barn.svg';
        } else if (index % 4 === 1) {
            return '/image/icon/process.svg';
        } else if (index % 4 === 2) {
            return '/image/icon/test-sample.svg';
        } else {
            return '/image/icon/training.svg';
        }
    };

    const onHide = async (result: IQuestionSetRetail | null) => {
        setShowEdit(false);
        if (result) {
            if (!select) {
                setQuestionSets([result, ...questionSets]);
            } else {
                setQuestionSets([
                    ...questionSets.map((q) => {
                        if (q._id === result._id) {
                            q = result;
                        }
                        return q;
                    }),
                ]);
            }
        }
    };

    const setActive = async (id: string) => {
        const res = await QuestionSetRetailService.setActive(id);
        if (res) {
            toast.success(t('successfully'), { position: 'bottom-center' });
            getQuestionSets();
        }
    };

    const setInactive = async (id: string) => {
        const res = await QuestionSetRetailService.setInactive(id);
        if (res) {
            toast.success(t('successfully'), { position: 'bottom-center' });
            getQuestionSets();
        }
    };

    const deleteQuestionSet = async () => {
        const res = await QuestionSetRetailService.remove(select?._id || '');
        if (res) {
            toast.success(t('successfully'), { position: 'bottom-center' });
            setQuestionSets([...questionSets.filter((q) => q._id !== select?._id)]);
        }
    };

    return (
        <div className="p-2">
            <div className="d-flex justify-content-between">
                <h4 className="fw-semibold">{t('list-question-set')}</h4>
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        setSelect(undefined);
                        setTimeout(() => {
                            setShowEdit(true);
                        }, 200);
                    }}>
                    <i className="fa-solid fa-plus" />
                    <span className="ms-3">{t('create-question-set')}</span>
                </button>
            </div>
            <div className="mt-2">
                {questionSets.length > 0 &&
                    questionSets.map((qs) => (
                        <div key={qs._id} className="p-3 mt-3 div-shadow-2">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                    <h4 className="my-auto">{qs.title}</h4>
                                    <span
                                        className={[
                                            'badge fs-6 my-auto ms-3',
                                            qs.isActive ? 'bg-success' : 'bg-secondary text-dark',
                                        ].join(' ')}>
                                        {qs.isActive ? t('active') : t('inactive')}
                                    </span>
                                </div>
                                <div className="d-flex gap-1">
                                    <button
                                        className="btn btn-outline-secondary"
                                        onClick={() => {
                                            setSelect(qs);
                                            setTimeout(() => {
                                                setShowEdit(true);
                                            }, 200);
                                        }}>
                                        <i className="fa-solid fa-pen-clip" />
                                    </button>
                                    {!qs.isActive && (
                                        <button
                                            className="btn btn-outline-danger"
                                            onClick={() => {
                                                setSelect(qs);
                                                setTimeout(() => {
                                                    setShow(true);
                                                }, 200);
                                            }}>
                                            <i className="fa-solid fa-trash-can" />
                                        </button>
                                    )}
                                    {!qs.isActive && (
                                        <button
                                            className="btn btn-outline-primary"
                                            onClick={() => setActive(qs._id)}>
                                            {t('active')}
                                        </button>
                                    )}
                                    {qs.isActive && (
                                        <button
                                            className="btn btn-outline-info"
                                            onClick={() => setInactive(qs._id)}>
                                            {t('make-inactive')}
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-wrap gap-3 mt-3">
                                {qs.listQuestion.map((list, indexList) => (
                                    <div
                                        key={indexList}
                                        className="div-shadow question-set-step p-3">
                                        <div className="d-flex justify-content-between">
                                            <img
                                                className="theme-picture"
                                                style={{ maxWidth: 64 }}
                                                src={getSetImage(indexList)}
                                                alt=""
                                            />
                                            <div>
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    onClick={() => {
                                                        setSelect(qs);
                                                        setTimeout(() => {
                                                            setShowEdit(true);
                                                        }, 200);
                                                    }}>
                                                    <i className="fa-solid fa-circle-info" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="d-flex text-secondary text-uppercase mt-2">{`${t(
                                            'step'
                                        )} ${indexList + 1}`}</div>
                                        <div className="d-flex mt-2 fw-semibold">
                                            {list.groupTitle}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
            </div>
            <nav className="d-flex justify-content-end mt-3">
                <ul className="pagination">
                    <li className={['page-item', !hasPreviousPage && 'disabled'].join(' ')}>
                        <a className="page-link" href="#" onClick={() => setPage(page - 1)}>
                            {t('previous')}
                        </a>
                    </li>
                    {hasPreviousPage && (
                        <li className="page-item">
                            <a className="page-link" href="#" onClick={() => setPage(page - 1)}>
                                {page - 1}
                            </a>
                        </li>
                    )}
                    <li className="page-item active">
                        <span className="page-link">{page}</span>
                    </li>
                    {hasNextPage && (
                        <li className="page-item">
                            <a className="page-link" href="#" onClick={() => setPage(page + 1)}>
                                {page + 1}
                            </a>
                        </li>
                    )}
                    <li className={['page-item', !hasNextPage && 'disabled'].join(' ')}>
                        <a className="page-link" href="#" onClick={() => setPage(page + 1)}>
                            {t('next')}
                        </a>
                    </li>
                </ul>
            </nav>
            <ConfirmDialog
                title={t('confirmation-title')}
                body={t('are-you-sure-to-delete')}
                confirmTitle={t('yes')}
                declineTitle={t('cancel')}
                show={show}
                onHide={function (result: boolean): void {
                    if (result) {
                        deleteQuestionSet();
                    }
                    setShow(false);
                }}
            />
            <EditQuestionSetRetail
                show={showEdit}
                questionSet={select}
                onHide={(result: IQuestionSetRetail | null) => {
                    onHide(result);
                }}
            />
        </div>
    );
}
