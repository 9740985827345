/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { CompanyService } from 'module/admin/company-management/service/company.service';
import { useAuthContext } from 'module/auth/context';
import { useEffect, useState } from 'react';
import { Col, Collapse, Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { ConfirmDialog } from 'shared/components';
import { RoleName } from 'shared/enum/role-name.enum';
import { IUser } from 'shared/interface/user.interface';
import UserService from 'shared/service/user.service';
import PendingUserFarm from '../pending-user-farm/pending-user-farm';
import DetailUser from './detail-user';
import EditUser from './edit-user';

export default function ActiveUser() {
    const { t } = useTranslation();
    const [activeUsers, setActiveUsers] = useState<IUser[]>([]);
    const [page, setPage] = useState<number>(1);
    const [limit] = useState<number>(10);
    const [hasNextPage, setHasNextPage] = useState<boolean>(false);
    const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(false);
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [selectUser, setSelectUser] = useState<IUser | undefined>();
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [showBlockConfirmation, setShowBlockConfirmation] = useState<boolean>(false);

    const [keyword, setKeyword] = useState<string>('');
    const [owningCompany, setOwningCompany] = useState<{ value: string; label: string }>();
    const [roleName, setRoleName] = useState<string>('');

    const authContext = useAuthContext();

    const getActiveUser = async () => {
        authContext.setLoading(true);
        const res = await UserService.findAll(
            page,
            limit,
            true,
            false,
            keyword,
            owningCompany?.value,
            roleName
        );
        setHasNextPage(res.hasNextPage);
        setHasPreviousPage(res.hasPrevPage);
        setActiveUsers(res.docs);
        authContext.setLoading(false);
    };

    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('view-detail')}
        </Tooltip>
    );

    const renderAddTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('add-user')}
        </Tooltip>
    );

    const renderBlockTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('block-user')}
        </Tooltip>
    );

    const blockUser = async () => {
        // @ts-ignore
        window.gtag('event', 'BLOCK_USER', { id: selectUser?._id });
        const res = await UserService.block(selectUser?._id || '');
        if (res) {
            toast.success(t('successfully'), { position: 'bottom-center' });
            setActiveUsers(activeUsers?.filter((u) => u._id !== selectUser?._id));
        }
    };

    const onHideEdit = (result: IUser | null) => {
        setShowEdit(false);
        if (result) {
            if (!selectUser) {
                setActiveUsers([result, ...activeUsers]);
            } else {
                setActiveUsers([
                    ...activeUsers.map((user) => {
                        if (user._id === result._id) {
                            user = result;
                        }
                        return user;
                    }),
                ]);
            }
        }
    };

    const checkKeyPress = (ev) => {
        ev.preventDefault();
        if (ev.key === 'Enter') {
            getActiveUser();
        }
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<{ label: string; value: string }[]>((resolve) => {
            setTimeout(async () => {
                const res = await CompanyService.find(1, 999, inputValue);
                resolve(
                    res.docs.map((u) => {
                        return {
                            label: u.name,
                            value: u._id,
                        };
                    })
                );
            }, 300);
        });

    useEffect(() => {
        getActiveUser();
    }, [page, limit]);

    return (
        <>
            <div className="d-flex flex-column w-100 mb-3 div-shadow rounded">
                <button
                    className="btn btn-outline-secondary w-100 border-0"
                    onClick={() => setOpenFilter(!openFilter)}
                >
                    <div className="d-flex justify-content-between">
                        <i className="fa-solid fa-filter my-auto"/>
                        <span className="fw-bold">{t('filter')}</span>
                        <i className="fa-solid fa-circle-arrow-down my-auto"/>
                    </div>
                </button>
                <Collapse in={openFilter} className="py-2">
                    <div className="p-3">
                        <Form className="row">
                            <Form.Group as={Col} sm="4" className="mb-3">
                                <Form.Label>{t('name-phone-or-email')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('name-phone-or-email')}
                                    value={keyword}
                                    onChange={(event) => setKeyword(event.target.value)}
                                    onKeyUpCapture={checkKeyPress}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm="4">
                                <Form.Label>{t('customer')}</Form.Label>
                                <InputGroup hasValidation={true}>
                                    <AsyncSelect
                                        className="w-100"
                                        cacheOptions={true}
                                        isClearable={true}
                                        placeholder={t('customer')}
                                        value={owningCompany ? owningCompany : undefined}
                                        onChange={(newValue) =>
                                            setOwningCompany(
                                                newValue
                                                    ? {
                                                          label: newValue?.label,
                                                          value: newValue?.value,
                                                      }
                                                    : undefined
                                            )
                                        }
                                        defaultOptions={owningCompany ? [owningCompany] : []}
                                        loadOptions={promiseOptions}
                                        isMulti={false}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} sm="4" className="mb-3">
                                <Form.Label>{t('role')}</Form.Label>
                                <Form.Select
                                    placeholder={t('role')}
                                    value={roleName}
                                    onChange={(event) => setRoleName(event.target.value)}
                                >
                                    {Object.values(RoleName).map((role) => (
                                        <option
                                            key={role}
                                            value={role !== 'NONE' ? role : undefined}
                                        >
                                            {role}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Form>
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-primary" onClick={() => getActiveUser()}>
                                <i className="fa-solid fa-magnifying-glass my-auto"/>
                                <span className="ms-3">{t('search')}</span>
                            </button>
                        </div>
                    </div>
                </Collapse>
            </div>
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                        <tr className="table-secondary">
                            <th scope="col"/>
                            <th scope="col">{t('full-name')}</th>
                            <th scope="col">{t('phone')}</th>
                            <th scope="col">{t('email')}</th>
                            <th scope="col">{t('user-code')}</th>
                            <th scope="col">{t('affiliated-farm')}</th>
                            <th scope="col">{t('role')}</th>
                            <th scope="col">
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 100, hide: 200 }}
                                    overlay={renderAddTooltip}
                                >
                                    <button
                                        className="btn btn-success"
                                        onClick={() => {
                                            setSelectUser(undefined);
                                            setShowEdit(true);
                                        }}
                                    >
                                        <i className="fa-solid fa-plus"/>
                                    </button>
                                </OverlayTrigger>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {activeUsers &&
                            activeUsers.map((user, index) => (
                                <tr className="align-middle" key={user._id}>
                                    <td>{index + limit * (page - 1) + 1}</td>
                                    <td>{user.name}</td>
                                    <td>{user.phone}</td>
                                    <td>{user.email}</td>
                                    <td>{user.cargillUserCode}</td>
                                    <td>
                                        <PendingUserFarm
                                            userId={user._id}
                                            textClass="text-secondary"
                                        />
                                    </td>
                                    <td
                                        className={[
                                            user.roleName === 'USER' && 'text-warning',
                                            user.roleName === 'ADMIN' && 'text-danger',
                                            user.roleName === 'SALE_MANAGER' && 'text-success',
                                            user.roleName === 'SALE' && 'text-info',
                                        ].join(' ')}
                                    >
                                        {t(user.roleName)}
                                    </td>
                                    <td>
                                        <div className="d-flex flex-nowrap">
                                            <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 100, hide: 200 }}
                                                overlay={renderTooltip}
                                            >
                                                <button
                                                    className="btn btn-warning"
                                                    onClick={() => {
                                                        setSelectUser(user);
                                                        setTimeout(() => {
                                                            setShowDetail(true);
                                                        }, 200);
                                                    }}
                                                >
                                                    <i className="fa-solid fa-info"/>
                                                </button>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 100, hide: 200 }}
                                                overlay={renderBlockTooltip}
                                            >
                                                <button
                                                    className="btn btn-danger ms-2"
                                                    onClick={() => {
                                                        setSelectUser(user);
                                                        setShowBlockConfirmation(true);
                                                    }}
                                                >
                                                    <i className="fa-solid fa-user-lock"/>
                                                </button>
                                            </OverlayTrigger>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <nav className="d-flex justify-content-end">
                    <ul className="pagination">
                        <li className={['page-item', !hasPreviousPage && 'disabled'].join(' ')}>
                            <a className="page-link" href="#" onClick={() => setPage(page - 1)}>
                                {t('previous')}
                            </a>
                        </li>
                        {hasPreviousPage && (
                            <li className="page-item">
                                <a className="page-link" href="#" onClick={() => setPage(page - 1)}>
                                    {page - 1}
                                </a>
                            </li>
                        )}
                        <li className="page-item active">
                            <span className="page-link">{page}</span>
                        </li>
                        {hasNextPage && (
                            <li className="page-item">
                                <a className="page-link" href="#" onClick={() => setPage(page + 1)}>
                                    {page + 1}
                                </a>
                            </li>
                        )}
                        <li className={['page-item', !hasNextPage && 'disabled'].join(' ')}>
                            <a className="page-link" href="#" onClick={() => setPage(page + 1)}>
                                {t('next')}
                            </a>
                        </li>
                    </ul>
                </nav>
                <DetailUser
                    user={selectUser}
                    show={showDetail}
                    onEditClicked={() => {
                        setShowDetail(false);
                        setShowEdit(true);
                    }}
                    onHide={function (data: any): void {
                        setShowDetail(false);
                    }}
                />
                <EditUser show={showEdit} user={selectUser} onHide={onHideEdit} />
                <ConfirmDialog
                    title={t('confirmation-title')}
                    body={t('blocking-confirmation')}
                    confirmTitle={t('yes')}
                    declineTitle={t('cancel')}
                    show={showBlockConfirmation}
                    onHide={function (result: boolean): void {
                        setShowBlockConfirmation(false);
                        if (result) {
                            blockUser();
                        }
                    }}
                />
            </div>
        </>
    );
}
