import { toast } from 'react-toastify';
import { IBioReport } from 'shared/interface/bio-report.interface';
import api from 'shared/service/api';

const url = 'bio-report';

export default class BioReportService {
    static async initialize(farmId: string, questionSet: string): Promise<IBioReport> {
        const res = await api.post(`${url}/initialize`, { farmId, questionSet });
        return res.data;
    }

    static async update(id: string, dto?: IBioReport, isDone?: boolean): Promise<IBioReport> {
        const extraDto = {};
        if (isDone) {
            extraDto['isDone'] = isDone;
        }
        if (id) {
            const res = await api.patch(`${url}/${id}`, { ...dto, ...extraDto });
            return res.data;
        }

        return {} as any;
    }

    static async findById(id: string): Promise<IBioReport> {
        const res = await api.get(`${url}/${id}`);
        return res.data;
    }

    static async findPerformers(farmId: string, from: Date, to: Date) {
        const res = await api.get(`${url}/performers`, {
            params: {
                farmId,
                from: from.toISOString(),
                to: to.toISOString(),
            },
        });
        return res.data;
    }

    static async findByFarmPerformer(
        farmId: string,
        userId: string,
        from: Date,
        to: Date,
        questionSet?: string
    ): Promise<IBioReport[]> {
        const res = await api.get(`${url}/report-by-farm-performer`, {
            params: {
                farmId,
                userId,
                from: from.toISOString(),
                to: to.toISOString(),
                questionSet,
            },
        });

        return res.data;
    }

    static async delete(id: string) {
        try {
            const res = await api.delete(`${url}/${id}`);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }
}
