/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './report.scss';
import {
    Col,
    Collapse,
    Form,
    InputGroup,
    OverlayTrigger,
    Tooltip as BootstrapToolTip,
} from 'react-bootstrap';
import FarmService from '../farm/service/farm.service';
import ReportService from './service/report.service';
import { IBioReport, IBioReportPagination } from 'shared/interface/bio-report.interface';
import { useAppContext, useAuthContext } from 'module/auth/context';
import Table from 'react-bootstrap/Table';
import { calculateReport, calculateReportRetail } from 'shared/util/bio-report';
import { format, subMonths } from 'date-fns';
import i18n from 'i18n';
import { useNavigate } from 'react-router-dom';
import { buildFileView } from 'shared/service/file';
import ExcelService from 'shared/service/excel.service';
import Select, { GroupProps, components } from 'react-select';
import UserService from 'shared/service/user.service';
import DatePicker, { registerLocale } from 'react-datepicker';
import { vi, enUS } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import { emptyBox } from 'shared/assets/images';
import BioReportRequestService from 'shared/service/bio-report-request.service';
import { CreateBioReportRequestDto } from 'shared/interface/bio-report-request.interface';
import { BioReportRequestAction } from 'shared/enum/bio-report-request.enum';
import { toast } from 'react-toastify';
import { FilterStore } from 'shared/enum/local-store.enum';
import AsyncSelect from 'react-select/async';
import { IFarm, IGroupFarms } from 'shared/interface/farm.interface';
import { groupBy } from 'lodash';
import { CompanyService } from 'module/admin/company-management/service/company.service';
import { RiskLevel } from 'shared/enum/bio-report.enum';
import QuestionSetTitle from 'shared/components/question-set/QuestionSetTitle';
import { IQuestionSet } from 'shared/interface/question-set.interface';
import QuestionSetService from '../question/service/questionSet.service';
import { IPagination } from 'shared/interface/base.interface';
import { IBioReportRetail } from 'shared/interface/bio-report-retail.interface';
import { BioReportRetailService } from 'shared/service/bio-report-retail.service';
import QuestionSetTitleRetail from 'shared/components/question-set/QuestionSetTitleRetail';
registerLocale('en-US', enUS);
registerLocale('vi', vi);

const groupStyles = {
    borderRadius: '5px',
    background: '#f2fcff',
};

export default function Report() {
    const { t } = useTranslation();
    const appContext = useAppContext();
    const navigate = useNavigate();
    const authContext = useAuthContext();
    const [reports, setReports] = useState<IBioReport[]>([]);
    const [reportsRetail, setReportsRetail] = useState<IBioReportRetail[]>([]);
    const [myReports, setMyReports] = useState<IBioReport[]>([]);
    const [farmOptions, setFarmOptions] = useState<{ value: string; label: string }[]>([]);
    const [from, setFrom] = useState<Date>(subMonths(new Date(), 1));
    const [to, setTo] = useState<Date>(new Date());
    const [openFilter, setOpenFilter] = useState<boolean>(false);

    const [owningCompany, setOwningCompany] = useState<{ value: string; label: string }[]>([]);
    const [owningUser, setOwningUser] = useState<{ value: string; label: string }[]>([]);
    const [selectedFarms, setSelectedFarms] = useState<{ value: string; label: string }[]>([]);
    const [farms, setFarms] = useState<IFarm[]>([]);
    const [questionSets, setQuestionSets] = useState<IQuestionSet[]>([]);
    const [options, setOptions] = useState<any>([]);
    const [riskLevels, setRiskLevels] = useState<{ value: string; label: string }[]>([]);
    const [questionSetsSelected, setQuestionSetsSelected] = useState<
        { value: string; label: string }[]
    >([]);
    const [init, setInit] = useState<boolean>(true);

    const riskLevelOptions = [
        { value: RiskLevel.LOW, label: t('low') },
        { value: RiskLevel.MEDIUM, label: t('medium') },
        { value: RiskLevel.HIGH, label: t('high') },
        { value: RiskLevel.WARNING, label: t('warning') },
    ];

    const filterRef = useRef({
        owningCompany,
        from,
        to,
        selectedFarms,
        owningUser,
        riskLevels,
        questionSetsSelected,
    });

    useEffect(() => {
        filterRef.current = {
            owningCompany,
            from,
            to,
            selectedFarms,
            owningUser,
            riskLevels,
            questionSetsSelected,
        };
    }, [owningCompany, from, to, selectedFarms, owningUser, riskLevels, questionSetsSelected]);

    const Group = (props: GroupProps<{ label: string; value: string }[], true>) => (
        <div style={groupStyles}>
            <components.Group {...props} />
        </div>
    );

    const getAllFarmsRelated = async (owningCompanies: { label: string; value: string }[]) => {
        if (owningCompanies.length === 0) {
            return;
        }
        const resultFarms = await FarmService.getAll(
            false,
            1,
            99999,
            undefined,
            owningCompanies.map((o) => o.value)
        );
        // @ts-ignore
        window.gtag('event', 'GET_ALL_FARM_REPORT_CLIENT', {
            owningCompanies,
            owningUser,
            selectedFarms,
            questionSetsSelected,
        });
        setFarms(resultFarms.docs);
        const grouped = groupBy(resultFarms.docs, 'owningCompany');
        const resultGrouped: IGroupFarms[] = [];
        for (const [key, value] of Object.entries(grouped)) {
            resultGrouped.push({
                companyId: key,
                farms: value as unknown as IFarm[],
            });
        }
        const newOptions = resultGrouped.map((group) => ({
            label: owningCompanies.find((o) => o.value === group.companyId)?.label,
            options: group.farms.map((farm) => ({
                value: farm._id,
                label: farm.title,
            })),
        }));
        setOptions(newOptions);
        setSelectedFarms([
            ...resultFarms.docs.map((doc) => ({
                label: doc.title,
                value: doc._id,
            })),
        ]);
    };

    const promiseCompanyOptions = (inputValue: string) =>
        new Promise<{ label: string; value: string }[]>((resolve) => {
            setTimeout(async () => {
                const res = await CompanyService.find(1, 999, inputValue);
                resolve(
                    res.docs.map((u) => {
                        return {
                            label: u.name,
                            value: u._id,
                        };
                    })
                );
            }, 300);
        });

    const promiseUserOptions = (inputValue: string) =>
        new Promise<{ label: string; value: string }[]>((resolve) => {
            setTimeout(async () => {
                const res = await UserService.searchNotRoleAdmin(inputValue);
                resolve(
                    res.map((u) => {
                        return {
                            label: u.name,
                            value: u._id,
                        };
                    })
                );
            }, 300);
        });

    const onSearch = async (e) => {
        e.preventDefault();
        getReports(selectedFarms.map((item) => item.value));

        setFilter();
    };

    const getFilter = async () => {
        await getQuestionSets();
        const rawFilter = localStorage.getItem(FilterStore.CLIENT_BIO_REPORT_FILTER);
        if (rawFilter) {
            setOpenFilter(true);
            const convertedFilter = JSON.parse(rawFilter);
            if (convertedFilter.owningCompany) {
                setOwningCompany(convertedFilter.owningCompany);
            }
            if (convertedFilter.from) {
                setFrom(new Date(convertedFilter.from));
            }
            if (convertedFilter.to) {
                setTo(new Date(convertedFilter.to));
            }
            if (convertedFilter.selectedFarms) {
                setSelectedFarms(convertedFilter.selectedFarms);
            }
            if (convertedFilter.owningUser) {
                setOwningUser(convertedFilter.owningUser);
            }
            if (convertedFilter.riskLevels) {
                setRiskLevels(convertedFilter.riskLevels);
            }
            if (convertedFilter.questionSetsSelected) {
                setQuestionSetsSelected(convertedFilter.questionSetsSelected);
            }
        }
        setInit(false);
    };

    const getQuestionSets = async () => {
        const res = await QuestionSetService.findAll(1, 9999);
        setQuestionSets(res.docs);
    };

    useEffect(() => {
        if (!init) {
            getReports(selectedFarms.map((farm) => farm.value));
            getReportsRetail(selectedFarms.map((farm) => farm.value));
        }
    }, [init]);

    useEffect(() => {
        getFilter();
        return () => {
            setFilter();
        };
    }, []);

    useEffect(() => {
        // @ts-ignore
        window.gtag('event', 'GO_TO_CLIENT_REPORT', {});
        setMyReports(reports.filter((e) => e.updatedBy === appContext.user?.id));
    }, [reports]);

    const setFilter = () => {
        localStorage.setItem(
            FilterStore.CLIENT_BIO_REPORT_FILTER,
            JSON.stringify(filterRef.current)
        );
    };

    const renderAddTooltip = (props: any) => (
        <BootstrapToolTip id="button-tooltip" {...props}>
            {t('export-excel')}
        </BootstrapToolTip>
    );

    const renderDeleteRequest = (props: any) => (
        <BootstrapToolTip id="button-tooltip" {...props}>
            {t('delete-request')}
        </BootstrapToolTip>
    );

    const downloadExcel = async (id: string) => {
        const response = await ExcelService.getOne(id);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Report.xlsx');
        document.body.appendChild(link);
        link.click();
    };

    const downloadExcelRetail = async (id: string) => {
        const response = await ExcelService.getOneRetail(id);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Report.xlsx');
        document.body.appendChild(link);
        link.click();
    };

    const getReports = async (farmIds: string[], latest = false) => {
        authContext.setLoading(true);
        let result: IBioReportPagination;
        if (!latest) {
            result = await ReportService.getReportByFarmId(
                farmIds,
                from,
                to,
                owningUser.map((o) => o.value),
                riskLevels.map((r) => r.value) as RiskLevel[],
                questionSetsSelected.map((q) => q.value)
            );
        } else {
            result = await ReportService.getReportByFarmId(
                farmIds,
                undefined,
                undefined,
                undefined,
                riskLevels.map((r) => r.value) as RiskLevel[],
                questionSetsSelected.map((q) => q.value)
            );
        }
        // @ts-ignore
        window.gtag('event', 'SEARCH_REPORT_CLIENT', { owningCompany, owningUser, selectedFarms });
        if (result) {
            const doneReports = result.docs.filter((e) => e.isDone);
            setReports([...doneReports]);
        }
        authContext.setLoading(false);
    };

    const getReportsRetail = async (farmIds: string[], latest = false) => {
        authContext.setLoading(true);
        let result: IPagination<IBioReportRetail>;
        if (!latest) {
            result = await BioReportRetailService.getReportByFarmId(
                farmIds,
                from,
                to,
                owningUser.map((o) => o.value),
                riskLevels.map((r) => r.value) as RiskLevel[],
                questionSetsSelected.map((q) => q.value)
            );
        } else {
            result = await BioReportRetailService.getReportByFarmId(
                farmIds,
                undefined,
                undefined,
                undefined,
                riskLevels.map((r) => r.value) as RiskLevel[],
                questionSetsSelected.map((q) => q.value)
            );
        }
        // @ts-ignore
        window.gtag('event', 'SEARCH_REPORT_CLIENT', { owningCompany, owningUser, selectedFarms });
        if (result) {
            const doneReports = result.docs.filter((e) => e.isDone);
            setReportsRetail([...doneReports]);
        }
        authContext.setLoading(false);
    };

    const getRisk = (list: any) => {
        if (list.percentage <= 90) {
            if (list.totalViolated <= 0) {
                return <span className="text-primary">{t('medium')}</span>;
            } else if (list.totalViolated <= 3 && list.totalViolated >= 1) {
                return <span className="text-warning">{t('high')}</span>;
            } else {
                return <span className="text-danger">{t('warning')}</span>;
            }
        } else {
            if (list.totalViolated <= 0) {
                return <span className="text-success">{t('low')}</span>;
            } else if (list.totalViolated <= 3 && list.totalViolated >= 1) {
                return <span className="text-primary">{t('medium')}</span>;
            } else {
                return <span className="text-warning">{t('high')}</span>;
            }
        }
    };

    const deleteRequest = async (reportId: string) => {
        const dto: CreateBioReportRequestDto = {
            note: '',
            reportId,
            action: BioReportRequestAction.DELETE,
        };
        const res = await BioReportRequestService.create(dto);
        if (res) {
            toast.success(t('successfully'), { position: 'bottom-center' });
            appContext.setRequests(appContext.requests + 1);
        }
    };

    return (
        <div className="container-fluid w-100 d-flex flex-column pb-5">
            <div className="d-flex w-100 col-sm-8 p-3 justify-content-between flex-wrap">
                <span className="fs-5 fw-semibold ms-2">{t('reports')}</span>
            </div>

            <div className="d-flex flex-column w-100 mb-3 div-shadow rounded mx-3">
                <button
                    className="btn btn-outline-secondary w-100 border-0"
                    onClick={() => setOpenFilter(!openFilter)}>
                    <div className="d-flex justify-content-between">
                        <i className="fa-solid fa-filter my-auto" />
                        <span className="fw-bold">{t('filter')}</span>
                        <i className="fa-solid fa-circle-arrow-down my-auto" />
                    </div>
                </button>
                <Collapse in={openFilter} className="py-2">
                    <div className="p-3">
                        <Form onSubmit={(e) => onSearch(e)}>
                            <div className="row">
                                <div className="col-12 col-sm-4">
                                    <div className="input-group flex-nowrap">
                                        <span className="input-group-text">{t('from-date')}</span>
                                        <DatePicker
                                            locale={i18n.language}
                                            dateFormat={
                                                i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                            }
                                            className="form-control"
                                            selected={from}
                                            onChange={(date: Date) => {
                                                setFrom(date);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                    <div className="input-group flex-nowrap">
                                        <span className="input-group-text">{t('to-date')}</span>
                                        <DatePicker
                                            locale={i18n.language}
                                            dateFormat={
                                                i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                            }
                                            className="form-control"
                                            selected={to}
                                            onChange={(date: Date) => setTo(date)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <Form.Group as={Col} sm="4">
                                    <Form.Label>{t('customer')}</Form.Label>
                                    <InputGroup hasValidation={true}>
                                        <AsyncSelect
                                            className="w-100"
                                            cacheOptions={true}
                                            isClearable={true}
                                            placeholder={t('customer')}
                                            value={owningCompany ? owningCompany : undefined}
                                            onChange={(newValue) => {
                                                const companies = newValue.map((v) => ({
                                                    label: v.label,
                                                    value: v.value,
                                                }));
                                                setOwningCompany(companies);
                                                getAllFarmsRelated(companies);
                                            }}
                                            defaultOptions={owningCompany ? owningCompany : []}
                                            loadOptions={promiseCompanyOptions}
                                            isMulti={true}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} sm="4">
                                    <Form.Label>{t('farm')}</Form.Label>
                                    <InputGroup hasValidation={true}>
                                        <Select
                                            className="w-100"
                                            options={options}
                                            components={{ Group }}
                                            isMulti={true}
                                            onChange={(newValue) => {
                                                const mapped = newValue.map((v: any) => v.value);
                                                setSelectedFarms(mapped);
                                            }}
                                            value={selectedFarms}
                                        />
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group as={Col} sm="4">
                                    <Form.Label>{t('incharge-people')}</Form.Label>
                                    <InputGroup hasValidation={true}>
                                        <AsyncSelect
                                            className="w-100"
                                            cacheOptions={true}
                                            isClearable={true}
                                            placeholder={t('incharge-people')}
                                            value={owningUser ? owningUser : undefined}
                                            onChange={(newValue) => {
                                                const users = newValue.map((v) => ({
                                                    label: v.label,
                                                    value: v.value,
                                                }));
                                                setOwningUser(users);
                                            }}
                                            defaultOptions={owningUser ? owningUser : []}
                                            loadOptions={promiseUserOptions}
                                            isMulti={true}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </div>
                            <div className="row mt-2">
                                <Form.Group as={Col} sm="4">
                                    <Form.Label>{t('risk-level')}</Form.Label>
                                    <InputGroup>
                                        <Select
                                            className="w-100"
                                            isMulti={true}
                                            name="riskLevel"
                                            options={riskLevelOptions}
                                            value={riskLevels}
                                            onChange={(newValue) => {
                                                const levels = newValue.map((v) => ({
                                                    label: v.label,
                                                    value: v.value,
                                                }));
                                                setRiskLevels(levels);
                                            }}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} sm="4">
                                    <Form.Label>{t('question-set')}</Form.Label>
                                    <InputGroup>
                                        <Select
                                            className="w-100"
                                            isMulti={true}
                                            name="questionSets"
                                            options={questionSets.map((e) => ({
                                                label: e.title,
                                                value: e._id,
                                            }))}
                                            value={questionSetsSelected}
                                            onChange={(newValue) => {
                                                const questionSets = newValue.map((v) => ({
                                                    label: v.label,
                                                    value: v.value,
                                                }));
                                                setQuestionSetsSelected(questionSets);
                                            }}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </div>
                            <div className="d-flex justify-content-end gap-2 mt-2">
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() =>
                                        getReports(selectedFarms.map((farm) => farm.value))
                                    }>
                                    {t('latest')}
                                </button>
                                <button className="btn btn-primary" type="submit">
                                    {t('search')}
                                </button>
                            </div>
                        </Form>
                    </div>
                </Collapse>
            </div>

            <div
                className="row w-100 mx-0 my-3 m-sm-3 p-sm-3 p-0
            "
                style={{ backgroundColor: '#fff' }}>
                <div className="d-flex col-12 flex-column">
                    <span className="fs-5">{t('my-report')}</span>
                    <Table bordered={true} hover={true} className="mt-3" responsive={true}>
                        <thead style={{ backgroundColor: '#F2F4F6' }}>
                            <tr>
                                <th className="border-start border-0">{t('created-at')}</th>
                                <th>{t('farm')}</th>
                                <th>{t('question-set')}</th>
                                <th>{t('passed-point')}</th>
                                <th>{t('passed-percentage')}</th>
                                <th>{t('risk')}</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {myReports.map((report, index) => {
                                const result = calculateReport(report);
                                const { bioReport, finalResult } = result;

                                return (
                                    <tr className="clickable align-middle" key={index}>
                                        <td
                                            className="border-start border-0 fs-6 custom-color"
                                            onClick={() => navigate(`/bio-report/${report._id}`)}>
                                            {format(
                                                new Date(
                                                    bioReport.updatedAt ?? bioReport.createdAt
                                                ),
                                                i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                            )}
                                        </td>
                                        <td onClick={() => navigate(`/bio-report/${report._id}`)}>
                                            <Farm farmId={bioReport.owningFarm} />
                                        </td>
                                        <td onClick={() => navigate(`/bio-report/${report._id}`)}>
                                            <QuestionSetTitle id={report.questionSet} />
                                        </td>
                                        <td onClick={() => navigate(`/bio-report/${report._id}`)}>
                                            {finalResult.finalPoint}
                                        </td>
                                        <td onClick={() => navigate(`/bio-report/${report._id}`)}>
                                            {finalResult.percentage?.toFixed(2)}%
                                        </td>
                                        <td onClick={() => navigate(`/bio-report/${report._id}`)}>
                                            {getRisk(finalResult as any)}
                                        </td>
                                        <td className="d-flex gap-2">
                                            <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 100, hide: 200 }}
                                                overlay={renderAddTooltip}>
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    onClick={() => {
                                                        // @ts-ignore
                                                        window.gtag(
                                                            'event',
                                                            'DOWNLOAD_EXCEL_CLIENT',
                                                            {}
                                                        );
                                                        downloadExcel(report._id);
                                                    }}>
                                                    <i className="fa-solid fa-cloud-arrow-down" />
                                                </button>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 100, hide: 200 }}
                                                overlay={renderDeleteRequest}>
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    onClick={() => {
                                                        deleteRequest(report._id);
                                                    }}>
                                                    <i className="fa-solid fa-trash" />
                                                </button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    {myReports.length === 0 && (
                        <div className="d-flex py-3 flex-column align-items-center justify-content-center w-100">
                            <img src={emptyBox} alt="empty-box" />
                            <span>{t('no-report')}</span>
                        </div>
                    )}
                </div>
            </div>

            <div
                className="row w-100 mx-0 my-3 m-sm-3 p-sm-3 p-0
            "
                style={{ backgroundColor: '#fff' }}>
                <div className="d-flex col-12 flex-column">
                    <span className="fs-5">{t('list-report')}</span>
                    <Table bordered={true} hover={true} className="mt-3" responsive={true}>
                        <thead style={{ backgroundColor: '#F2F4F6' }}>
                            <tr>
                                <th className="border-start border-0">{t('created-at')}</th>
                                <th className="border border-0">{t('created-by')}</th>
                                <th>{t('farm')}</th>
                                <th>{t('question-set')}</th>
                                <th>{t('passed-point')}</th>
                                <th>{t('passed-percentage')}</th>
                                <th>{t('risk')}</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody className="w-100">
                            {reports.map((report, index) => {
                                const result = calculateReport(report);
                                const { bioReport, finalResult } = result;

                                return (
                                    <tr className="clickable align-middle" key={index}>
                                        <td
                                            className="border-start border-0 fs-6 custom-color"
                                            onClick={() => navigate(`/bio-report/${report._id}`)}>
                                            {format(
                                                new Date(
                                                    bioReport.updatedAt ?? bioReport.createdAt
                                                ),
                                                i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                            )}
                                        </td>
                                        <td
                                            className="border border-0"
                                            onClick={() => navigate(`/bio-report/${report._id}`)}>
                                            <Avatar userId={bioReport.updatedBy} />
                                        </td>
                                        <td onClick={() => navigate(`/bio-report/${report._id}`)}>
                                            <Farm farmId={bioReport.owningFarm} />
                                        </td>
                                        <td onClick={() => navigate(`/bio-report/${report._id}`)}>
                                            <QuestionSetTitle
                                                id={report.questionSet}></QuestionSetTitle>
                                        </td>
                                        <td onClick={() => navigate(`/bio-report/${report._id}`)}>
                                            {finalResult.finalPoint}
                                        </td>
                                        <td onClick={() => navigate(`/bio-report/${report._id}`)}>
                                            {finalResult.percentage?.toFixed(2)}%
                                        </td>
                                        <td onClick={() => navigate(`/bio-report/${report._id}`)}>
                                            {getRisk(finalResult as any)}
                                        </td>
                                        <td className="d-flex gap-2">
                                            <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 100, hide: 200 }}
                                                overlay={renderAddTooltip}>
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    onClick={() => {
                                                        downloadExcel(report._id);
                                                    }}>
                                                    <i className="fa-solid fa-cloud-arrow-down" />
                                                </button>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 100, hide: 200 }}
                                                overlay={renderDeleteRequest}>
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    onClick={() => {
                                                        deleteRequest(report._id);
                                                    }}>
                                                    <i className="fa-solid fa-trash" />
                                                </button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                );
                            })}
                            {reportsRetail.map((report, index) => {
                                const result = calculateReportRetail(report);
                                const { bioReport, finalResult } = result;

                                return (
                                    <tr className="clickable align-middle" key={index}>
                                        <td
                                            className="border-start border-0 fs-6 custom-color"
                                            onClick={() => navigate(`/bio-report/${report._id}`)}>
                                            {format(
                                                new Date(
                                                    bioReport.updatedAt ?? bioReport.createdAt
                                                ),
                                                i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                            )}
                                        </td>
                                        <td
                                            className="border border-0"
                                            onClick={() => navigate(`/bio-report-retail/${report._id}`)}>
                                            <Avatar userId={bioReport.updatedBy} />
                                        </td>
                                        <td onClick={() => navigate(`/bio-report-retail/${report._id}`)}>
                                            <Farm farmId={bioReport.owningFarm} />
                                        </td>
                                        <td onClick={() => navigate(`/bio-report-retail/${report._id}`)}>
                                            <QuestionSetTitleRetail
                                                id={report.questionSet}></QuestionSetTitleRetail>
                                        </td>
                                        <td onClick={() => navigate(`/bio-report-retail/${report._id}`)}>
                                            {finalResult.finalPoint}
                                        </td>
                                        <td onClick={() => navigate(`/bio-report-retail/${report._id}`)}>
                                            {finalResult.percentage?.toFixed(2)}%
                                        </td>
                                        <td onClick={() => navigate(`/bio-report-retail/${report._id}`)}>
                                            {getRisk(finalResult as any)}
                                        </td>
                                        <td className="d-flex gap-2">
                                            <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 100, hide: 200 }}
                                                overlay={renderAddTooltip}>
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    onClick={() => {
                                                        downloadExcelRetail(report._id);
                                                    }}>
                                                    <i className="fa-solid fa-cloud-arrow-down" />
                                                </button>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 100, hide: 200 }}
                                                overlay={renderDeleteRequest}>
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    onClick={() => {
                                                        deleteRequest(report._id);
                                                    }}>
                                                    <i className="fa-solid fa-trash" />
                                                </button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    {reports.length === 0 && (
                        <div className="d-flex py-3 flex-column align-items-center justify-content-center w-100">
                            <img src={emptyBox} alt="empty-box" />
                            <span>{t('no-report')}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

interface IAvatarProps {
    userId: string;
}

const Avatar = (props: IAvatarProps) => {
    const { userId } = props;
    const [avatar, setAvatar] = useState<string>();
    const [userName, setUserName] = useState<string>();

    useEffect(() => {
        getUserInfo();
    }, [userId]);

    const getUserInfo = async () => {
        const result = await UserService.getUserInfo(userId);

        if (result) {
            setAvatar(result.avatar);
            setUserName(result.name);
        }
    };

    return (
        <div className="d-flex align-items-center">
            <img
                alt=""
                src={buildFileView(avatar)}
                className="rounded-circle me-2"
                style={{ height: 22, width: 22, objectFit: 'cover' }}
            />
            <span>{userName}</span>
        </div>
    );
};

interface IFarmProp {
    farmId: string;
}

const Farm = (props: IFarmProp) => {
    const { farmId } = props;
    const [avatar, setAvatar] = useState<string>();
    const [farmName, setFarmName] = useState<string>();

    useEffect(() => {
        getFarmInfo();
    }, [farmId]);

    const getFarmInfo = async () => {
        const result = await FarmService.getFarmById(farmId);

        if (result) {
            setAvatar(result.avatar);
            setFarmName(result.title);
        }
    };

    return (
        <div className="d-flex align-items-center">
            <img
                alt=""
                src={buildFileView(avatar)}
                className="rounded-circle me-2"
                style={{ height: 22, width: 22, objectFit: 'cover' }}
            />
            <span>{farmName}</span>
        </div>
    );
};
