import { ITeam, IPaginationTeam } from 'shared/interface/partition.interface';
import api from '../../../../shared/service/api';

const url = '/sale-manager';
export default class PartitionService {
    static async getAll(): Promise<IPaginationTeam> {
        const res = await api.get(url, {
            params: {
                limit: 100,
            },
        });
        return res.data;
    }

    static async update(id: string, data: ITeam): Promise<ITeam> {
        const res = await api.patch(`${url}/${id}`, data);
        return res.data;
    }

    static async create(data: any): Promise<ITeam> {
        const res = await api.post(url, data);
        return res.data;
    }

    static async delete(id: string): Promise<ITeam> {
        const res = await api.delete(`${url}/${id}`);
        return res.data;
    }
}
