/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import TopicService from './service/topic.service';
import { useParams, useNavigate } from 'react-router-dom';
import i18n from 'i18n';

import { ITopic } from 'shared/interface/topic.interface';
import createPurify from 'dompurify';
import { logo } from 'shared/assets/images';
import { iconClock } from 'shared/assets/icons';
import { format } from 'date-fns';

const DOMPurify = createPurify();

export default function TopicDetail() {
    const params = useParams();
    const navigate = useNavigate();
    const [topicDetail, setTopicDetail] = useState<ITopic | undefined>();

    useEffect(() => {
        getTopicDetail();
    }, []);

    const getTopicDetail = async () => {
        const { topicId } = params;

        if (topicId) {
            const result = await TopicService.getTopicDetail(topicId);
            setTopicDetail(result);
            if (result.url && result.url !== '') {
                window.open(result.url, '_blank', 'noopener,noreferrer');
            }
        }
    };

    return (
        <div className="container">
            <div className="row justify-content-center h-100">
                <div
                    className="d-flex w-100 justify-content-start py-2"
                    style={{ backgroundColor: 'rgba(0, 131, 60, 0.1)' }}
                >
                    <img
                        alt="logo"
                        className="clickable"
                        onClick={() => navigate('/dashboard')}
                        src={logo}
                        style={{ height: 32 }}
                    />
                </div>
                <div className="d-flex mt-4 flex-column h-100 justify-content-between">
                    <div>
                        <h2>{topicDetail?.title}</h2>
                        {topicDetail?.content && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(topicDetail.content, {
                                        ADD_TAGS: ['figure', 'oembed', 'iframe'],
                                        ADD_ATTR: [
                                            'allow',
                                            'allowfullscreen',
                                            'frameborder',
                                            'scrolling',
                                        ],
                                    }),
                                }}
                            />
                        )}
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="me-1">
                            <img
                                alt="clock"
                                style={{
                                    width: '1.25rem',
                                    height: '1.25rem',
                                }}
                                src={iconClock}
                            />
                        </div>
                        <div>
                            <span className="create-at">
                                {' '}
                                {topicDetail &&
                                    format(
                                        new Date(topicDetail?.updatedAt),
                                        i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                    )}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
