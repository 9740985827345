export interface IProp {
    active?: boolean;
    activeColor?: string;
    defaultColor?: string;
}

const IconNews = (props: IProp) => {
    const { active = false, activeColor, defaultColor } = props;
    const fill = active ? activeColor ?? '#638C1C' : defaultColor || '#474C55';
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_588_8367)">
                <path
                    d="M5.23566 2.89014V11.2111H1.43694C0.525405 11.2111 -0.348794 10.849 -0.993344 10.2045C-1.6379 9.55994 -2 8.68574 -2 7.77421V6.32707C-2 5.41554 -1.6379 4.54134 -0.993344 3.89679C-0.348794 3.25224 0.525405 2.89014 1.43694 2.89014H5.23566Z"
                    fill={fill}
                />
                <path
                    d="M13.0299 1.23823C12.8671 1.12112 12.6788 1.04437 12.4806 1.01431C12.2823 0.984239 12.0797 1.00172 11.8895 1.0653L5.9563 3.04136V11.0599L11.8895 13.036C12.0185 13.0794 12.1536 13.1014 12.2897 13.1011C12.456 13.1013 12.6207 13.0687 12.7744 13.0052C12.9281 12.9417 13.0678 12.8485 13.1855 12.7309C13.3031 12.6134 13.3965 12.4738 13.4602 12.3202C13.5239 12.1666 13.5566 12.0019 13.5566 11.8356V2.2657C13.5565 2.06495 13.5087 1.86711 13.4171 1.68847C13.3255 1.50984 13.1928 1.35552 13.0299 1.23823Z"
                    fill={fill}
                />
                <path
                    d="M6.06775 16.1169C5.80215 16.4564 5.44655 16.7144 5.04145 16.8616C4.63635 17.0088 4.19805 17.0393 3.77648 16.9496C3.35491 16.8598 2.96702 16.6535 2.65701 16.354C2.34699 16.0546 2.12732 15.6741 2.02302 15.2559L1.19092 11.9202C1.27234 11.9323 1.35466 11.9371 1.43693 11.9347H5.93027L6.48018 14.1416C6.5661 14.4805 6.57361 14.8346 6.50214 15.1769C6.43068 15.5192 6.28211 15.8407 6.06775 16.1169Z"
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_588_8367">
                    <rect width="16" height="16" fill={fill} />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconNews;
