import { toast } from 'react-toastify';
import api from 'shared/service/api';

const url = '/version';
export default class VersionService {
    static async getLatestVersion(): Promise<string> {
        try {
            const res = await api.get(`${url}`);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }
}
