/* eslint-disable react-hooks/exhaustive-deps */
import { format } from 'date-fns';
import { useAuthContext } from 'module/auth/context';
import { useEffect, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { emptyBox } from 'shared/assets/images';
import { RiskLevel } from 'shared/enum/bio-report.enum';
import FarmName from '../report-management/farmName';
import PerformerName from '../report-management/performerName';
import AdminDashboardService from './service/admin-dashboard-retail.service';
import { IBioReportRetail } from 'shared/interface/bio-report-retail.interface';
import AdminDashboardRetailService from './service/admin-dashboard-retail.service';

export interface AdminDashboardAnalyticFilter {
    from?: Date;
    to?: Date;
    owningCompanies?: string[];
    owningUsers?: string[];
    questionId?: string;
    answer?: number;
    riskLevel?: RiskLevel;
    position?: number;
}

interface IProp {
    show: boolean;
    onHide: () => void;
    filter: AdminDashboardAnalyticFilter;
}

export default function ReportListDashboardRetailAdmin(props: IProp) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [reports, setReports] = useState<IBioReportRetail[]>([]);
    const { show, filter } = props;
    const authContext = useAuthContext();

    const getRisk = (list: { percentage: number; totalViolated: number }) => {
        if (list.percentage <= 86) {
            if (list.totalViolated < 3) {
                return <span className="text-primary">{t('medium')}</span>;
            } else if (list.totalViolated <= 5 && list.totalViolated >= 3) {
                return <span className="text-warning">{t('high')}</span>;
            } else {
                return <span className="text-danger">{t('warning')}</span>;
            }
        } else {
            if (list.totalViolated < 3) {
                return <span className="text-success">{t('low')}</span>;
            } else if (list.totalViolated <= 5 && list.totalViolated >= 3) {
                return <span className="text-dark">{t('medium')}</span>;
            } else {
                return <span className="text-warning">{t('high')}</span>;
            }
        }
    };

    const getReports = async () => {
        if (filter.questionId || filter.riskLevel || filter.answer) {
            authContext.setLoading(true);
            const {
                from,
                to,
                owningCompanies,
                owningUsers,
                questionId,
                answer,
                riskLevel,
                position,
            } = filter;
            const res = await AdminDashboardRetailService.findWithAnalytic(
                from ?? new Date(),
                to ?? new Date(),
                owningCompanies,
                owningUsers,
                questionId,
                answer,
                riskLevel,
                position
            );
            setReports(res);
            authContext.setLoading(false);
        }
    };

    useEffect(() => {
        getReports();
    }, [filter]);

    return (
        <Modal show={show} dialogClassName="max-width-800" fullscreen="sm-down">
            <Modal.Header className="flex-column">
                <div className="d-flex justify-content-end w-100">
                    <button
                        className="btn btn-icon border-0 rounded-circle"
                        onClick={() => {
                            props.onHide();
                        }}
                    >
                        <i className="fa-solid fa-xmark text-primary"/>
                    </button>
                </div>
                <div className="d-flex justify-content-center">
                    <span className="fs-3 fw-semibold">{t('reports')}</span>
                </div>
            </Modal.Header>
            <Modal.Body className="p-5">
                <Table responsive={true} striped={true}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{t('created-at')}</th>
                            <th>{t('performer')}</th>
                            <th>{t('farm')}</th>
                            <th>{t('passed-percentage')}</th>
                            <th>{t('violated-point')}</th>
                            <th>{t('risk')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reports.map((r, index) => (
                            <tr
                                key={r._id}
                                className="clickable"
                                onClick={() => navigate(`/bio-report/${r._id}`)}
                            >
                                <td>{index + 1}</td>
                                <td>
                                    {format(
                                        new Date(r.updatedAt),
                                        i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                    )}
                                </td>
                                <td>
                                    <PerformerName id={r.updatedBy} />
                                </td>
                                <td>
                                    <FarmName id={r.owningFarm} />
                                </td>
                                <td>{r.percentage.toFixed(2)} %</td>
                                <td>{r.violatedPointsCount}</td>
                                <td>
                                    {getRisk({
                                        percentage: r.percentage,
                                        totalViolated: r.violatedPointsCount,
                                    })}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {reports.length === 0 && (
                    <div className="d-flex py-3 flex-column align-items-center justify-content-center w-100">
                        <img src={emptyBox} alt="empty-box" />
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
}
