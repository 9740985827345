/* eslint-disable react-hooks/exhaustive-deps */
import { CompanyService } from 'module/admin/company-management/service/company.service';
import { useEffect, useState } from 'react';
import { ICompany } from 'shared/interface/company.interface';

interface IProp {
    id?: string;
}

export default function CompanyName(prop: IProp) {
    const { id } = prop;
    const [company, setCompany] = useState<ICompany | undefined>(undefined);
    const getFarm = async () => {
        const result = await CompanyService.findOne(id || '');
        setCompany(result);
    };
    useEffect(() => {
        if (id) {
            getFarm();
        }
    }, [id]);
    return <span>{company ? company.name : ''}</span>;
}
