import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FinalBioReportResultEnum, RiskLevel } from 'shared/enum/bio-report.enum';
import { IDashboardReport } from 'shared/interface/bio-report.interface';
import { ClientDashboardAnalyticFilter } from './report-list-dashboard-admin';

interface IProp {
    dashboardReport?: IDashboardReport;
    setReportWithAnalyticFilter: (filter: ClientDashboardAnalyticFilter) => void;
}

export default function ReportAnalysis(prop: IProp) {
    const { dashboardReport } = prop;
    const { t } = useTranslation();
    return (
        <div className="swing-in-right-fwd">
            <div className="d-flex fs-4 fw-bold text-secondary mb-2">{t('report-analysis')}</div>
            <div className="d-flex p-3 bg-secondary-primary mb-3">
                <span className="text-uppercase fw-bold text-primary">{t('total-point')}</span>
            </div>

            <div className="row g-0">
                <div className="col-3 d-flex justify-content-center align-items-center p-2 p-sm-3">
                    90% - 100%
                </div>
                <div
                    className={[
                        'col-3 bio-chart-bg-yellow d-flex justify-content-center align-items-center p-2 p-sm-3 border',
                    ].join(' ')}
                >
                    <div className="d-flex flex-column">
                        <span className="bio-chart-text-yellow text-center">{t('high')}</span>
                        <Button
                            variant="link"
                            onClick={() =>
                                prop.setReportWithAnalyticFilter({
                                    riskLevel: RiskLevel.HIGH,
                                    position: 1,
                                })
                            }
                        >
                            {
                                dashboardReport?.finalReportArray.find(
                                    (e) =>
                                        e.type === FinalBioReportResultEnum.NINETY_HUNDRED_GREATER_3
                                )?.count
                            }{' '}
                            {t('report')}
                        </Button>
                    </div>
                </div>
                <div
                    className={[
                        'col-3 bio-chart-bg-blue d-flex justify-content-center align-items-center p-2 p-sm-3 border',
                    ].join(' ')}
                >
                    <div className="d-flex flex-column">
                        <span className="bio-chart-text-blue text-center">{t('medium')}</span>
                        <Button
                            variant="link"
                            onClick={() =>
                                prop.setReportWithAnalyticFilter({
                                    riskLevel: RiskLevel.MEDIUM,
                                    position: 1,
                                })
                            }
                        >
                            {
                                dashboardReport?.finalReportArray.find(
                                    (e) =>
                                        e.type ===
                                        FinalBioReportResultEnum.NINETY_HUNDRED_FROM_1_TO_3
                                )?.count
                            }{' '}
                            {t('report')}
                        </Button>
                    </div>
                </div>
                <div
                    className={[
                        'col-3 bio-chart-bg-green d-flex justify-content-center align-items-center p-2 p-sm-3 border',
                    ].join(' ')}
                >
                    <div className="d-flex flex-column">
                        <span className="bio-chart-text-green text-center">{t('low')}</span>
                        <Button
                            variant="link"
                            onClick={() =>
                                prop.setReportWithAnalyticFilter({
                                    riskLevel: RiskLevel.LOW,
                                    position: 1,
                                })
                            }
                        >
                            {
                                dashboardReport?.finalReportArray.find(
                                    (e) => e.type === FinalBioReportResultEnum.NINETY_HUNDRED_0
                                )?.count
                            }{' '}
                            {t('report')}
                        </Button>
                    </div>
                </div>
            </div>

            <div className="row g-0">
                <div className="col-3 d-flex justify-content-center align-items-center p-2 p-sm-3">
                    80% - 90%
                </div>
                <div
                    className={[
                        'col-3 bio-chart-bg-red d-flex justify-content-center align-items-center p-2 p-sm-3 border',
                    ].join(' ')}
                >
                    <div className="d-flex flex-column">
                        <span className="bio-chart-text-red text-center">{t('warning')}</span>
                        <Button
                            variant="link"
                            onClick={() =>
                                prop.setReportWithAnalyticFilter({
                                    riskLevel: RiskLevel.WARNING,
                                    position: 1,
                                })
                            }
                        >
                            {
                                dashboardReport?.finalReportArray.find(
                                    (e) =>
                                        e.type === FinalBioReportResultEnum.EIGHTY_NINETY_GREATER_3
                                )?.count
                            }{' '}
                            {t('report')}
                        </Button>
                    </div>
                </div>
                <div
                    className={[
                        'col-3 bio-chart-bg-yellow d-flex justify-content-center align-items-center p-2 p-sm-3 border',
                    ].join(' ')}
                >
                    <div className="d-flex flex-column">
                        <span className="bio-chart-text-yellow text-center">{t('high')}</span>
                        <Button
                            variant="link"
                            onClick={() =>
                                prop.setReportWithAnalyticFilter({
                                    riskLevel: RiskLevel.HIGH,
                                    position: 2,
                                })
                            }
                        >
                            {
                                dashboardReport?.finalReportArray.find(
                                    (e) =>
                                        e.type ===
                                        FinalBioReportResultEnum.EIGHTY_NINETY_FROM_1_TO_3
                                )?.count
                            }{' '}
                            {t('report')}
                        </Button>
                    </div>
                </div>
                <div
                    className={[
                        'col-3 bio-chart-bg-blue d-flex justify-content-center align-items-center p-2 p-sm-3 border',
                    ].join(' ')}
                >
                    <div className="d-flex flex-column">
                        <span className="bio-chart-text-blue text-center">{t('medium')}</span>
                        <Button
                            variant="link"
                            onClick={() =>
                                prop.setReportWithAnalyticFilter({
                                    riskLevel: RiskLevel.MEDIUM,
                                    position: 2,
                                })
                            }
                        >
                            {
                                dashboardReport?.finalReportArray.find(
                                    (e) => e.type === FinalBioReportResultEnum.EIGHTY_NINETY_0
                                )?.count
                            }{' '}
                            {t('report')}
                        </Button>
                    </div>
                </div>
            </div>

            <div className="row g-0">
                <div className="col-3 d-flex justify-content-center align-items-center p-2 p-sm-3">
                    {'<80%'}
                </div>
                <div
                    className={[
                        'col-3 bio-chart-bg-red d-flex justify-content-center align-items-center p-2 p-sm-3 border',
                    ].join(' ')}
                >
                    <div className="d-flex flex-column">
                        <span className="bio-chart-text-red text-center">{t('warning')}</span>
                        <Button
                            variant="link"
                            onClick={() =>
                                prop.setReportWithAnalyticFilter({
                                    riskLevel: RiskLevel.WARNING,
                                    position: 2,
                                })
                            }
                        >
                            {
                                dashboardReport?.finalReportArray.find(
                                    (e) =>
                                        e.type === FinalBioReportResultEnum.LOWER_EIGHTY_GREATER_3
                                )?.count
                            }{' '}
                            {t('report')}
                        </Button>
                    </div>
                </div>
                <div
                    className={[
                        'col-3 bio-chart-bg-yellow d-flex justify-content-center align-items-center p-2 p-sm-3 border',
                    ].join(' ')}
                >
                    <div className="d-flex flex-column">
                        <span className="bio-chart-text-yellow text-center">{t('high')}</span>
                        <Button
                            variant="link"
                            onClick={() =>
                                prop.setReportWithAnalyticFilter({
                                    riskLevel: RiskLevel.HIGH,
                                    position: 3,
                                })
                            }
                        >
                            {
                                dashboardReport?.finalReportArray.find(
                                    (e) =>
                                        e.type === FinalBioReportResultEnum.LOWER_EIGHTY_FROM_1_TO_3
                                )?.count
                            }{' '}
                            {t('report')}
                        </Button>
                    </div>
                </div>
                <div
                    className={[
                        'col-3 bio-chart-bg-blue d-flex justify-content-center align-items-center p-2 p-sm-3 border',
                    ].join(' ')}
                >
                    <div className="d-flex flex-column">
                        <span className="bio-chart-text-blue text-center">{t('medium')}</span>
                        <Button
                            variant="link"
                            onClick={() =>
                                prop.setReportWithAnalyticFilter({
                                    riskLevel: RiskLevel.MEDIUM,
                                    position: 3,
                                })
                            }
                        >
                            {
                                dashboardReport?.finalReportArray.find(
                                    (e) => e.type === FinalBioReportResultEnum.LOWER_EIGHTY_0
                                )?.count
                            }{' '}
                            {t('report')}
                        </Button>
                    </div>
                </div>
            </div>

            <div className="row g-0 my-3">
                <div className="col-3 d-flex justify-content-center align-items-center"/>
                <div className="col-3 d-flex justify-content-center align-items-center">
                    <span>{'>3'}</span>
                </div>
                <div className="col-3 d-flex justify-content-center align-items-center">
                    <span>{'1 - 3'}</span>
                </div>
                <div className="col-3 d-flex justify-content-center align-items-center">
                    <span>{'0'}</span>
                </div>
            </div>

            <div className="d-flex p-3 bg-secondary-primary w-100 justify-content-end">
                <span className="text-uppercase fw-bold text-primary">{t('fail-vital-point')}</span>
            </div>
        </div>
    );
}
