import api from './api';

const url = 'excel';

export default class ExcelService {
    static async getOne(id: string) {
        const res = await api.get(`${url}/${id}`, {
            responseType: 'blob',
        });
        return res.data;
    }

    static async getMany(ids: string[], simple: boolean = false) {
        const params = new URLSearchParams();
        if (simple) {
            params.append('simple', 'true');
        } else {
            params.append('simple', 'false');
        }
        for (const id of ids) {
            params.append('ids', id);
        }
        const res = await api.get(`${url}`, {
            params,
            responseType: 'blob',
        });
        return res.data;
    }

    static async getOneRetail(id: string) {
        const res = await api.get(`${url}/${id}/retail`, {
            responseType: 'blob',
        });
        return res.data;
    }

    static async getManyRetail(ids: string[], simple: boolean = false) {
        const params = new URLSearchParams();
        if (simple) {
            params.append('simple', 'true');
        } else {
            params.append('simple', 'false');
        }
        for (const id of ids) {
            params.append('ids', id);
        }
        const res = await api.get(`${url}/retail`, {
            params,
            responseType: 'blob',
        });
        return res.data;
    }
}
