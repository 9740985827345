/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BioSafetyTab from './bio-safety-tab/bio-safety-tab';
import BioReportService from '../service/bio-report.service';
import {
    IBioReport,
    IBioReportQuestion,
    IGroupViolated,
} from 'shared/interface/bio-report.interface';
import { IFarm } from 'shared/interface/farm.interface';
import FarmService from 'module/client/farm/service/farm.service';
import UserService from 'shared/service/user.service';
import { IUser } from 'shared/interface/user.interface';
import { calculateReport } from 'shared/util/bio-report';
import { ITopic } from 'shared/interface/topic.interface';
import TopicService from 'module/client/topic/service/topic.service';
import { buildFileView } from 'shared/service/file';
import { iconClock } from 'shared/assets/icons';
import { format } from 'date-fns';
import { getWindowDimensions } from 'shared/util/window-dimensions';
import FooterHelp from 'shared/components/footer-help/footer-help';

export default function BioReportDetail() {
    const { bioReportId } = useParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [bioReport, setBioReport] = useState<IBioReport>();
    const [farm, setFarm] = useState<IFarm>();
    const [customer, setCustomer] = useState<IUser>();
    const [finalResult, setFinalResult] = useState<{
        finalPoint: number;
        finalTotalPoint: number;
        percentage: number;
        totalViolated: number;
    }>();
    const [violatedQuestions, setViolatedQuestions] = useState<IBioReportQuestion[]>([]);
    const [groupViolated, setGroupViolated] = useState<IGroupViolated[]>([]);
    const [topics, setTopics] = useState<ITopic[]>([]);
    const [windowDimensions] = useState(getWindowDimensions());

    const calculateTotalListPoint = () => {
        if (bioReport) {
            const result = calculateReport(bioReport);
            setFinalResult(result?.finalResult);
            setViolatedQuestions(result?.violatedQuestions);
            setBioReport(result?.bioReport);
            setGroupViolated(result?.groupViolated);
        }
    };

    const getTopics = async () => {
        const resultTopics = await TopicService.find(1, 5);
        setTopics(resultTopics.docs);
    };

    const getUser = async (userId: string) => {
        const result = await UserService.getUserInfo(userId);
        setCustomer(result);
    };

    const getFarm = async (farmId: string) => {
        const result = await FarmService.getFarmById(farmId);
        setFarm(result);
        getUser(result.owningUser[0]);
    };

    const getBioReport = async () => {
        if (bioReportId) {
            const result = await BioReportService.findById(bioReportId);
            setBioReport(result);
            getFarm(result.owningFarm);
        }
    };

    useEffect(() => {
        getBioReport();
        getTopics();
    }, [bioReportId]);

    useEffect(() => {
        calculateTotalListPoint();
    }, [bioReport]);

    return (
        <div className="mt-3 p-0 p-sm-3 bg-cargill-gray">
            <div>
                <div className="d-flex mb-3 bg-white ps-2">
                    <i
                        className="fa-solid fa-chevron-left text-secondary my-auto clickable"
                        onClick={() => navigate('/bio-report')}
                    />
                    <span className="fs-3 my-auto ms-2">{t('report')}</span>
                </div>

                <BioSafetyTab
                    farm={farm}
                    bioReport={bioReport}
                    customer={customer}
                    finalResult={finalResult}
                    violatedQuestions={violatedQuestions}
                    groupViolated={groupViolated}
                />
            </div>
            <h4 className="mt-5 text-secondary">{t('news')}</h4>
            <div className="d-flex w-100 gap-3 my-2 overflow-auto p-3 div-shadow bg-cargill-gray">
                {topics.map((topic, index) => {
                    return (
                        <div
                            key={index}
                            style={{
                                minWidth: windowDimensions.width >= 576 ? '30%' : '80%',
                                backgroundColor: '#FFFFFF',
                            }}>
                            <div
                                className="d-flex flex-column clickable"
                                onClick={() => navigate(`/news/${topic._id}`)}>
                                <div className="col-12">
                                    <img
                                        alt=""
                                        className="xs-picture"
                                        src={buildFileView(topic?.image)}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = '/image/picture/default.jpeg';
                                        }}
                                    />
                                </div>
                                <div className="col-12 px-2 py-2 d-flex flex-column justify-content-between border rounded-bottom">
                                    <div>
                                        <div className="row">
                                            <span className="main-news-title xs-news-title">
                                                {topic?.title}
                                            </span>
                                        </div>
                                        <div className="row">
                                            <span className="main-news-detail xs-news-detail">
                                                {topic?.previewContent}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="me-1">
                                            <img
                                                alt="clock"
                                                style={{
                                                    width: '1.25rem',
                                                    height: '1.25rem',
                                                }}
                                                src={iconClock}
                                            />
                                        </div>
                                        <div>
                                            <span className="create-at">
                                                {' '}
                                                {topic &&
                                                    format(
                                                        new Date(topic?.createdAt),
                                                        i18n.language === 'vi'
                                                            ? 'dd/MM/yyyy'
                                                            : 'MM/dd/yyyy'
                                                    )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="d-flex">
                <FooterHelp />
            </div>
        </div>
    );
}
