/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { logo, logoShort } from 'shared/assets/images';
import '../sidebar/sidebar.scss';
import { IRoute } from 'shared/interface/route.interface';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { MdSell } from 'react-icons/md';
import { TbReport } from 'react-icons/tb';
import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarContent,
    SidebarFooter,
    SubMenu,
} from 'react-pro-sidebar';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
    IconHome,
    IconReport,
    IconEvaluate,
    IconFarm,
    IconNews,
    IconPartition,
    IconGoToAdmin,
    IconEnterprise,
    IconRole,
} from 'shared/assets/iconComponents';
import { iconHamburger, iconCloseDrawer } from 'shared/assets/icons';
import { useAppContext, useAuthContext } from 'module/auth/context';
import { buildFileView } from 'shared/service/file';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import Drawer from 'react-modern-drawer';

import 'react-modern-drawer/dist/index.css';
import BioReportRequestService from 'shared/service/bio-report-request.service';
import UserService from 'shared/service/user.service';
import { FaDatabase } from 'react-icons/fa';
// @ts-nocheck
const { version } = require('../../../../package.json');

export interface ISidebar {
    route: IRoute[];
}

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
};

const SidebarAdmin = (props: ISidebar) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const appContext = useAppContext();
    const authContext = useAuthContext();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [collapsed, setCollapsed] = useState(false);
    const [open, setOpen] = useState(false);
    // const [requests, setRequests] = useState<number>(0);
    // const [pendingUsers, setPendingUsers] = useState<number>(0);
    const location = useLocation();

    const setStyle = () => {
        const root = document.documentElement;
        root?.style.setProperty('--sidebar-bg-color', '#242E42');
        root?.style.setProperty('--sidebar-color', '#638C1C');
        root?.style.setProperty('--sidebar-width', '230px');
        root?.style.setProperty('--sidebar-collapsed-width', '65px');
        root?.style.setProperty('--highlight-color', '#a3e138');
        root?.style.setProperty('--submenu-bg-color', '#2b2b2b');
        root?.style.setProperty('--submenu-bg-color-collapsed', '#f7f8fa');
        root?.style.setProperty('--icon-bg-color', '#242E42');
        root?.style.setProperty('--icon-size', '35px');
        root?.style.setProperty('--submenu-indent', '24px');
        root?.style.setProperty('--pro-menu-item-bg', '#FFFFFF');
        root?.style.setProperty('--pro-sidebar-label-color', '#638C1C');
    };

    useEffect(() => {
        setStyle();
        getRequests();
        getUsers();
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const changeLanguage = () => {
        if (i18n.language === 'en-US') {
            i18n.changeLanguage('vi');
        } else {
            i18n.changeLanguage('en-US');
        }
    };

    const logOut = () => {
        localStorage.clear();
        authContext.setHasToken(false);
        navigate('/login');
    };

    const getRequests = async () => {
        const res = await BioReportRequestService.findAll(1, 999, undefined, new Date());
        appContext.setRequests(res.docs?.filter((d) => !d.resolve).length);
    };

    const getUsers = async () => {
        const resPending = await UserService.findAll(1, 9999, false);
        appContext.setPendingUsers(resPending?.docs?.length);
    };

    return (
        <div className="d-flex w-100 sidebar-admin">
            {windowDimensions.width >= 576 ? (
                <>
                    <ProSidebar collapsed={collapsed}>
                        <SidebarHeader
                            className="d-flex justify-content-center"
                            style={{ padding: '15px 0' }}>
                            <img src={collapsed ? logoShort : logo} alt="logo" />
                        </SidebarHeader>
                        <SidebarContent>
                            <Menu iconShape="square">
                                <SubMenu title={t('dashboard')} icon={<IconHome />}>
                                    <Link to={'/admin/dashboard'}>
                                        <MenuItem
                                            icon={
                                                <IconHome
                                                    active={
                                                        location.pathname === '/admin/dashboard'
                                                    }
                                                />
                                            }
                                            active={location.pathname === '/admin/dashboard'}>
                                            <span className="side-bar-label">
                                                {t('more-than-300-sows-farm')}
                                            </span>
                                        </MenuItem>
                                    </Link>
                                    <Link to={'/admin/dashboard-retail'}>
                                        <MenuItem
                                            icon={
                                                <IconHome
                                                    active={
                                                        location.pathname ===
                                                        '/admin/dashboard-retail'
                                                    }
                                                />
                                            }
                                            active={
                                                location.pathname === '/admin/dashboard-retail'
                                            }>
                                            <span className="side-bar-label">
                                                {t('less-than-300-sows-farm')}
                                            </span>
                                        </MenuItem>
                                    </Link>
                                </SubMenu>

                                <Link to={'/admin/user'}>
                                    <MenuItem
                                        icon={
                                            <IconEvaluate
                                                active={location.pathname === '/admin/user'}
                                            />
                                        }
                                        active={location.pathname === '/admin/user'}>
                                        <span className="side-bar-label">
                                            {t('user-management')}
                                        </span>
                                    </MenuItem>
                                </Link>
                                <SubMenu
                                    title={t('question-management')}
                                    icon={<HiOutlineDocumentReport />}>
                                    <Link to={'/admin/question'}>
                                        <MenuItem
                                            icon={
                                                <IconReport
                                                    active={location.pathname === 'admin/question'}
                                                />
                                            }
                                            active={location.pathname === '/admin/question'}>
                                            <span className="side-bar-label">
                                                {t('question-management')}
                                            </span>
                                        </MenuItem>
                                    </Link>
                                    <Link to={'/admin/question-retail'}>
                                        <MenuItem
                                            icon={<MdSell />}
                                            active={location.pathname === '/admin/question-retail'}>
                                            <span className="side-bar-label">
                                                {t('question-management-retail')}
                                            </span>
                                        </MenuItem>
                                    </Link>
                                </SubMenu>
                                <SubMenu title={t('bio-report-management')} icon={<TbReport />}>
                                    <Link to={'/admin/bio-report'}>
                                        <MenuItem
                                            icon={
                                                <IconReport
                                                    active={
                                                        location.pathname === 'admin/bio-report'
                                                    }
                                                />
                                            }
                                            active={location.pathname === '/admin/bio-report'}>
                                            <span className="side-bar-label">
                                                {t('more-than-300-sows-farm')}
                                            </span>
                                        </MenuItem>
                                    </Link>
                                    <Link to={'/admin/bio-report-retail'}>
                                        <MenuItem
                                            icon={
                                                <IconReport
                                                    active={
                                                        location.pathname ===
                                                        '/admin/bio-report-retail'
                                                    }
                                                />
                                            }
                                            active={
                                                location.pathname === '/admin/bio-report-retail'
                                            }>
                                            <span className="side-bar-label">
                                                {t('less-than-300-sows-farm')}
                                            </span>
                                        </MenuItem>
                                    </Link>
                                </SubMenu>
                                <Link to={'/admin/bio-report-request'}>
                                    <MenuItem
                                        icon={
                                            <IconReport
                                                active={
                                                    location.pathname === 'admin/bio-report-request'
                                                }
                                            />
                                        }
                                        active={location.pathname === '/admin/bio-report-request'}>
                                        <span className="side-bar-label">
                                            {t('bio-report-request')}
                                        </span>
                                    </MenuItem>
                                </Link>
                                <Link to={'/admin/company'}>
                                    <MenuItem
                                        icon={
                                            <IconEnterprise
                                                active={location.pathname === '/admin/company'}
                                            />
                                        }
                                        active={location.pathname === '/admin/company'}>
                                        <span className="side-bar-label">
                                            {t('customer-management')}
                                        </span>
                                    </MenuItem>
                                </Link>
                                <Link to={'/admin/farms'}>
                                    <MenuItem
                                        icon={
                                            <IconFarm
                                                active={location.pathname === '/admin/farms'}
                                            />
                                        }
                                        active={location.pathname === '/admin/farms'}>
                                        <span className="side-bar-label">
                                            {t('farms-management')}
                                        </span>
                                    </MenuItem>
                                </Link>
                                <Link to={'/admin/partition'}>
                                    <MenuItem
                                        icon={
                                            <IconPartition
                                                active={location.pathname === '/admin/partition'}
                                            />
                                        }
                                        active={location.pathname === '/admin/partition'}>
                                        <span className="side-bar-label">
                                            {t('partition-management')}
                                        </span>
                                    </MenuItem>
                                </Link>
                                <Link to={'/admin/topic'}>
                                    <MenuItem
                                        icon={
                                            <IconNews
                                                active={location.pathname === '/admin/topic'}
                                            />
                                        }
                                        active={location.pathname === '/admin/topic'}>
                                        <span className="side-bar-label">
                                            {t('topic-management')}
                                        </span>
                                    </MenuItem>
                                </Link>
                                <Link to={'/admin/runtime-setting'}>
                                    <MenuItem
                                        icon={
                                            <IconEvaluate
                                                active={
                                                    location.pathname === '/admin/runtime-setting'
                                                }
                                            />
                                        }
                                        active={location.pathname === '/admin/runtime-setting'}>
                                        <span className="side-bar-label">
                                            {t('runtime-setting')}
                                        </span>
                                    </MenuItem>
                                </Link>
                                <Link to={'admin/authorization'}>
                                    <MenuItem
                                        icon={
                                            <IconRole
                                                active={
                                                    location.pathname === '/admin/authorization'
                                                }
                                            />
                                        }
                                        active={location.pathname === '/admin/authorization'}>
                                        <span className="side-bar-label">
                                            {t('role-management')}
                                        </span>
                                    </MenuItem>
                                </Link>
                                <Link to={'admin/automation-import'}>
                                    <MenuItem
                                        icon={
                                            <FaDatabase
                                                className={`${
                                                    location.pathname ===
                                                        '/admin/automation-import' && 'text-primary'
                                                }`}
                                            />
                                        }
                                        active={location.pathname === '/admin/automation-import'}>
                                        <span className="side-bar-label">
                                            {t('automation-import')}
                                        </span>
                                    </MenuItem>
                                </Link>
                            </Menu>
                        </SidebarContent>
                        <SidebarFooter className="d-flex flex-column align-items-center w-100 pb-3">
                            <Link to={'/dashboard'}>
                                <button className="btn btn-outline-primary">
                                    <IconGoToAdmin active={true} />
                                    {!collapsed && (
                                        <span className="ms-3">{t('access-client-side')}</span>
                                    )}
                                </button>
                            </Link>
                            <div className="d-flex justify-content-end text-secondary w-100 me-2 mt-2">{`${t(
                                'version'
                            )}: ${version}`}</div>
                        </SidebarFooter>
                    </ProSidebar>
                    <div className="w-100">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light d-flex">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between w-100">
                                    <img
                                        alt=""
                                        role="button"
                                        src={iconHamburger}
                                        onClick={() => setCollapsed(!collapsed)}
                                    />
                                    <Dropdown>
                                        <Dropdown.Toggle variant="default" id="dropdown-basic">
                                            <div className="position-relative">
                                                <img
                                                    src={buildFileView(appContext.user?.avatar)}
                                                    alt="avatar"
                                                    className="avatar border border-secondary rounded-circle"
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src =
                                                            '/image/picture/default.jpeg';
                                                    }}
                                                />
                                                {appContext.pendingUsers || appContext.requests ? (
                                                    <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                                                        <span className="visually-hidden">
                                                            New alerts
                                                        </span>
                                                    </span>
                                                ) : null}
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {appContext.pendingUsers ? (
                                                <Dropdown.Item>
                                                    <Link to={'/admin/user'}>
                                                        <div className="d-flex justify-content-between">
                                                            <span className="fs-6 me-2">
                                                                {t('you-have-new-pending-user')}
                                                            </span>
                                                            <span className="fs-6 fw-bold text-danger">
                                                                {appContext.pendingUsers}
                                                            </span>
                                                        </div>
                                                    </Link>
                                                </Dropdown.Item>
                                            ) : null}

                                            {appContext.requests ? (
                                                <Dropdown.Item>
                                                    <Link to={'/admin/bio-report-request'}>
                                                        <div className="d-flex justify-content-between">
                                                            <span className="fs-6 me-2">
                                                                {t(
                                                                    'you-have-new-remove-report-request'
                                                                )}
                                                            </span>
                                                            <span className="fs-6 fw-bold text-danger">
                                                                {appContext.requests}
                                                            </span>
                                                        </div>
                                                    </Link>
                                                </Dropdown.Item>
                                            ) : null}
                                            <Dropdown.Item onClick={() => logOut()}>
                                                <div className="d-flex justify-content-between">
                                                    <span className="my-auto">{t('logout')}</span>
                                                    <span className="material-symbols-outlined text-danger">
                                                        logout
                                                    </span>
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => changeLanguage()}>
                                                <div className="d-flex justify-content-between">
                                                    <span className="my-auto">
                                                        {i18n.language === 'en-US'
                                                            ? 'Tiếng Việt'
                                                            : 'English'}
                                                    </span>
                                                    <span className="material-symbols-outlined text-primary">
                                                        language
                                                    </span>
                                                </div>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </nav>
                        <div
                            className="container-fluid overflow-auto"
                            style={{ maxHeight: 'calc(100vh - 64px)' }}>
                            <Outlet />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="w-100">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light d-flex">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between w-100">
                                    <img src={logo} alt="logo" style={{ height: 30 }} />

                                    <img
                                        alt=""
                                        role="button"
                                        src={iconHamburger}
                                        onClick={() => setOpen(!open)}
                                    />
                                </div>
                            </div>
                        </nav>
                        <Drawer
                            open={open}
                            onClose={() => setOpen(!open)}
                            direction="right"
                            size={'100%'}
                            style={{ height: '378px' }}
                            className="drawer-container">
                            <div className="drawer-custom d-flex w-100 h-100 p-3 flex-column">
                                <div
                                    className="d-flex justify-content-between w-100 align-items-center"
                                    style={{ height: '2rem' }}>
                                    <img src={logo} alt="logo" style={{ height: 30 }} />
                                    <img
                                        src={iconCloseDrawer}
                                        alt=""
                                        style={{ height: 16, width: 16 }}
                                        role="button"
                                        onClick={() => setOpen(false)}
                                    />
                                </div>

                                <Link
                                    to={'/dashboard'}
                                    className="d-flex w-100 align-items-center mt-4">
                                    <div className="col-1">
                                        <IconHome active={location.pathname === '/dashboard'} />
                                    </div>
                                    <span
                                        style={{
                                            color:
                                                location.pathname === '/dashboard'
                                                    ? '#638C1C'
                                                    : '#62666c',
                                        }}
                                        className="side-bar-label">
                                        {t('dashboard')}
                                    </span>
                                </Link>

                                <Link
                                    to={'/evaluate'}
                                    className="d-flex w-100 align-items-center mt-4">
                                    <div className="col-1">
                                        <IconEvaluate active={location.pathname === '/evaluate'} />
                                    </div>
                                    <span
                                        style={{
                                            color:
                                                location.pathname === '/evaluate'
                                                    ? '#638C1C'
                                                    : '#62666c',
                                        }}
                                        className="side-bar-label">
                                        {t('evaluate')}
                                    </span>
                                </Link>

                                <Link
                                    to={'/bio-report'}
                                    className="d-flex w-100 align-items-center mt-4">
                                    <div className="col-1">
                                        <IconReport active={location.pathname === '/bio-report'} />
                                    </div>
                                    <span
                                        style={{
                                            color:
                                                location.pathname === '/bio-report'
                                                    ? '#638C1C'
                                                    : '#62666c',
                                        }}
                                        className="side-bar-label">
                                        {t('report')}
                                    </span>
                                </Link>

                                <Link
                                    to={'/farms'}
                                    className="d-flex w-100 align-items-center mt-4 border-bottom pb-4 ">
                                    <div className="col-1">
                                        <IconFarm active={location.pathname === '/farms'} />
                                    </div>
                                    <span
                                        style={{
                                            color:
                                                location.pathname === '/farms'
                                                    ? '#638C1C'
                                                    : '#62666c',
                                        }}
                                        className="side-bar-label">
                                        {t('farms')}
                                    </span>
                                </Link>
                                <div className="d-flex w-100 align-items-center my-2">
                                    <span className="drawer-language-setting-lable me-3">
                                        {t('language')}
                                    </span>
                                    <div
                                        onClick={() => changeLanguage()}
                                        className="d-flex justify-content-between px-2 py-1 rounded-pill clickable"
                                        style={{ background: '#fff' }}>
                                        <span className="my-auto me-1">
                                            {i18n.language === 'en-US' ? 'Tiếng Việt' : 'English'}
                                        </span>
                                        <span className="material-symbols-outlined text-primary">
                                            language
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-start">
                                    <button
                                        className="btn btn-outline-secondary btn-sm"
                                        onClick={() => logOut()}>
                                        <span className="">{t('logout')}</span>
                                        <span
                                            className="material-symbols-outlined text-danger ms-2 fs-5"
                                            style={{ top: 3 }}>
                                            logout
                                        </span>
                                    </button>
                                </div>
                                <div className="d-flex text-secondary w-100 me-2 mt-2">{`${'version'}: ${version}`}</div>
                            </div>
                        </Drawer>
                        <div className="container-fluid">
                            <Outlet />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default SidebarAdmin;
