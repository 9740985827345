import { LocalStoreName } from 'shared/enum/local-store.enum';
import api from './api';

export const buildFileView = (id?: string) => {
    if (!id) {
        return '/image/picture/default.jpeg';
    }
    return `${process.env.REACT_APP_API_URL}/file/${id}/with-token?token=${localStorage.getItem(
        LocalStoreName.TOKEN
    )}`;
};

export const uploadFile = (image: any) => {
    const body = new FormData();
    body.append('file', image);
    return api.post('/file', body);
};
