import { toast } from 'react-toastify';
import { IWebPushNotification } from 'shared/interface/webPushNotification.interface';
import api from './api';

const url = '/web-push-notification';
export default class WebPushNotificationService {
    static async create(subscription: string): Promise<IWebPushNotification> {
        try {
            const res = await api.post(url, { subscription });
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }
}
