/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { logo, logoShort } from 'shared/assets/images';
import './sidebar.scss';
import { IRoute } from 'shared/interface/route.interface';
import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
    SubMenu,
} from 'react-pro-sidebar';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
    IconHome,
    IconReport,
    IconEvaluate,
    IconFarm,
    IconGoToAdmin,
    IconCompareReport,
} from 'shared/assets/iconComponents';
import { iconHamburger, iconCloseDrawer, iconDown, iconUp } from 'shared/assets/icons';
import { useAppContext, useAuthContext } from 'module/auth/context';
import { buildFileView } from 'shared/service/file';
import { useTranslation } from 'react-i18next';
import { Dropdown, Collapse } from 'react-bootstrap';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { ActionPermission } from 'shared/enum/action-permission.enum';
import { getWindowDimensions } from 'shared/util/window-dimensions';
import { MdCompare } from 'react-icons/md';
// @ts-nocheck
const { version } = require('../../../../package.json');

export interface ISidebar {
    route: IRoute[];
}

const Sidebar = (props: ISidebar) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const appContext = useAppContext();
    const authContext = useAuthContext();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [collapsed, setCollapsed] = useState(false);
    const [open, setOpen] = useState(false);
    const [expand, setExpand] = useState(false);
    const location = useLocation();

    const setStyle = () => {
        const root = document.documentElement;
        root?.style.setProperty('--sidebar-bg-color', '#ffffff');
        root?.style.setProperty('--sidebar-color', '#62666C');
        root?.style.setProperty('--sidebar-width', '230px');
        root?.style.setProperty('--sidebar-collapsed-width', '65px');
        root?.style.setProperty('--highlight-color', '#616161');
        root?.style.setProperty('--submenu-bg-color', '#e3e3e3');
        root?.style.setProperty('--submenu-bg-color-collapsed', '#f7f8fa');
        root?.style.setProperty('--icon-bg-color', '#f7f8fa');
        root?.style.setProperty('--icon-size', '35px');
        root?.style.setProperty('--submenu-indent', '24px');
        root?.style.setProperty('--pro-menu-item-bg', 'rgba(0, 131, 60, 0.08)');
        root?.style.setProperty('--pro-sidebar-label-color', '#62666c');
    };

    useEffect(() => {
        setStyle();
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const changeLanguage = () => {
        if (i18n.language === 'en-US') {
            i18n.changeLanguage('vi');
        } else {
            i18n.changeLanguage('en-US');
        }
    };

    const logOut = () => {
        localStorage.clear();
        authContext.setHasToken(false);
        navigate('/login');
    };

    return (
        <div className="d-flex w-100 sidebar-client">
            {windowDimensions.width >= 576 ? (
                <>
                    <ProSidebar collapsed={collapsed} style={{ backgroundColor: '#fff' }}>
                        <SidebarHeader
                            className="d-flex justify-content-center"
                            style={{ padding: '15px 0' }}>
                            <img src={collapsed ? logoShort : logo} alt="logo" />
                        </SidebarHeader>
                        <SidebarContent>
                            <Menu iconShape="square">
                                <Link to={'/dashboard'}>
                                    <MenuItem
                                        icon={
                                            <IconHome active={location.pathname === '/dashboard'} />
                                        }
                                        active={location.pathname === '/dashboard'}>
                                        <span className="side-bar-label">{t('dashboard')}</span>
                                    </MenuItem>
                                </Link>
                                <Link to={'/evaluate'}>
                                    <MenuItem
                                        icon={
                                            <IconEvaluate
                                                active={location.pathname === '/evaluate'}
                                            />
                                        }
                                        active={location.pathname === '/evaluate'}>
                                        <span className="side-bar-label">{t('evaluate')}</span>
                                    </MenuItem>
                                </Link>
                                <SubMenu
                                    icon={
                                        <IconReport
                                            active={
                                                location.pathname.indexOf('/bio-report') > -1 &&
                                                location.pathname.indexOf('/bio-report-compare') < 0
                                            }
                                        />
                                    }
                                    title={t('report')}>
                                    <Link to={'/bio-report'}>
                                        <MenuItem
                                            active={
                                                location.pathname.indexOf('/bio-report') > -1 &&
                                                location.pathname.indexOf('/bio-report-compare') <
                                                    0 &&
                                                location.pathname.indexOf(
                                                    '/bio-report-retail-compare'
                                                ) < 0
                                            }
                                            icon={
                                                <IconReport
                                                    active={
                                                        location.pathname.indexOf('/bio-report') >
                                                            -1 &&
                                                        location.pathname.indexOf(
                                                            '/bio-report-compare'
                                                        ) < 0 &&
                                                        location.pathname.indexOf(
                                                            '/bio-report-retail-compare'
                                                        ) < 0
                                                    }
                                                />
                                            }>
                                            <span className="side-bar-label">
                                                {t('report-detail')}
                                            </span>
                                        </MenuItem>
                                    </Link>
                                    <SubMenu
                                        icon={
                                            <IconReport
                                                active={
                                                    location.pathname.indexOf('/bio-report') > -1
                                                }
                                            />
                                        }
                                        title={t('report-compare')}>
                                        <Link to={'/bio-report-compare'}>
                                            <MenuItem
                                                active={location.pathname === '/bio-report-compare'}
                                                icon={
                                                    <IconCompareReport
                                                        active={
                                                            location.pathname ===
                                                            '/bio-report-compare'
                                                        }
                                                    />
                                                }>
                                                <span className="side-bar-label">
                                                    {t('more-than-300-sows-farm')}
                                                </span>
                                            </MenuItem>
                                        </Link>
                                        <Link to={'/bio-report-retail-compare'}>
                                            <MenuItem
                                                active={
                                                    location.pathname ===
                                                    '/bio-report-retail-compare'
                                                }
                                                icon={
                                                    <MdCompare
                                                        className={`${
                                                            location.pathname ===
                                                                '/bio-report-retail-compare' &&
                                                            'text-primary'
                                                        }`}
                                                    />
                                                }>
                                                <span className="side-bar-label">{t('less-than-300-sows-farm')}</span>
                                            </MenuItem>
                                        </Link>
                                    </SubMenu>
                                </SubMenu>
                                <Link to={'/farms'}>
                                    <MenuItem
                                        icon={<IconFarm active={location.pathname === '/farms'} />}
                                        active={location.pathname === '/farms'}>
                                        <span className="side-bar-label">{t('farms')}</span>
                                    </MenuItem>
                                </Link>
                            </Menu>
                        </SidebarContent>
                        {authContext.actionPermission.includes(
                            ActionPermission.SHOW_ADMIN_ACCESS_BUTTON
                        ) && (
                            <SidebarFooter className="d-flex flex-column align-items-center w-100 pb-3">
                                <Link to={'/admin/dashboard'}>
                                    <button className="btn btn-outline-primary">
                                        <IconGoToAdmin active={true} />
                                        {!collapsed && (
                                            <span className="ms-3">{t('access-admin-side')}</span>
                                        )}
                                    </button>
                                </Link>
                                <div className="d-flex justify-content-end text-secondary w-100 me-2 mt-2">{`${t(
                                    'version'
                                )}: ${version}`}</div>
                            </SidebarFooter>
                        )}
                    </ProSidebar>
                    <div className="w-100">
                        <nav className="navbar navbar-expand-lg navbar-light d-flex">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between w-100">
                                    <img
                                        alt=""
                                        role="button"
                                        src={iconHamburger}
                                        onClick={() => setCollapsed(!collapsed)}
                                    />
                                    <Dropdown>
                                        <Dropdown.Toggle variant="default" id="dropdown-basic">
                                            <img
                                                src={buildFileView(appContext.user?.avatar)}
                                                alt="avatar"
                                                className="avatar"
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src =
                                                        '/image/picture/default.jpeg';
                                                }}
                                            />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => logOut()}>
                                                <div className="d-flex justify-content-between">
                                                    <span className="my-auto">{t('logout')}</span>
                                                    <span className="material-symbols-outlined text-danger">
                                                        logout
                                                    </span>
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => changeLanguage()}>
                                                <div className="d-flex justify-content-between">
                                                    <span className="my-auto">
                                                        {i18n.language === 'en-US'
                                                            ? 'Tiếng Việt'
                                                            : 'English'}
                                                    </span>
                                                    <span className="material-symbols-outlined text-primary">
                                                        language
                                                    </span>
                                                </div>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </nav>
                        <div
                            className="overflow-auto container-fluid"
                            style={{ backgroundColor: '#F7F8FA', maxHeight: 'calc(100vh - 64px)' }}>
                            <Outlet />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="w-100">
                        <nav className="navbar navbar-expand-lg navbar-light d-flex nav-bar-xs">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between w-100">
                                    <img src={logo} alt="logo" style={{ height: 30 }} />

                                    <img
                                        alt=""
                                        role="button"
                                        src={iconHamburger}
                                        onClick={() => setOpen(!open)}
                                    />
                                </div>
                            </div>
                        </nav>
                        <Drawer
                            open={open}
                            onClose={() => setOpen(!open)}
                            direction="right"
                            size={'100%'}
                            style={{ height: 'auto' }}>
                            <div className="drawer-custom d-flex w-100 p-3 flex-column">
                                <div
                                    className="d-flex justify-content-between w-100 align-items-center"
                                    style={{ height: '2rem' }}>
                                    <img src={logo} alt="logo" style={{ height: 30 }} />
                                    <img
                                        src={iconCloseDrawer}
                                        alt=""
                                        style={{ height: 16, width: 16 }}
                                        role="button"
                                        onClick={() => setOpen(false)}
                                    />
                                </div>

                                <Link
                                    to={'/dashboard'}
                                    className="d-flex w-100 align-items-center mt-4">
                                    <div className="col-1">
                                        <IconHome active={location.pathname === '/dashboard'} />
                                    </div>
                                    <span
                                        style={{
                                            color:
                                                location.pathname === '/dashboard'
                                                    ? '#638C1C'
                                                    : '#62666c',
                                        }}
                                        className="side-bar-label">
                                        {t('dashboard')}
                                    </span>
                                </Link>

                                <Link
                                    to={'/evaluate'}
                                    className="d-flex w-100 align-items-center mt-4">
                                    <div className="col-1">
                                        <IconEvaluate active={location.pathname === '/evaluate'} />
                                    </div>
                                    <span
                                        style={{
                                            color:
                                                location.pathname === '/evaluate'
                                                    ? '#638C1C'
                                                    : '#62666c',
                                        }}
                                        className="side-bar-label">
                                        {t('evaluate')}
                                    </span>
                                </Link>

                                <div
                                    aria-controls="collapse-report"
                                    className="d-flex w-100 flex-column mt-4">
                                    <div
                                        className="d-flex align-items-center"
                                        onClick={() => setExpand(!expand)}>
                                        <div className="col-1">
                                            <IconReport
                                                active={
                                                    location.pathname === '/bio-report' ||
                                                    location.pathname === '/bio-report-compare'
                                                }
                                            />
                                        </div>
                                        <span
                                            style={{
                                                color:
                                                    location.pathname === '/bio-report' ||
                                                    location.pathname === '/bio-report-compare'
                                                        ? '#638C1C'
                                                        : '#62666c',
                                            }}
                                            className="side-bar-label col-10">
                                            {t('report')}
                                        </span>
                                        <img
                                            alt=""
                                            className="col-1"
                                            src={expand ? iconUp : iconDown}
                                            style={{ width: 16 }}
                                        />
                                    </div>

                                    <Collapse in={expand} timeout={1000}>
                                        <div id="example-collapse-text">
                                            <Link
                                                to={'/bio-report'}
                                                className="d-flex w-100 align-items-center mt-4">
                                                <div className="ms-3 col-1">
                                                    <IconReport
                                                        active={location.pathname === '/bio-report'}
                                                    />
                                                </div>
                                                <span
                                                    style={{
                                                        color:
                                                            location.pathname === '/bio-report'
                                                                ? '#638C1C'
                                                                : '#62666c',
                                                    }}
                                                    className="side-bar-label">
                                                    {t('report-detail')}
                                                </span>
                                            </Link>
                                            <Link
                                                to={'/bio-report-compare'}
                                                className="d-flex w-100 align-items-center mt-4">
                                                <div className="ms-3 col-1">
                                                    <IconCompareReport
                                                        active={
                                                            location.pathname ===
                                                            '/bio-report-compare'
                                                        }
                                                    />
                                                </div>
                                                <span
                                                    style={{
                                                        color:
                                                            location.pathname ===
                                                            '/bio-report-compare'
                                                                ? '#638C1C'
                                                                : '#62666c',
                                                    }}
                                                    className="side-bar-label">
                                                    {t('report-compare')} {t('more-than-300-sows-farm')}
                                                </span>
                                            </Link>
                                            <Link
                                                to={'/bio-report-retail-compare'}
                                                className="d-flex w-100 align-items-center mt-4">
                                                <div className="ms-3 col-1">
                                                    <IconCompareReport
                                                        active={
                                                            location.pathname ===
                                                            '/bio-report-compare'
                                                        }
                                                    />
                                                </div>
                                                <span
                                                    style={{
                                                        color:
                                                            location.pathname ===
                                                            '/bio-report-retail-compare'
                                                                ? '#638C1C'
                                                                : '#62666c',
                                                    }}
                                                    className="side-bar-label">
                                                    {t('report-compare')} {t('less-than-300-sows-farm')}
                                                </span>
                                            </Link>
                                        </div>
                                    </Collapse>
                                </div>

                                <Link
                                    to={'/farms'}
                                    className="d-flex w-100 align-items-center mt-4 border-bottom pb-4 ">
                                    <div className="col-1">
                                        <IconFarm active={location.pathname === '/farms'} />
                                    </div>
                                    <span
                                        style={{
                                            color:
                                                location.pathname === '/farms'
                                                    ? '#638C1C'
                                                    : '#62666c',
                                        }}
                                        className="side-bar-label">
                                        {t('farms')}
                                    </span>
                                </Link>
                                <div className="d-flex w-100 align-items-center my-2">
                                    <span className="drawer-language-setting-lable me-3">
                                        {t('language')}
                                    </span>
                                    <div
                                        onClick={() => changeLanguage()}
                                        className="d-flex justify-content-between px-2 py-1 rounded-pill clickable"
                                        style={{ background: '#fff' }}>
                                        <span className="my-auto me-1">
                                            {i18n.language === 'en-US' ? 'Tiếng Việt' : 'English'}
                                        </span>
                                        <span className="material-symbols-outlined text-primary">
                                            language
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-start">
                                    <button
                                        className="btn btn-outline-secondary btn-sm"
                                        onClick={() => logOut()}>
                                        <span className="">{t('logout')}</span>
                                        <span
                                            className="material-symbols-outlined text-danger ms-2 fs-5"
                                            style={{ top: 3 }}>
                                            logout
                                        </span>
                                    </button>
                                </div>
                                <div className="d-flex text-secondary w-100 me-2 mt-2">{`${t(
                                    'version'
                                )}: ${version}`}</div>
                            </div>
                        </Drawer>
                        <div className="container-fluid">
                            <Outlet />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Sidebar;
