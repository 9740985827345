import { RiskLevel } from 'shared/enum/bio-report.enum';
import { IBioReportPagination } from 'shared/interface/bio-report.interface';
import api from '../../../../shared/service/api';

const url = '/bio-report';

export default class ReportService {
    static async getReportByFarmId(
        farmId: string[],
        from?: Date,
        to?: Date,
        owningUsers?: string[],
        riskLevels?: RiskLevel[],
        questionSet?: string[],
        page: number = 1,
        limit: number = 999999
    ): Promise<IBioReportPagination> {
        const additionalParams = {};
        if (from && to) {
            additionalParams['from'] = from.toISOString();
            additionalParams['to'] = to.toISOString();
        }
        if (owningUsers?.length) {
            additionalParams['owningUsers'] = owningUsers;
        }
        if (riskLevels?.length) {
            additionalParams['riskLevels'] = riskLevels;
        }
        if (questionSet) {
            additionalParams['questionSet'] = questionSet;
        }
        const res = await api.get(`${url}`, {
            params: {
                farmId,
                ...additionalParams,
                page,
                limit,
            },
        });
        return res.data;
    }

    static async getPerformers(
        farmId: string,
        from: Date,
        to: Date
    ): Promise<IBioReportPagination> {
        const res = await api.get(`${url}/performers`, {
            params: {
                farmId,
                from: from.toISOString(),
                to: to.toISOString(),
            },
        });
        return res.data;
    }

    static async getReportByPerformer(
        farmId: string,
        from: Date,
        to: Date,
        userId: string
    ): Promise<IBioReportPagination> {
        const res = await api.get(`${url}/report-by-farm-performer`, {
            params: {
                farmId,
                userId,
                from: from.toISOString(),
                to: to.toISOString(),
            },
        });
        return res.data;
    }
}
