/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ConfirmDialog from 'shared/components/confirm-dialog/confirm-dialog';
import { IUser } from 'shared/interface/user.interface';
import UserService from 'shared/service/user.service';
import PendingUserFarm from '../pending-user-farm/pending-user-farm';
import { useAppContext } from 'module/auth/context';
import { RoleName } from 'shared/enum/role-name.enum';

export default function PendingApprovalUser() {
    const { t } = useTranslation();
    const appContext = useAppContext();
    const [page, setPage] = useState<number>(1);
    const [limit] = useState<number>(10);
    const [hasNextPage, setHasNextPage] = useState<boolean>(false);
    const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(false);
    const [pendingUsers, setPendingUsers] = useState<IUser[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [showBlockConfirmation, setShowBlockConfirmation] = useState<boolean>(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] = useState<boolean>(false);
    const [showApproveAllConfirmation, setShowApproveAllConfirmation] = useState<boolean>(false);
    const [selectUser, setSelectUser] = useState<IUser>();
    const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
    const [keyword, setKeyword] = useState<string>('');
    const [cargillUserCode, setCargillUserCode] = useState<string>('');
    const [roleName, setRoleName] = useState<string>('');
    const [totalDelete, setTotalDelete] = useState<number>(0);

    const getUsers = async () => {
        const resPending = await UserService.findAll(
            page,
            limit,
            false,
            false,
            keyword === '' ? undefined : keyword,
            undefined,
            roleName == '' ? undefined : roleName,
            cargillUserCode
        );
        setPendingUsers(resPending.docs);
        setHasNextPage(resPending.hasNextPage);
        setHasPreviousPage(resPending.hasPrevPage);
    };

    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('approve-to-access-system')}
        </Tooltip>
    );

    const renderBlockTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('block-user')}
        </Tooltip>
    );

    const renderDeleteTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('delete-user')}
        </Tooltip>
    );

    const setAllowAccessProgram = async () => {
        // @ts-ignore
        window.gtag('event', 'ALLOW_USER', { id: selectUser?._id });
        const res = await UserService.setAllowAccessProgram(selectUser?._id || '', true);
        if (res) {
            toast.success(t('successfully'), { position: 'bottom-center' });
            if (appContext.pendingUsers) {
                appContext.setPendingUsers(appContext.pendingUsers - 1);
            }
            setPendingUsers(pendingUsers?.filter((u) => u._id !== selectUser?._id));
        }
    };

    const blockUser = async () => {
        // @ts-ignore
        window.gtag('event', 'BLOCK_USER', { id: selectUser?._id });
        const res = await UserService.block(selectUser?._id || '');
        if (res) {
            toast.success(t('successfully'), { position: 'bottom-center' });
            if (appContext.pendingUsers) {
                appContext.setPendingUsers(appContext.pendingUsers - 1);
            }
            setPendingUsers(pendingUsers?.filter((u) => u._id !== selectUser?._id));
        }
    };

    const deleteUser = async () => {
        // @ts-ignore
        window.gtag('event', 'DELETE_USER', { id: selectUser?._id });
        const res = await UserService.delete(selectUser?._id || '');
        if (res) {
            toast.success(t('successfully'), { position: 'bottom-center' });
            if (appContext.pendingUsers) {
                appContext.setPendingUsers(appContext.pendingUsers - 1);
            }
            setPendingUsers(pendingUsers?.filter((u) => u._id !== selectUser?._id));
        }
    };

    const deleteUsers = async () => {
        // @ts-ignore
        window.gtag('event', 'DELETE_MANY_USERS', { id: selectUser?._id });
        setTotalDelete(0);
        setTimeout(() => {
            selectedUsers.forEach(async (user) => {
                const res = await UserService.delete(user._id || '');
                if (res) {
                    setTotalDelete(totalDelete + 1);
                    if (totalDelete === selectedUsers.length) {
                        toast.success(t('successfully'), { position: 'bottom-center' });
                        if (appContext.pendingUsers) {
                            appContext.setPendingUsers(appContext.pendingUsers - 1);
                        }
                        getUsers();
                    }
                }
            });
        }, 200);
    };

    const approveUsers = async () => {
        // @ts-ignore
        window.gtag('event', 'APPROVE_MANY_USERS', { id: selectUser?._id });
        setTotalDelete(0);
        setTimeout(() => {
            selectedUsers.forEach(async (user) => {
                const res = await UserService.setAllowAccessProgram(user._id || '');
                if (res) {
                    setTotalDelete(totalDelete + 1);
                    if (totalDelete === selectedUsers.length) {
                        toast.success(t('successfully'), { position: 'bottom-center' });
                        if (appContext.pendingUsers) {
                            appContext.setPendingUsers(appContext.pendingUsers - 1);
                        }
                        getUsers();
                    }
                }
            });
        }, 200);
    };

    useEffect(() => {
        getUsers();
    }, [page, limit]);

    const checkKeyPress = (ev) => {
        ev.preventDefault();
        if (ev.key === 'Enter') {
            getUsers();
        }
    };

    return (
        <div>
            <div className="p-3">
                <Form className="row">
                    <Form.Group as={Col} sm="4" className="mb-3">
                        <Form.Label>{t('name-phone-or-email')}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t('name-phone-or-email')}
                            value={keyword}
                            onChange={(event) => setKeyword(event.target.value)}
                            onKeyUpCapture={checkKeyPress}
                        />
                    </Form.Group>
                    <Form.Group as={Col} sm="4" className="mb-3">
                        <Form.Label>Cargill Code</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Cargill Code'}
                            value={cargillUserCode}
                            onChange={(event) => setCargillUserCode(event.target.value)}
                            onKeyUpCapture={checkKeyPress}
                        />
                    </Form.Group>
                    <Form.Group as={Col} sm="4" className="mb-3">
                        <Form.Label>{t('role')}</Form.Label>
                        <Form.Select
                            placeholder={t('role')}
                            value={roleName}
                            onChange={(event) => setRoleName(event.target.value)}>
                            {Object.values(RoleName).map((role) => (
                                <option key={role} value={role !== 'NONE' ? role : undefined}>
                                    {role}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Form>
                <div className="d-flex justify-content-end">
                    <button className="btn btn-primary" onClick={() => getUsers()}>
                        <i className="fa-solid fa-magnifying-glass my-auto" />
                        <span className="ms-3">{t('search')}</span>
                    </button>
                </div>
            </div>
            <table className="table table-hover">
                <thead>
                    <tr className="table-secondary">
                        <th scope="col">
                            <Form.Check
                                className="my-auto d-flex align-items-center gap-3"
                                type={'checkbox'}
                                id={`select-all`}
                                label={t('select-all')}
                                checked={selectedUsers.length === pendingUsers.length}
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        setSelectedUsers(pendingUsers);
                                    } else {
                                        setSelectedUsers([]);
                                    }
                                }}
                            />
                        </th>
                        <th scope="col" />
                        <th scope="col">{t('full-name')}</th>
                        <th scope="col">{t('phone')}</th>
                        <th scope="col">{t('email')}</th>
                        <th scope="col">{t('user-code')}</th>
                        <th scope="col">{t('affiliated-farm')}</th>
                        <th scope="col">{t('role')}</th>
                        <th scope="col">
                            <div className="d-flex gap-2">
                                <button
                                    className="btn btn-success"
                                    onClick={() => {
                                        setShowApproveAllConfirmation(true);
                                    }}>
                                    <i className="fa-solid fa-person-circle-check" />
                                    <span className="ms-2">{t('approve-selected')}</span>
                                </button>
                                <button
                                    className="btn btn-danger"
                                    onClick={() => {
                                        setShowDeleteAllConfirmation(true);
                                    }}>
                                    <i className="fa-solid fa-trash" />
                                    <span className="ms-2">{t('delete-selected')}</span>
                                </button>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {pendingUsers &&
                        pendingUsers.map((user, index) => (
                            <tr className="align-middle" key={user._id}>
                                <td>
                                    <Form.Check
                                        type={'checkbox'}
                                        id={user._id}
                                        label={''}
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                setSelectedUsers([...selectedUsers, user]);
                                            } else {
                                                setSelectedUsers(
                                                    selectedUsers.filter((e) => e._id !== user._id)
                                                );
                                            }
                                        }}
                                        checked={
                                            selectedUsers.find((e) => e._id === user._id) !==
                                            undefined
                                        }
                                    />
                                </td>
                                <td>{index + limit * (page - 1) + 1}</td>
                                <td>{user.name}</td>
                                <td>{user.phone}</td>
                                <td>{user.email}</td>
                                <td>{user.cargillUserCode}</td>
                                <td>
                                    <PendingUserFarm userId={user._id} textClass="text-secondary" />
                                </td>
                                <td
                                    className={[
                                        user.roleName === 'USER' && 'text-warning',
                                        user.roleName === 'ADMIN' && 'text-danger',
                                        user.roleName === 'SALE_MANAGER' && 'text-success',
                                        user.roleName === 'SALE' && 'text-info',
                                    ].join(' ')}>
                                    {t(user.roleName)}
                                </td>
                                <td>
                                    <div className="d-flex gap-2">
                                        <OverlayTrigger
                                            placement="left"
                                            delay={{ show: 100, hide: 200 }}
                                            overlay={renderTooltip}>
                                            <button
                                                className="btn btn-success"
                                                onClick={() => {
                                                    setSelectUser(user);
                                                    setShowConfirmation(true);
                                                }}>
                                                <i className="fa-solid fa-person-circle-check" />
                                            </button>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            placement="left"
                                            delay={{ show: 100, hide: 200 }}
                                            overlay={renderBlockTooltip}>
                                            <button
                                                className="btn btn-danger"
                                                onClick={() => {
                                                    setSelectUser(user);
                                                    setShowBlockConfirmation(true);
                                                }}>
                                                <i className="fa-solid fa-user-lock" />
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="left"
                                            delay={{ show: 100, hide: 200 }}
                                            overlay={renderDeleteTooltip}>
                                            <button
                                                className="btn btn-danger"
                                                onClick={() => {
                                                    setSelectUser(user);
                                                    setShowDeleteConfirmation(true);
                                                }}>
                                                <i className="fa-solid fa-person-circle-minus" />
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <nav className="d-flex justify-content-end">
                <ul className="pagination">
                    <li className={['page-item', !hasPreviousPage && 'disabled'].join(' ')}>
                        <a className="page-link" href="#" onClick={() => setPage(page - 1)}>
                            {t('previous')}
                        </a>
                    </li>
                    {hasPreviousPage && (
                        <li className="page-item">
                            <a className="page-link" href="#" onClick={() => setPage(page - 1)}>
                                {page - 1}
                            </a>
                        </li>
                    )}
                    <li className="page-item active">
                        <span className="page-link">{page}</span>
                    </li>
                    {hasNextPage && (
                        <li className="page-item">
                            <a className="page-link" href="#" onClick={() => setPage(page + 1)}>
                                {page + 1}
                            </a>
                        </li>
                    )}
                    <li className={['page-item', !hasNextPage && 'disabled'].join(' ')}>
                        <a className="page-link" href="#" onClick={() => setPage(page + 1)}>
                            {t('next')}
                        </a>
                    </li>
                </ul>
            </nav>
            <ConfirmDialog
                title={t('confirmation-title')}
                body={t('approve-access-program-body-confirmation')}
                confirmTitle={t('yes')}
                declineTitle={t('cancel')}
                show={showConfirmation}
                onHide={function (result: boolean): void {
                    setShowConfirmation(false);
                    if (result) {
                        setAllowAccessProgram();
                    }
                }}
            />
            <ConfirmDialog
                title={t('confirmation-title')}
                body={t('blocking-confirmation')}
                confirmTitle={t('yes')}
                declineTitle={t('cancel')}
                show={showBlockConfirmation}
                onHide={function (result: boolean): void {
                    setShowBlockConfirmation(false);
                    if (result) {
                        blockUser();
                    }
                }}
            />
            <ConfirmDialog
                title={t('confirmation-title')}
                body={t('delete-confirmation')}
                confirmTitle={t('yes')}
                declineTitle={t('cancel')}
                show={showDeleteConfirmation}
                onHide={function (result: boolean): void {
                    setShowDeleteConfirmation(false);
                    if (result) {
                        deleteUser();
                    }
                }}
            />
            <ConfirmDialog
                title={t('confirmation-title')}
                body={t('delete-confirmation')}
                confirmTitle={t('yes')}
                declineTitle={t('cancel')}
                show={showDeleteAllConfirmation}
                onHide={function (result: boolean): void {
                    setShowDeleteAllConfirmation(false);
                    if (result) {
                        deleteUsers();
                    }
                }}
            />
            <ConfirmDialog
                title={t('confirmation-title')}
                body={t('approve-access-program-body-confirmation')}
                confirmTitle={t('yes')}
                declineTitle={t('cancel')}
                show={showApproveAllConfirmation}
                onHide={function (result: boolean): void {
                    setShowApproveAllConfirmation(false);
                    if (result) {
                        approveUsers();
                    }
                }}
            />
        </div>
    );
}
