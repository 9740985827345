/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import '../farms.scss';
import { iconClose } from 'shared/assets/icons';
import { IFarm } from 'shared/interface/farm.interface';
import { Col, Form, InputGroup } from 'react-bootstrap';
import FarmService from '../service/farm.service';
import UserService from 'shared/service/user.service';
import LoadingSpinner from 'shared/components/loading-spinner/loading-spinner';
import { toast } from 'react-toastify';
import { ModalMode } from 'shared/enum/modal-mode.enum';
import { useAppContext } from 'module/auth/context';
import AsyncSelect from 'react-select/async';
import { EditCover, EditAvatar } from './index';
import { CompanyService } from 'module/admin/company-management/service/company.service';
import axios from 'axios';

interface IProps {
    show: boolean;
    farm?: IFarm;
    mode: string;
    handleClose(): void;
    onChangeFarm?(farm: IFarm): void;
    onAddFarm?(farm: any): void;
}

function EditFarmInfoModal(props: IProps) {
    const { handleClose, show = false, farm, onChangeFarm, mode, onAddFarm } = props;
    const { t } = useTranslation();
    const appContext = useAppContext();
    const [validated, setValidated] = useState<boolean>(false);
    const [farmDetail, setFarmDetail] = useState<any>(farm);
    const [loading, setLoading] = useState<boolean>(false);
    const [incharge, setIncharge] = useState<{ value: string; label: string }[]>([]);
    const [title, setTitle] = useState<string>('');
    const [customCode, setCustomCode] = useState<string>('');
    const [numberOfSow, setNumberOfSow] = useState<number>(0);
    const [numberOfPork, setNumberOfPork] = useState<number>(0);
    const [location, setLocation] = useState<string>('');
    const [pigFarmInNearestRadius, setPigFarmInNearestRadius] = useState<number>(0);
    const [poultryFarmInNearestRadius, setPoultryFarmInNearestRadius] = useState<number>(0);
    const [residentialAreaInNearestRadius, setResidentialAreaInNearestRadius] = useState<number>(0);
    const [description, setDescription] = useState<string>('');
    const [deleted, setDeleted] = useState<boolean>(false);
    const [avatar, setAvatar] = useState<string>();
    const [cover, setCover] = useState<string>();
    const [newAvatar, setNewAvatar] = useState<File>();
    const [newCover, setNewCover] = useState<File>();
    const [technician, setTechnician] = useState<{ value: string; label: string }[]>([]);
    const [owningCompany, setOwningCompany] = useState<{ value: string; label: string }>();
    const [owningSales, setOwningSales] = useState<{ value: string; label: string }[]>([]);

    useEffect(() => {
        if (farm && mode === ModalMode.EDIT) {
            const {
                avatar = '',
                cover = '',
                title = '',
                owningUser = [],
                owningTechnician = [],
                owningSales = [],
                owningCompany = '',
                customCode = '',
                numberOfSow = 0,
                numberOfPork = 0,
                location = '',
                pigFarmInNearestRadius = 0,
                poultryFarmInNearestRadius = 0,
                residentialAreaInNearestRadius = 0,
                description = '',
                deleted = false,
            } = farm;
            if (owningUser) {
                getOwningUser(owningUser);
            }
            if (owningTechnician) {
                getOwningTechnician(owningTechnician);
            }
            if (owningSales) {
                getOwningSales(owningSales);
            }
            if (owningCompany) {
                getOwningCompany(owningCompany);
            }
            setAvatar(avatar);
            setCover(cover);
            setTitle(title);
            setCustomCode(customCode);
            setNumberOfSow(numberOfSow);
            setNumberOfPork(numberOfPork);
            setLocation(location);
            setPigFarmInNearestRadius(pigFarmInNearestRadius);
            setPoultryFarmInNearestRadius(poultryFarmInNearestRadius);
            setResidentialAreaInNearestRadius(residentialAreaInNearestRadius);
            setDescription(description);
            setFarmDetail(farm);
            setDeleted(deleted);
        }
    }, [farm]);

    const getOwningCompany = async (id: string) => {
        const result = await CompanyService.findOne(id);
        setOwningCompany({
            value: result._id,
            label: result.name,
        });
    };

    const getOwningUser = async (userIds: string[]) => {
        const result = await axios.all(userIds.map((id) => UserService.getUserInfo(id)));
        setIncharge(
            result.map((user) => ({
                label: user.name,
                value: user._id,
            }))
        );
    };

    const getOwningTechnician = async (ids: string[]) => {
        const result = await axios.all(ids.map((id) => UserService.getUserInfo(id)));
        setTechnician(
            result.map((user) => ({
                label: user.name,
                value: user._id,
            }))
        );
    };

    const getOwningSales = async (ids: string[]) => {
        const result = await axios.all(ids.map((id) => UserService.getUserInfo(id)));
        setOwningSales(
            result.map((user) => ({
                label: user.name,
                value: user._id,
            }))
        );
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<{ label: string; value: string }[]>((resolve) => {
            setTimeout(async () => {
                const res = await UserService.search(inputValue);
                resolve(
                    res.map((u) => ({
                            label: `${u.name} - ${u.cargillUserCode} - ${u.roleName}`,
                            value: u._id,
                        })
                    )
                );
            }, 300);
        });

    const promiseCompanies = (inputValue: string) =>
        new Promise<{ label: string; value: string }[]>((resolve) => {
            setTimeout(async () => {
                const res = await CompanyService.find(1, 999, inputValue);
                resolve(
                    res.docs.map((u) => ({
                            label: `${u.name} - ${u.code}`,
                            value: u._id,
                        })
                    )
                );
            }, 300);
        });

    const createData = () => {
        const data = {
            title,
            customCode,
            numberOfSow,
            numberOfPork,
            location,
            pigFarmInNearestRadius,
            poultryFarmInNearestRadius,
            residentialAreaInNearestRadius,
            description,
            deleted,
        };
        if (incharge) {
            data['owningUser'] = incharge.map((i) => i.value);
        }
        if (technician) {
            data['owningTechnician'] = technician.map((t) => t.value);
        }
        if (owningSales) {
            data['owningSales'] = owningSales.map((s) => s.value);
        }
        if (newAvatar) {
            data['avatar'] = newAvatar;
        }
        if (newCover) {
            data['cover'] = newCover;
        }
        if (owningCompany) {
            data['owningCompany'] = owningCompany.value;
        }
        return data;
    };

    const onSaveFarmInfo = async () => {
        setLoading(true);
        if (farmDetail && incharge) {
            const result = await FarmService.updateFarmById(farmDetail._id, createData());
            if (result && onChangeFarm) {
                onChangeFarm(result);
                toast.success(t('successfully'), { position: 'bottom-center' });
            }
            setLoading(false);
            handleClose();
        }
    };

    const onCreateFarm = async () => {
        setLoading(true);
        if (incharge) {
            const result = await FarmService.createFarm(createData());
            if (result && onAddFarm) {
                onAddFarm(result);
                toast.success(t('successfully'), { position: 'bottom-center' });
            }
            setLoading(false);
            handleClose();
        }
    };

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() && mode === ModalMode.EDIT) {
            onSaveFarmInfo();
        } else if (form.checkValidity() && mode === ModalMode.CREATE) {
            onCreateFarm();
        }
    };

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="farm-modal-custom">
            <Modal.Header className="w-100">
                <div className="d-flex justify-content-center w-100 position-relative">
                    <span className="farm-modal-title mt-4">
                        {mode === ModalMode.EDIT ? t('edit-farm-info') : t('add-farm')}
                    </span>
                    <img
                        role="button"
                        onClick={() => handleClose()}
                        alt=""
                        src={iconClose}
                        className="position-absolute top-0 end-0"
                    />
                </div>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <Form onSubmit={handleSubmit} noValidate={true} validated={validated}>
                    <div className="d-flex flex-column w-100" style={{ maxWidth: 600 }}>
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="farm-property">{t('cover-photo')}</span>
                            {/* <span className="farm-address" role="button">
                                {t('edit')}
                            </span> */}
                        </div>
                        <div className="d-flex py-3 w-100 border-bottom">
                            <EditCover setCover={setNewCover} previousCover={cover} />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-3">
                            <span className="farm-property">{t('avatar')}</span>
                            {/* <span className="farm-address" role="button">
                                {t('edit')}
                            </span> */}
                        </div>
                        <div className="d-flex py-3 w-100 justify-content-center border-bottom">
                            <EditAvatar previousAvatar={avatar} setAvatar={setNewAvatar} />
                        </div>

                        <div className="d-flex py-3 w-100 justify-content-center flex-column">
                            <Form.Group as={Col} sm="12" className="w-100">
                                <Form.Label className="fw-semibold" style={{ color: '#474C55' }}>
                                    {t('farm-name')}
                                </Form.Label>
                                <InputGroup hasValidation={true}>
                                    <Form.Control
                                        type="text"
                                        className="rounded-1"
                                        required={true}
                                        placeholder={t('farm-name')}
                                        defaultValue={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t('required-field')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} sm="12" className="w-100 mt-3">
                                <Form.Label className="fw-semibold" style={{ color: '#474C55' }}>
                                    {t('address')}
                                </Form.Label>
                                <InputGroup hasValidation={true}>
                                    <Form.Control
                                        type="text"
                                        className="rounded-1"
                                        required={true}
                                        placeholder={t('address')}
                                        defaultValue={location}
                                        onChange={(e) => setLocation(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t('required-field')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} sm="12" className="w-100 mt-3">
                                <Form.Label className="fw-semibold" style={{ color: '#474C55' }}>
                                    {t('description')}
                                </Form.Label>
                                <InputGroup hasValidation={true}>
                                    <Form.Control
                                        type="text"
                                        className="rounded-1"
                                        required={true}
                                        placeholder={t('description')}
                                        defaultValue={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t('required-field')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} sm="12" className="w-100 mt-3">
                                <Form.Label className="fw-semibold" style={{ color: '#474C55' }}>
                                    {`${t('number-of-sow')} (${t('pigs-unit')})`}
                                </Form.Label>
                                <InputGroup hasValidation={true}>
                                    <Form.Control
                                        type="number"
                                        className="rounded-1"
                                        required={true}
                                        placeholder={'0'}
                                        defaultValue={numberOfSow || 0}
                                        onChange={(e) =>
                                            setNumberOfSow(
                                                parseInt(e.target.value, 10) > 0
                                                    ? parseInt(e.target.value, 10)
                                                    : 0
                                            )
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t('required-field')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} sm="12" className="w-100 mt-3">
                                <Form.Label className="fw-semibold" style={{ color: '#474C55' }}>
                                    {`${t('number-of-pig')} (${t('pigs-unit')})`}
                                </Form.Label>
                                <InputGroup hasValidation={true}>
                                    <Form.Control
                                        className="rounded-1"
                                        type="number"
                                        required={true}
                                        placeholder={'0'}
                                        defaultValue={numberOfPork || 0}
                                        onChange={(e) =>
                                            setNumberOfPork(
                                                parseInt(e.target.value, 10) > 0
                                                    ? parseInt(e.target.value, 10)
                                                    : 0
                                            )
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t('required-field')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} sm="12" className="w-100 mt-3">
                                <Form.Label className="fw-semibold" style={{ color: '#474C55' }}>
                                    {`${t('nearest-pig-farm')} (m)`}
                                </Form.Label>
                                <InputGroup hasValidation={true}>
                                    <Form.Control
                                        type="number"
                                        className="rounded-1"
                                        required={true}
                                        placeholder={t('nearest-pig-farm')}
                                        defaultValue={pigFarmInNearestRadius || 0}
                                        onChange={(e) =>
                                            setPigFarmInNearestRadius(
                                                parseInt(e.target.value, 10) > 0
                                                    ? parseInt(e.target.value, 10)
                                                    : 0
                                            )
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t('required-field')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} sm="12" className="w-100 mt-3">
                                <Form.Label className="fw-semibold" style={{ color: '#474C55' }}>
                                    {`${t('nearest-other-farm')} (m)`}
                                </Form.Label>
                                <InputGroup hasValidation={true}>
                                    <Form.Control
                                        type="number"
                                        className="rounded-1"
                                        required={true}
                                        placeholder={t('nearest-other-farm')}
                                        defaultValue={poultryFarmInNearestRadius || 0}
                                        onChange={(e) =>
                                            setPoultryFarmInNearestRadius(
                                                parseInt(e.target.value, 10) > 0
                                                    ? parseInt(e.target.value, 10)
                                                    : 0
                                            )
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t('required-field')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} sm="12" className="w-100 mt-3">
                                <Form.Label className="fw-semibold" style={{ color: '#474C55' }}>
                                    {`${t('nearest-residential-area')} (m)`}
                                </Form.Label>
                                <InputGroup hasValidation={true}>
                                    <Form.Control
                                        type="number"
                                        className="rounded-1"
                                        required={true}
                                        placeholder={t('nearest-residential-area')}
                                        defaultValue={residentialAreaInNearestRadius || 0}
                                        onChange={(e) =>
                                            setResidentialAreaInNearestRadius(
                                                parseInt(e.target.value, 10) > 0
                                                    ? parseInt(e.target.value, 10)
                                                    : 0
                                            )
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t('required-field')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            {(appContext.user?.roleName === 'ADMIN' ||
                                appContext.user?.roleName === 'SALE') && (
                                <>
                                    <Form.Group as={Col} sm="12" className="w-100 mt-3">
                                        <Form.Label
                                            className="fw-semibold"
                                            style={{ color: '#474C55' }}
                                        >
                                            {t('farm-owner')}
                                        </Form.Label>
                                        <InputGroup hasValidation={true}>
                                            <InputGroup hasValidation={true}>
                                                <AsyncSelect
                                                    className="w-100"
                                                    cacheOptions={true}
                                                    isClearable={true}
                                                    value={incharge ? incharge : undefined}
                                                    onChange={(newValue) =>
                                                        setIncharge(
                                                            newValue.map((n) => ({
                                                                value: n.value,
                                                                label: n.label,
                                                            }))
                                                        )
                                                    }
                                                    defaultOptions={incharge ? incharge : []}
                                                    loadOptions={promiseOptions}
                                                    isMulti={true}
                                                />
                                                {!incharge && validated && (
                                                    <div className="invalid-email-block">
                                                        {t('required-field')}
                                                    </div>
                                                )}
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                {t('required-field')}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} sm="12" className="w-100 mt-3">
                                        <Form.Label
                                            className="fw-semibold"
                                            style={{ color: '#474C55' }}
                                        >
                                            {t('technician')}
                                        </Form.Label>
                                        <InputGroup hasValidation={true}>
                                            <InputGroup hasValidation={true}>
                                                <AsyncSelect
                                                    className="w-100"
                                                    cacheOptions={true}
                                                    isClearable={true}
                                                    value={technician ? technician : undefined}
                                                    onChange={(newValue) =>
                                                        setTechnician(
                                                            newValue.map((n) => ({
                                                                value: n.value,
                                                                label: n.label,
                                                            }))
                                                        )
                                                    }
                                                    defaultOptions={technician ? technician : []}
                                                    loadOptions={promiseOptions}
                                                    isMulti={true}
                                                />
                                                {!incharge && validated && (
                                                    <div className="invalid-email-block">
                                                        {t('required-field')}
                                                    </div>
                                                )}
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                {t('required-field')}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} sm="12" className="w-100 mt-3">
                                        <Form.Label
                                            className="fw-semibold"
                                            style={{ color: '#474C55' }}
                                        >
                                            {t('sale-incharge')}
                                        </Form.Label>
                                        <InputGroup hasValidation={true}>
                                            <InputGroup hasValidation={true}>
                                                <AsyncSelect
                                                    className="w-100"
                                                    cacheOptions={true}
                                                    isClearable={true}
                                                    value={owningSales ? owningSales : undefined}
                                                    onChange={(newValue) =>
                                                        setOwningSales(
                                                            newValue.map((n) => ({
                                                                value: n.value,
                                                                label: n.label,
                                                            }))
                                                        )
                                                    }
                                                    defaultOptions={owningSales ? owningSales : []}
                                                    loadOptions={promiseOptions}
                                                    isMulti={true}
                                                />
                                                {!incharge && validated && (
                                                    <div className="invalid-email-block">
                                                        {t('required-field')}
                                                    </div>
                                                )}
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                {t('required-field')}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} sm="12" className="w-100 mt-3">
                                        <Form.Label
                                            className="fw-semibold"
                                            style={{ color: '#474C55' }}
                                        >
                                            {t('company-owns')}
                                        </Form.Label>
                                        <InputGroup hasValidation={true}>
                                            <InputGroup hasValidation={true}>
                                                <AsyncSelect
                                                    className="w-100"
                                                    cacheOptions={true}
                                                    isClearable={true}
                                                    value={
                                                        owningCompany ? owningCompany : undefined
                                                    }
                                                    onChange={(newValue) =>
                                                        setOwningCompany(
                                                            newValue
                                                                ? {
                                                                      label: newValue?.label,
                                                                      value: newValue?.value,
                                                                  }
                                                                : undefined
                                                        )
                                                    }
                                                    defaultOptions={
                                                        owningCompany ? [owningCompany] : []
                                                    }
                                                    loadOptions={promiseCompanies}
                                                    isMulti={false}
                                                />
                                                {!incharge && validated && (
                                                    <div className="invalid-email-block">
                                                        {t('required-field')}
                                                    </div>
                                                )}
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                {t('required-field')}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </>
                            )}
                        </div>
                    </div>
                    <div
                        className="d-flex justify-content-center w-100 mb-5"
                        style={{ maxWidth: 600 }}
                    >
                        <button className="btn btn-primary w-100" type="submit">
                            {t('save')}
                        </button>
                    </div>
                </Form>
            </Modal.Body>
            <LoadingSpinner loading={loading} />
        </Modal>
    );
}

export default EditFarmInfoModal;
