/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Collapse,
    Form,
    InputGroup,
    OverlayTrigger,
    Tooltip as BootstrapToolTip,
} from 'react-bootstrap';
import FarmService from 'module/client/farm/service/farm.service';
import ReportService from 'module/client/report/service/report.service';
import { IFarm, IGroupFarms } from 'shared/interface/farm.interface';
import { IBioReport, IBioReportPagination } from 'shared/interface/bio-report.interface';
import Table from 'react-bootstrap/Table';
import { format, subMonths } from 'date-fns';
import Select, { components, GroupProps } from 'react-select';
import { useNavigate } from 'react-router-dom';
import ExcelService from 'shared/service/excel.service';
import { CompanyService } from '../company-management/service/company.service';
import AsyncSelect from 'react-select/async';
import { groupBy } from 'lodash';
import DatePicker, { registerLocale } from 'react-datepicker';
import { enUS, vi } from 'date-fns/locale';
import { useAuthContext } from 'module/auth/context';
import UserService from 'shared/service/user.service';
import { toast } from 'react-toastify';
import { RiskLevel } from 'shared/enum/bio-report.enum';
import { ICompany } from 'shared/interface/company.interface';
import PerformerName from './performerName';
import BioReportService from 'module/client/bio-report/service/bio-report.service';
import { FilterStore } from 'shared/enum/local-store.enum';
import CompanyName from 'shared/components/company/companyName';
import QuestionSetService from 'module/client/question/service/questionSet.service';
import { IQuestionSet } from 'shared/interface/question-set.interface';
import QuestionSetTitle from 'shared/components/question-set/QuestionSetTitle';

registerLocale('en-US', enUS);
registerLocale('vi', vi);

const groupStyles = {
    borderRadius: '5px',
    background: '#f2fcff',
};

export default function ReportManagement() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const authContext = useAuthContext();
    const [farms, setFarms] = useState<IFarm[]>([]);
    const [reports, setReports] = useState<IBioReport[]>([]);
    const [groupedReports, setGroupedReports] = useState<
        { farmId: string; farm?: IFarm; reports: IBioReport[]; owningCompany: string }[]
    >([]);
    const [options, setOptions] = useState<any>([]);
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [from, setFrom] = useState<Date>(subMonths(new Date(), 1));
    const [to, setTo] = useState<Date>(new Date());

    const [owningCompany, setOwningCompany] = useState<{ value: string; label: string }[]>([]);
    const [idsToExport, setIdsToExport] = useState<string[]>([]);
    const [selectedFarms, setSelectedFarms] = useState<{ value: string; label: string }[]>([]);
    const [owningUser, setOwningUser] = useState<{ value: string; label: string }[]>([]);
    const [riskLevels, setRiskLevels] = useState<{ value: string; label: string }[]>([]);
    const [questionSets, setQuestionSets] = useState<IQuestionSet[]>([]);
    const [companies, setCompanies] = useState<ICompany[]>([]);
    const [reportFarms, setReportFarms] = useState<IFarm[]>([]);
    const [questionSetsSelected, setQuestionSetsSelected] = useState<
        { value: string; label: string }[]
    >([]);
    const [init, setInit] = useState<boolean>(true);
    const [groupByCompany, setGroupByCompany] = useState<
        {
            company?: ICompany;
            companyId: string;
            farms: { farmId: string; farm?: IFarm; reports: IBioReport[]; owningCompany: string }[];
        }[]
    >([]);

    const filterRef = useRef({
        owningCompany,
        from,
        to,
        selectedFarms,
        owningUser,
        riskLevels,
        questionSetsSelected,
    });

    useEffect(() => {
        filterRef.current = {
            owningCompany,
            from,
            to,
            selectedFarms,
            owningUser,
            riskLevels,
            questionSetsSelected,
        };
    }, [owningCompany, from, to, selectedFarms, owningUser, riskLevels, questionSetsSelected]);

    const riskLevelOptions = [
        { value: RiskLevel.LOW, label: t('low') },
        { value: RiskLevel.MEDIUM, label: t('medium') },
        { value: RiskLevel.HIGH, label: t('high') },
        { value: RiskLevel.WARNING, label: t('warning') },
    ];

    const toggleTicked = (id: string) => {
        if (idsToExport.includes(id)) {
            setIdsToExport([...idsToExport.filter((i) => i !== id)]);
        } else {
            setIdsToExport([...idsToExport, id]);
        }
    };

    const getRisk = (list: { percentage: number; totalViolated: number }) => {
        if (list.percentage <= 90) {
            if (list.totalViolated <= 0) {
                return <span className="text-primary">{t('medium')}</span>;
            } else if (list.totalViolated <= 3 && list.totalViolated >= 1) {
                return <span className="text-warning">{t('high')}</span>;
            } else {
                return <span className="text-danger">{t('warning')}</span>;
            }
        } else {
            if (list.totalViolated <= 0) {
                return <span className="text-success">{t('low')}</span>;
            } else if (list.totalViolated <= 3 && list.totalViolated >= 1) {
                return <span className="text-dark">{t('medium')}</span>;
            } else {
                return <span className="text-warning">{t('high')}</span>;
            }
        }
    };

    const getListCompany = async (reports: IBioReport[]) => {
        // const companies: ICompany[] = [];
        let farms: IFarm[] = [];
        const farmIds: string[] = [];
        const promises: Promise<IFarm>[] = [];
        for (const report of reports) {
            if (!farmIds.includes(report.owningFarm)) {
                promises.push(FarmService.getFarmById(report.owningFarm));
                farmIds.push(report.owningFarm);
            }
            // if (
            //     gotFarm &&
            //     gotFarm.owningCompany &&
            //     !companies.find((c) => c._id === gotFarm.owningCompany)
            // ) {
            //     const gotCompany = await CompanyService.findOne(gotFarm.owningCompany);
            //     companies.push(gotCompany);
            // }
        }
        farms = await Promise.all(promises);

        // setCompanies(companies);
        setReportFarms(farms);
        return {
            // listCompany: companies,
            listFarm: farms,
        };
    };

    const getReports = async (farmIds: string[], latest = false) => {
        if (farmIds.length === 0 && owningCompany.length > 0 && owningUser.length === 0) {
            toast.warn(t('customers-have-no-farm'), { position: 'bottom-center' });
            return;
        }
        authContext.setLoading(true);
        let result: IBioReportPagination;
        if (!latest) {
            result = await ReportService.getReportByFarmId(
                farmIds,
                from,
                to,
                owningUser.map((o) => o.value),
                riskLevels.map((r) => r.value) as RiskLevel[],
                questionSetsSelected.map((q) => q.value)
            );
        } else {
            result = await ReportService.getReportByFarmId(
                farmIds,
                undefined,
                undefined,
                undefined,
                riskLevels.map((r) => r.value) as RiskLevel[],
                questionSetsSelected.map((q) => q.value)
            );
        }
        // @ts-ignore
        window.gtag('event', 'SEARCH_REPORT_ADMIN', {
            owningCompany,
            owningUser,
            selectedFarms,
            questionSetsSelected,
        });
        if (result) {
            const doneReports = result.docs.filter((e) => e.isDone);
            setReports([...doneReports]);
            const { listFarm } = await getListCompany(doneReports);

            const grouped = groupBy([...result.docs.filter((e) => e.isDone)], 'owningFarm');
            const newGroup: {
                farmId: string;
                farm?: IFarm;
                reports: IBioReport[];
                owningCompany: string;
            }[] = [];
            for (const [key, value] of Object.entries(grouped)) {
                newGroup.push({
                    farmId: key,
                    farm: listFarm.find((f) => f._id === key),
                    reports: value as unknown as IBioReport[],
                    owningCompany: listFarm.find((f) => f._id === key)?.owningCompany || '',
                });
            }

            const groupByCompany = groupBy(newGroup, 'owningCompany');
            const newGroupByCompany: any = [];
            for (const [key, value] of Object.entries(groupByCompany)) {
                newGroupByCompany.push({
                    companyId: key,
                    farms: value,
                });
            }

            setGroupedReports(newGroup);
            setGroupByCompany(newGroupByCompany);
        }
        authContext.setLoading(false);
    };

    const getQuestionSets = async () => {
        const res = await QuestionSetService.findAll(1, 9999);
        setQuestionSets(res.docs);
    };

    useEffect(() => {
        getFilter();
        return () => {
            setFilter();
        };
    }, []);

    useEffect(() => {
        if (!init) {
            getReports(selectedFarms.map((farm) => farm.value));
        }
    }, [init]);

    const getFilter = async () => {
        await getQuestionSets();
        const rawFilter = localStorage.getItem(FilterStore.ADMIN_BIO_REPORT_FILTER);
        if (rawFilter) {
            setOpenFilter(true);
            const convertedFilter = JSON.parse(rawFilter);
            if (convertedFilter.owningCompany) {
                setOwningCompany(convertedFilter.owningCompany);
            }
            if (convertedFilter.from) {
                setFrom(new Date(convertedFilter.from));
            }
            if (convertedFilter.to) {
                setTo(new Date(convertedFilter.to));
            }
            if (convertedFilter.selectedFarms) {
                setSelectedFarms(convertedFilter.selectedFarms);
            }
            if (convertedFilter.owningUser) {
                setOwningUser(convertedFilter.owningUser);
            }
            if (convertedFilter.riskLevels) {
                setRiskLevels(convertedFilter.riskLevels);
            }
            if (convertedFilter.questionSetsSelected) {
                setQuestionSetsSelected(convertedFilter.questionSetsSelected);
            }
        }
        setInit(false);
    };

    const setFilter = () => {
        localStorage.setItem(
            FilterStore.ADMIN_BIO_REPORT_FILTER,
            JSON.stringify(filterRef.current)
        );
    };

    const renderAddTooltip = (props: any) => (
        <BootstrapToolTip id="button-tooltip" {...props}>
            {t('export-excel')}
        </BootstrapToolTip>
    );

    const downloadExcel = async (id: string) => {
        // @ts-ignore
        window.gtag('event', 'DOWNLOAD_EXCEL_ADMIN', {});
        authContext.setLoading(true);
        const response = await ExcelService.getOne(id);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Report.xlsx');
        document.body.appendChild(link);
        link.click();
        authContext.setLoading(false);
    };

    const downloadAllExcel = async () => {
        // @ts-ignore
        window.gtag('event', 'DOWNLOAD_ALL_EXCEL_ADMIN', {});
        authContext.setLoading(true);
        const response = await ExcelService.getMany(idsToExport, false);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ReportAll.xlsx');
        document.body.appendChild(link);
        link.click();
        authContext.setLoading(false);
    };

    const downloadGroupExcel = async () => {
        // @ts-ignore
        window.gtag('event', 'DOWNLOAD_GROUP_EXCEL_ADMIN', {});
        authContext.setLoading(true);
        const response = await ExcelService.getMany(
            reports.map((r) => r._id),
            true
        );
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ReportGrouped.xlsx');
        document.body.appendChild(link);
        link.click();
        authContext.setLoading(false);
    };

    const promiseCompanyOptions = (inputValue: string) =>
        new Promise<{ label: string; value: string }[]>((resolve) => {
            setTimeout(async () => {
                const res = await CompanyService.find(1, 999, inputValue);
                resolve(
                    res.docs.map((u) => {
                        return {
                            label: u.name,
                            value: u._id,
                        };
                    })
                );
            }, 300);
        });

    const getAllFarmsRelated = async (owningCompanies: { label: string; value: string }[]) => {
        if (owningCompanies.length === 0) {
            return;
        }
        const resultFarms = await FarmService.getAll(
            false,
            1,
            99999,
            undefined,
            owningCompanies.map((o) => o.value)
        );
        // @ts-ignore
        window.gtag('event', 'GET_ALL_FARM_REPORT_ADMIN', {
            owningCompanies,
            owningUser,
            selectedFarms,
        });
        setFarms(resultFarms.docs);
        const grouped = groupBy(resultFarms.docs, 'owningCompany');
        const resultGrouped: IGroupFarms[] = [];
        for (const [key, value] of Object.entries(grouped)) {
            resultGrouped.push({
                companyId: key,
                farms: value as unknown as IFarm[],
            });
        }
        const newOptions = resultGrouped.map((group) => ({
            label: owningCompanies.find((o) => o.value === group.companyId)?.label,
            options: group.farms.map((farm) => ({
                value: farm._id,
                label: farm.title,
            })),
        }));
        setOptions(newOptions);
        setSelectedFarms(
            resultFarms.docs.map((doc) => ({
                label: doc.title,
                value: doc._id,
            }))
        );
    };

    const Group = (props: GroupProps<{ label: string; value: string }[], true>) => (
        <div style={groupStyles}>
            <components.Group {...props} />
        </div>
    );

    const onSearch = (e) => {
        e.preventDefault();
        getReports(selectedFarms.map((farm) => farm.value));
        // @ts-ignore
        window.gtag('event', 'SEARCH_REPORT_ADMIN_CLICK', {});
        setFilter();
    };

    const remove = async (id: string) => {
        authContext.setLoading(true);
        const res = await BioReportService.delete(id);
        if (res) {
            toast.success(t('successfully'), { position: 'bottom-center' });
            getReports(selectedFarms.map((farm) => farm.value));
        }
        authContext.setLoading(false);
    };

    const promiseUserOptions = (inputValue: string) =>
        new Promise<{ label: string; value: string }[]>((resolve) => {
            setTimeout(async () => {
                const res = await UserService.search(inputValue);
                resolve(
                    res.map((u) => {
                        return {
                            label: u.name,
                            value: u._id,
                        };
                    })
                );
            }, 300);
        });

    return (
        <div className="container-fluid p-3">
            <div className="d-flex flex-column w-100 mb-3 div-shadow rounded">
                <button
                    className="btn btn-outline-secondary w-100 border-0"
                    onClick={() => setOpenFilter(!openFilter)}>
                    <div className="d-flex justify-content-between">
                        <i className="fa-solid fa-filter my-auto" />
                        <span className="fw-bold">{t('filter')}</span>
                        <i className="fa-solid fa-circle-arrow-down my-auto" />
                    </div>
                </button>
                <Collapse in={openFilter} className="py-2">
                    <div className="p-3">
                        <Form onSubmit={(e) => onSearch(e)}>
                            <div className="row">
                                <div className="col-12 col-sm-4">
                                    <div className="input-group flex-nowrap">
                                        <span className="input-group-text">{t('from-date')}</span>
                                        <DatePicker
                                            locale={i18n.language}
                                            dateFormat={
                                                i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                            }
                                            className="form-control"
                                            selected={from}
                                            onChange={(date: Date) => {
                                                setFrom(date);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                    <div className="input-group flex-nowrap">
                                        <span className="input-group-text">{t('to-date')}</span>
                                        <DatePicker
                                            locale={i18n.language}
                                            dateFormat={
                                                i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                            }
                                            className="form-control"
                                            selected={to}
                                            onChange={(date: Date) => setTo(date)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <Form.Group as={Col} sm="4">
                                    <Form.Label>{t('customer')}</Form.Label>
                                    <InputGroup hasValidation={true}>
                                        <AsyncSelect
                                            className="w-100"
                                            cacheOptions={true}
                                            isClearable={true}
                                            placeholder={t('customer')}
                                            value={owningCompany ? owningCompany : undefined}
                                            onChange={(newValue) => {
                                                const companies = newValue.map((v) => ({
                                                    label: v.label,
                                                    value: v.value,
                                                }));
                                                setOwningCompany(companies);
                                                getAllFarmsRelated(companies);
                                            }}
                                            defaultOptions={owningCompany ? owningCompany : []}
                                            loadOptions={promiseCompanyOptions}
                                            isMulti={true}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} sm="4">
                                    <Form.Label>{t('farm')}</Form.Label>
                                    <InputGroup hasValidation={true}>
                                        <Select
                                            className="w-100"
                                            options={options}
                                            components={{ Group }}
                                            isMulti={true}
                                            onChange={(newValue) => {
                                                const mapped = newValue.map((v: any) => v.value);
                                                setSelectedFarms(mapped);
                                            }}
                                            value={selectedFarms}
                                        />
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group as={Col} sm="4">
                                    <Form.Label>{t('incharge-people')}</Form.Label>
                                    <InputGroup hasValidation={true}>
                                        <AsyncSelect
                                            className="w-100"
                                            cacheOptions={true}
                                            isClearable={true}
                                            placeholder={t('incharge-people')}
                                            value={owningUser ? owningUser : undefined}
                                            onChange={(newValue) => {
                                                const users = newValue.map((v) => ({
                                                    label: v.label,
                                                    value: v.value,
                                                }));
                                                setOwningUser(users);
                                            }}
                                            defaultOptions={owningUser ? owningUser : []}
                                            loadOptions={promiseUserOptions}
                                            isMulti={true}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </div>
                            <div className="row mt-2">
                                <Form.Group as={Col} sm="4">
                                    <Form.Label>{t('risk-level')}</Form.Label>
                                    <InputGroup>
                                        <Select
                                            className="w-100"
                                            isMulti={true}
                                            name="riskLevel"
                                            options={riskLevelOptions}
                                            value={riskLevels}
                                            onChange={(newValue) => {
                                                const levels = newValue.map((v) => ({
                                                    label: v.label,
                                                    value: v.value,
                                                }));
                                                setRiskLevels(levels);
                                            }}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} sm="4">
                                    <Form.Label>{t('question-set')}</Form.Label>
                                    <InputGroup>
                                        <Select
                                            className="w-100"
                                            isMulti={true}
                                            name="questionSets"
                                            options={questionSets.map((e) => ({
                                                label: e.title,
                                                value: e._id,
                                            }))}
                                            value={questionSetsSelected}
                                            onChange={(newValue) => {
                                                const questionSets = newValue.map((v) => ({
                                                    label: v.label,
                                                    value: v.value,
                                                }));
                                                setQuestionSetsSelected(questionSets);
                                            }}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </div>
                            <div className="d-flex justify-content-end gap-2 mt-2">
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() =>
                                        getReports(
                                            selectedFarms.map((farm) => farm.value),
                                            true
                                        )
                                    }>
                                    {t('latest')}
                                </button>
                                <button className="btn btn-primary" type="submit">
                                    {t('search')}
                                </button>
                            </div>
                        </Form>
                    </div>
                </Collapse>
            </div>

            <div className="d-flex col-12 flex-column mt-4">
                <div className="d-flex justify-content-between">
                    <span className="fs-5">{t('list-report')}</span>
                    <div className="d-flex gap-2">
                        <button
                            className="btn btn-primary"
                            disabled={reports.length === 0}
                            onClick={() => {
                                downloadGroupExcel();
                            }}>
                            {t('download-grouped-reports')}
                        </button>
                        <button
                            className="btn btn-primary"
                            disabled={reports.length === 0}
                            onClick={() => {
                                downloadAllExcel();
                            }}>
                            {t('download-all-marked-reports')}
                        </button>
                    </div>
                </div>
                {groupByCompany &&
                    groupByCompany.length > 0 &&
                    groupByCompany.map((group) => {
                        return (
                            <div className="div-shadow p-3" key={group.companyId}>
                                <h5 className="text-primary fw-bold">
                                    {t('customer')}: <CompanyName id={group.companyId} />
                                </h5>
                                <Table
                                    bordered={true}
                                    hover={true}
                                    className="mt-3"
                                    responsive={true}>
                                    <thead style={{ backgroundColor: '#F2F4F6' }}>
                                        <tr>
                                            <th />
                                            <th className="border-start border-0">
                                                {t('created-at')}
                                            </th>
                                            <th className="border border-0">{t('performer')}</th>
                                            <th>{t('farm')}</th>
                                            <th>{t('question-set')}</th>
                                            <th>{t('passed-percentage')}</th>
                                            <th>{t('total-violated-point-got')}</th>
                                            <th>{t('risk')}</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {group.farms.map((farm, indexFarm) => {
                                            return farm.reports.map((report, reportIndex) => {
                                                return (
                                                    <tr
                                                        className="clickable align-middle"
                                                        key={reportIndex}>
                                                        <td>
                                                            <div className="d-flex justify-content-center">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id={report._id}
                                                                    onChange={() =>
                                                                        toggleTicked(report._id)
                                                                    }
                                                                    checked={idsToExport.includes(
                                                                        report._id
                                                                    )}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td
                                                            className="border-start border-0 fs-6 custom-color"
                                                            onClick={() =>
                                                                navigate(
                                                                    `/bio-report/${report._id}`
                                                                )
                                                            }>
                                                            {format(
                                                                new Date(
                                                                    report.updatedAt ??
                                                                        report.createdAt
                                                                ),
                                                                i18n.language === 'vi'
                                                                    ? 'dd/MM/yyyy'
                                                                    : 'MM/dd/yyyy'
                                                            )}
                                                        </td>
                                                        <td
                                                            className="border border-0"
                                                            onClick={() =>
                                                                navigate(
                                                                    `/bio-report/${report._id}`
                                                                )
                                                            }>
                                                            <PerformerName id={report.updatedBy} />
                                                        </td>
                                                        <td
                                                            onClick={() =>
                                                                navigate(
                                                                    `/bio-report/${report._id}`
                                                                )
                                                            }>
                                                            {
                                                                reportFarms.find(
                                                                    (f) =>
                                                                        f._id === report.owningFarm
                                                                )?.title
                                                            }
                                                        </td>
                                                        <td
                                                            onClick={() =>
                                                                navigate(
                                                                    `/bio-report/${report._id}`
                                                                )
                                                            }>
                                                            <QuestionSetTitle
                                                                id={report.questionSet}
                                                            />
                                                        </td>
                                                        <td
                                                            onClick={() =>
                                                                navigate(
                                                                    `/bio-report/${report._id}`
                                                                )
                                                            }>
                                                            {report.percentage?.totalEvaluation.toFixed(
                                                                2
                                                            )}
                                                            %
                                                        </td>
                                                        <td
                                                            className="text-center"
                                                            onClick={() =>
                                                                navigate(
                                                                    `/bio-report/${report._id}`
                                                                )
                                                            }>
                                                            {report.violatedPointsCount}
                                                        </td>
                                                        <td
                                                            onClick={() =>
                                                                navigate(
                                                                    `/bio-report/${report._id}`
                                                                )
                                                            }>
                                                            {getRisk({
                                                                percentage:
                                                                    report.percentage
                                                                        .totalEvaluation,
                                                                totalViolated:
                                                                    report.violatedPointsCount,
                                                            })}
                                                        </td>
                                                        <td className="d-flex gap-2 justify-content-end w-100">
                                                            <OverlayTrigger
                                                                placement="left"
                                                                delay={{ show: 100, hide: 200 }}
                                                                overlay={renderAddTooltip}>
                                                                <button
                                                                    className="btn btn-outline-secondary"
                                                                    onClick={() => {
                                                                        downloadExcel(report._id);
                                                                    }}>
                                                                    <i className="fa-solid fa-cloud-arrow-down" />
                                                                </button>
                                                            </OverlayTrigger>
                                                            <button
                                                                className="btn btn-danger"
                                                                onClick={() => {
                                                                    remove(report._id);
                                                                }}>
                                                                <i className="fa-solid fa-trash" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            });
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}
