import {
    IRuntimeSetting,
    IRuntimeSettingPagination,
} from 'shared/interface/runtime-setting.interface';
import api from 'shared/service/api';

const url = 'runtime-setting';

export default class RuntimeService {
    static async findAll(page: number = 1, limit: number = 999): Promise<IRuntimeSettingPagination> {
        const res = await api.get(`${url}`, {
            params: {
                page,
                limit,
            },
        });
        return res.data;
    }

    static async create(dto: any): Promise<IRuntimeSetting> {
        const res = await api.post(`${url}`, dto);
        return res.data;
    }

    static async update(id: string, dto: any): Promise<IRuntimeSetting> {
        const res = await api.patch(`${url}/${id}`, dto);
        return res.data;
    }

    static async remove(id: string): Promise<IRuntimeSetting> {
        const res = await api.delete(`${url}/${id}`);
        return res.data;
    }

    static async getKeys(): Promise<string[]> {
        const res = await api.get(`${url}/keys`);
        return res.data;
    }
}
