import FarmService from 'module/client/farm/service/farm.service';
import TopicService from 'module/client/topic/service/topic.service';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    IBioReportQuestionRetail,
    IBioReportRetail,
    IGroupViolatedRetail,
} from 'shared/interface/bio-report-retail.interface';
import { IFarm } from 'shared/interface/farm.interface';
import { ITopic } from 'shared/interface/topic.interface';
import { IUser } from 'shared/interface/user.interface';
import { BioReportRetailService } from 'shared/service/bio-report-retail.service';
import UserService from 'shared/service/user.service';
import { calculateReport, calculateReportRetail } from 'shared/util/bio-report';
import { getWindowDimensions } from 'shared/util/window-dimensions';
import BioSafetyTabRetail from './bio-safety-tab-retail';

export default function BioReportRetailDetail() {
    const { bioReportId } = useParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [bioReport, setBioReport] = useState<IBioReportRetail>();
    const [farm, setFarm] = useState<IFarm>();
    const [customer, setCustomer] = useState<IUser>();

    const [finalResult, setFinalResult] = useState<{
        finalPoint: number;
        finalTotalPoint: number;
        percentage: number;
        totalViolated: number;
    }>();
    const [violatedQuestions, setViolatedQuestions] = useState<IBioReportQuestionRetail[]>([]);
    const [groupViolated, setGroupViolated] = useState<IGroupViolatedRetail[]>([]);
    const [topics, setTopics] = useState<ITopic[]>([]);
    const [windowDimensions] = useState(getWindowDimensions());

    const calculateTotalListPoint = () => {
        if (bioReport) {
            const result = calculateReportRetail(bioReport);
            setFinalResult(result?.finalResult);
            setViolatedQuestions(result?.violatedQuestions);
            setBioReport(result?.bioReport);
            setGroupViolated(result?.groupViolated);
        }
    };

    const getTopics = async () => {
        const resultTopics = await TopicService.find(1, 5);
        setTopics(resultTopics.docs);
    };

    const getUser = async (userId: string) => {
        const result = await UserService.getUserInfo(userId);
        setCustomer(result);
    };

    const getFarm = async (farmId: string) => {
        const result = await FarmService.getFarmById(farmId);
        setFarm(result);
        getUser(result.owningUser[0]);
    };

    const getBioReport = async () => {
        if (bioReportId) {
            const result = await BioReportRetailService.findById(bioReportId);
            setBioReport(result);
            getFarm(result.owningFarm);
        }
    };

    useEffect(() => {
        getBioReport();
        getTopics();
    }, [bioReportId]);

    useEffect(() => {
        calculateTotalListPoint();
    }, [bioReport]);

    return (
        <div className="mt-3 p-0 p-sm-3 bg-cargill-gray">
            <div>
                <div className="d-flex mb-3 bg-white ps-2">
                    <i
                        className="fa-solid fa-chevron-left text-secondary my-auto clickable"
                        onClick={() => navigate('/bio-report')}
                    />
                    <span className="fs-3 my-auto ms-2">{t('report')}</span>
                </div>

                <BioSafetyTabRetail
                    farm={farm}
                    bioReport={bioReport}
                    customer={customer}
                    finalResult={finalResult}
                    violatedQuestions={violatedQuestions}
                    groupViolated={groupViolated}
                />
            </div>
        </div>
    );
}
