import QuestionSetService from 'module/client/question/service/questionSet.service';
import { useEffect, useState } from 'react';
import { IQuestionSet } from 'shared/interface/question-set.interface';

export default function QuestionSetTitle({ id }: { id: string }) {
    const [questionSet, setQuestionSet] = useState<IQuestionSet>();

    const getQuestionSet = async () => {
        const res = await QuestionSetService.findOne(id);
        setQuestionSet(res);
    };

    useEffect(() => {
        getQuestionSet();
    }, [id]);

    return <span>{questionSet?.title}</span>;
}
