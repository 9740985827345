/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IFarm } from 'shared/interface/farm.interface';
import { IUser } from 'shared/interface/user.interface';
import './bio-safety-tab-retail.scss';
import 'shared/styles/animation.scss';
import { QuestionType } from 'shared/enum/question.enum';
import FarmService from 'module/client/farm/service/farm.service';
import BioReportChartRetail from './bio-report-chart-tab-retail/bio-report-chart-retail';
import BioReportTabRetail from './bio-report-tab-retail/bio-report-tab-retail';
import {
    IBioReportListQuestionRetail,
    IBioReportQuestionRetail,
    IBioReportRetail,
    IGroupViolatedRetail,
} from 'shared/interface/bio-report-retail.interface';

interface IProp {
    bioReport?: IBioReportRetail;
    farm?: IFarm;
    customer?: IUser;
    finalResult?: {
        finalPoint: number;
        finalTotalPoint: number;
        percentage: number;
        totalViolated: number;
    };
    violatedQuestions?: IBioReportQuestionRetail[];
    groupViolated: IGroupViolatedRetail[];
}

export default function BioSafetyTab(prop: IProp) {
    const { bioReport, customer, finalResult, violatedQuestions, groupViolated } = prop;
    const { t } = useTranslation();
    const [listCollapse, setListCollapse] = useState<number[]>([]);
    const [childListCollapse, setChildListCollapse] = useState<string[]>([]);
    const [fillInGroups, setFillInGroups] = useState<IBioReportListQuestionRetail[]>([]);

    const [farm, setFarm] = useState<IFarm>();

    const checkHasFillIn = (questions: IBioReportQuestionRetail[]) => {
        for (const question of questions) {
            if (question.questionType === QuestionType.FILL_IN) {
                return true;
            }
        }
        return false;
    };

    const getFarm = async () => {
        if (bioReport) {
            const res = await FarmService.getFarmById(bioReport.owningFarm);
            setFarm(res);
        }
    };

    useEffect(() => {
        setFillInGroups([]);
        if (bioReport && fillInGroups.length === 0) {
            bioReport.listQuestion.forEach((list) => {
                // if (!list.isGroupStyle && checkHasFillIn(list.questions)) {
                //     setFillInGroups([...fillInGroups, list]);
                // }
            });
            getFarm();
        }
    }, [bioReport]);

    const toggleIndexList = (index: number) => {
        if (listCollapse.includes(index)) {
            setListCollapse([...listCollapse.filter((l) => l !== index)]);
        } else {
            setListCollapse([...listCollapse, index]);
        }
    };

    const toggleChildList = (indexList: number, indexGroup: number) => {
        if (childListCollapse.includes(`${indexList}-${indexGroup}`)) {
            setChildListCollapse([
                ...childListCollapse.filter((l) => l !== `${indexList}-${indexGroup}`),
            ]);
        } else {
            setChildListCollapse([...childListCollapse, `${indexList}-${indexGroup}`]);
        }
    };

    const getRisk = (list: IBioReportListQuestionRetail | { percentage: number }) => {
        if (!list.percentage) {
            return <span className="text-danger">{t('high')}</span>;
        }
        if (list.percentage > 90) {
            return <span className="text-success">{t('low')}</span>;
        } else if (list.percentage > 80) {
            return <span className="text-warning">{t('medium')}</span>;
        } else {
            return <span className="text-danger">{t('high')}</span>;
        }
    };

    const getStartNumber = (indexList: number, indexGroup?: number) => {
        let sum = 0;
        for (let i = 0; i < indexList; i++) {
            if (!bioReport?.listQuestion[i].isGroupStyle) {
                sum += bioReport?.listQuestion[i].questions.length || 0;
            } else {
                let subSum = 0;
                bioReport.listQuestion[i].groupQuestions.forEach((e) => {
                    subSum += e.questions.length;
                });
                sum += subSum;
            }
        }
        if (bioReport?.listQuestion[indexList || 0].isGroupStyle) {
            for (let i = 0; i < (indexGroup || 0); i++) {
                sum += bioReport.listQuestion[indexList || 0].groupQuestions[i].questions.length;
            }
        }
        return sum + 1;
    };

    const buildSingleQuestionList = (
        listQuestionQuestions: IBioReportQuestionRetail[],
        startNum: number
    ) => {
        return (
            <div className="row">
                {listQuestionQuestions.map((question, index) => (
                    <div
                        key={index}
                        className={`col-12 col-sm-12 px-3 border-bottom div-picking-bg d-flex justify-content-between gap-2`}>
                        <div className="d-flex flex-column py-3 w-100">
                            <div className="ps-4 d-flex flex-row">
                                <span
                                    className={`border-bottom border-5 me-2 ${
                                        question.isViolatedPoint
                                            ? 'border-warning'
                                            : 'border-success'
                                    }`}>
                                    {t('question')} {startNum + index}
                                </span>
                                {question.isViolatedPoint && (
                                    <div
                                        className="p-1"
                                        style={{ background: 'rgba(96, 143, 67, 0.1)' }}>
                                        <span style={{ color: '#608f43' }}>
                                            {t('violated-question')}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className="text-secondary ps-4">
                                <span>{question.title}</span>
                            </div>

                            <div className="d-flex justify-content-between ps-4">
                                <span className="my-auto">
                                    {t('point-answer')}: <strong>{question.answer?.answer}</strong>
                                </span>
                                <div className="text-center py-3 d-flex gap-2 justify-content-center align-items-center">
                                    <strong>{question.answer?.point} {t('point')}</strong>
                                    {question.answer!.point > 0 && (
                                        <i className="fa-solid fa-circle-check text-success fs-4" />
                                    )}
                                    {question.answer!.point === 0 && (
                                        <i className="fa-solid fa-circle-xmark text-danger fs-4" />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const buildGroupQuestionList = (indexList: number) => {
        return bioReport?.listQuestion[indexList].groupQuestions.map((group, indexGroup) => {
            const startNum = getStartNumber(indexList, indexGroup);
            return (
                <div key={indexGroup}>
                    <div
                        className="row p-3 ps-4 border-bottom div-picking-bg clickable"
                        onClick={() => {
                            toggleChildList(indexList, indexGroup);
                        }}>
                        <span className="col-7">{group.groupTitle}</span>
                        <span className="col-2">{group.percentage?.toFixed(2)} %</span>
                        <div className="col-3 d-flex justify-content-end">
                            <button className="btn btn-outline-secondary rounded-circle border-0 py-0">
                                {childListCollapse.includes(`${indexList}-${indexGroup}`) ? (
                                    <i className="fa-solid fa-caret-up" />
                                ) : (
                                    <i className="fa-solid fa-caret-down" />
                                )}
                            </button>
                        </div>
                    </div>
                    <Collapse in={childListCollapse.includes(`${indexList}-${indexGroup}`)}>
                        {/* <div>{buildSingleQuestionList(group.questions, startNum)}</div> */}
                        <div>asdasd</div>
                    </Collapse>
                </div>
            );
        });
    };

    return (
        <div className="swing-in-right-fwd div-shadow bg-cargill-gray">
            {bioReport && (
                <>
                    <BioReportChartRetail finalResult={finalResult} bioReport={bioReport} />
                    <BioReportTabRetail
                        finalResult={finalResult}
                        violatedQuestions={violatedQuestions}
                        groupViolated={groupViolated}
                    />
                    <div className="div-shadow px-3 mt-5 bg-white">
                        <div className="row bg-secondary border-bottom report-detail-fixed-header bg-cargill-gray-darker">
                            <div className="col-6 py-3">
                                <span className="text-secondary">{t('customer')}</span>{' '}
                                {customer?.name}
                            </div>
                            <div className="col-3 text-center py-3 border-start">{`% ${t(
                                'passed'
                            )}`}</div>
                            <div className="col-2 text-center py-3 border-start">{t('risk')}</div>
                            <div className="col-1 text-center py-3" />
                        </div>
                        <div className="row px-3 border-bottom clickable div-picking-bg">
                            <div className="col-6 my-auto py-3">{t('total')}</div>
                            <div className="col-3 text-center my-auto py-3 border-start">
                                {finalResult?.percentage.toFixed(2)}%
                            </div>
                            <div className="col-2 text-center py-3 border-start">
                                {getRisk({ percentage: finalResult?.percentage || 0 })}
                            </div>
                        </div>
                        {bioReport &&
                            bioReport.listQuestion.map(
                                (list, indexList) =>
                                    !checkHasFillIn(list.questions) && (
                                        <div key={indexList}>
                                            <div
                                                className="row px-3 border-bottom clickable div-picking-bg"
                                                key={indexList}
                                                onClick={() => {
                                                    toggleIndexList(indexList);
                                                }}>
                                                <div className="col-6 my-auto py-3">
                                                    {list.groupTitle}
                                                </div>
                                                <div className="col-3 text-center my-auto py-3 border-start">
                                                    {list.percentage?.toFixed(2)}%
                                                </div>
                                                <div className="col-2 text-center my-auto py-3 border-start">
                                                    {getRisk(list)}
                                                </div>
                                                <div className="col-1 text-center my-auto py-3">
                                                    <button className="btn btn-outline-primary rounded-circle border-0 py-0">
                                                        {listCollapse?.includes(indexList) ? (
                                                            <i className="fa-solid fa-caret-up" />
                                                        ) : (
                                                            <i className="fa-solid fa-caret-down" />
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                            <Collapse in={listCollapse?.includes(indexList)}>
                                                <div className="div-shadow-1 border-0">
                                                    {!list.isGroupStyle &&
                                                        buildSingleQuestionList(
                                                            bioReport?.listQuestion[indexList]
                                                                .questions,
                                                            getStartNumber(indexList, undefined)
                                                        )}
                                                    {list.isGroupStyle &&
                                                        buildGroupQuestionList(indexList)}
                                                </div>
                                            </Collapse>
                                        </div>
                                    )
                            )}
                    </div>
                </>
            )}
        </div>
    );
}
