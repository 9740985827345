/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import '../farms.scss';
import { buildFileView } from 'shared/service/file';
import { useDropzone } from 'react-dropzone';

interface IAvatarProps {
    setAvatar: any;
    previousAvatar?: string;
}
const EditAvatar = (props: IAvatarProps) => {
    const { previousAvatar, setAvatar } = props;
    const [newFile, setNewFile] = useState<File>();

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/jpeg': ['.jpeg', '.png'],
        },
        maxFiles: 1,
    });

    useEffect(() => {
        acceptedFiles.forEach((file, index: number) => {
            setAvatar(file);
            setNewFile(file);
        });
    }, [acceptedFiles]);

    return (
        <div {...getRootProps({ className: 'drop-zone clickable' })}>
            <input {...getInputProps()} />
            <div className="d-flex justify-content-center">
                <button className="btn btn-outline-light btn-lg" type="button">
                    {newFile ? (
                        <img
                            alt="farm-cover"
                            className="rounded-circle"
                            style={{ height: 120, width: 120, objectFit: 'cover' }}
                            src={URL.createObjectURL(newFile)}
                        />
                    ) : (
                        <img
                            alt="farm-cover"
                            className="rounded-circle"
                            style={{ height: 120, width: 120, objectFit: 'cover' }}
                            src={buildFileView(previousAvatar)}
                        />
                    )}
                </button>
            </div>
        </div>
    );
};

export default EditAvatar;
