import React, { useEffect, useRef } from 'react';
import { FarmCard } from 'shared/components';
import { IGroupFarms } from 'shared/interface/farm.interface';
import { useNavigate } from 'react-router-dom';
import Company from 'module/client/farm/components/company';

interface IProps {
    groupedFarms: IGroupFarms[];
}

function ActiveFarms(props: IProps) {
    const { groupedFarms = [] } = props;
    const navigate = useNavigate();

    return (
        <div className="container-fluid p-3">
            {groupedFarms.map((group, index) => (
                <div
                    key={group.companyId}
                    className="mt-3 div-shadow p-3"
                >
                    <div className="d-flex bg-white p-2">
                        <Company id={group.companyId} />
                    </div>
                    <div className="row flex-wrap mt-3 w-100 gx-3 gy-3">
                        {group.farms.map((farm, index) => {
                            return (
                                <div key={index} className="col-lg-3 col-md-4">
                                    <FarmCard
                                        farm={farm}
                                        onPress={() => window.open(`/admin/farms/${farm._id}`, '_blank')}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ActiveFarms;
