/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import TopicService from 'module/client/topic/service/topic.service';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import { buildFileView, uploadFile } from 'shared/service/file';
import { ITopic } from 'shared/interface/topic.interface';

const uploadAdapter = (loader): any => {
    return {
        upload: () => {
            return new Promise((resolve, reject) => {
                loader.file.then((file) => {
                    uploadFile(file)
                        .then((res) => res.data)
                        .then((res) => {
                            resolve({
                                default: buildFileView(res._id),
                            });
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            });
        },
    };
};

const editorConfig = {
    ...ClassicEditor.defaultConfig,
};

editorConfig['mediaEmbed'] = { previewsInData: true };

export default function TopicEdit() {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [validated, setValidated] = useState<boolean>(false);

    const [title, setTitle] = useState<string>('');
    const [previewContent, setPreviewContent] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [topic, setTopic] = useState<ITopic>();
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/jpeg': ['.jpeg', '.png'],
        },
        maxFiles: 1,
    });
    const [previewImage, setPreviewImage] = useState<File>();
    const [files, setFiles] = useState<any>(undefined);
    const [url, setUrl] = useState<string>('');

    const getTopicAndSet = async () => {
        const res = await TopicService.getTopicDetail(id || '');
        setTitle(res.title);
        setPreviewContent(res.previewContent);
        setContent(res.content);
        setTopic(res);
        setUrl(res.url || '');
    };

    useEffect(() => {
        setFiles(
            acceptedFiles.map((file, index: number) => {
                setPreviewImage(file);
                const url = URL.createObjectURL(file);
                return <img key={index} src={url} className="img-fluid" alt="" />;
            })
        );
    }, [acceptedFiles]);

    useEffect(() => {
        if (id) {
            getTopicAndSet();
        }
    }, []);

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity()) {
            if (id) {
                const res = await TopicService.update(
                    id,
                    {
                        title,
                        previewContent,
                        content,
                        url,
                    },
                    previewImage
                );
                if (res) {
                    toast.success(t('successfully'), { position: 'bottom-center' });
                    navigate('/admin/topic');
                }
            } else {
                const res = await TopicService.create(
                    {
                        title,
                        previewContent,
                        content,
                        url,
                    },
                    previewImage
                );
                if (res) {
                    toast.success(t('successfully'), { position: 'bottom-center' });
                    navigate('/admin/topic');
                }
            }
        }
    };

    return (
        <div className="container-fluid">
            <h3>{!id ? t('add-topic') : t('edit-topic')}</h3>
            <Form onSubmit={handleSubmit} noValidate={true} validated={validated}>
                <div className="row">
                    <Form.Group as={Col} sm="12">
                        <Form.Label>{t('title')}</Form.Label>
                        <InputGroup hasValidation={true}>
                            <Form.Control
                                type="text"
                                className="text-gray"
                                required={true}
                                placeholder={t('title')}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {t('required-field')}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </div>
                <div className="row mt-2">
                    <Form.Group as={Col} sm="12">
                        <Form.Label>{t('preview-content')}</Form.Label>
                        <InputGroup hasValidation={true}>
                            <Form.Control
                                type="text"
                                as="textarea"
                                className="text-gray"
                                required={true}
                                placeholder={t('preview-content')}
                                value={previewContent}
                                onChange={(e) => setPreviewContent(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {t('required-field')}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </div>
                <div className="row mt-2">
                    <Form.Group as={Col} sm="12" md="6">
                        <Form.Label>{t('preview-image')}</Form.Label>
                        <div {...getRootProps({ className: 'drop-zone p-5 clickable' })}>
                            <input {...getInputProps()} />
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-outline-secondary btn-lg" type="button">
                                    <i className="fa-solid fa-download"/>
                                </button>
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                {t('choose-a-file-or-drag-it-here')}
                            </div>
                            <div className="d-flex justify-content-center text-secondary mt-2">
                                {t('file-rule')}
                            </div>
                        </div>
                    </Form.Group>
                    <div className="col-12 col-sm-6 p-3">
                        {files && files.length > 0 ? (
                            files
                        ) : (
                            <div>
                                {topic && (
                                    <img
                                        className="img-fluid"
                                        src={buildFileView(topic?.image)}
                                        alt=""
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = '/image/picture/default.jpeg';
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="row mt-2">
                    <Form.Group as={Col} sm="12">
                        <Form.Label>{t('content')}</Form.Label>
                        <CKEditor
                            editor={ClassicEditor}
                            config={editorConfig}
                            data={content}
                            onChange={(event: any, editor: any) => {
                                const data = editor.getData();
                                setContent(data);
                            }}
                            onReady={(editor) => {
                                editor.plugins.get('FileRepository').createUploadAdapter = (
                                    loader
                                ) => {
                                    return uploadAdapter(loader);
                                };
                            }}
                        />
                    </Form.Group>
                </div>
                <div className="row mt-2">
                    <Form.Group as={Col} sm="12">
                        <Form.Label>{t('external-url')}</Form.Label>
                        <InputGroup hasValidation={true}>
                            <Form.Control
                                type="text"
                                className="text-gray"
                                required={false}
                                placeholder={t('external-url')}
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                            />
                        </InputGroup>
                    </Form.Group>
                </div>
                <div className="row justify-content-center mt-3">
                    <button type="submit" className="col-12 col-sm-6 btn btn-primary">
                        {t('finish')}
                    </button>
                </div>
            </Form>
        </div>
    );
}
