/* eslint-disable react-hooks/exhaustive-deps */
import FarmService from 'module/client/farm/service/farm.service';
import { useEffect, useState } from 'react';
import { IFarm } from 'shared/interface/farm.interface';

interface IProp {
    id?: string;
}

export default function FarmName(prop: IProp) {
    const { id } = prop;
    const [farm, setFarm] = useState<IFarm | undefined>(undefined);
    const getFarm = async () => {
        const result = await FarmService.getFarmById(id || '');
        setFarm(result);
    };
    useEffect(() => {
        if (id) {
            getFarm();
        }
    }, [id]);
    return <span>{farm ? farm.title : ''}</span>;
}
