import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IRuntimeSetting } from 'shared/interface/runtime-setting.interface';
import RuntimeService from './service/runtime-setting.service';
import { format } from 'date-fns';
import ConfirmDialog from 'shared/components/confirm-dialog/confirm-dialog';
import { toast } from 'react-toastify';
import EditRuntimeSetting from './edit-runtime-setting';

export default function RuntimeSetting() {
    const { t, i18n } = useTranslation();
    const [runtimeSettings, setRuntimeSettings] = useState<IRuntimeSetting[]>([]);
    const [select, setSelect] = useState<IRuntimeSetting>();
    const [show, setShow] = useState<boolean>(false);
    const [showEdit, setShowEdit] = useState<boolean>(false);

    const renderAddTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('add-topic')}
        </Tooltip>
    );

    const getRuntimeSetting = async () => {
        const res = await RuntimeService.findAll();
        setRuntimeSettings(res.docs);
    };

    const deleteRuntimeSetting = async () => {
        const res = await RuntimeService.remove(select?._id || '');
        if (res) {
            toast.success(t('successfully'), { position: 'bottom-center' });
            setRuntimeSettings(runtimeSettings.filter((r) => r._id !== select?._id));
        }
    };

    useEffect(() => {
        getRuntimeSetting();
    }, []);

    return (
        <div className="container-fluid">
            <h2 className="mt-2">{t('runtime-setting')}</h2>
            <div className="table-responsive">
                <table
                    className="table table-hover align-middle"
                    style={{ tableLayout: 'fixed', wordWrap: 'break-word' }}
                >
                    <thead>
                        <tr className="table-secondary">
                            <th scope="col"/>
                            <th scope="col">{t('key')}</th>
                            <th scope="col">{t('value')}</th>
                            <th scope="col">{t('updated-at')}</th>
                            <th scope="col">
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 100, hide: 200 }}
                                    overlay={renderAddTooltip}
                                >
                                    <button
                                        className="btn btn-success"
                                        onClick={() => {
                                            setSelect(undefined);
                                            setShowEdit(true);
                                        }}
                                    >
                                        <i className="fa-solid fa-plus"/>
                                    </button>
                                </OverlayTrigger>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {runtimeSettings.length > 0 &&
                            runtimeSettings.map((runtime, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td className="text-primary">{runtime.name}</td>
                                    <td className="text-secondary">{runtime.value}</td>
                                    <td>
                                        {format(
                                            new Date(runtime.updatedAt),
                                            i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                        )}
                                    </td>
                                    <td>
                                        <button
                                            className="btn btn-outline-secondary"
                                            onClick={() => {
                                                setSelect(runtime);
                                                setTimeout(() => {
                                                    setShowEdit(true);
                                                }, 200);
                                            }}
                                        >
                                            <i className="fa-solid fa-pen-clip"/>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary"
                                            onClick={() => {
                                                setSelect(runtime);
                                                setTimeout(() => {
                                                    setShow(true);
                                                }, 200);
                                            }}
                                        >
                                            <i className="fa-solid fa-trash-can"/>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            <ConfirmDialog
                title={t('confirmation-title')}
                body={`${t('are-you-sure-to-delete')} ${select?.name}`}
                confirmTitle={t('yes')}
                declineTitle={t('cancel')}
                show={show}
                onHide={function (result: boolean): void {
                    if (result) {
                        deleteRuntimeSetting();
                    }
                    setShow(false);
                }}
            />
            <EditRuntimeSetting
                runtimeSetting={select}
                show={showEdit}
                onHide={function (data: IRuntimeSetting | null): void {
                    setShowEdit(false);
                    if (data) {
                        if (select) {
                            setRuntimeSettings(
                                runtimeSettings.map((r) => {
                                    if (r._id === data._id) {
                                        r = data;
                                    }
                                    return r;
                                })
                            );
                        } else {
                            setRuntimeSettings([...runtimeSettings, data]);
                        }
                    }
                }}
            />
        </div>
    );
}
