/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { QuestionType } from 'shared/enum/question.enum';
import { IQuestion } from 'shared/interface/question.interface';
import { buildFileView } from 'shared/service/file';
import QuestionService from '../../service/question.service';

interface IProp {
    yesAnswerTitle: string;
    noAnswerTitle: string;
    id: string;
    questionIndex: number;
    onAnswer: (result: boolean) => void;
    onAnswerFillIn: (result: string) => void;
    initializeValue?: boolean;
    initializeFillValue: string;
    startNumber?: number;
}

export default function SingleQuestion(prop: IProp) {
    const { t } = useTranslation();
    const [question, setQuestion] = useState<IQuestion>();

    const getQuestion = async () => {
        const result = await QuestionService.getById(prop.id);
        setQuestion(result);
    };

    useEffect(() => {
        getQuestion();
    }, [prop.id]);

    return (
        <div className="div-shadow p-3 mt-3">
            {question && question.questionType === QuestionType.YES_NO && (
                <div id={question._id}>
                    <div className="d-flex">
                        {question.image && (
                            <img
                                className="img-fluid"
                                alt=""
                                src={buildFileView(question?.image)}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = '/image/picture/default.jpeg';
                                }}
                            />
                        )}
                    </div>
                    <div className="d-flex mt-2">
                        <span className="text-primary">
                            {t('question')} {(prop.startNumber || 0)}
                        </span>
                    </div>
                    <div className="d-flex mt-2">{question.title}</div>
                    <div className="d-flex my-2">
                        <div className="form-check">
                            <input
                                onClick={() => {
                                    prop?.onAnswer(true);
                                }}
                                className="form-check-input"
                                type="radio"
                                name={`flexRadioDefault${prop.questionIndex}`}
                                id="flexRadioDefault1"
                                defaultChecked={prop.initializeValue === true}
                            />
                            <label className="form-check-label">{t(prop.yesAnswerTitle)}</label>
                        </div>
                        <div className="form-check ms-5">
                            <input
                                onClick={() => {
                                    prop?.onAnswer(false);
                                }}
                                className="form-check-input"
                                type="radio"
                                name={`flexRadioDefault${prop.questionIndex}`}
                                id="flexRadioDefault2"
                                defaultChecked={prop.initializeValue === false}
                            />
                            <label className="form-check-label">{t(prop.noAnswerTitle)}</label>
                        </div>
                    </div>
                </div>
            )}
            {question && question.questionType === QuestionType.FILL_IN && (
                <div>
                    <div className="d-flex">
                        {question.image && (
                            <img
                                alt=""
                                src={buildFileView(question?.image)}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = '/image/picture/default.jpeg';
                                }}
                            />
                        )}
                    </div>
                    <div className="row">
                        <h6 className="text-primary">
                            {t('question')} {(prop.startNumber || 0)}
                        </h6>
                        <Form.Group as={Col} sm="12" controlId="validatePhone" className="w-100">
                            <Form.Label>{question.title}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Control
                                    type="text"
                                    required={true}
                                    placeholder={'6.5'}
                                    defaultValue={prop.initializeFillValue}
                                    onChange={(e) => prop.onAnswerFillIn(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('required-field')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </div>
                </div>
            )}
        </div>
    );
}
