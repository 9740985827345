import { useEffect, useState } from 'react';
import { Step, Stepper } from 'react-form-stepper';
import { IBioReportRetail, RetailPoint } from 'shared/interface/bio-report-retail.interface';
import SingleQuestionRetail from './single-question-retail/SingleQuestionRetail';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { BioReportRetailService } from 'shared/service/bio-report-retail.service';
import { ConfirmDialog } from 'shared/components';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface Props {
    bioReportRetail?: IBioReportRetail | null;
    onFinish?: (report: IBioReportRetail) => void;
}

const stepStyleDto = {
    activeBgColor: '#638c1c',
    activeTextColor: '#ffffff',
    completedBgColor: '#638c1c',
    completedTextColor: '#ffffff',
    inactiveBgColor: '#F3F3F3',
    inactiveTextColor: 'rgba(99, 140, 28, 0.3)',
    size: '2em',
    circleFontSize: '1rem',
    labelFontSize: '0.875rem',
    borderRadius: '50%',
    fontWeight: '500',
};

export default function EvaluateRetail(props: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { bioReportRetail } = props;
    const [data, setData] = useState<IBioReportRetail | null | undefined>();
    const [activeStep, setActiveStep] = useState<number>(0);
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        setData(bioReportRetail);
    }, [bioReportRetail]);

    const setIcon = (index: number) => {
        if (index === activeStep) {
            return <i className="fa-solid fa-pen" />;
        } else if (index < activeStep) {
            return <i className="fa-solid fa-check" />;
        } else {
            return <i className="fa-solid fa-circle" />;
        }
    };

    const getStartNumber = () => {
        let sum = 0;
        for (let i = 0; i < activeStep; i++) {
            sum += data?.listQuestion[i].questions.length || 0;
        }
        return sum + 1;
    };

    const reviewAndScroll = (showToast = true) => {
        if (data) {
            if (!data.listQuestion[activeStep].isGroupStyle) {
                for (let j = 0; j < data.listQuestion[activeStep].questions.length; j++) {
                    if (!data.listQuestion[activeStep].questions[j].answer) {
                        const startNum = getStartNumber();
                        if (showToast) {
                            toast.dark(`${t('missing-answer-question')} ${startNum + j}`);
                        }
                        const elem = document.getElementById(
                            data.listQuestion[activeStep].questions[j].dataCore
                        );
                        elem?.scrollIntoView({
                            behavior: 'smooth',
                        });
                        return false;
                    }
                }
            }
            return true;
        }
        return false;
    };

    const nextStep = async () => {
        if (activeStep + 1 === data?.listQuestion?.length) {
            await BioReportRetailService.update(data?._id || '', data, false);
            setShow(true);
            return;
        }
        const ok = reviewAndScroll();
        setActiveStep(ok ? activeStep + 1 : activeStep);
    };

    const saveAndGo = async () => {
        if (data) {
            const result = await BioReportRetailService.update(data?._id || '', data, true);
            // @ts-ignore
            window.gtag('event', 'FINISH_TEST_RETAIL', {});
            if (result) {
                toast.success(t('save-successfully'), { position: 'bottom-center' });
                navigate({
                    pathname: `/bio-report-retail/${data?._id}`,
                });
            }
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (data) {
                const elem = document.getElementById(
                    data.listQuestion[activeStep].questions[0].dataCore
                );
                elem?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    }, [activeStep]);

    return (
        <div>
            <Stepper activeStep={activeStep} styleConfig={stepStyleDto}>
                {data &&
                    data?.listQuestion?.length > 0 &&
                    data?.listQuestion?.map((list, index) => (
                        <Step
                            key={index}
                            label={list.groupTitle}
                            children={setIcon(index)}
                            onClick={() => setActiveStep(index)}
                        />
                    ))}
            </Stepper>
            <div className="overflow-auto px-2" style={{ height: '55vh' }}>
                {data &&
                    data?.listQuestion?.length > 0 &&
                    data?.listQuestion[activeStep].questions.map((q, qIndex) => (
                        <SingleQuestionRetail
                            key={q.dataCore}
                            question={q}
                            defaultAnswer={q.answer}
                            startNumber={getStartNumber() + qIndex}
                            onAnswer={function (answer: RetailPoint): void {
                                if (data) {
                                    data.listQuestion[activeStep].questions[qIndex].answer = answer;
                                    setData({ ...data });
                                }
                            }}></SingleQuestionRetail>
                    ))}
            </div>
            <div className="d-flex pt-3">
                <Button variant="primary" onClick={() => nextStep()} className="w-100">
                    {t('continue')}
                </Button>
            </div>
            <ConfirmDialog
                title={t('do-you-want-to-save-result')}
                body={t('question-confirm-body')}
                confirmTitle={t('yes')}
                declineTitle={t('continue-to-edit')}
                show={show}
                onHide={function (result: boolean): void {
                    if (result) {
                        saveAndGo();
                    }
                    setShow(false);
                }}
            />
        </div>
    );
}
