import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ConfirmDialog } from 'shared/components';
import { IAuthorization } from 'shared/interface/authorization.interface';
import AuthorizationService from 'shared/service/authorization.service';
import EditAuthorization from './edit-authorization';

export default function AuthorizationManagement() {
    const { t, i18n } = useTranslation();
    const [authorizations, setAuthorizations] = useState<IAuthorization[]>([]);
    const [open, setOpen] = useState<string[]>([]);
    const [select, setSelect] = useState<IAuthorization>();
    const [show, setShow] = useState<boolean>(false);
    const [showEdit, setShowEdit] = useState<boolean>(false);

    const getAuthorizations = async () => {
        const res = await AuthorizationService.findAll();
        setAuthorizations(res.docs);
    };

    useEffect(() => {
        getAuthorizations();
    }, []);

    const toggleCollapse = (id: string) => {
        if (open.includes(id)) {
            setOpen([...open.filter((e) => e !== id)]);
        } else {
            setOpen([...open, id]);
        }
    };

    const deleteAuthorization = async () => {
        if (select) {
            const res = await AuthorizationService.delete(select?._id);
            if (res) {
                toast.success(t('successfully'), { position: 'bottom-center' });
                setAuthorizations([...authorizations.filter((a) => a._id !== select._id)]);
                setSelect(undefined);
            }
        }
    };

    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between mt-2">
                <h4 className="mt-3 fw-bold">{t('role-management')}</h4>
                <div className="my-auto">
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            setSelect(undefined);
                            setTimeout(() => {
                                setShowEdit(true);
                            }, 200);
                        }}
                    >
                        <i className="fa-solid fa-plus"/>
                        <span className="ms-3">{t('add-role')}</span>
                    </button>
                </div>
            </div>

            <div className="row">
                {authorizations.map((authz) => (
                    <div key={authz._id} className="col-12 col-md-4 mt-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <h5 className="card-title fw-bold text-secondary my-auto">
                                        {authz.name}
                                    </h5>
                                    <div className="d-flex gap-1">
                                        <button
                                            className="btn btn-outline-secondary"
                                            onClick={() => {
                                                setSelect(authz);
                                                setTimeout(() => {
                                                    setShowEdit(true);
                                                }, 200);
                                            }}
                                        >
                                            <i className="fa-solid fa-pen-clip"/>
                                        </button>
                                        <button
                                            className="btn btn-outline-danger"
                                            onClick={() => {
                                                setSelect(authz);
                                                setTimeout(() => {
                                                    setShow(true);
                                                }, 200);
                                            }}
                                        >
                                            <i className="fa-solid fa-trash-can"/>
                                        </button>
                                    </div>
                                </div>
                                <p className="card-text">
                                    <i className="fa-regular fa-clock text-secondary"/>
                                    <span className="ms-2 text-gray">
                                        {format(
                                            new Date(authz.updatedAt),
                                            i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                        )}
                                    </span>
                                </p>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary w-100 mb-3"
                                    onClick={() => toggleCollapse(authz._id)}
                                >
                                    {t('permissions')}
                                </button>
                                <Collapse in={open.includes(authz._id)}>
                                    <div className="div-shadow">
                                        <ul
                                            className="list-group list-group-flush overflow-auto"
                                            style={{ maxHeight: 300 }}
                                        >
                                            {authz.actions.map((action, index) => (
                                                <li key={index} className="list-group-item">
                                                    {action}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <ConfirmDialog
                title={t('confirmation-title')}
                body={t('are-you-sure-to-delete')}
                confirmTitle={t('yes')}
                declineTitle={t('cancel')}
                show={show}
                onHide={function (result: boolean): void {
                    if (result) {
                        deleteAuthorization();
                    }
                    setShow(false);
                }}
            />
            <EditAuthorization
                authorization={select}
                show={showEdit}
                onHide={function (data: IAuthorization | null): void {
                    setShowEdit(false);
                    if (data) {
                        if (select) {
                            setAuthorizations(
                                authorizations.map((r) => {
                                    if (r._id === data._id) {
                                        r = data;
                                    }
                                    return r;
                                })
                            );
                        } else {
                            setAuthorizations([...authorizations, data]);
                        }
                    }
                }}
            />
        </div>
    );
}
