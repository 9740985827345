export interface IProp {
    active?: boolean;
    activeColor?: string;
    defaultColor?: string;
}

const IconReport = (props: IProp) => {
    const { active = false, activeColor, defaultColor } = props;
    const fill = active ? activeColor ?? '#638C1C' : defaultColor || '#474C55';
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M15.56 8.64282H10.0148C9.7725 8.64282 9.57495 8.84037 9.57495 9.08266C9.57495 9.20608 9.6274 9.31726 9.70919 9.39746L13.6138 13.3021C13.694 13.3885 13.8099 13.4426 13.9365 13.4426C14.0892 13.4426 14.2235 13.3654 14.3022 13.2481C15.2622 12.1338 15.8795 10.7186 15.9907 9.16134C15.9969 9.13511 15.9999 9.10889 15.9999 9.08262C16 8.84037 15.8039 8.64282 15.56 8.64282Z"
                fill={fill}
            />
            <path
                d="M8.34017 0.671387C8.09782 0.672868 7.90186 0.868936 7.90186 1.11123V7.27383C7.90186 7.5177 8.09782 7.71525 8.34017 7.71525H14.5043C14.7467 7.71525 14.9442 7.5177 14.9442 7.27383C14.7265 3.72728 11.8883 0.889036 8.34017 0.671387Z"
                fill={fill}
            />
            <path
                d="M11.7279 12.9426L7.18126 8.3959C7.10411 8.31717 7.05784 8.20758 7.05784 8.08876L7.05621 1.66683C7.05621 1.42453 6.86024 1.22852 6.6179 1.22852C2.92467 1.45387 0 4.52201 0 8.2709C0 12.1678 3.1593 15.3286 7.05621 15.3286C8.83415 15.3286 10.4578 14.6712 11.6986 13.5862C11.7943 13.5058 11.8545 13.3855 11.8545 13.2513C11.8545 13.1324 11.8066 13.0228 11.7279 12.9426Z"
                fill={fill}
            />
        </svg>
    );
};

export default IconReport;
