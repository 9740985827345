import { useTranslation } from 'react-i18next';
import './forbidden.scss';

export default function Forbidden() {
    const { t } = useTranslation();
    return (
        <div className="wrapper w-100 h-100 d-flex flex-column justify-content-center align-items-center">
            <div className="lock"/>
            <div className="message">
                <h1>{t('forbidden-title')}</h1>
                <p>{t('forbidden-subtitle')}</p>
            </div>
        </div>
    );
}
