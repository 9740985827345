/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LocalStoreName } from '../../../shared/enum/local-store.enum';
import { RoleName } from '../../../shared/enum/role-name.enum';
import { toast } from 'react-toastify';
import SignupService from './service/signup.service';
import {jwtDecode} from 'jwt-decode';
import './signup.scss';
import { useAppContext, useAuthContext } from '../context';
import { ITokenPayload } from 'shared/interface/auth.interface';

export default function SignUp() {
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const authContext = useAuthContext();
    const appContext = useAppContext();
    const navigate = useNavigate();
    const [accountType, setAccountType] = useState<RoleName>(RoleName.NONE);
    const [step, setStep] = useState<number>(0);
    const [validated, setValidated] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [cargillUserCode, setCargillUserCode] = useState<string>();
    const [email, setEmail] = useState<string>('');

    const isValidEmail = (email: string) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    useEffect(() => {
        if (isValidEmail(searchParams.get('phone') || '')) {
            setEmail(searchParams.get('phone') || '');
        } else {
            setPhone(searchParams.get('phone') || '');
        }
    }, []);

    const onSetAccount = () => {
        setStep(1);
    };

    const changeLanguage = () => {
        if (i18n.language === 'en-US') {
            i18n.changeLanguage('vi');
        } else {
            i18n.changeLanguage('en-US');
        }
    };

    const handleSubmit = async (event: any) => {
        // @ts-ignore
        window.gtag('event', 'SIGN_UP', { name, phone, email });
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity()) {
            const result = await SignupService.signup(
                {
                    name,
                    phone,
                    cargillUserCode,
                    email,
                    roleName: accountType,
                },
                searchParams.get('otp') || ''
            );
            if (!result.result) {
                toast.error(t('signup-fail'), { position: 'bottom-center' });
                return;
            }
            if (result.refreshToken && result.token) {
                localStorage.setItem(LocalStoreName.TOKEN, result.token);
                localStorage.setItem(LocalStoreName.REFRESH_TOKEN, result.refreshToken);
                authContext.setHasToken(true);
                const decodeToken = jwtDecode<ITokenPayload>(result.token);
                appContext.setUser(decodeToken);
                toast.success(t('signup-successfully'), { position: 'bottom-center' });
                navigate('/dashboard');
            }
        }
    };

    const goBackSignup = () => {
        setStep(0);
    };

    return (
        <div className="row w-100 h-100 mx-0 gx-0">
            <div
                className="change-language d-flex justify-content-center clickable px-3"
                onClick={() => changeLanguage()}
            >
                <img
                    className="language-icon my-auto"
                    src="/image/icon/language.svg"
                    alt="change language"
                />
                <span className="my-auto ms-1">
                    {i18n.language === 'en-US' ? 'Tiếng Việt' : 'English'}
                </span>
            </div>
            <div className="col-12 col-sm-6 h-sm-100">
                <img
                    className="theme-picture"
                    src="/image/picture/pig-introduction.jpg"
                    alt="theme"
                />
            </div>
            <div className="col-12 col-sm-6 mobile-container">
                {step === 0 && (
                    <div className="d-flex flex-column justify-content-center h-100">
                        <div className="d-flex welcome-text justify-content-sm-center">
                            {t('hello')}
                        </div>
                        <div className="d-flex welcome-text justify-content-sm-center">
                            {t('account-type-to-signup')}
                        </div>
                        <div className="d-flex mt-3 gap-2 justify-content-center">
                            <div
                                className={[
                                    'd-flex flex-column align-items-center justify-content-between border px-5 py-5 signup-type-box clickable',
                                    accountType === RoleName.CUSTOMER && 'border-primary',
                                ].join(' ')}
                                onClick={() => setAccountType(RoleName.CUSTOMER)}
                            >
                                <img
                                    className="icon"
                                    src="/image/icon/customer.svg"
                                    alt="customer"
                                />
                                <span className="h5 mt-3">{t('customer')}</span>
                            </div>
                            <div
                                className={[
                                    'd-flex flex-column align-items-center justify-content-between border px-5 py-5 signup-type-box clickable',
                                    accountType === RoleName.SALE && 'border-primary',
                                ].join(' ')}
                                onClick={() => setAccountType(RoleName.SALE)}
                            >
                                <img className="icon" src="/image/icon/sale.svg" alt="customer" />
                                <span className="h5 mt-3">{t('sale')}</span>
                            </div>
                            <div
                                className={[
                                    'd-flex flex-column align-items-center justify-content-between border px-5 py-5 signup-type-box clickable',
                                    accountType === RoleName.TECHNICIAN && 'border-primary',
                                ].join(' ')}
                                onClick={() => setAccountType(RoleName.TECHNICIAN)}
                            >
                                <img
                                    className="icon"
                                    src="/image/icon/technician.svg"
                                    alt="customer"
                                />
                                <span className="h5 mt-3">{t('technician')}</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <button
                                className="btn btn-primary signup-btn-continue"
                                onClick={() => onSetAccount()}
                                disabled={accountType === RoleName.NONE}
                            >
                                {t('continue')}
                            </button>
                        </div>
                    </div>
                )}
                {step === 1 && (
                    <Form
                        onSubmit={handleSubmit}
                        noValidate={true}
                        validated={validated}
                        className="d-flex flex-column justify-content-sm-between h-100 p-5 mt-3"
                    >
                        <div className="d-flex">
                            <button
                                className="btn btn-outline-secondary btn-icon rounded-circle border border-white"
                                onClick={() => {
                                    goBackSignup();
                                }}
                            >
                                <span className="material-symbols-outlined mt-1">
                                    arrow_back_ios_new
                                </span>
                            </button>
                            <span className="my-auto h5 ms-3 text-gray">{t('signup')}</span>
                        </div>
                        <div className="row">
                            <Form.Group
                                as={Col}
                                sm="12"
                                controlId="validatePhone"
                                className="w-100"
                            >
                                <Form.Label>{t('full-name')}</Form.Label>
                                <InputGroup hasValidation={true}>
                                    <Form.Control
                                        type="text"
                                        required={true}
                                        placeholder={t('input-full-name')}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t('required-field')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                sm="12"
                                controlId="validatePhone"
                                className="w-100 mt-3"
                            >
                                <Form.Label>{t('phone')}</Form.Label>
                                <InputGroup hasValidation={true}>
                                    <Form.Control
                                        type="text"
                                        required={true}
                                        placeholder={t('input-your-phone-number')}
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t('required-field')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                sm="12"
                                controlId="validatePhone"
                                className="w-100 mt-3"
                            >
                                <Form.Label>
                                    {`${t('user-code')}`}{' '}
                                    <span className="text-secondary">
                                        ({t('provided-by-cargill')})
                                    </span>
                                </Form.Label>
                                <InputGroup hasValidation={true}>
                                    <Form.Control
                                        type="text"
                                        required={true}
                                        placeholder={t('input-user-code')}
                                        value={cargillUserCode}
                                        onChange={(e) => setCargillUserCode(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t('required-field')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                sm="12"
                                controlId="validatePhone"
                                className="w-100 mt-3"
                            >
                                <Form.Label>
                                    {`${t('email')}`}{' '}
                                    <span className="text-secondary">
                                        {accountType === RoleName.CUSTOMER &&
                                            `(${t('not-required')})`}
                                    </span>
                                </Form.Label>
                                <InputGroup hasValidation={true}>
                                    <Form.Control
                                        className={[
                                            !isValidEmail(email || 'aaa@mail.com') &&
                                                'invalid-email-form-control',
                                        ].join(' ')}
                                        type="text"
                                        required={RoleName.SALE ? true : false}
                                        placeholder={t('input-email')}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t('required-field')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                                {!isValidEmail(email || 'aaa@mail.com') && (
                                    <div className="invalid-email-block">{t('invalid-email')}</div>
                                )}
                            </Form.Group>
                            <div className="d-flex mt-4 w-100 justify-content-center">
                                <button type="submit" className="btn btn-primary w-100">
                                    {t('signup')}
                                </button>
                            </div>
                            <div className="d-flex mt-4 justify-content-center">
                                {t('first-line-agree')}
                            </div>
                            <div className="d-flex justify-content-center">
                                <span>{t('second-line-agree-part1')}&nbsp;</span>
                                <span className="text-primary clickable">
                                    {t('second-line-agree-part2')}&nbsp;
                                </span>
                                <span>{t('second-line-agree-part3')}</span>
                            </div>
                        </div>
                        <div/>
                    </Form>
                )}
            </div>
        </div>
    );
}
