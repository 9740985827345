/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IAuthorization } from 'shared/interface/authorization.interface';
import { IUser } from 'shared/interface/user.interface';
import AuthorizationService from 'shared/service/authorization.service';
import UserService from 'shared/service/user.service';
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import { isPhoneNumber } from 'class-validator';
import { CompanyService } from 'module/admin/company-management/service/company.service';
import AsyncSelect from 'react-select/async';
import { RoleName } from 'shared/enum/role-name.enum';

interface IProp {
    user?: IUser;
    show: boolean;
    onHide: (result: IUser | null) => void;
}

export default function EditUser(prop: IProp) {
    const { user, show } = prop;
    const { t } = useTranslation();
    const [validated, setValidated] = useState<boolean>(false);
    const [roles, setRoles] = useState<IAuthorization[]>([]);

    const [name, setName] = useState<string>('');
    const [roleName, setRoleName] = useState<string>('');
    const [cargillUserCode, setCargillUserCode] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [zalo, setZalo] = useState<string>('');
    const [allowAccessProgram, setAllowAccessProgram] = useState<boolean>(false);
    const [owningCompany, setOwningCompany] = useState<{ value: string; label: string }>();

    const getRoles = async () => {
        const res = await AuthorizationService.findAll();
        setRoles(res.docs);
    };

    const getCompany = async (id: string) => {
        const res = await CompanyService.findOne(id);
        const mapped = {
            label: res.name,
            value: res._id,
        };
        if (user && user.inChargeCustomer.length > 0) {
            setOwningCompany({ value: res._id, label: res.name });
        }
        return mapped;
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<{ label: string; value: string }[]>((resolve) => {
            setTimeout(async () => {
                const res = await CompanyService.find(1, 999, inputValue);
                resolve(
                    res.docs.map((u) => {
                        return {
                            label: u.name,
                            value: u._id,
                        };
                    })
                );
            }, 300);
        });

    const initUser = () => {
        setName(user ? user.name : '');
        setRoleName(user ? user.roleName : 'ADMIN');
        setCargillUserCode(user ? user.cargillUserCode || '' : '');
        setEmail(user ? user.email || '' : '');
        setPhone(user ? user.phone : '');
        setZalo(user ? user.zalo : '');
        setAllowAccessProgram(user ? user.allowAccessProgram : false);
    };

    const reset = () => {
        setName('');
        setRoleName('ADMIN');
        setCargillUserCode('');
        setEmail('');
        setPhone('');
        setZalo('');
        setAllowAccessProgram(false);
    };

    useEffect(() => {
        initUser();
        if (user?.owningCompany) {
            getCompany(user.owningCompany);
        }
    }, [user]);

    useEffect(() => {
        getRoles();
    }, []);

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        let phoneToCheck = phone;
        if (phoneToCheck[0] === '0') {
            phoneToCheck = phoneToCheck.replace('0', '+84');
        }
        if (!isPhoneNumber(phoneToCheck)) {
            toast.error(t('invalid-phone'), { position: 'top-right' });
            return;
        }
        if (form.checkValidity() && isPhoneNumber(phoneToCheck)) {
            if (user) {
                // @ts-ignore
                window.gtag('event', 'UPDATE_USER_ADMIN', { phone });
                const res = await UserService.updateUserInfo(user?._id || '', {
                    name,
                    roleName,
                    cargillUserCode,
                    email,
                    phone,
                    zalo,
                    allowAccessProgram,
                    owningCompany: owningCompany?.value,
                });
                if (res) {
                    toast.success(t('successfully'), { position: 'bottom-center' });
                    reset();
                    prop.onHide(res);
                }
            } else {
                // @ts-ignore
                window.gtag('event', 'CREATE_USER_ADMIN', { phone });
                const res = await UserService.createUser({
                    name,
                    roleName,
                    cargillUserCode,
                    email,
                    phone,
                    zalo,
                    allowAccessProgram,
                    owningCompany: owningCompany?.value,
                });
                if (res) {
                    toast.success(t('successfully'), { position: 'bottom-center' });
                    reset();
                    prop.onHide(res);
                }
            }
        }
    };

    return (
        <Modal show={show} dialogClassName="max-width-800" fullscreen="sm-down">
            <Modal.Header className="flex-column">
                <div className="d-flex justify-content-end w-100">
                    <button
                        className="btn btn-icon border-0 rounded-circle"
                        onClick={() => {
                            prop.onHide(null);
                        }}
                    >
                        <i className="fa-solid fa-xmark text-primary"/>
                    </button>
                </div>
                <div className="d-flex justify-content-center">
                    <span className="fs-3 fw-semibold">
                        {user ? t('edit-info') : t('create-user')}
                    </span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} noValidate={true} validated={validated}>
                    <div className="row">
                        <Form.Group as={Col} sm="12">
                            <Form.Label>{t('full-name')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Control
                                    type="text"
                                    className="text-gray"
                                    required={true}
                                    placeholder={t('input-full-name')}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('required-field')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="row">
                        <Form.Group as={Col} sm="12" className="mt-2">
                            <Form.Label>{t('role')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Select
                                    className="text-gray"
                                    required={true}
                                    value={roleName}
                                    onChange={(event) => setRoleName(event.target.value)}
                                >
                                    {roles.length &&
                                        roles.map((role) => (
                                            <option key={role._id} value={role.name}>
                                                {t(role.name)}
                                            </option>
                                        ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {t('required-field')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="row">
                        <Form.Group as={Col} sm="12" className="mt-2">
                            <Form.Label>{t('user-code')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Control
                                    type="text"
                                    className="text-gray"
                                    required={true}
                                    placeholder={t('input-user-code')}
                                    value={cargillUserCode}
                                    onChange={(e) => setCargillUserCode(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('required-field')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="row">
                        <Form.Group as={Col} sm="12" className="mt-2">
                            <Form.Label>{t('email')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Control
                                    type="text"
                                    className="text-gray"
                                    required={false}
                                    placeholder={t('input-email')}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="row">
                        <Form.Group as={Col} sm="6" className="mt-2">
                            <Form.Label>{t('phone')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Control
                                    type="text"
                                    className="text-gray"
                                    required={true}
                                    placeholder={t('input-phone')}
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('required-field')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} sm="6" className="mt-2">
                            <Form.Label>{t('zalo')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Control
                                    type="text"
                                    className="text-gray"
                                    required={true}
                                    placeholder={t('input-zalo')}
                                    value={zalo}
                                    onChange={(e) => setZalo(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('required-field')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="row g-0 mt-2">
                        <label className="d-flex">
                            <span className="text-secondary me-3">{t('allow-access-program')}</span>
                            <Switch
                                onChange={(checked) => setAllowAccessProgram(checked)}
                                checked={allowAccessProgram}
                            />
                        </label>
                    </div>
                    {(roleName === RoleName.CUSTOMER || roleName === RoleName.TECHNICIAN) && (
                        <div className="row mt-2">
                            <Form.Group as={Col} sm="12" className="mt-2">
                                <Form.Label>{t('company-name')}</Form.Label>
                                <InputGroup hasValidation={true}>
                                    <AsyncSelect
                                        className="w-100"
                                        cacheOptions={true}
                                        isClearable={true}
                                        value={owningCompany ? owningCompany : undefined}
                                        onChange={(newValue) =>
                                            setOwningCompany(
                                                newValue
                                                    ? {
                                                          label: newValue?.label,
                                                          value: newValue?.value,
                                                      }
                                                    : undefined
                                            )
                                        }
                                        defaultOptions={owningCompany ? [owningCompany] : []}
                                        loadOptions={promiseOptions}
                                        isMulti={false}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </div>
                    )}
                    <div className="d-flex justify-content-center mt-3">
                        <button type="submit" className="btn btn-primary w-50">
                            {t('finish')}
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
