/* eslint-disable react-hooks/exhaustive-deps */
import FarmService from 'module/client/farm/service/farm.service';
import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { emptyBox } from 'shared/assets/images';
import { IFarm } from 'shared/interface/farm.interface';
import { buildFileView } from 'shared/service/file';
import './question-pick-farm.scss';

interface IProp {
    show: boolean;
    onHide: (result: string | null) => void;
    disableClose?: boolean;
}

export default function QuestionPickFarm(prop: IProp) {
    const [farms, setFarms] = useState<IFarm[]>([]);
    const [searchResult, setSearchResult] = useState<IFarm[]>([]);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const getFarms = useCallback(async (keyword?: string) => {
        const resultFarms = await FarmService.findMyFarms(1, 999999, keyword);
        setFarms(resultFarms.docs);
        setSearchResult(resultFarms.docs);
    }, []);

    useEffect(() => {
        getFarms();
    }, []);

    const selectFarmAndEvaluate = (id: string) => {
        prop.onHide(id);
        navigate({
            pathname: '/evaluate',
            search: createSearchParams({ id }).toString(),
        });
    };

    const searchFarm = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTimeout(() => {
            getFarms(e.target.value);
        }, 300);
    };

    return (
        <Modal show={prop.show}>
            <Modal.Header className="flex-column">
                <div className="d-flex justify-content-end w-100">
                    <button
                        className="btn btn-icon border-0 rounded-circle"
                        onClick={() => {
                            prop.onHide(null);
                        }}
                    >
                        <i className="fa-solid fa-xmark text-primary"/>
                    </button>
                </div>
                <div className="d-flex justify-content-center">
                    <span className="fs-4">{t('pick-farm')}</span>
                </div>
                <div className="d-flex justify-content-center">
                    <span className="text-secondary">{t('pick-farm-description')}</span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <input type={'text'} className="form-control mb-3" onChange={searchFarm} />
                {searchResult?.length > 0 ? (
                    searchResult.map((farm, index) => (
                        <div
                            key={index}
                            className="div-shadow p-3 mt-2 rounded div-picking clickable"
                            onClick={() => {
                                selectFarmAndEvaluate(farm._id);
                            }}
                        >
                            <div className="d-flex">
                                <img
                                    alt=""
                                    className="rounded-circle avatar-farm"
                                    src={buildFileView(farm.avatar)}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = '/image/picture/default.jpeg';
                                    }}
                                />
                                <span className="ms-3 fs-5 my-auto">{farm.title}</span>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="d-flex py-3 flex-column align-items-center justify-content-center w-100">
                        <img src={emptyBox} alt="empty-box" />
                        <span>{t('no-farm')}</span>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
}
