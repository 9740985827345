/* eslint-disable react-hooks/exhaustive-deps */
import { InputHTMLAttributes, ReactNode, useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { OTPMethod } from '../../../shared/enum/otp.enum';
import AuthService from './service/login.service';
import './login.scss';
import '../../../shared/styles/animation.scss';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { LocalStoreName } from '../../../shared/enum/local-store.enum';
import { useAuthContext } from '../context/auth-context';
import { fmtMSS } from '../../../shared/util/time';
import OtpInput, { AllowedInputTypes } from 'react-otp-input';
import PickingSendingType from './picking-sending-type';
import { isPhoneNumber } from 'class-validator';
import { jwtDecode } from 'jwt-decode';
import { useAppContext } from '../context';
import { ITokenPayload } from 'shared/interface/auth.interface';

const timeout = 300;

export default function Login() {
    const { t, i18n } = useTranslation();
    const [validated, setValidated] = useState(false);
    const [otpValidatedFail, setOtpValidatedFail] = useState(false);
    const [phone, setPhone] = useState<string>('');
    const [otp1, setOTP1] = useState<string>('');
    const [step, setStep] = useState<number>(0);
    const [timer, setTimer] = useState<number>(0);
    const navigate = useNavigate();
    const authContext = useAuthContext();
    const appContext = useAppContext();

    const [show, setShow] = useState<boolean>(false);
    const [method, setMethod] = useState<OTPMethod>(OTPMethod.ZALO);

    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    useEffect(() => {
        if (authContext.hasToken) {
            navigate('/dashboard');
        }
        setStep(0);
    }, []);

    useEffect(() => {
        if (timer === 0 && step === 1) {
            toast.error(t('verification-code-is-timeout'), { position: 'top-right' });
            return;
        }
        const interval = setInterval(() => {
            if (timer > 0) {
                setTimer(timer - 1);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [timer]);

    const sendOtp = async (method: OTPMethod | null) => {
        if (phone && phone !== '' && method) {
            authContext.setLoading(true);
            const result = await AuthService.getOTP(method || OTPMethod.SMS, phone || '');
            if (result.result) {
                if (method === OTPMethod.EMAIL) {
                    // @ts-ignore
                    window.gtag('event', 'SEND_OTP_EMAIL', { phone });
                    toast.success(t('otp-sent-please-check-email'), { position: 'top-right' });
                } else {
                    toast.success(t('otp-sent-please-check'), { position: 'top-right' });
                }
                setStep(1);
                setShow(false);
                setTimer(timeout);
            }
            authContext.setLoading(false);
        }
    };

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        let phoneToCheck = phone;
        if (phoneToCheck[0] === '0') {
            phoneToCheck = phoneToCheck.replace('0', '+84');
        }
        if (!isPhoneNumber(phoneToCheck) && !validateEmail(phone)) {
            toast.error(t('invalid-phone'), { position: 'top-right' });
        }
        if (
            form.checkValidity() &&
            phone &&
            (isPhoneNumber(phoneToCheck) || validateEmail(phone))
        ) {
            if (validateEmail(phone)) {
                sendOtp(OTPMethod.EMAIL);
                setMethod(OTPMethod.EMAIL);
            } else {
                setShow(true);
            }
        }
    };

    const handleLogin = async (event: any) => {
        // @ts-ignore
        window.gtag('event', 'LOG_IN', { phone });
        event.preventDefault();
        if (otp1 === '') {
            setOtpValidatedFail(true);
            event.stopPropagation();
            return;
        }
        const otp = `${otp1}`;
        authContext.setLoading(true);
        const result = await AuthService.login(phone || '', otp || '');
        if (!result.result) {
            setOtpValidatedFail(true);
            authContext.setLoading(false);
            return;
        }
        setOtpValidatedFail(false);
        if (result.token && result.refreshToken) {
            localStorage.setItem(LocalStoreName.TOKEN, result.token);
            localStorage.setItem(LocalStoreName.REFRESH_TOKEN, result.refreshToken);
            authContext.setHasToken(true);
            const decodeToken = jwtDecode<ITokenPayload>(result.token);
            appContext.setUser(decodeToken);
        }
        authContext.setLoading(false);
        if (result.hasSignup) {
            toast.success(t('login-successfully'), { position: 'bottom-center' });
            navigate('/dashboard');
        } else if (!result.hasSignup) {
            navigate({
                pathname: '/signup',
                search: createSearchParams({ phone: phone || '', otp }).toString(),
            });
        }
    };

    const changeLanguage = () => {
        if (i18n.language === 'en-US') {
            i18n.changeLanguage('vi');
        } else {
            i18n.changeLanguage('en-US');
        }
    };

    const renderReceivedMethodDescription = () => {
        switch (method) {
            case OTPMethod.SMS:
                return t('otp-description-line2');
            case OTPMethod.ZALO:
                return t('otp-description-line2-zalo');
            case OTPMethod.EMAIL:
                return t('otp-description-line2-email');
            default:
                return '';
        }
    };

    return (
        <div className="row w-100 h-100 mx-0 gx-0">
            <div
                className="change-language d-flex justify-content-center clickable px-3 border border-secondary"
                onClick={() => changeLanguage()}
            >
                <img
                    className="language-icon my-auto"
                    src="/image/icon/language.svg"
                    alt="change language"
                />
                <span className="my-auto ms-1">
                    {i18n.language === 'en-US' ? 'Tiếng Việt' : 'English'}
                </span>
            </div>
            <div className="col-12 col-sm-6 h-sm-100">
                <img
                    className="theme-picture"
                    src="/image/picture/pig-introduction.jpg"
                    alt="theme"
                />
            </div>
            <div className="col-12 col-sm-6 mobile-container">
                {step === 0 && (
                    <Form
                        onSubmit={handleSubmit}
                        noValidate={true}
                        validated={validated}
                        className="d-flex flex-column justify-content-md-center align-items-center h-100 tilt-in-right-2"
                    >
                        <div className="d-flex farm-logo-mobile">
                            <img src="/image/icon/smart-farm-logo.svg" alt="smart farm icon" />
                        </div>
                        <div className="d-flex mt-4 hello">{t('hello')}!</div>
                        <div className="d-flex login">{t('login')}</div>
                        <div className="d-flex mt-3 justify-content-center w-100">
                            <Form.Group controlId="validatePhone" className="w-75">
                                <InputGroup hasValidation={true}>
                                    <Form.Control
                                        type="text"
                                        required={true}
                                        placeholder={t('input-your-phone-number-or-email')}
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t('required-field')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </div>
                        <div className="d-flex mt-3 w-100 justify-content-center">
                            <button type="submit" className="btn btn-primary w-75">
                                {t('get-otp')}
                            </button>
                        </div>
                        <div className="d-flex mt-4">{t('first-line-agree')}</div>
                        <div className="d-flex">
                            <span>{t('second-line-agree-part1')}&nbsp;</span>
                            <span className="text-primary clickable">
                                {t('second-line-agree-part2')}&nbsp;
                            </span>
                            <span>{t('second-line-agree-part3')}</span>
                        </div>
                    </Form>
                )}
                {step === 1 && (
                    <Form
                        onSubmit={handleLogin}
                        noValidate={true}
                        validated={otpValidatedFail}
                        className="d-flex flex-column justify-content-center align-items-center h-100 tilt-in-right-2"
                    >
                        <div className="d-flex farm-logo-mobile">
                            <img src="/image/icon/smart-farm-logo.svg" alt="smart farm icon" />
                        </div>
                        <div className="d-flex mt-4 hello">{t('input-OTP')}</div>
                        <div className="d-flex login">{t('otp-description-line1')}</div>
                        <div className="d-flex login">{renderReceivedMethodDescription()}</div>
                        <div className="d-flex mt-3">
                            <span>{t('valid-verification-code-time')}</span>
                            <span className="text-danger">&nbsp;{fmtMSS(timer)}</span>
                        </div>
                        <div className="d-flex justify-content-center w-100">
                            <OtpInput
                                containerStyle={'d-flex'}
                                inputStyle={['otp-box', otpValidatedFail && 'border-danger'].join(
                                    ' '
                                )}
                                value={otp1}
                                onChange={(value: string) => setOTP1(value)}
                                numInputs={6}
                                inputType="number"
                                renderInput={function (inputProps: Required<Pick<InputHTMLAttributes<HTMLInputElement>,
                                    'style' | 'value' | 'onChange' | 'inputMode' | 'aria-label' | 'onPaste' | 'onFocus' | 'onBlur' | 'onInput' | 'onKeyDown' | 'autoComplete' | 'maxLength'>
                                    & { ref: (instance: HTMLInputElement | null) => void; placeholder: string | undefined; className: string | undefined; type: AllowedInputTypes; }>,
                                    index: number): ReactNode {
                                   return <input {...inputProps} />;
                                } }
                            />
                        </div>
                        {otpValidatedFail && (
                            <div className="d-flex swing-in-right-fwd text-danger mt-1">
                                {t('invalid-otp')}
                            </div>
                        )}
                        <div className="d-flex mt-3 w-100 justify-content-center">
                            <button type="submit" id="login" className="btn btn-primary w-75">
                                {t('login')}
                            </button>
                        </div>
                        <div className="d-flex mt-2">
                            <span
                                className="text-primary clickable"
                                onClick={() => sendOtp(method)}
                            >
                                {t('resend-otp')}
                            </span>
                        </div>
                    </Form>
                )}
            </div>
            <PickingSendingType
                disableClose={false}
                show={show}
                onHide={function (result: OTPMethod | null): void {
                    setShow(false);
                    setMethod(result || OTPMethod.SMS);
                    sendOtp(result);
                }}
            />
        </div>
    );
}
