/* eslint-disable react-hooks/exhaustive-deps */
import { format } from 'date-fns';
import FarmService from 'module/client/farm/service/farm.service';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IUser } from 'shared/interface/user.interface';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { subMonths } from 'date-fns';
import { calculateReportRetail } from 'shared/util/bio-report';
import AsyncSelect from 'react-select/async';
import FooterHelp from 'shared/components/footer-help/footer-help';
import { IQuestionSet } from 'shared/interface/question-set.interface';
import { BioReportRetailService } from 'shared/service/bio-report-retail.service';
import { IBioReportRetail, ICalculatedFinalResultRetail } from 'shared/interface/bio-report-retail.interface';
import { QuestionSetRetailService } from 'shared/service/question-set-retail.service';
import ViolatedCompareRetail from './violated-compare-retail';
import ReportDetailCompareRetail from './report-detail-compare';
import ChartCompareRetail from './chart-compare-retail';

export default function BioReportCompareRetail() {
    const { t, i18n } = useTranslation();

    const [questionSets, setQuestionSets] = useState<IQuestionSet[]>([]);
    const [selectedQuestionSet, setSelectedQuestionSet] = useState<string>();

    // const [selectionRange, setSelectionRange] = useState([subMonths(new Date(), 1), new Date()]);
    const [from, setFrom] = useState<Date>(subMonths(new Date().setHours(0, 0, 0, 0), 1));
    const [to, setTo] = useState<Date>(new Date(new Date().setHours(23, 59, 59, 59)));
    const [performers1, setPerformers1] = useState<IUser[]>([]);
    const [reports1, setReports1] = useState<IBioReportRetail[]>([]);
    const [selectFarm1, setSelectFarm1] = useState<{ value: string; label: string }>();
    const [finalResult1, setFinalResult1] = useState<ICalculatedFinalResultRetail>();
    const [selectedPerformer1, setSelectedPerformer1] = useState<IUser>();

    const [performers2, setPerformers2] = useState<IUser[]>([]);
    const [reports2, setReports2] = useState<IBioReportRetail[]>([]);
    const [selectFarm2, setSelectFarm2] = useState<{ value: string; label: string }>();
    const [finalResult2, setFinalResult2] = useState<ICalculatedFinalResultRetail>();
    const [selectedPerformer2, setSelectedPerformer2] = useState<IUser>();

    const [performers3, setPerformers3] = useState<IUser[]>([]);
    const [reports3, setReports3] = useState<IBioReportRetail[]>([]);
    const [selectFarm3, setSelectFarm3] = useState<{ value: string; label: string }>();
    const [finalResult3, setFinalResult3] = useState<ICalculatedFinalResultRetail>();
    const [selectedPerformer3, setSelectedPerformer3] = useState<IUser>();

    const reset = () => {
        setSelectFarm1(undefined);
        setSelectFarm2(undefined);
        setSelectFarm3(undefined);

        setSelectedPerformer1(undefined);
        setSelectedPerformer2(undefined);
        setSelectedPerformer3(undefined);

        setFinalResult1(undefined);
        setFinalResult2(undefined);
        setFinalResult3(undefined);

        setPerformers1([]);
        setPerformers2([]);
        setPerformers3([]);

        setReports1([]);
        setReports2([]);
        setReports3([]);
    };

    const getPerformers1 = async (idFarm: string) => {
        if (!idFarm) {
            return;
        }
        const res = await BioReportRetailService.findPerformers(idFarm, from, to);
        setPerformers1(
            res.map((item) => {
                return item.user;
            })
        );
    };

    const getPerformers2 = async (idFarm: string) => {
        if (!idFarm) {
            return;
        }
        const res = await BioReportRetailService.findPerformers(idFarm, from, to);
        setPerformers2(
            res.map((item) => {
                return item.user;
            })
        );
    };

    const getPerformers3 = async (idFarm: string) => {
        if (!idFarm) {
            return;
        }
        const res = await BioReportRetailService.findPerformers(idFarm, from, to);
        setPerformers3(
            res.map((item) => {
                return item.user;
            })
        );
    };

    const getReport1 = async (idUser: string) => {
        if (!idUser) {
            return;
        }
        const res = await BioReportRetailService.findByFarmPerformer(
            selectFarm1?.value || '',
            idUser,
            from,
            to,
            selectedQuestionSet
        );
        setSelectedPerformer1(performers1.find((p) => p._id === idUser));
        setReports1(res);
    };

    const getReport2 = async (idUser: string) => {
        if (!idUser) {
            return;
        }
        const res = await BioReportRetailService.findByFarmPerformer(
            selectFarm2?.value || '',
            idUser,
            from,
            to,
            selectedQuestionSet
        );
        setSelectedPerformer2(performers2.find((p) => p._id === idUser));
        setReports2(res);
    };

    const getReport3 = async (idUser: string) => {
        if (!idUser) {
            return;
        }
        const res = await BioReportRetailService.findByFarmPerformer(
            selectFarm3?.value || '',
            idUser,
            from,
            to,
            selectedQuestionSet
        );
        setSelectedPerformer3(performers3.find((p) => p._id === idUser));
        setReports3(res);
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<{ label: string; value: string }[]>((resolve) => {
            setTimeout(async () => {
                const res = await FarmService.findMyFarms(1, 999999, inputValue);
                resolve(
                    res.docs.map((u) => {
                        return {
                            label: u.title,
                            value: u._id,
                        };
                    })
                );
            }, 300);
        });

    const getQuestionSets = async () => {
        const res = await QuestionSetRetailService.findAll(1, 9999);
        setQuestionSets(res.docs);
        if (res.docs.length) {
            setSelectedQuestionSet(res.docs[0]._id);
        }
    };

    useEffect(() => {
        getQuestionSets();
    }, []);

    return (
        <div className="container-fluid p-0 p-md-3">
            <div className="row mb-3">
                <div className="col-12 col-md-3">
                    <span className="fs-3">{t('report-compare')} {t('less-than-300-sows-farm')}</span>
                </div>
                <div className="col-12 col-md-3">
                    <div className="input-group flex-nowrap">
                        <span className="input-group-text">{t('from-date')}</span>
                        <DatePicker
                            locale={i18n.language}
                            dateFormat={i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                            className="form-control"
                            selected={from}
                            onChange={(date: Date) => setFrom(date)}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="input-group flex-nowrap">
                        <span className="input-group-text">{t('to-date')}</span>
                        <DatePicker
                            locale={i18n.language}
                            dateFormat={i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                            className="form-control"
                            selected={to}
                            onChange={(date: Date) => setTo(date)}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="input-group flex-nowrap">
                        <Form.Select
                            value={selectedQuestionSet}
                            onChange={(event) => {
                                setSelectedQuestionSet(event.target.value);
                                reset();
                            }}>
                            {questionSets.map((set, index) => (
                                <option key={index} value={set._id}>
                                    {set.title}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-2 my-auto">
                    <i className="fa-solid fa-filter" />
                    <span className="ms-3">{t('report')} 1</span>
                </div>
                <div className="col-12 col-md-3">
                    <AsyncSelect
                        className="w-100"
                        cacheOptions={true}
                        isClearable={true}
                        value={selectFarm1 ? selectFarm1 : undefined}
                        onChange={(newValue) => {
                            setSelectFarm1(
                                newValue
                                    ? {
                                          label: newValue?.label,
                                          value: newValue?.value,
                                      }
                                    : undefined
                            );
                            getPerformers1(newValue ? newValue.value : '');
                        }}
                        defaultOptions={selectFarm1 ? [selectFarm1] : []}
                        loadOptions={promiseOptions}
                        isMulti={false}
                    />
                </div>
                <div className="col-12 col-md-3">
                    <Form.Select
                        onChange={(event) => {
                            getReport1(event.target.value);
                        }}>
                        <option value={undefined}>--- {t('choose-executor')} ---</option>
                        {performers1.map((performer, index) => (
                            <option key={index} value={performer._id}>
                                {performer.name}
                            </option>
                        ))}
                    </Form.Select>
                </div>
                <div className="col-12 col-md-3">
                    <Form.Select
                        onChange={(event) => {
                            const found = reports1.find((i) => i._id === event.target.value);
                            setFinalResult1(calculateReportRetail(found as IBioReportRetail) as any);
                        }}>
                        <option value={undefined}>--- {t('choose-report')} ---</option>
                        {reports1.map((report, index) => (
                            <option key={index} value={report._id}>
                                {format(
                                    new Date(report.updatedAt),
                                    i18n.language === 'vi' ? 'dd/MM/yyyy HH:mm' : 'MM/dd/yyyy HH:mm'
                                )}
                            </option>
                        ))}
                    </Form.Select>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 col-md-2 my-auto">
                    <i className="fa-solid fa-filter" />
                    <span className="ms-3">{t('report')} 2</span>
                </div>
                <div className="col-12 col-md-3">
                    <AsyncSelect
                        className="w-100"
                        cacheOptions={true}
                        isClearable={true}
                        value={selectFarm2 ? selectFarm2 : undefined}
                        onChange={(newValue) => {
                            setSelectFarm2(
                                newValue
                                    ? {
                                          label: newValue?.label,
                                          value: newValue?.value,
                                      }
                                    : undefined
                            );
                            getPerformers2(newValue ? newValue.value : '');
                        }}
                        defaultOptions={selectFarm2 ? [selectFarm2] : []}
                        loadOptions={promiseOptions}
                        isMulti={false}
                    />
                </div>
                <div className="col-12 col-md-3">
                    <Form.Select
                        onChange={(event) => {
                            getReport2(event.target.value);
                        }}>
                        <option value={undefined}>--- {t('choose-executor')} ---</option>
                        {performers2.map((performer, index) => (
                            <option key={index} value={performer._id}>
                                {performer.name}
                            </option>
                        ))}
                    </Form.Select>
                </div>
                <div className="col-12 col-md-3">
                    <Form.Select
                        onChange={(event) => {
                            const found = reports2.find((i) => i._id === event.target.value);
                            setFinalResult2(calculateReportRetail(found as IBioReportRetail) as any);
                        }}>
                        <option value={undefined}>--- {t('choose-report')} ---</option>
                        {reports2.map((report, index) => (
                            <option key={index} value={report._id}>
                                {format(
                                    new Date(report.updatedAt),
                                    i18n.language === 'vi' ? 'dd/MM/yyyy HH:mm' : 'MM/dd/yyyy HH:mm'
                                )}
                            </option>
                        ))}
                    </Form.Select>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 col-md-2 my-auto">
                    <i className="fa-solid fa-filter" />
                    <span className="ms-3">{t('report')} 3</span>
                </div>
                <div className="col-12 col-md-3">
                    <AsyncSelect
                        className="w-100"
                        cacheOptions={true}
                        isClearable={true}
                        value={selectFarm3 ? selectFarm3 : undefined}
                        onChange={(newValue) => {
                            setSelectFarm3(
                                newValue
                                    ? {
                                          label: newValue?.label,
                                          value: newValue?.value,
                                      }
                                    : undefined
                            );
                            getPerformers3(newValue ? newValue.value : '');
                        }}
                        defaultOptions={selectFarm3 ? [selectFarm3] : []}
                        loadOptions={promiseOptions}
                        isMulti={false}
                    />
                </div>
                <div className="col-12 col-md-3">
                    <Form.Select
                        onChange={(event) => {
                            getReport3(event.target.value);
                        }}>
                        <option value={undefined}>--- {t('choose-executor')} ---</option>
                        {performers3.map((performer, index) => (
                            <option key={index} value={performer._id}>
                                {performer.name}
                            </option>
                        ))}
                    </Form.Select>
                </div>
                <div className="col-12 col-md-3">
                    <Form.Select
                        onChange={(event) => {
                            const found = reports3.find((i) => i._id === event.target.value);
                            setFinalResult3(calculateReportRetail(found as IBioReportRetail) as any);
                        }}>
                        <option value={undefined}>--- {t('choose-report')} ---</option>
                        {reports3.map((report, index) => (
                            <option key={index} value={report._id}>
                                {format(
                                    new Date(report.updatedAt),
                                    i18n.language === 'vi' ? 'dd/MM/yyyy HH:mm' : 'MM/dd/yyyy HH:mm'
                                )}
                            </option>
                        ))}
                    </Form.Select>
                </div>
            </div>
            <ChartCompareRetail
                finalResult1={finalResult1}
                finalResult2={finalResult2}
                finalResult3={finalResult3}
                performer1={selectedPerformer1}
                performer2={selectedPerformer2}
                performer3={selectedPerformer3}
            />
            <ViolatedCompareRetail
                finalResult1={finalResult1}
                finalResult2={finalResult2}
                finalResult3={finalResult3}
                performer1={selectedPerformer1}
                performer2={selectedPerformer2}
                performer3={selectedPerformer3}
            />
            <ReportDetailCompareRetail
                finalResult1={finalResult1}
                finalResult2={finalResult2}
                finalResult3={finalResult3}
                performer1={selectedPerformer1}
                performer2={selectedPerformer2}
                performer3={selectedPerformer3}
            />

            <div className="d-flex">
                <FooterHelp />
            </div>
        </div>
    );
}
