import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../partition.scss';
import { iconPlus } from 'shared/assets/icons';
import AsyncSelect from 'react-select/async';
import { IUser } from 'shared/interface/user.interface';
import UserService from 'shared/service/user.service';
import { RoleName } from 'shared/enum/role-name.enum';

interface IProps {
    team: string;
    listOfSaleHasTeam: string[];
    onSave?(user: IUser): void;
}

function AddMore(props: IProps) {
    const { onSave, listOfSaleHasTeam } = props;
    const { t } = useTranslation();
    const [adding, setAdding] = useState<boolean>(false);
    const [user, setUser] = useState<{ label: string; value: IUser } | undefined>();

    const promiseOptions = (inputValue: string) =>
        new Promise<{ label: string; value: IUser }[]>((resolve) => {
            setTimeout(async () => {
                const res = await UserService.search(inputValue, RoleName.SALE);
                const rawList = res.map((u) => {
                    return {
                        label: u.name,
                        value: u,
                    };
                });
                const filterList = rawList.filter((sale) => {
                    if (listOfSaleHasTeam.includes(sale.value._id)) {
                        return false;
                    }
                    return true;
                });

                resolve(filterList);
            }, 300);
        });

    const onAddMember = async () => {
        if (onSave && user) {
            onSave(user.value);
            setAdding(false);
        }
    };

    return (
        <div className="d-flex px-3 py-2 w-100 mt-2 rounded clickable border-dash align-items-center justify-content-center">
            {!adding ? (
                <div className="w-100" onClick={() => setAdding(!adding)}>
                    <img alt="" src={iconPlus} />
                    <span className="fs-6" style={{ color: '#638C1C', fontFamily: 'Inter' }}>
                        {t('add-new-member')}
                    </span>
                </div>
            ) : (
                <div className="w-100 d-flex flex-column">
                    <AsyncSelect
                        className="w-100"
                        cacheOptions={true}
                        value={user ? user : undefined}
                        onChange={(newValue) => setUser(newValue ? newValue : undefined)}
                        loadOptions={promiseOptions}
                        isMulti={false}
                        isClearable={true}
                    />
                    <div className="w-100 d-flex mt-2">
                        <button
                            className="me-1 btn btn-outline-primary col-6"
                            onClick={() => setAdding(false)}
                        >
                            {t('cancel')}
                        </button>
                        <button
                            className="ms-1 btn btn-primary col-6"
                            onClick={() => onAddMember()}
                        >
                            {t('save')}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AddMore;
