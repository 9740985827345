import { createContext, useContext, useState } from 'react';
import { ActionPermission } from 'shared/enum/action-permission.enum';

interface IAuthContext {
    hasToken: boolean;
    setHasToken: (hasToken: boolean) => void;
    disableNavBar: boolean;
    setDisableNavBar: (disable: boolean) => void;
    loading: boolean;
    setLoading: (load: boolean) => void;
    actionPermission: ActionPermission[];
    setActionPermission: (action: ActionPermission[]) => void;
}

const AuthContext = createContext<IAuthContext>(null!);

export const AuthProvider = ({ children }: any) => {
    const [hasToken, setHasToken] = useState<boolean>(false);
    const [disableNavBar, setDisableNavBar] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [actionPermission, setActionPermission] = useState<ActionPermission[]>([]);
    const value = {
        hasToken,
        setHasToken,
        disableNavBar,
        setDisableNavBar,
        loading,
        setLoading,
        actionPermission,
        setActionPermission,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
