import { useTranslation } from 'react-i18next';
import { iconDown } from 'shared/assets/icons';

interface IProps {
    onClick?(): void;
}

export default function LoadMore(props: IProps) {
    const { onClick } = props;
    const { t } = useTranslation();
    return (
        <div
            className="d-flex align-items-center border py-2 px-3 rounded-pill clickable"
            onClick={() => onClick && onClick()}
        >
            <div className="fw-normal fs-6 me-2" style={{ color: '#62666C' }}>
                {t('load-more')}
            </div>
            <img alt="" src={iconDown} />
        </div>
    );
}
