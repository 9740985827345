import { AutomationImportDto } from 'shared/interface/automation-import.interface';
import api from './api';

export const AutomationImportService = {
    prefix: 'automation-import',
    import: async (
        dto: AutomationImportDto,
        file: File
    ): Promise<{ result: boolean; time: number }> => {
        const formData = new FormData();
        if (file) {
            formData.append('file', file as any);
        }
        formData.append('address', String(dto.address));
        formData.append('companyName', String(dto.companyName));
        formData.append('email', String(dto.email));
        formData.append('nameOfUser', String(dto.nameOfUser));
        const res = await api.post(`${AutomationImportService.prefix}/import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return res.data;
    },
};
