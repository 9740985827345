export interface IProp {
    active?: boolean;
    activeColor?: string;
    defaultColor?: string;
}

const IconGoToAdmin = (props: IProp) => {
    const { active = false, activeColor, defaultColor } = props;
    const fill = active ? activeColor ?? '#638C1C' : defaultColor || '#474C55';
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.064 7.12811L15.912 6.91211C15.768 6.48011 15.624 6.04811 15.336 5.61611L16.056 4.68011C16.272 4.39211 16.2 4.03211 15.984 3.74411L14.328 2.08811C14.112 1.87211 13.68 1.80011 13.392 2.01611L12.456 2.66411C12.024 2.44811 11.52 2.23211 11.016 2.08811L10.8 0.936107C10.728 0.576107 10.44 0.360107 10.08 0.360107H7.70398C7.34399 0.360107 7.05598 0.648107 6.98398 0.936107L6.83998 2.08811C6.40799 2.16011 5.90399 2.37611 5.47198 2.66411L4.60799 2.01611C4.31999 1.80011 3.95999 1.80011 3.67199 2.08811L2.01599 3.74411C1.79999 3.96011 1.72799 4.39211 1.94399 4.68011L2.59199 5.54411C2.30399 5.97611 2.15999 6.48011 2.01599 6.98411L0.935985 7.12811C0.575985 7.20011 0.359985 7.48811 0.359985 7.84811V10.2241C0.359985 10.5841 0.647985 10.8721 0.935985 10.9441L2.01599 11.0881C2.15999 11.5921 2.37599 12.0241 2.59199 12.5281L2.01599 13.3921C1.79999 13.6801 1.79999 14.0401 2.08799 14.3281L3.74399 15.9841C3.95999 16.2001 4.39198 16.2721 4.67999 16.0561L5.54398 15.4081C5.97599 15.6241 6.40799 15.8401 6.83998 15.9841L6.98398 17.0641C7.05598 17.4241 7.34399 17.6401 7.70398 17.6401H10.08C10.44 17.6401 10.728 17.3521 10.8 17.0641L10.944 15.9841C11.448 15.8401 11.88 15.6241 12.312 15.4081L13.248 16.0561C13.536 16.2721 13.968 16.2001 14.184 15.9841L15.84 14.3281C16.056 14.1121 16.128 13.6801 15.912 13.3921L15.264 12.4561C15.48 12.0241 15.696 11.5921 15.84 11.1601L16.992 10.9441C17.352 10.8721 17.568 10.5841 17.568 10.2241V7.84811C17.64 7.48811 17.352 7.20011 17.064 7.12811ZM13.176 12.3841C12.528 11.5921 11.736 11.0161 10.728 10.6561C10.584 10.5841 10.44 10.6561 10.368 10.6561C9.93598 10.8001 9.50399 10.9441 9.07199 10.9441C8.63999 10.9441 8.13599 10.8721 7.77599 10.6561C7.63199 10.5841 7.48799 10.5841 7.41599 10.6561C6.40799 11.0161 5.54399 11.5921 4.96799 12.3841C4.24799 11.4481 3.81599 10.2961 3.81599 9.07211C3.81599 6.12011 6.19199 3.67211 9.21599 3.67211C12.168 3.67211 14.544 6.04811 14.544 9.07211C14.328 10.2961 13.896 11.4481 13.176 12.3841Z"
                fill={fill}
            />
            <path
                d="M8.99997 5.47217C7.77597 5.47217 6.83997 6.48017 6.83997 7.63217C6.83997 8.85617 7.77597 9.79217 8.99997 9.79217C10.152 9.79217 11.16 8.85617 11.16 7.63217C11.16 6.40817 10.224 5.47217 8.99997 5.47217Z"
                fill={fill}
            />
        </svg>
    );
};

export default IconGoToAdmin;
