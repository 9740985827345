import { createContext, useContext, useState } from 'react';
import { ITokenPayload } from 'shared/interface/auth.interface';

interface IAppContext {
    user: ITokenPayload | undefined;
    setUser: (user: ITokenPayload) => void;
    requests: number;
    setRequests: (requests: number) => void;
    pendingUsers: number;
    setPendingUsers: (requests: number) => void;
}

const AppContext = createContext<IAppContext>(null!);

export const AppProvider = ({ children }: any) => {
    const [user, setUser] = useState<ITokenPayload>();
    const [requests, setRequests] = useState<number>(0);
    const [pendingUsers, setPendingUsers] = useState<number>(0);
    const value = {
        user,
        setUser,
        requests,
        setRequests,
        pendingUsers,
        setPendingUsers,
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
