/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { IRuntimeSetting } from 'shared/interface/runtime-setting.interface';
import RuntimeService from './service/runtime-setting.service';

interface IProp {
    show: boolean;
    runtimeSetting?: IRuntimeSetting;
    onHide: (data: IRuntimeSetting | null) => void;
}

export default function EditRuntimeSetting(prop: IProp) {
    const { show, runtimeSetting } = prop;
    const { t } = useTranslation();
    const [validated, setValidated] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [value, setValue] = useState<string>('');
    const [keys, setKeys] = useState<string[]>([]);

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity()) {
            if (runtimeSetting) {
                const res = await RuntimeService.update(runtimeSetting._id, { name, value });
                if (res) {
                    toast.success(t('successfully'), { position: 'bottom-center' });
                    prop.onHide(res);
                }
            } else {
                const res = await RuntimeService.create({ name, value });
                if (res) {
                    toast.success(t('successfully'), { position: 'bottom-center' });
                    prop.onHide(res);
                }
            }
        }
    };

    const reset = () => {
        setName(runtimeSetting ? runtimeSetting.name : '');
        setValue(runtimeSetting ? runtimeSetting.value : '');
    };

    const getKeys = async () => {
        const res = await RuntimeService.getKeys();
        setKeys(res);
    };

    useEffect(() => {
        getKeys();
        reset();
    }, [runtimeSetting]);

    return (
        <Modal show={show} dialogClassName="max-width-800" fullscreen="sm-down">
            <Modal.Header className="flex-column">
                <div className="d-flex justify-content-end w-100">
                    <button
                        className="btn btn-icon border-0 rounded-circle"
                        onClick={() => {
                            prop.onHide(null);
                        }}
                    >
                        <i className="fa-solid fa-xmark text-primary"/>
                    </button>
                </div>
                <div className="d-flex justify-content-center">
                    <span className="fs-3 fw-semibold">
                        {runtimeSetting ? t('edit-info') : t('create-runtime-setting')}
                    </span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} noValidate={true} validated={validated}>
                    <div className="row">
                        <Form.Group as={Col} sm="12">
                            <Form.Label>{t('key')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Select
                                    className="text-gray"
                                    required={true}
                                    placeholder={t('key')}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                >
                                    {keys.map((key, index) => (
                                        <option key={index} value={key}>
                                            {key}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {t('required-field')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="row">
                        <Form.Group as={Col} sm="12">
                            <Form.Label>{t('value')}</Form.Label>
                            <InputGroup hasValidation={true}>
                                <Form.Control
                                    type="text"
                                    as="textarea"
                                    className="text-gray"
                                    required={true}
                                    placeholder={t('value')}
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('required-field')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <button type="submit" className="btn btn-primary w-50">
                            {t('finish')}
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
