import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { iconClose } from 'shared/assets/icons';
import { Col, Form, InputGroup } from 'react-bootstrap';
import UserService from 'shared/service/user.service';
import LoadingSpinner from 'shared/components/loading-spinner/loading-spinner';
import AsyncSelect from 'react-select/async';
import PartitionService from '../service/partition.service';
import { ITeam } from 'shared/interface/partition.interface';
import { RoleName } from 'shared/enum/role-name.enum';

interface IProps {
    show: boolean;
    handleClose(): void;
    onAddTeam(team: ITeam): void;
}

function CreateTeamModal(props: IProps) {
    const { handleClose, show = false, onAddTeam } = props;
    const { t } = useTranslation();
    const [validated, setValidated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [incharge, setIncharge] = useState<{ value: string; label: string }>();

    const promiseOptions = (inputValue: string) =>
        new Promise<{ label: string; value: string }[]>((resolve) => {
            setTimeout(async () => {
                // note: will map sale manager later
                const res = await UserService.search(inputValue, RoleName.SALE_MANAGER);
                resolve(
                    res.map((u) => {
                        return {
                            label: u.name,
                            value: u._id,
                        };
                    })
                );
            }, 300);
        });

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setLoading(true);
        if (incharge) {
            // @ts-ignore
            window.gtag('event', 'CREATE_PARTITION', {});
            const result = await PartitionService.create({
                owningUser: incharge.value,
                sales: [],
            });
            if (result) {
                onAddTeam(result);
                handleClose();
            }
        }
        setValidated(true);
        setLoading(false);
    };

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="farm-modal-custom">
            <Modal.Header className="w-100">
                <div className="d-flex justify-content-center w-100 position-relative">
                    <span className="farm-modal-title mt-4">{t('add-new-team')}</span>
                    <img
                        role="button"
                        onClick={() => handleClose()}
                        alt=""
                        src={iconClose}
                        className="position-absolute top-0 end-0"
                    />
                </div>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <Form
                    onSubmit={handleSubmit}
                    noValidate={true}
                    validated={validated}
                    className="w-100 d-flex justify-content-center flex-column align-items-center"
                >
                    <div className="d-flex flex-column w-100" style={{ maxWidth: 600 }}>
                        <div className="d-flex py-3 w-100 justify-content-center flex-column">
                            <Form.Group as={Col} sm="12" className="w-100 mt-3">
                                <Form.Label className="fw-semibold" style={{ color: '#474C55' }}>
                                    {t('sale-manager')} {t('search-hint')}
                                </Form.Label>
                                <InputGroup hasValidation={true}>
                                    <InputGroup hasValidation={true}>
                                        <AsyncSelect
                                            className="w-100"
                                            cacheOptions={true}
                                            isClearable={true}
                                            value={incharge ? incharge : undefined}
                                            onChange={(newValue) =>
                                                setIncharge(
                                                    newValue
                                                        ? {
                                                              label: newValue?.label,
                                                              value: newValue?.value,
                                                          }
                                                        : undefined
                                                )
                                            }
                                            defaultOptions={incharge ? [incharge] : []}
                                            loadOptions={promiseOptions}
                                            isMulti={false}
                                        />
                                        {!incharge && validated && (
                                            <div className="invalid-email-block">
                                                {t('required-field')}
                                            </div>
                                        )}
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        {t('required-field')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </div>
                    </div>
                    <div
                        className="d-flex justify-content-center w-100 mb-5"
                        style={{ maxWidth: 600 }}
                    >
                        <button className="btn btn-primary w-100" type="submit">
                            {t('save')}
                        </button>
                    </div>
                </Form>
            </Modal.Body>
            <LoadingSpinner loading={loading} />
        </Modal>
    );
}

export default CreateTeamModal;
