import { toast } from 'react-toastify';
import { RoleName } from 'shared/enum/role-name.enum';
import { IUser, IUserPagination } from 'shared/interface/user.interface';
import api from 'shared/service/api';

const url = '/user';
export default class UserService {
    static async getUserInfo(id: string): Promise<IUser> {
        try {
            const res = await api.get(`${url}/${id}`);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async createUser(data: any): Promise<IUser> {
        try {
            const res = await api.post(url, data);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async updateUserInfo(id: string, data: any): Promise<IUser> {
        try {
            const res = await api.patch(`${url}/${id}`, data);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async updateUserAvatar(id: string, avatar: File): Promise<any> {
        try {
            const formData = new FormData();
            formData.append('file', avatar);
            const res = await api.patch(`${url}/${id}/avatar`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async findAll(
        page: number,
        limit: number,
        allowAccessProgram: boolean = false,
        deleted: boolean = false,
        keyword?: string,
        owningCompany?: string,
        roleName?: string,
        cargillUserCode?: string
    ): Promise<IUserPagination> {
        try {
            const res = await api.get(`${url}`, {
                params: {
                    page,
                    limit,
                    allowAccessProgram,
                    deleted,
                    keyword,
                    owningCompany,
                    roleName: roleName !== RoleName.NONE ? roleName : null,
                    cargillUserCode: cargillUserCode !== '' ? cargillUserCode : null,
                },
            });
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async setAllowAccessProgram(
        id: string,
        allowAccessProgram: boolean = true,
        deleted: boolean = false
    ): Promise<IUser> {
        try {
            const res = await api.patch(`${url}/${id}/set-allow-access`, {
                allowAccessProgram,
                deleted,
            });
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async search(keyword: string, roleName?: string): Promise<IUser[]> {
        try {
            const params = {
                keyword,
            };
            if (roleName) {
                params['roleName'] = roleName;
            }
            const res = await api.get(`${url}/search`, {
                params,
            });
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async searchNotRoleAdmin(keyword: string, roleName?: string): Promise<IUser[]> {
        try {
            const params = {
                keyword,
            };
            if (roleName) {
                params['roleName'] = roleName;
            }
            const res = await api.get(`${url}/search/not-role-admin`, {
                params,
            });
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async block(id: string): Promise<IUser> {
        try {
            const res = await api.patch(`${url}/${id}/block`);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async unblock(id: string): Promise<IUser> {
        try {
            const res = await api.patch(`${url}/${id}/unblock`);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async delete(id: string): Promise<IUser> {
        try {
            const res = await api.delete(`${url}/${id}/hard`);
            return res.data;
        } catch (error: any) {
            toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }
}
