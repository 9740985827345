/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import './dashboard.scss';
import { iconClock, iconPencil } from 'shared/assets/icons';
import TopicService from '../topic/service/topic.service';
import { ITopic } from 'shared/interface/topic.interface';
import { useTranslation } from 'react-i18next';
import { buildFileView } from 'shared/service/file';
import { format, subMonths } from 'date-fns';
import i18n from 'i18n';
import { useAppContext } from 'module/auth/context';
import QuestionPickFarm from '../question/pick-farm/question-pick-farm';
import { EditUserInfoModal } from './components';
import { getWindowDimensions } from 'shared/util/window-dimensions';
import { useNavigate } from 'react-router-dom';
import FooterHelp from 'shared/components/footer-help/footer-help';
import DatePicker, { registerLocale } from 'react-datepicker';
import enUS from 'date-fns/locale/en-US';
import vi from 'date-fns/locale/vi';
import ReportListDashboardAdmin, {
    ClientDashboardAnalyticFilter,
} from './components/report-list-dashboard-admin';
import ClientDashboardService from './service/client-dashboard.service';
import { IDashboardReport } from 'shared/interface/bio-report.interface';
import ReportCountCalculate from './components/report-count-calculate';
import ReportAnalysis from './components/report-analysis';
import ReportQuestionAnalysis from './components/report-question-analysis';
import { Collapse } from 'react-bootstrap';

registerLocale('en-US', enUS);
registerLocale('vi', vi);

export default function Dashboard() {
    const { t } = useTranslation();
    const appContext = useAppContext();
    const navigate = useNavigate();
    const [topics, setTopics] = useState<ITopic[]>([]);
    const [show, setShow] = useState<boolean>(false);
    const [showEdituserInfo, setShowEditUserInfo] = useState<boolean>(false);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [from, setFrom] = useState<Date>(subMonths(new Date(), 1));
    const [to, setTo] = useState<Date>(new Date());
    const [showReportList, setShowReportList] = useState<boolean>(false);
    const [analyticFilter, setAnalyticFilter] = useState<ClientDashboardAnalyticFilter>({
        from,
        to,
    });
    const [dashboardReport, setDashboardReport] = useState<IDashboardReport>();
    const [openFilter, setOpenFilter] = useState<boolean>(false);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getTopics = async () => {
        const resultTopics = await TopicService.find(1, 5);
        setTopics(resultTopics.docs);
    };

    const getDashboard = useCallback(async () => {
        const res = await ClientDashboardService.findAll(from, to);
        setDashboardReport(res);
    }, [from, to]);

    useEffect(() => {
        // @ts-ignore
        window.gtag('event', 'GO_TO_DASHBOARD', { name: appContext.user?.name });
        getTopics();
        getDashboard();
    }, []);

    return (
        <>
            {windowDimensions.width >= 576 ? (
                <>
                    <div className="row g-0 w-100 d-flex">
                        <div className="col-12 col-sm-8 p-3">
                            <div className="bg-white p-3 div-shadow">
                                <div className="d-flex">
                                    <img
                                        style={{ height: 30, width: 'auto' }}
                                        src="/image/icon/smart-farm-logo.svg"
                                        alt="smart farm icon"
                                    />
                                </div>
                                <div className="d-flex flex-column mt-2">
                                    <span className="fs-5" style={{ fontWeight: 500 }}>
                                        {t('explain-title-line1')} {t('explain-title-line2')}
                                    </span>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-6">
                                        <button
                                            className="btn btn-1 w-100 py-3 h-100"
                                            onClick={() => navigate('/farms')}
                                        >
                                            <div className="d-flex justify-content-center align-items-center gap-3">
                                                <img
                                                    style={{ width: 45, height: 40 }}
                                                    alt=""
                                                    src="/image/icon/pig-btn.svg"
                                                />
                                                <span className="text-white fw-bold fs-5">
                                                    {t('my-farms')}
                                                </span>
                                            </div>
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        <button
                                            className="btn btn-2 w-100 py-3 h-100"
                                            onClick={() => navigate('/evaluate')}
                                        >
                                            <div className="d-flex justify-content-center align-items-center gap-3">
                                                <img
                                                    style={{ width: 40, height: 40 }}
                                                    alt=""
                                                    src="/image/icon/evaluate-btn.svg"
                                                />
                                                <span className="text-white fw-bold fs-5">
                                                    {t('please-do-evaluation')}
                                                </span>
                                            </div>
                                        </button>
                                    </div>
                                    <div className="col-6 mt-4">
                                        <button
                                            className="btn btn-3 w-100 py-3 h-100"
                                            onClick={() => navigate('/bio-report')}
                                        >
                                            <div className="d-flex justify-content-center align-items-center gap-3">
                                                <img
                                                    style={{ width: 40, height: 40 }}
                                                    alt=""
                                                    src="/image/icon/report-detail-btn.svg"
                                                />
                                                <span className="text-white fw-bold fs-5">
                                                    {t('report-detail')}
                                                </span>
                                            </div>
                                        </button>
                                    </div>
                                    <div className="col-6 mt-4">
                                        <button
                                            className="btn btn-4 w-100 py-3 h-100"
                                            onClick={() => navigate('/bio-report-compare')}
                                        >
                                            <div className="d-flex justify-content-center align-items-center gap-3">
                                                <img
                                                    style={{ width: 40, height: 40 }}
                                                    alt=""
                                                    src="/image/icon/evaluate-btn.svg"
                                                />
                                                <span className="text-white fw-bold fs-5">
                                                    {t('report-compare')}
                                                </span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 p-3 d-table-cell">
                            <div className="d-flex">
                                <span className="title">{t('information')}</span>
                            </div>
                            <div
                                className="d-flex flex-column mt-3 align-items-center justify-content-center p-3 position-relative bg-secondary div-shadow"
                                style={{ height: '86%' }}
                            >
                                <div
                                    className="position-absolute clickable top-0 end-0 pe-1 mt-1"
                                    onClick={() => {
                                        setShowEditUserInfo(true);
                                    }}
                                >
                                    <img alt="" src={iconPencil} />
                                </div>
                                <div className="d-flex col-12 align-items-center justify-content-center p-1">
                                    <img
                                        alt=""
                                        className="rounded-circle avatar-user"
                                        src={buildFileView(appContext.user?.avatar)}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = '/image/picture/default.jpeg';
                                        }}
                                    />
                                </div>
                                <div className="d-flex col-12 align-items-center justify-content-center p-1 mt-2">
                                    <span className="user-name">{appContext.user?.name}</span>
                                </div>
                                <div className="d-flex col-12 align-items-center justify-content-center p-1">
                                    <span className="text-primary fw-bold">
                                        {t(appContext.user?.roleName || '')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 px-3 g-0 border border-white">
                        <div className="col-12 introduction-div border border-white div-shadow">
                            <div
                                className="d-flex flex-column justify-content-center bg-white py-3 introduction-border px-3"
                                style={{ minHeight: 250 }}
                            >
                                <span className="text-primary fs-5 fw-bold text-start">
                                    {t('introduction-about-smart-farm')}
                                </span>
                                <span className="text-secondary mt-2">
                                    {t('introduction-description')}
                                </span>
                                <span className="text-primary clickable mt-2">
                                    {t('view-detail')}
                                    <i className="fa-solid fa-angle-right ms-2"/>
                                </span>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="row bg-cargill-gray">
                    <div className="p-3">
                        <div className="div-shadow p-3 rounded-1 d-flex justify-content-between bg-white">
                            <div className="d-flex">
                                <div>
                                    <img
                                        alt=""
                                        className="rounded-circle avatar-user me-3"
                                        style={{ width: 40, height: 40 }}
                                        src={buildFileView(appContext.user?.avatar)}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = '/image/picture/default.jpeg';
                                        }}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <span>{t('hello')}</span>
                                    <span className="fw-bold">{appContext.user?.name}!</span>
                                </div>
                            </div>
                            <div className="my-auto">
                                <img
                                    alt=""
                                    src={iconPencil}
                                    className="clickable"
                                    onClick={() => {
                                        setShowEditUserInfo(true);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-3">
                        <div className="d-flex">
                            <img
                                style={{ height: 30, width: 'auto' }}
                                src="/image/icon/smart-farm-logo.svg"
                                alt="smart farm icon"
                            />
                        </div>
                        <div className="d-flex flex-column mt-2">
                            <span className="fs-5" style={{ fontWeight: 500 }}>
                                {t('explain-title-line1')} {t('explain-title-line2')}
                            </span>
                        </div>
                        <div className="row mt-4">
                            <div className="col-6">
                                <button
                                    className="btn btn-1 w-100 py-3 h-100"
                                    onClick={() => navigate('/farms')}
                                >
                                    <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                                        <img
                                            style={{ width: 45, height: 40 }}
                                            alt=""
                                            src="/image/icon/pig-btn.svg"
                                        />
                                        <span className="text-white">{t('my-farms')}</span>
                                    </div>
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    className="btn btn-2 w-100 py-3 h-100"
                                    onClick={() => navigate('/evaluate')}
                                >
                                    <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                                        <img
                                            style={{ width: 40, height: 40 }}
                                            alt=""
                                            src="/image/icon/evaluate-btn.svg"
                                        />
                                        <span className="text-white">
                                            {t('please-do-evaluation')}
                                        </span>
                                    </div>
                                </button>
                            </div>
                            <div className="col-6 mt-4">
                                <button
                                    className="btn btn-3 w-100 py-3 h-100"
                                    onClick={() => navigate('/bio-report')}
                                >
                                    <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                                        <img
                                            style={{ width: 40, height: 40 }}
                                            alt=""
                                            src="/image/icon/report-detail-btn.svg"
                                        />
                                        <span className="text-white">{t('report-detail')}</span>
                                    </div>
                                </button>
                            </div>
                            <div className="col-6 mt-4">
                                <button
                                    className="btn btn-4 w-100 py-3 h-100"
                                    onClick={() => navigate('/bio-report-compare')}
                                >
                                    <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                                        <img
                                            style={{ width: 40, height: 40 }}
                                            alt=""
                                            src="/image/icon/evaluate-btn.svg"
                                        />
                                        <span className="text-white">{t('report-compare')}</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className="row mt-4 g-0 border border-white">
                            <div className="col-12 introduction-div border g-0">
                                <div className="d-flex flex-column bg-white py-3 introduction-border ps-1">
                                    <span className="text-primary fs-5 fw-bold">
                                        {t('introduction-about-smart-farm')}
                                    </span>
                                    <span className="text-secondary">
                                        {t('introduction-description')}
                                    </span>
                                    <span className="text-primary clickable">
                                        {t('view-detail')}
                                        <i className="fa-solid fa-angle-right ms-2"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {dashboardReport && (
                <div className="container-fluid mt-3 p-3">
                    <h3>{t('report')}</h3>
                    <div className="div-shadow rounded p-3">
                        <button
                            className="btn btn-outline-secondary w-100 border-0"
                            onClick={() => setOpenFilter(!openFilter)}
                        >
                            <div className="d-flex justify-content-between">
                                <i className="fa-solid fa-filter my-auto"/>
                                <span className="fw-bold">{t('filter')}</span>
                                <i className="fa-solid fa-circle-arrow-down my-auto"/>
                            </div>
                        </button>
                        <Collapse in={openFilter} className="py-2">
                            <div className="row my-3">
                                <div className="col-12 col-sm-4">
                                    <div className="input-group flex-nowrap">
                                        <span className="input-group-text">{t('from-date')}</span>
                                        <DatePicker
                                            locale={i18n.language}
                                            dateFormat={
                                                i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                            }
                                            className="form-control"
                                            selected={from}
                                            onChange={(date: Date) => setFrom(date)}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-sm-4">
                                    <div className="input-group flex-nowrap">
                                        <span className="input-group-text">{t('to-date')}</span>
                                        <DatePicker
                                            locale={i18n.language}
                                            dateFormat={
                                                i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                            }
                                            className="form-control"
                                            selected={to}
                                            onChange={(date: Date) => setTo(date)}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end mb-2">
                                    <div>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                                // @ts-ignore
                                                window.gtag(
                                                    'event',
                                                    'SEARCH_IN_DASHBOARD_ADMIN',
                                                    {}
                                                );
                                                getDashboard();
                                            }}
                                        >
                                            {t('search')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                    <div className="row g-0">
                        <div className="col-12 col-sm-6 div-shadow p-2 rounded">
                            <ReportAnalysis
                                dashboardReport={dashboardReport}
                                setReportWithAnalyticFilter={(filter) => {
                                    setAnalyticFilter({
                                        ...filter,
                                        from,
                                        to,
                                    });
                                    setTimeout(() => {
                                        setShowReportList(true);
                                    }, 300);
                                }}
                            />
                        </div>
                        <div className="col-12 col-sm-6 div-shadow p-2 rounded">
                            <ReportCountCalculate
                                dashboardReport={dashboardReport}
                            />
                        </div>
                    </div>
                    <div className="row g-0 mt-3">
                        <div className="col-12 div-shadow p-2 rounded">
                            <ReportQuestionAnalysis
                                dashboardReport={dashboardReport}
                                setReportWithAnalyticFilter={(filter) => {
                                    setAnalyticFilter({
                                        ...filter,
                                        from,
                                        to,
                                    });
                                    setTimeout(() => {
                                        setShowReportList(true);
                                    }, 300);
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className="d-flex justify-content-between mt-4 bg-white pt-3 px-2">
                <span className="title ms-2">{t('news')}</span>
                <span
                    className="fs-6 ms-2 clickable link-secondary"
                    style={{ color: '#62666C' }}
                    onClick={() => navigate('/news')}
                >
                    {t('view-all')}
                </span>
            </div>
            <div className="d-flex w-100 gap-3 my-2 overflow-auto bg-white px-3">
                {topics.map((topic, index) => {
                    return (
                        <div
                            key={index}
                            style={{
                                minWidth: windowDimensions.width >= 576 ? '30%' : '80%',
                            }}
                        >
                            <div
                                className="d-flex flex-column clickable"
                                onClick={() => navigate(`/news/${topic._id}`)}
                            >
                                <div className="col-12">
                                    <img
                                        alt=""
                                        className="xs-picture"
                                        src={buildFileView(topic?.image)}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = '/image/picture/default.jpeg';
                                        }}
                                    />
                                </div>
                                <div className="col-12 px-2 py-2 d-flex flex-column justify-content-between border rounded-bottom h-100">
                                    <div>
                                        <div className="row">
                                            <span className="main-news-title xs-news-title">
                                                {topic?.title}
                                            </span>
                                        </div>
                                        <div className="row">
                                            <span className="main-news-detail xs-news-detail">
                                                {topic?.previewContent}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="me-1">
                                            <img
                                                alt="clock"
                                                style={{
                                                    width: '1.25rem',
                                                    height: '1.25rem',
                                                }}
                                                src={iconClock}
                                            />
                                        </div>
                                        <div>
                                            <span className="create-at">
                                                {' '}
                                                {topic &&
                                                    format(
                                                        new Date(topic?.createdAt),
                                                        i18n.language === 'vi'
                                                            ? 'dd/MM/yyyy'
                                                            : 'MM/dd/yyyy'
                                                    )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="d-flex">
                <FooterHelp />
            </div>
            <QuestionPickFarm
                show={show}
                onHide={function (result: string | null): void {
                    setShow(false);
                }}
            />
            <EditUserInfoModal
                show={showEdituserInfo}
                handleClose={() => setShowEditUserInfo(false)}
            />
            <ReportListDashboardAdmin
                show={showReportList}
                onHide={() => setShowReportList(false)}
                filter={analyticFilter}
            />
        </>
    );
}
