import { RiskLevel } from 'shared/enum/bio-report.enum';
import { IBioReport, IDashboardReport } from 'shared/interface/bio-report.interface';
import api from 'shared/service/api';

const url = 'client-dashboard-report';

export default class ClientDashboardService {
    static async findAll(from: Date, to: Date, owningUser?: string): Promise<IDashboardReport> {
        const params = {};
        if (owningUser) {
            params['owningUser'] = owningUser;
        }
        const response = await api.get(url, {
            params: {
                from: from.toISOString(),
                to: to.toISOString(),
                ...params,
            },
        });
        return response.data;
    }

    static async findWithAnalytic(
        from: Date,
        to: Date,
        owningUser?: string,
        questionId?: string,
        answer?: boolean,
        riskLevel?: RiskLevel,
        position?: number
    ): Promise<IBioReport[]> {
        const params = {};
        if (owningUser && owningUser.length) {
            params['owningUser'] = owningUser;
        }
        if (questionId && answer !== undefined) {
            params['questionId'] = questionId;
            params['answer'] = answer;
        }
        if (riskLevel && position) {
            params['riskLevel'] = riskLevel;
            params['position'] = position;
        }
        const response = await api.get(`${url}/with-analytic`, {
            params: {
                from: from.toISOString(),
                to: to.toISOString(),
                ...params,
            },
        });
        return response.data;
    }

    static async getExcel(from: Date, to: Date, owningUser?: string) {
        const params = {};
        if (owningUser) {
            params['owningUsers'] = owningUser;
        }
        const response = await api.get(`${url}/excel`, {
            params: {
                from: from.toISOString(),
                to: to.toISOString(),
                ...params,
            },
            responseType: 'blob',
        });
        return response.data;
    }
}
