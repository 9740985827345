export interface IProp {
    active?: boolean;
    activeColor?: string;
    defaultColor?: string;
}

const IconPartition = (props: IProp) => {
    const { active = false, activeColor, defaultColor } = props;
    const fill = active ? activeColor ?? '#638C1C' : defaultColor || '#474C55';
    return (
        <svg
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.75313 4.3273C5.69117 4.21956 5.69117 4.08832 5.75313 3.98095L7.69954 0.609046C7.82317 0.394641 8.17577 0.394641 8.29938 0.609046L10.2461 3.98095C10.3081 4.08829 10.3081 4.21956 10.2461 4.3273C10.1841 4.43465 10.0698 4.50048 9.94615 4.50048H6.05308C5.92939 4.50048 5.81514 4.43465 5.75313 4.3273ZM3.32358 9.22793H12.676C12.8 9.22793 12.9143 9.1621 12.976 9.05476C13.0379 8.94741 13.0379 8.81575 12.976 8.7084L11.2776 5.76667C11.2156 5.65933 11.1013 5.5935 10.9776 5.5935H5.02272C4.89909 5.5935 4.78442 5.65933 4.72277 5.76667L3.024 8.7084C2.96201 8.81575 2.96201 8.94741 3.024 9.05476C3.08534 9.16174 3.19964 9.22793 3.32358 9.22793ZM15.9534 13.866L14.0067 10.4944C13.9447 10.3871 13.8304 10.3212 13.7067 10.3212H2.29292C2.16928 10.3212 2.05461 10.3871 1.99297 10.4944L0.0462461 13.866C-0.0157409 13.9734 -0.0157409 14.105 0.0462461 14.2124C0.108233 14.3197 0.222508 14.3855 0.346194 14.3855H15.6535C15.7771 14.3855 15.8918 14.3197 15.9534 14.2124C16.015 14.105 16.0154 13.9731 15.9534 13.866Z"
                fill={fill}
            />
        </svg>
    );
};

export default IconPartition;
